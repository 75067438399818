import LOGO_IMG from "../src/Components/Images/logo3.png";

export class B2BConstant {
  readonly BASE_URL = "https://kiosk-backend.nutritap.in/api/";
  // readonly BASE_URL = "http://localhost:8080/api/";
  readonly USER_TOKEN = "USER_TOKEN";
  readonly USERNAME = "USERNAME";
  readonly USER_ID = "USER_ID";
  readonly CATALOGUE_ID = "CATALOGUE_ID";
  readonly EMAIL = "EMAIL";
  readonly ROLE = "ROLE";
  readonly PAGES = "PAGES";
  readonly PERMISSION = "PERMISSION";
  readonly PO_CLIENT = "PO_CLIENT";
  readonly CITY = "CITY";
  readonly ORGANIZATION = "ORGANIZATION";
  readonly MOBILE_NUMBER = "MOBILE_NUMBER";
  readonly CART = "CART";
  readonly NO_IMAGE = "No Image Found";
  readonly REGIONS = ["North", "South", "East", "West"];
  readonly COUNTRY = ["India"];
  readonly TEMP_PASS = "TEMP_PASS";
  readonly SAVE = "SAVE";
  readonly RAZORPAY_ORDER_ID = "razorpayOrderId";
  readonly TRANSACTION_ORDER_ID = "transactionOrderId";
  readonly LOGO = LOGO_IMG;
  readonly EXISTING_CLIENTS = [25,50,58,59,79,82,83,84,85,86,87,88,89,92,94];
  readonly DIWALI_BANNER = "https://nutritap.s3.ap-south-1.amazonaws.com/kiosk/Banner/Diwali+Banner+2024.png";


}

export const B2BConstants = new B2BConstant();
