import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

import { forwardRef, useState } from "react";
import B2BAlertProps from "../../Model/Props/B2BAlertProps";
import React from "react";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant={"filled"} {...props} />;
});

const Alerts = (props: B2BAlertProps) => {
  const [open, setOpen] = useState<boolean>(true);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={open}
        autoHideDuration={props.duration ?? 2000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: props.vertical,
          horizontal: props.horizontal,
        }}
      >
        <Alert
          onClose={handleClose}
          severity={props.severity}
          sx={{ width: "100%" }}
        >
          {props.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default Alerts;
