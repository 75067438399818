import { PurchaseOrderDto } from "../../../Model/PurchaseOrderModel";
import { PurchaseOrderService } from "../../../Services/PurchaseOrderService";
import { DateUtil } from "../../../Services/DateService/dateUtil";
import { PurchaseOrderStore } from "../../../Store";
import { OrderStatusEnum } from "../../../Model/Enums/OrderStatusEnums";
import { CheckService } from "../../../Services/checkValidationService";
import { createOrderStatusElement } from "../../../utils/StatusBox";

const NewPurchaseOrderRow = (props: { poDetails: PurchaseOrderDto }) => {
  const purchaseOrder = new PurchaseOrderService();
  const date = new DateUtil();

  const downloadPurchaseOrderHandler = (poId?: number, doc?: boolean) => {
    purchaseOrder.downloadExistingPurchaseOrder(poId, doc);
  };

  const approvePoHandler = (poId: number, isApprove: boolean) => {
    purchaseOrder.approveOrRejectPO(poId, isApprove);
  };
  const rejectHandler = (poId: number, isRejected: boolean) => {
    purchaseOrder.approveOrRejectPO(
      poId,
      isRejected,
      PurchaseOrderStore.getRemark()
    );
  };
  //@ts-ignore
  const orderStatus = createOrderStatusElement(props.poDetails.status);

  return (
    <tr>
      <td>
        <div className="d-flex align-items-center justify-content-start">
          <div className="order_user_name">
            <p className="fs-14 fw-500 mb-0">{props.poDetails.poNo}</p>
          </div>
        </div>
      </td>
      <td> {date.getDateFromEpoch(props.poDetails.poDate)}</td>
      <td>{date.getDateFromEpoch(props.poDetails.expDate)}</td>
      <td>{props.poDetails.createdByName}</td>
      <td>{`₹ ${props.poDetails.totalAmount?.toLocaleString("en-in")}`}</td>
      <td>{`₹ ${props.poDetails.amountGstExcl?.toLocaleString("en-in")}`}</td>
      {CheckService.isAdmin() &&
      props.poDetails.status === OrderStatusEnum.APPROVAL_PENDING ? (
        <td>
          <button
            className="btn btn-success p-1 m-1 rounded"
            onClick={() => {
              if (props.poDetails.id) {
                approvePoHandler(props.poDetails.id, true);
              }
            }}
          >
            Approve
          </button>
          <button
            className="btn btn-danger p-1 m-1 rounded"
            onClick={() => {
              if (props.poDetails.id) {
                rejectHandler(props.poDetails.id, false);
              }
            }}
          >
            Reject
          </button>
        </td>
      ) : (
       <td>{orderStatus}</td> 
      )}
      {/* <td>{props.poDetails.status}</td> */}
      <td onClick={() => downloadPurchaseOrderHandler(props.poDetails.id)}>
        <div className="download_icon">
          <i className="bi bi-download"></i>
        </div>
      </td>
      <td
        onClick={() => downloadPurchaseOrderHandler(props.poDetails.id, true)}
      >
        <div className="download_icon">
          <i className="bi-cloud-download"></i>
        </div>
      </td>
    </tr>
  );
};

export default NewPurchaseOrderRow;
