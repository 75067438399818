export type ButtonType = 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
export interface DialogButton {
    text: string;
    color: ButtonType;
    onClickHandler: any;
}

export interface DialogProps {
    title: string;
    open: boolean;
    content: React.ReactElement<any, any> | string;
    buttons: DialogButton[];
    hidedisagreeText?: boolean;
    hideagreeText?: boolean;
}

export enum ColorEnum {
    INHERIT = 'inherit',
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    SUCCESS = 'success',
    ERROR = 'error',
    INFO = 'info',
    WARNING = 'warning',
}