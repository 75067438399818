import React, { useEffect, useState } from "react";
import "./AddMembers.css";
import { HashLink } from "react-router-hash-link";
import NewNavbar from "../../../Components/NavBar/NewNavbar";
import NewFooter from "../../PublicView/NewFooter/NewFooter";
import {
  ApiCallResponse,
  PageEventTypeEnum,
} from "../../../Model/ApiCallResponse";
import { ApiCallState } from "../../../Model/Enums/ApiCallState";
import { apiLoadingSubject, ProfileStore } from "../../../Store";
import { messageConstants } from "../../../StringConstants";
import { errorDialogHandler } from "../../../utils/ErrorDialogHandler";
import {
  UserDto,
  UserDtoInitialValue,
  UserRegisterModel,
} from "../../../Model/UserModel";
import { Subscription } from "rxjs";
import { RoleTypeEnum } from "../../../Model/Enums/RoleTypeEnum";
import { ClientInfoService } from "../../../Services/ClientInfoService";
import { useNavigate } from "react-router-dom";
import { userSubUserMappingService } from "../../../Services/UserSubUserMappingService";
import { ValidationService } from "../../../Services/ValidationService";
import { showWarningToast } from "../../../utils/Toastify/ToastifyHandler";

const AddMembers = () => {
  const clientInfoService = new ClientInfoService();
  const userSubUserMapping = new userSubUserMappingService();

  const [loggedInUserDetails, setLoggedInUserDetails] =
    useState<UserDto>(UserDtoInitialValue);

  const [employeeName, setEmployeeName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNo, setPhoneNo] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const navigate = useNavigate();
  // const navigateToLogin = () => {
  //   navigate(`/${PageTypeEnum.LOGIN}`);
  // };

  const navigateToViewAllClients = () => {
    navigate(`/newMyAccount`);
  };

  useEffect(() => {
    let loggedInClientDetailsSubscriber: Subscription;
    let userMappingSubscriber: Subscription;

    clientInfoService.getLoggedInClientInfo();

    loggedInClientDetailsSubscriber = apiLoadingSubject.subscribe(
      (response: ApiCallResponse) => {
        if (PageEventTypeEnum.PROFILE === response.eventType) {
          switch (response.apiCallState) {
            case ApiCallState.LOADING:
              break;
            case ApiCallState.LOADING_SUCCESS:
              setLoggedInUserDetails(ProfileStore.getDetails());
              break;
            case ApiCallState.LOADING_ERROR:
              errorDialogHandler(messageConstants.REFRESH);
              break;
          }
        }
      }
    );

    userMappingSubscriber = apiLoadingSubject.subscribe((response) => {
      if (response.eventType === PageEventTypeEnum.USER_REGISTER) {
        switch (response.apiCallState) {
          case ApiCallState.LOADING:
            break;
          case ApiCallState.LOADING_SUCCESS:

            userSubUserMapping.userSubUserMapping(
              ProfileStore.getDetails().id,
              response.data.id
            );

            break;
          case ApiCallState.LOADING_ERROR:
            break;
        }
      }
      if (response.eventType === PageEventTypeEnum.SUB_USER_MAPPING_ADD) {
        switch (response.apiCallState) {
          case ApiCallState.LOADING:
            break;
          case ApiCallState.LOADING_SUCCESS:
            navigateToViewAllClients();
            break;
          case ApiCallState.LOADING_ERROR:
            break;
        }
      }
    });

    return () => {
      if (loggedInClientDetailsSubscriber) {
        loggedInClientDetailsSubscriber.unsubscribe();
      }
      if (userMappingSubscriber) {
        userMappingSubscriber.unsubscribe();
      }
    };
  }, []);

  const raiseAlert = (message: string) => {
    showWarningToast(message);
  };

  const textChangeHandlers = {
    EmpName: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value && event.target.value.trim() !== "")
        setEmployeeName(event.target.value.trim());
    },
    Email: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value && event.target.value.trim() !== "")
        setEmail(event.target.value.trim());
    },
    Phone: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value && event.target.value.trim() !== "")
        setPhoneNo(event.target.value.trim());
    },
    Password: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value && event.target.value.trim() !== "")
        setPassword(event.target.value.trim());
    },
    ConfirmPassword: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value && event.target.value.trim() !== "")
        setConfirmPassword(event.target.value.trim());
    },
  };

  const addNewMemberHandler = () => {
    if (!ValidationService.validateFieldEmpty(employeeName)) {
      raiseAlert(messageConstants.EMPLOYEE_NOT_EMPTY);
    } else if (
      !ValidationService.validatePhoneNo(parseInt(phoneNo).toString())
    ) {
      raiseAlert(messageConstants.PHONE_NO_INVALID);
    } else if (!ValidationService.validateEmail(email)) {
      raiseAlert(messageConstants.EMAIL_INVALID);
    } else if (password === "" && confirmPassword === "") {
      raiseAlert(messageConstants.PASSWORD_IS_REQUIRED);
    } else if (password !== confirmPassword) {
      raiseAlert(messageConstants.PASSWORD_NOT_MATCHED);
    }

    const clientRegisterRequestDto: UserRegisterModel = {
      name: loggedInUserDetails?.name,
      ntSpoc: 1,
      userRoles: RoleTypeEnum.ADMIN,
      active: true,
      gst: loggedInUserDetails?.gst,
      clSpocCompany: employeeName,
      email: email,
      mobile: phoneNo,
      password: password,
      locationDto: {
        name: loggedInUserDetails?.name,
        address: loggedInUserDetails?.address,
        stateName: loggedInUserDetails?.state,
        region: "",
        pincode: loggedInUserDetails?.pincode,
        country: "India",
        city: loggedInUserDetails?.city,
      },
      billingAddress: `${loggedInUserDetails.address};${loggedInUserDetails.city};${loggedInUserDetails.state};${loggedInUserDetails.pincode}`,
      shippingAddress: `${loggedInUserDetails.address};${loggedInUserDetails.city};${loggedInUserDetails.state};${loggedInUserDetails.pincode}`,
    };

    clientInfoService.register(clientRegisterRequestDto, navigateToViewAllClients);
  };

  return (
    <>
      <NewNavbar
        signIn={false}
        account={false}
        cart={false}
        searchBar={false}
        admin={false}
        getFilteredProductsFromNav={() => {}}
      />
      <div className="container">
        <div className="page_path border-bottom">
          <p>
            <HashLink to={"/"}>Home</HashLink>
            <span>
              <i className="bi bi-chevron-right"></i>
            </span>
            <HashLink to={"/newMyAccount"}>My Account</HashLink>
            <span>
              <i className="bi bi-chevron-right"></i>
            </span>
            Add Member
          </p>
        </div>

        <div className="forms_wrpapper paddingBottom paddingTop text-center">
          <div className="default_tabs paddingBottom">
            <ul
              className="nav nav-pills mb-2 mb-md-3 justify-content-center"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active fw-bold"
                  id="pills-addMember-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-addMember"
                  type="button"
                  role="tab"
                  aria-controls="pills-addMember"
                  aria-selected="true"
                >
                  Add Member
                </button>
              </li>
            </ul>

            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-addMember"
                role="tabpanel"
                aria-labelledby="pills-addMember-tab"
              >
                <p className="grey_clr mobile_fs mb-0">
                  Invite new member to the team.
                </p>
                <div
                  className="tab-pane"
                  id="pills-Registration"
                  role="tabpanel"
                  aria-labelledby="pills-Registration-tab"
                >
                  <form className="default_form mt-3 mt-md-4" action="">
                    <div className="row gx-3">
                      <div className="col-sm-6">
                        <input
                          className="w-100"
                          type="text"
                          placeholder="Name of Employee *"
                          required
                          onChange={textChangeHandlers.EmpName}
                        />
                      </div>

                      <div className="col-sm-6">
                        <input
                          className="w-100"
                          type="email"
                          placeholder="E-mail Address *"
                          required
                          onChange={textChangeHandlers.Email}
                        />
                      </div>
                      <div className="col-sm-6">
                        <input
                          className="w-100"
                          type="tel"
                          placeholder="Phone Number *"
                          required
                          pattern="/^+91(7\d|8\d|9\d)\d{9}$/"
                          maxLength={10}
                          size={10}
                          // min={1000000000}
                          // max={9999999999}
                          onChange={textChangeHandlers.Phone}
                        />
                      </div>

                      <div className="col-sm-6">
                        <input
                          className="w-100"
                          type="password"
                          placeholder="Password *"
                          required
                          onChange={textChangeHandlers.Password}
                        />
                      </div>
                      <div className="col-sm-6">
                        <input
                          className="w-100"
                          type="password"
                          placeholder="Confirm Password *"
                          required
                          onChange={textChangeHandlers.ConfirmPassword}
                        />
                      </div>
                    </div>
                  </form>

                  <div className="button_wrapper">
                    <HashLink
                      to="/newMyAccount"
                      className="anchor_link fw-500 fs-16"
                    >
                      Cancel
                    </HashLink>
                    <button
                      className="theme_btn ms-3"
                      type="submit"
                      name="submit"
                      onClick={addNewMemberHandler}
                    >
                      Add Member
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewFooter />
    </>
  );
};

export default AddMembers;
