import { ProductEntity } from "./CatalogueModel";
import { RoleTypeEnum } from "./Enums/RoleTypeEnum";

// export interface CartModel {
//   productId: number;
//   productName: string;
//   photo: string;
//   cost: number;
//   brandName: string;
//   qty: number;
// }

export interface CartItem {
  gst: number;
  product: ProductEntity;
  discountedPrice: number;
  applicableDiscount: number;
  qty: number;
  avlQty?: number;
}

export const initialCartItemDto: CartItem = {
  product: {
    pid: 0,
    photo: "",
    pname: "",
    cost: 0,
  },
  discountedPrice: 0,
  applicableDiscount: 0,
  qty: 0,
  gst: 0,
  avlQty: 0,
};

export const getInitialCheckoutProducts = (): CartItem => {
  return JSON.parse(JSON.stringify(initialCartItemDto));
};

export interface ProductsInCartProps {
  productsList: Array<CartItem>;
  updateCart: Function;
  currentCart: Map<string, CartItem>;
  setUpdatedProducts: Function;
}

export interface PaymentTypeProps {
  itemCount: number;
  paymentTotal: number;
  addedProducts: CartItem[];
  placeOrderHandler: Function;
  isOrderPlaced: boolean;
  purchaseNoChangeHandler: Function;
}

export interface LocationRequestDto {
  isActive: boolean;
  coordinates?: string;
  latitude?: number;
  longitude?: number;
  region?: string;
  stateName: string;
  country: string;
  active: boolean;
  locationId: number;
  id: number;
  role: RoleTypeEnum;
  userId?: number;
  address: string;
  state: string;
  city: string;
  pincode: string;
  name: string;
  userName: string;
  userMobile: string;
}

const initialLocationModelDto: LocationRequestDto = {
  id: 0,
  address: "",
  coordinates: "",
  latitude: 0,
  longitude: 0,
  name: "",
  region: "North",
  state: "",
  stateName: "",
  pincode: "",
  city: "",
  country: "",
  active: true,
  isActive: true,
  userId: 0,
  locationId: 0,
  role: RoleTypeEnum.ADMIN,
  userName: "",
  userMobile: ""
};

export const getInitialLocationModelDto = (): LocationRequestDto => {
  return JSON.parse(JSON.stringify(initialLocationModelDto));
};

export interface AddEditLocationDialogProps {
  location?: LocationRequestDto;
  isEdit: boolean;
  service: CallableFunction;
}

export interface OrderExcelDto {
  pid: number;
  pCode: string;
  category?: string;
  subCategory?: string;
  brand?:string ;
  gstExclAmount?: number;
  productCode?:number;
  pname: string;
  gst: number;
  discount: number;
  qty: number;
  photo:string;
  cost: number;
  avlQty: number;
  
}
export const transformData = (data: any[]): OrderExcelDto[] => {
  return data.map(item => ({
    pid:item["Pid"],
    pCode:item["pCode"],
    pname: item["Product Name"],
    photo: item["Photo"],
    category: item["Category"],
    subCategory: item["SubCategory"],
    brand: item["Brand"],
    gst: item["GST (%)"] || 0,
    discount: item["Discount"] || 0 ,
    gstExclAmount: item["GST Excl. Amount"] || 0,
    productCode: item["Product Code"],
    qty: item["Qty"],
    cost: item["MRP"] || 0,
    avlQty: item["Available Qty"] || 0
  }));
};