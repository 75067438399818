import { PageEventTypeEnum } from "../Model/ApiCallResponse";
import { ApiResponseStatusEnum } from "../Model/Enums/ApiCallState";
import { RoleTypeEnum } from "../Model/Enums/RoleTypeEnum";
import { ApiResponseModel } from "../Model/NutritapResponseModel";
import { messageConstants } from "../StringConstants";
import { errorDialogHandler } from "../utils/ErrorDialogHandler";
import { ApiTypeEnum, SubPathEnum } from "../Model/Enums/ApiConstantEnum";
import { ApiUrlBuilder } from "../utils/Stringbuilder";
import { apiCaller } from "./ApiCallService";
import { PublishEventService } from "./PublishEventService";
import { showErrorToast, showSuccessToast } from "../utils/Toastify/ToastifyHandler";

export class userSubUserMappingService {
    private publishEventService = new PublishEventService();

    userSubUserMapping(adminUserId: number, clientUserId: number) {

        // const url = `${B2BConstants.BASE_URL}${ApiConstants.USER.USER_SUB_USER_MAPPING}`
        const userSubUserMappingUrl = new ApiUrlBuilder(ApiTypeEnum.USER_SUB_USER_MAPPING)
        userSubUserMappingUrl.addSubPath(SubPathEnum.SAVE)
        const url = userSubUserMappingUrl.buildURL()
        const body = JSON.stringify({
            user: adminUserId,
            subUser: clientUserId,
            active: true,
            type: RoleTypeEnum.CLIENT
        })
        this.publishEventService.loading(PageEventTypeEnum.SUB_USER_MAPPING_ADD)
        apiCaller.postResponseToServer(url, body)
            .then((res: ApiResponseModel) => {
                if (!res) {
                    return errorDialogHandler(messageConstants.FAILED);
                }
                if (res.success || res.status === ApiResponseStatusEnum.SUCCESS) {
                    showSuccessToast(messageConstants.CLIENT_ADDED);
                    this.publishEventService.loadingSuccess(PageEventTypeEnum.SUB_USER_MAPPING_ADD)
                }
                else if (res.status === ApiResponseStatusEnum.FAILURE || parseInt(res.status) === 400) {
                    this.publishEventService.loadingError(PageEventTypeEnum.SUB_USER_MAPPING_ADD)
                    showErrorToast(res.message);
                }
            })
    }
}