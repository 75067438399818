import { Delete } from "@mui/icons-material";
import CheckCircleOutlineSharpIcon from "@mui/icons-material/CheckCircleOutlineSharp";
import Edit from "@mui/icons-material/Edit";
import * as React from "react";
import { useState } from "react";
import { OrderStatusEnum } from "../../../Model/Enums/OrderStatusEnums";
import { Item, OrderDto, OrderEntity } from "../../../Model/OrderModel";
import { OrderService } from "../../../Services/OrderService";
import { messageConstants } from "../../../StringConstants";
import AddNewProductDialogBox from "./AddNewProductDialog";
import TrackOrderStatus from "./TrackOrderStatus";
import { CartItem } from "../../../Model/CartModel";
import {
  PaymentStatuEnum,
  PaymentTypeEnum,
} from "../../../Model/Enums/PaymentTypeEnum";
import { OrderItemsEntity } from "../../../Model/OrderItems";
import { CheckService } from "../../../Services/checkValidationService";
import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import '../../Admin/InProcessOrder/InProcessOrder.css';
import { showWarningToast } from "../../../utils/Toastify/ToastifyHandler";
import { CartStore } from "../../../Store";

interface props {
  orderDetail: OrderDto;
  Address: string;
  orderId: string;
  email: string;
  billingAmount: number;
}

const CompleteOrderDetail = ({
  orderDetail,
  Address,
  email,
  orderId,
  billingAmount,
}: props) => {
  const orderService = new OrderService();
  const [openDropdown, setOpenDropdown] = useState<number | null>(null);
  const [existingProduct, setExistingProduct] = useState(
    orderDetail?.orderItems
  );
  const [isEdit, setIsEdit] = useState<number | null>(null);
  const [newlyAddedProducts, setNewAddedProducts] = useState<
    Map<string, CartItem>
  >(CartStore.getCart());

  const toggleEditIcon = (index: number) => {
    setOpenDropdown((prevState) => (prevState === index ? null : index));
    setIsEdit((prevState) => (prevState === index ? null : index));
  };

  const UpdateOrderItem = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let quantity = Number(e.target.value);
    if (quantity > 10000) {
      showWarningToast(messageConstants.CANNOT_EXCEED_MAX_LIMIT(10000));
      return;
    } else if (quantity <= 0) {
      showWarningToast(messageConstants.CANNOT_EXCEED_MIN_QUANTITY);
    } else {
      const updatedProducts = [...existingProduct];
      updatedProducts[index] = {
        ...updatedProducts[index],
        qty: quantity,
      };
      setExistingProduct(updatedProducts);
    }
  };

  const deleteOrder = (index: number) => {
    const deletedProducts = [...existingProduct];
    deletedProducts[index] = {
      ...deletedProducts[index],
      qty: 0,
    };
    setExistingProduct(deletedProducts);
  };
  const addNewProductsHandler = (productId: string, newCartItem: CartItem) => {
    newlyAddedProducts.set(productId, newCartItem);
    setNewAddedProducts(new Map(newlyAddedProducts));
  };
  const createRequestOrderEditDto = (
    orderDetails: OrderDto,
    orderItems: Array<Item>
  ) => {
    let newOrder: OrderEntity = {
      ...orderDetails,
      orderItems: orderItems.reduce((itemsList, item, index) => {
        if (item && orderDetails?.orderItems[index]?.qty !== item?.qty) {
          let newOrderItem: OrderItemsEntity = {
            ...item,
            product: item.product.pid,
            orderStatus: OrderStatusEnum.IN_TRANSIT,
            gst: 0,
          };
          itemsList.push(newOrderItem);
        }
        return itemsList;
      }, new Array<OrderItemsEntity>()),
      client: orderDetails.client.id,
      orderStatus: CheckService.isAdmin()
        ? OrderStatusEnum.ORDER_APPROVED
        : OrderStatusEnum.APPROVAL_PENDING,
      approvalStatus: CheckService.isAdmin()
        ? OrderStatusEnum.APPROVED
        : OrderStatusEnum.APPROVAL_PENDING,
      deliveredDate: orderDetails.createdOn,
      paymentMode: PaymentTypeEnum.CASH,
      shippedTo: orderDetails.shippedTo,
    };

    const addedProducts: Array<OrderItemsEntity> = [];
    const products = Array.from(newlyAddedProducts.values());
    products.forEach((pro) => {
      addedProducts.push({
        product: pro.product.pid,
        productPrice: pro.discountedPrice,
        qty: pro.qty,
        gst: pro.gst,
        orderStatus: OrderStatusEnum.IN_TRANSIT,
        discount: pro.applicableDiscount,
        // remark: 'ADDED',
      });
    });
    newOrder.orderItems.push(...addedProducts);
    newOrder.remarks = "Editted";

    return newOrder;
  };
  const SaveUpdateQty = (order: OrderDto) => {
    const updateOrderDetail = createRequestOrderEditDto(order, existingProduct);
    orderService.updateOrder(updateOrderDetail);
  };

  return (
    <>
      <div className="row mt-5 ">
        <div className="col-3 border rounded p-4">
          <div className="fs-14 p-2">
            Address : <span className="text-muted">{Address}</span>
          </div>
          <div className="fs-14 p-2">
            Email: <span className="text-muted">{email}</span>{" "}
          </div>
          <div className="fs-14 p-2">
            order ID : <span className="text-muted">{orderId}</span>
          </div>
          <div className="fs-14 p-2">
            Billing Amount : <span className="text-muted">{Number(billingAmount.toFixed(2)).toLocaleString("en-IN")}</span>
          </div>
        </div>
        <TrackOrderStatus
          Orderstatus={orderDetail.orderStatus}
          Paymentstatus={orderDetail.paymentStatus}
          paymentMode={orderDetail.paymentMode}
        />
      </div>
      {CheckService.isAdmin()
        ? orderDetail.orderStatus === OrderStatusEnum.PENDING &&
          orderDetail.paymentStatus === PaymentStatuEnum.PENDING && (
            <div className="text-end pt-2 ">
              <button
                type="button"
                className="btn theme_btn mt-2"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalCenter"
              >
                Add New Product +
              </button>
              {
                <AddNewProductDialogBox
                  clientId={orderDetail.client.id}
                  orderDetail={orderDetail}
                  newlyAddedProduct={newlyAddedProducts}
                  addNewProductsHandler={addNewProductsHandler}
                  saveUpdateOrder={SaveUpdateQty}
                />
              }
            </div>
          )
        : (orderDetail.orderStatus === OrderStatusEnum.APPROVAL_PENDING ||
            orderDetail.orderStatus === OrderStatusEnum.REJECTED) &&
          orderDetail.paymentStatus === PaymentStatuEnum.PENDING && (
            <div className="text-end pt-2 ">
              <button
                type="button"
                className="btn theme_btn"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalCenter"
              >
                Add New Product +
              </button>
              {
                <AddNewProductDialogBox
                  clientId={orderDetail.client.id}
                  orderDetail={orderDetail}
                  newlyAddedProduct={newlyAddedProducts}
                  addNewProductsHandler={addNewProductsHandler}
                  saveUpdateOrder={SaveUpdateQty}
                />
              }
            </div>
          )}

      <div className="table-responsive  scrollbar py-2">
        <table className="default_table table table-hover">
          <thead>
            <tr className="green_clr">
              <th scope="col"> Product Name</th>
              <th scope="col"> Image</th>
              <th scope="col">Quantity</th>
              <th scope="col">MRP (₹)</th>
              <th scope="col">Discounted amount (₹) </th>
              <th scope="col">Excl Gst</th>
              <th scope="col">Total price(₹)</th>
              {CheckService.isAdmin()
                ? orderDetail.orderStatus === OrderStatusEnum.PENDING &&
                  orderDetail.paymentStatus === PaymentStatuEnum.PENDING && (
                    <th scope="col">Actions</th>
                  )
                : (orderDetail.orderStatus ===
                    OrderStatusEnum.APPROVAL_PENDING ||
                    orderDetail.orderStatus === OrderStatusEnum.REJECTED) &&
                  orderDetail.paymentStatus === PaymentStatuEnum.PENDING && (
                    <th scope="col">Actions</th>
                  )}
            </tr>
          </thead>
          <tbody>
            {orderDetail?.orderItems?.map((item, index) => {
              const discountedProductPrice: number = Number(
                (
                  item.productPrice *
                  (1 - (!isNaN(item.discount) ? item.discount : 0) / 100)
                ).toFixed(2)
              );
              const GSTAmount = (item.productPrice * item.gst) / 100;
              return (
                <tr>
                  <td className="fs-14">{item.product.pname}</td>
                  <td className="fs-14">
                    {" "}
                    <img
                      className="product-img"
                      src={item.product.photo}
                      alt=""
                    />
                  </td>
                  {openDropdown !== index ? (
                    <td className="fs-14">{item.qty}</td>
                  ) : (
                    <td>
                      <input
                        className="add-to-cart-input text-center"
                        type="number"
                        min={0}
                        max={10000}
                        value={existingProduct[index].qty}
                        onChange={(e) => UpdateOrderItem(index, e)}
                      />
                    </td>
                  )}
                  <td className="fs-14">{item.productPrice}</td>
                  <td className="fs-14">
                    {Number(
                      (
                        item.productPrice *
                        (1 - (!isNaN(item.discount) ? item.discount : 0) / 100)
                      ).toFixed(2)
                    ).toLocaleString("en-IN")}
                  </td>
                  <td className="excl-gst-price">
                    {Number(
                      (
                        item.productPrice *
                          (1 -
                            (!isNaN(item.discount) ? item.discount : 0) / 100) -
                        GSTAmount
                      ).toFixed(2)
                    ).toLocaleString("en-IN")}
                  </td>
                  <td className="fs-14">
                    {(discountedProductPrice * item.qty).toLocaleString(
                      "en-IN"
                    )}
                  </td>
                  {CheckService.isAdmin()
                    ? orderDetail.orderStatus === OrderStatusEnum.PENDING &&
                      orderDetail.paymentStatus ===
                        PaymentStatuEnum.PENDING && (
                        <>
                          {isEdit !== index ? (
                            <>
                              <td>
                                <span
                                  role="button"
                                  className="fs-14 p-4 "
                                  onClick={() => {
                                    toggleEditIcon(index);
                                  }}
                                >
                                  <Edit />
                                </span>

                                <span
                                  role="button"
                                  className="fs-14"
                                  onClick={() => {
                                    deleteOrder(index);
                                    toggleEditIcon(index);
                                  }}
                                >
                                  <Delete />
                                </span>
                              </td>
                            </>
                          ) : (
                            <>
                              <td className="fs-14">
                                <span
                                  role="button"
                                  className="fs-14 p-4"
                                  onClick={() => {
                                    toggleEditIcon(index);
                                    SaveUpdateQty(orderDetail);
                                  }}
                                >
                                  <CheckCircleOutlineSharpIcon />
                                </span>

                                <span
                                  role="button"
                                  onClick={() => toggleEditIcon(index)}
                                >
                                  <HighlightOffSharpIcon />
                                </span>
                              </td>
                            </>
                          )}
                        </>
                      )
                    : (orderDetail.orderStatus ===
                        OrderStatusEnum.APPROVAL_PENDING ||
                        orderDetail.orderStatus === OrderStatusEnum.REJECTED) &&
                      orderDetail.paymentStatus ===
                        PaymentStatuEnum.PENDING && (
                        <>
                          {isEdit !== index ? (
                            <>
                              <td>
                                <span
                                  role="button"
                                  className="fs-14 p-4 "
                                  onClick={() => {
                                    toggleEditIcon(index);
                                  }}
                                >
                                  <Edit />
                                </span>

                                <span
                                  role="button"
                                  className="fs-14"
                                  onClick={() => {
                                    deleteOrder(index);
                                    toggleEditIcon(index);
                                  }}
                                >
                                  <Delete />
                                </span>
                              </td>
                            </>
                          ) : (
                            <>
                              <td className="fs-14">
                                <span
                                  role="button"
                                  className="fs-14 p-4"
                                  onClick={() => {
                                    toggleEditIcon(index);
                                    SaveUpdateQty(orderDetail);
                                  }}
                                >
                                  <CheckCircleOutlineSharpIcon />
                                </span>

                                <span
                                  role="button"
                                  onClick={() => toggleEditIcon(index)}
                                >
                                  <HighlightOffSharpIcon />
                                </span>
                              </td>
                            </>
                          )}
                        </>
                      )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default CompleteOrderDetail;
