import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import {
  ApiCallResponse,
  PageEventTypeEnum,
} from "../../../Model/ApiCallResponse";
import { LocationRequestDto } from "../../../Model/CartModel";
import { ApiCallState } from "../../../Model/Enums/ApiCallState";
import { UserLocationMappingService } from "../../../Services/UserLocationMappingService";
import {
  PrincipleStore,
  apiLoadingSubject,
  LocationStore,
} from "../../../Store";
import { messageConstants } from "../../../StringConstants";
import NewCategoryLoader from "../../LoadingComponent/NewCategoryLoader";
import AddNewAddressDialogBox from "./AddNewAddressDialogBox";
import { LocationService } from "../../../Services/LocationService";
import "./NewCheckOut.css";
import { showErrorToast } from "../../../utils/Toastify/ToastifyHandler";

const ShippingAddress = () => {
  const [userAddressList, setUserAddressList] = useState<LocationRequestDto[]>(
    new Array<LocationRequestDto>()
  );
  const [isAddressLoading, setIsAddressLoading] = useState(true);
  // const [selectedAddress, setSelectedAddress] = useState<LocationRequestDto>();
  const [addressToShow, setAddressToShow] = useState(4);
  const [selectedAddressId, setSelectedAddressId] = useState<string>("");
  const [isHover, setIsHover] = useState<any>(null);

  const locationService = new LocationService();

  useEffect(() => {
    // let locationDetailsSubscriber: Subscription;
    const userLocationMappingService = new UserLocationMappingService();
    const userId = PrincipleStore.getUserId();
    let id = userId;
    if (id) {
      userLocationMappingService.getAllLocation(id);
    }

    let locationDetailsSubscriber: Subscription = apiLoadingSubject.subscribe(
      (response: ApiCallResponse) => {
        if (PageEventTypeEnum.LOCATIONS === response.eventType) {
          switch (response.apiCallState) {
            case ApiCallState.LOADING:
              setIsAddressLoading(true);
              break;
            case ApiCallState.LOADING_SUCCESS:
              LocationStore.getAllLocation();
              setUserAddressList(LocationStore.getAllLocation());
              setIsAddressLoading(false);
              break;
            case ApiCallState.LOADING_ERROR:
              showErrorToast(messageConstants.REFRESH);
              setIsAddressLoading(false);
              break;
            case ApiCallState.RELOAD:
              if (id) {
                userLocationMappingService.getAllLocation(id);
              }
              break;
          }
        }
      }
    );

    return () => {
      if (locationDetailsSubscriber) {
        locationDetailsSubscriber.unsubscribe();
      }
    };
  }, []);

  // const addressExtracting = () => {
  //   const result = userAddressList.filter((item) => {
  //     return item.id === Number(selectedAddressId);
  //   });
  //   setSelectedAddress(result[0]);
  // };
  // useEffect(() => {
  //   addressExtracting();
  // }, [selectedAddressId]);

  const handleAddressChange = (event: any) => {
    setSelectedAddressId(event.target.value);
  };

  const showmore = () => {
    // Expand Address
    setAddressToShow(userAddressList.length);
  };

  const showless = () => {
    // Collapse Address
    setAddressToShow(4);
  };

  return (
    <>
      <div className="add-new-address-btn">
        {
          <AddNewAddressDialogBox
            isEdit={false}
            service={locationService.addSingleLocation}
          />
        }
      </div>
      <div className="address_wrapper_shipping h-100">
        <label className="shipping-address">
          <div className="shipping_add">
            <p className="mb-1 fw-bold">Shipping Address</p>
            <div className="border-bottom"></div>
          </div>
          {!isAddressLoading ? (
            userAddressList.slice(0, addressToShow).map((item, index) => {
              return (
                <div
                  className="address_wrap border-bottom"
                  key={index}
                  onMouseEnter={() => {
                    setIsHover(index);
                  }}
                  onMouseLeave={() => setIsHover(null)}
                >
                  <label className="label_wrapper">
                    <input
                      type="radio"
                      id={`option-${index}`}
                      value={item.id}
                      checked={selectedAddressId === item.id.toString()}
                      onChange={handleAddressChange}
                      hidden
                    />
                    <span className="fw-500">
                      {`${item.userName} ${"\u00A0"} ${item.userMobile}`}
                    </span>
                    {isHover === index && (
                      <span className="edit_delete_options">
                        <i
                          className="bi bi-pencil-fill"
                          data-bs-toggle="modal"
                          data-bs-target={"#staticBackdrop_edit_" + item.id}
                        ></i>
                        <i className="bi bi-trash"></i>
                      </span>
                    )}

                    <br />
                    <div>
                      {` ${item.name}, ${item.address}, ${item.city}, ${item.stateName}, ${item.pincode}`}
                    </div>
                  </label>
                  <AddNewAddressDialogBox
                    location={item}
                    isEdit={true}
                    service={locationService.editLocation}
                  />
                </div>
              );
            })
          ) : (
            <NewCategoryLoader />
          )}
        </label>

        {userAddressList.length > 4 ? (
          addressToShow < 5 ? (
            <div className="show_btn">
              <button className="anchor_link" onClick={showmore}>
                Show More <i className="bi bi-chevron-down fw-500"></i>
              </button>
            </div>
          ) : (
            <div className="show_btn">
              <button
                className="anchor_link"
                style={{ marginBottom: "15px" }}
                onClick={showless}
              >
                Show Less <i className="bi bi-chevron-up fw-500"></i>
              </button>
            </div>
          )
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default ShippingAddress;
