import React from "react";
import NewFooter from "../NewFooter/NewFooter";
import { HashLink } from "react-router-hash-link";
import NewNavbar from "../../../Components/NavBar/NewNavbar";

const RefundPolicy = () => {
  return (
    <>
      <div className="container">
        <NewNavbar
          signIn={false}
          account={false}
          cart={false}
          searchBar={false}
          admin={false}
          getFilteredProductsFromNav={() => {}}
        />
        <div className="inner_banner terms_bg">
          <div className="page_path mt-0">
            <p>
              <HashLink to="/">Home</HashLink>
              <span>
                <i className="bi bi-chevron-right"></i>
              </span>{" "}
              Refund & Cancellation Policy
            </p>
          </div>
          <div className="heading_content_wrap text-white text-center">
            <h1 className="fw-500">Refund & Cancellation Policy</h1>
            <p className="mb-0 description">
              Let us introduce the furnob to you briefly, so you will have a
              better understanding of our quality.
            </p>
          </div>
        </div>

        <div className="paddingTop paddingBottom">
          <div className="mb-3 mb-md-4">
            <h5 className="mobile_heading_fs">
              1. Refund and Cancellation Policy
            </h5>
            <p className="mb-0 mobile_fs grey_clr">
              Our focus is complete customer satisfaction. In the event, if you
              are displeased with the services provided, we will refund back the
              money, provided the reasons are genuine and proved after
              investigation. Please read the fine prints of each deal before
              buying it, it provides all the details about the services or the
              product you purchase. In case of dissatisfaction from our
              services, clients have the liberty to cancel their projects and
              request a refund from us. Our Policy for the cancellation and
              refund will be as follows:
            </p>
          </div>
          <div className="mb-3 mb-md-4">
            <h5 className="mobile_heading_fs">2. Cancellation Policy</h5>
            <p className="mb-2 mobile_fs grey_clr">
              For Cancellations, please contact us by calling +91-7217647998 or
              by sending email to info@nutritap.in. Subscription would be cancelled within 30 days of receipt of
              request received to be removed from Nutritap app.
            </p>
          </div>

          <div className="mb-3 mb-md-4">
            <h5 className="mobile_heading_fs">3. Refund Policy</h5>
            <p className="mb-2 mobile_fs grey_clr">
              We will try our best to create the suitable design concepts for
              our clients. In case any client is not completely satisfied with
              our products we can provide a refund. If there is any issue in
              billing, then refund would be processed after checking the same
              within 24 hours if PayTm is used or 7 days if bank cards are used.
              If paid by credit card, refunds will be issued to the original
              credit card provided at the time of purchase and in case of
              payment gateway name payments refund will be made to the same
              account.
            </p>
          </div>
        </div>
      </div>
      <NewFooter />
    </>
  );
};

export default RefundPolicy;
