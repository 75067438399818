import { Bounce, ToastContainer, Zoom } from 'react-toastify'

const MountToastContainer = () => {
    return (
        <ToastContainer
            stacked
            position='bottom-right'
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
            transition={Bounce}
        />
    )
}

export default MountToastContainer
