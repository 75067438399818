import { B2BConstants } from "../B2BConstants";
import {
  ApiResponseModel,
  PrincipleResponse,
} from "../Model/NutritapResponseModel";
import { PrincipleStore } from "../Store";
import { apiCaller } from "./ApiCallService";
import { messageConstants } from "../StringConstants";
import { PageEventTypeEnum } from "../Model/ApiCallResponse";
import { ApiResponseStatusEnum } from "../Model/Enums/ApiCallState";
import { PublishEventService } from "./PublishEventService";
import { ApiUrlBuilder } from "../utils/Stringbuilder";
import { ApiTypeEnum, SubPathEnum } from "../Model/Enums/ApiConstantEnum";
import { showErrorToast, showSuccessToast } from "../utils/Toastify/ToastifyHandler";

export class UserService {
  private publishEvent = new PublishEventService();

  changePassword(
    oldPassword: string,
    newPassword: string,
    confirmPassword: string,
    navigateToLogin: CallableFunction
  ) {
    const trimmedOldPassword = oldPassword.trim();
    const trimmedNewPassword = newPassword.trim();
    const trimmedConfirmPassword = confirmPassword.trim();
    if (
      (!oldPassword && !newPassword) ||
      (trimmedOldPassword === "" && trimmedNewPassword === "")
    ) {
      showErrorToast(messageConstants.PASSWORD_NOT_EMPTY);
    } else if (trimmedOldPassword === trimmedNewPassword) {
      showErrorToast(messageConstants.SAME_PASSWORD);
    } else if (trimmedNewPassword !== trimmedConfirmPassword) {
      showErrorToast(messageConstants.CONFIRM_AND_NEW_PASSWORD);
    } else {
      const usersUrl = new ApiUrlBuilder(ApiTypeEnum.USERS);
      usersUrl.addSubPath(SubPathEnum.UPDATE_PASSWORD);
      // const url = `${B2BConstants.BASE_URL}${ApiConstants.USER.PASSWORD.UPDATE}`
      const url = usersUrl.buildURL();
      const body = JSON.stringify({
        oldPassword: oldPassword,
        newPassword: newPassword,
      });
      apiCaller
        .postResponseToServer(url, body)
        .then((res: ApiResponseModel) => {
          if (!res) {
            showErrorToast(messageConstants.FAILED);
          }
          if (res.success || res.status === ApiResponseStatusEnum.SUCCESS) {
            localStorage.removeItem(B2BConstants.TEMP_PASS);
            showSuccessToast(res.message);
            navigateToLogin();
          } else if (res.errors && res.errors.length > 0) {
            showErrorToast(res.errors[0]);
          } else {
            showErrorToast(res.message);
          }
        });
    }

  }
  resetPassword(email: string, emailVerfication?: CallableFunction) {
    if (!email || email === "") {
      showErrorToast(messageConstants.EMAIL_IS_REQUIRED);
    } else {
      const usersUrl = new ApiUrlBuilder(ApiTypeEnum.USERS);
      usersUrl.addSubPath(SubPathEnum.RESET_PASSWORD);
      const url = usersUrl.buildURL();
      // const url = `${B2BConstants.BASE_URL}${ApiConstants.USER.PASSWORD.RESET}`
      const body = JSON.stringify({
        email: email,
      });
      apiCaller
        .postResponseToServer(url, body)
        .then((res: ApiResponseModel) => {
          if (!res) {
            showErrorToast(messageConstants.FAILED);
          }
          if (res.success || res.status === ApiResponseStatusEnum.SUCCESS) {
            showSuccessToast(messageConstants.RESET_PASSWORD);
            if (emailVerfication) emailVerfication(true);
          } else {
            if (!res.errors && res.message !== "") {
              showErrorToast(res.message);
            } else if (res.errors[0]) {
              showErrorToast(res.errors[0]);
            } else {
              showErrorToast(res.message);
            }
          }
        });
    }
  }

  forcedLogin(
    userName: string,
    verificationCode: string,
    otpVerification: CallableFunction
  ) {
    // const url = `${B2BConstants.BASE_URL}${ApiConstants.USER.LOGIN}`
    const userUrl = new ApiUrlBuilder(ApiTypeEnum.USERS);
    userUrl.addSubPath(SubPathEnum.ADMIN_LOGIN);
    const url = userUrl.buildURL();
    const body = JSON.stringify({
      username: userName,
      password: verificationCode,
    });

    apiCaller.postResponseToServer(url, body).then((res) => {
      if (!res) {
        return;
      }
      const result: PrincipleResponse = res;

      if (result.success) {
        localStorage.setItem(B2BConstants.TEMP_PASS, verificationCode);
        PrincipleStore.setPrinciple(result);
        this.publishEvent.loadingSuccess(PageEventTypeEnum.ROLE);
        otpVerification(true);

        while (PrincipleStore.getUserName() === null) {
          PrincipleStore.getRole();
        }
      } else {
        showErrorToast(messageConstants.WRONG_VERIFICATION_CODE);
      }
    });
  }
}
