import NewFooter from '../NewFooter/NewFooter';
import NewNavbar from '../../../Components/NavBar/NewNavbar';
import './HowItWorks.css';
//@ts-ignore
import video from './video.mp4'
import { Link } from 'react-router-dom';
const HowItWorks = () => {
    return (
        <>
          <div className="container">
            <NewNavbar signIn={false} account={false} cart={false} searchBar={false} admin={false} getFilteredProductsFromNav={()=>{}}/>
            <div className="inner_banner works_bg">
              <div className="page_path mt-0">
                <p>
                  <Link to="/">Home</Link>
                  <span>
                    <i className="bi bi-chevron-right"></i>
                  </span>{" "}
                  How It Works
                </p>
              </div>
              <div className="heading_content_wrap text-white text-center">
                <p className="fs-14 mb-1">How It Works</p>
                <h1 className="fw-500">Shop Smart : How It Works</h1>
                <p className="mb-0 description">
                  Let us introduce the furnob to you briefly, so you will have a
                  better understanding of our quality.
                </p>
              </div>
            </div>
    
            <div className="how_it_work paddingTop paddingBottom">
              <h4 className="text-capitalize fw-bold text-center">How it works</h4>
              <div className="work_process justify-content-center mt-4 mt-md-5">
                <div className="workProcess_item">
                  <h4 className="fw-bold  black_clr">01.</h4>
                  <h2 className="text-uppercase green_clr fw-bold">ORDER</h2>
                </div>
                <div className="workProcess_item">
                  <h4 className="fw-bold  black_clr">02.</h4>
                  <h2 className="text-uppercase green_clr fw-bold">Track</h2>
                </div>
                <div className="workProcess_item">
                  <h4 className="fw-bold  black_clr">03.</h4>
                  <h2 className="text-uppercase green_clr fw-bold">Manage</h2>
                </div>
              </div>
            </div>
    
            <div className="youtube_video">
              <iframe
                width="100%"
                height="600"
                src={video}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <NewFooter />
        </>
      );
    
}

export default HowItWorks