import React from "react";
import NewNavbar from "../../../Components/NavBar/NewNavbar";
import NewFooter from "../NewFooter/NewFooter";
import { PrincipleStore } from "../../../Store";
import { HashLink } from "react-router-hash-link";

const Faq = () => {
  return (
    <>
      <div className="container">
        <NewNavbar
          signIn={false}
          account={false}
          cart={false}
          searchBar={false}
          admin={false}
          getFilteredProductsFromNav={() => {}}
        />
        <div className="inner_banner terms_bg">
          <div className="page_path mt-0">
            <p>
              <HashLink to="/">Home</HashLink>
              <span>
                <i className="bi bi-chevron-right"></i>
              </span>
              Frequently Asked Questions (FAQ)
            </p>
          </div>
          <div className="heading_content_wrap text-white text-center">
            <h1 className="fw-500">Frequently Asked Questions</h1>
            <p className="mb-0 description">
              Let us introduce the furnob to you briefly, so you will have a
              better understanding of our quality.
            </p>
          </div>
        </div>

        <div className="paddingTop paddingBottom">
          <div className="mb-3 mb-md-4">
            <h5 className="mobile_heading_fs">
              1. How do I register an account on the platform?
            </h5>
            <p className="mb-0 mobile_fs grey_clr">
              • To register, click on the "Register" button on the Login Page.
              <br />
              • Fill in the required information such as your e-Mail, Company
              Details, Address, GST No, and create a password.
              <br />• Follow the prompts to complete the registration process.
              Once done, one can log in using one's credentials. • To register,
              click on the "Register" button on the Login Page.
              <br />
              • Fill in the required information such as your e-Mail, Company
              Details, Address, GST No, and create a password.
              <br />• Follow the prompts to complete the registration process.
              Once done, one can log in using one's credentials.
            </p>
          </div>
          <div className="mb-3 mb-md-4">
            <h5 className="mobile_heading_fs">
              2. How do I browse products on the platform?
            </h5>
            <p className="mb-2 mobile_fs grey_clr">
              • You can browse products by navigating through the various
              categories displayed on the homepage or using the search bar to
              find specific items.
              <br />• Click on a category or use keywords to search for
              products, and you'll find relevant results.
            </p>
          </div>
          <div className="mb-3 mb-md-4">
            <h5 className="mobile_heading_fs">3. How can I place an order?</h5>
            <p className="mb-2 mobile_fs grey_clr">
              • Once you've found the products you want to purchase, add them to
              your cart by clicking the "Add to Cart" button.
              <br />
              • Proceed to the cart page by clicking on the cart icon, review
              your selected items, and click on the "Checkout" button to proceed
              to the checkout process.
              <br />• Follow the prompts to select delivery address, payment
              method, and review your order before confirming the purchase.
            </p>
          </div>
          <div className="mb-3 mb-md-4">
            <h5 className="mobile_heading_fs">
              4. Can I edit the quantity of products in my cart?
            </h5>
            <p className="mb-2 mobile_fs grey_clr">
              • Yes, you can edit the quantity of products in your cart on the
              cart page before proceeding to checkout.
              <br />• Simply adjust the quantity field next to each product as
              needed, and the total price will be updated accordingly.
            </p>
          </div>
          <div className="mb-3 mb-md-4">
            <h5 className="mobile_heading_fs">
              5. How do I track my order status?
            </h5>
            <p className="mb-2 mobile_fs grey_clr">
              • Once your order is placed, you can track its status by
              navigating to the "Orders" section in your account settings.
              <br />• Here, you'll find updates on order processing, shipping,
              and delivery, allowing you to monitor the progress of your order.
            </p>
          </div>
          <div className="mb-3 mb-md-4">
            <h5 className="mobile_heading_fs">
              6. What payment methods are accepted on the platform?
            </h5>
            <p className="mb-2 mobile_fs grey_clr">
              • We accept various payment methods, including credit/debit cards,
              net banking, and other online payment options.
              <br />• During the checkout process, you can choose your preferred
              payment method from the available options.
            </p>
          </div>
          <div className="mb-3 mb-md-4">
            <h5 className="mobile_heading_fs">
              7. How can I add Sub-users/Staff/Members to my account?
            </h5>
            <p className="mb-2 mobile_fs grey_clr">
              • As an admin user, navigate to the "Members" section in your
              account settings.
              <br />
              • Click on the "Add Members" button and fill in the necessary
              details of the subuser, including their name, email, and create a
              password.
              <br />• Save the changes, and the subuser will receive an
              invitation to join the account.
            </p>
          </div>
          <div className="mb-3 mb-md-4">
            <h5 className="mobile_heading_fs">
              8. How can I contact customer support for further assistance?
            </h5>
            <p className="mb-2 mobile_fs grey_clr">
              • If you have any additional questions or require assistance, you
              can reach out to our customer support team through the contact
              page or by emailing info@nutritap.in.
              <br />• Our support team is available to assist you with any
              inquiries or concerns you may have regarding the platform or your
              account.
            </p>
          </div>
          {PrincipleStore.getIsPoClient() && (
            <>
              <div className="mb-3 mb-md-4">
                <h5 className="mobile_heading_fs">
                  9. How do I upload a Purchase Order (PO) on the platform??
                </h5>
                <p className="mb-2 mobile_fs grey_clr">
                  • To upload a Purchase Order, navigate to the "Account"
                  section in your account. Click on the "Purchase Order" button
                  and follow the prompts to select the file from your device.
                  Supported file formats typically include Excel (XLS/XLSX).
                </p>
              </div>
              <div className="mb-3 mb-md-4">
                <h5 className="mobile_heading_fs">
                  10. What information should be included in the Purchase Order
                  file?
                </h5>
                <p className="mb-2 mobile_fs grey_clr">
                  • Ensure that your Purchase Order file contains essential
                  details such as PO No., PO Date, and any other relevant
                  information required for accurate processing.
                </p>
              </div>
              <div className="mb-3 mb-md-4">
                <h5 className="mobile_heading_fs">
                  11. How soon will the uploaded Purchase Order be processed?
                </h5>
                <p className="mb-2 mobile_fs grey_clr">
                  • Upon uploading the Purchase Order, our system will process
                  the file to update the available quantities of the
                  corresponding products. Processing time may vary depending on
                  the size and complexity of the PO, but you can typically
                  expect the quantities to be updated within a short timeframe.
                </p>
              </div>
              <div className="mb-3 mb-md-4">
                <h5 className="mobile_heading_fs">
                  12. How can I view the available quantity of products after
                  uploading a Purchase Order?
                </h5>
                <p className="mb-2 mobile_fs grey_clr">
                  • After the Purchase Order is processed, one can view the
                  updated available quantities of products directly on the
                  product pages. The available quantity for each product will be
                  displayed alongside the product information, allowing one to
                  make informed decisions when placing orders.
                </p>
              </div>
              <div className="mb-3 mb-md-4">
                <h5 className="mobile_heading_fs">
                  13. What should I do if there are discrepancies or errors in
                  the uploaded Purchase Order?
                </h5>
                <p className="mb-2 mobile_fs grey_clr">
                  • If you encounter any discrepancies or errors in the uploaded
                  Purchase Order, please review the file and ensure all
                  information is accurate. If necessary, one can re-upload an
                  updated version of the PO to correct any errors or
                  discrepancies.
                </p>
              </div>
              <div className="mb-3 mb-md-4">
                <h5 className="mobile_heading_fs">
                  14. How can I contact customer support for further assistance
                  regarding Purchase Orders and available quantities?
                </h5>
                <p className="mb-2 mobile_fs grey_clr">
                  • If you have any additional questions or require assistance
                  with Purchase Orders or available quantities, please reach out
                  to our customer support team @info@nutritap.in. Our support
                  representatives are available to assist you with any inquiries
                  or concerns you may have regarding the platform's
                  functionalities.
                </p>
              </div>
            </>
          )}
        </div>
      </div>
      <NewFooter />
    </>
  );
};

export default Faq;
