import { useEffect } from 'react'
import { Routes, Route, useNavigate, } from 'react-router'
import { ApprovalPendingOrderStore, CartStore, PrincipleStore, ProfileStore, apiLoadingSubject } from '../../Store';
import { PageTypeEnum } from '../../Model/Enums/PageTypeEnums';
import Register from '../../Views/PublicView/SignUp/SignUp';
import Password from '../../Views/PublicView/Password/Password';
import PaymentGateway from '../../Views/PrivateView/PaymentGateway/PaymentGateway';
import { PageEventTypeEnum } from '../../Model/ApiCallResponse';
import { ApiCallState } from '../../Model/Enums/ApiCallState';
import { messageConstants } from '../../StringConstants';
import { CheckService } from '../../Services/checkValidationService';
import { showErrorToast } from '../../utils/Toastify/ToastifyHandler';

const PrivateRoutes = () => {

    const accessiblePages = PrincipleStore.getPages();
    const getPageByName = (page: string) => {

        switch (page.toLowerCase()) {
            case PageTypeEnum.FORCED_CHANGE_PASSWORD: return <Password forced={true} />
            case PageTypeEnum.ADD_NEW_USER: return <Register client={true} />
            case PageTypeEnum.CHANGE_PASSWORD: return <Password change={true} />
            case PageTypeEnum.CHECKOUT: return <PaymentGateway />
        }
    }

    const navigate = useNavigate()

    const navigateToLogin = () => {
        navigate(`${PageTypeEnum.LOGIN}`)
    }
    const logout = () => {
        PrincipleStore.deleteUser();
        CartStore.deleteCart();
        ProfileStore.deleteProfile();
        ApprovalPendingOrderStore.deleteOrderDetails();
        navigateToLogin();
        window.location.reload()

    }
    useEffect(() => {
        const apiEventSubscriber = apiLoadingSubject.subscribe(
            (event) => {
                if (PageEventTypeEnum.PRINCIPLE === event.eventType) {
                    if (event.apiCallState === ApiCallState.UNAUTHORISED) {
                        logout()
                        showErrorToast(messageConstants.MAX_DEVICE_LIMIT)

                    }
                }

            })
        return () => {
            apiEventSubscriber.unsubscribe();
        }
    },[])

    return (
        <>
            {
                CheckService.isLoggedIn() &&
                accessiblePages.map((item, index) => {
                    return (
                        <Routes key={index}>
                            <Route path={item.toLowerCase()} element={getPageByName(item)}/>
                        </Routes>
                    )
                })
            }
        </>
    )

}


export default PrivateRoutes
