import React, { useState } from "react";
import { UserService } from "../../../Services/UserService";
import { useNavigate } from "react-router";
import { PageTypeEnum } from "../../../Model/Enums/PageTypeEnums";
import { messageConstants } from "../../../StringConstants";
import { B2BConstants } from "../../../B2BConstants";
import { PasswordUpdateProps } from "../../../Model/UserModel";
import { showWarningToast } from "../../../utils/Toastify/ToastifyHandler";
interface props {
  newPassword: string;
  confirmPassword: string;
  oldPassword: string;
}

const ConfirmNewPassword = (props: PasswordUpdateProps) => {
  const userData = new UserService();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState<props>({
    newPassword: "",
    confirmPassword: "",
    oldPassword:"",
  });
 
  const navigateToLogin = () => {
    navigate(`/${PageTypeEnum.LOGIN}`);
  };
  // const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      comparePassword();
    }
  };

  const passwordVerification = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewPassword({
      ...newPassword,
      [name]: value,
    });
   
  };

  const comparePassword = () => {
    if (newPassword.newPassword === newPassword.confirmPassword) {
      userData.changePassword(
        localStorage.getItem(B2BConstants.TEMP_PASS) || "",
        newPassword.newPassword,
        newPassword.confirmPassword,
        navigateToLogin
      );
      showWarningToast(messageConstants.SUCCESS);

    } else {
      showWarningToast(messageConstants.CONFIRM_AND_NEW_PASSWORD);
    }
  };

  // const changePasswordHandler = () => {
  //   userData.changePassword(
  //         //@ts-ignore

  //     oldPassword.oldPassword,
  //     newPassword.newPassword,
  //     newPassword.confirmPassword,
  //     navigateToLogin
  //   );
  // };

  return (
    <>
      <div className="container bg-white">
        <div className="default_tabs paddingBottom bg-white">
          <ul
            className="nav nav-pills mb-2 mb-md-3 justify-content-center"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="pills-forgetPass-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-forgetPass"
                type="button"
                role="tab"
                aria-controls="pills-forgetPass"
                aria-selected="true"
              >
                Change Your Password
              </button>
            </li>
          </ul>

          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active content-center"
              id="pills-forgetPass"
              role="tabpanel"
              aria-labelledby="pills-forgetPass-tab"
            >
              {props.change ? (
                <form className="default_form mt-3 mt-md-4" action="">
                  <p className="mobile_fs mb-0">New Password:</p>
                  <input
                    name="newPassword"
                    type="password"
                    placeholder="New Password"
                    required
                    onChange={(e) => passwordVerification(e)}
                  />
                  <p className="mobile_fs mb-0">Confirm New Password:</p>
                  <input
                    name="confirmPassword"
                    type="password"
                    placeholder="Confirm New Password"
                    required
                    onChange={(e) => passwordVerification(e)}
                    onKeyDown={handleKeyDown}
                  />
                </form>
              ) : (
                <form className="default_form mt-3 mt-md-4" action="">
                  <p className="mobile_fs mb-0">New Password:</p>
                  <input
                    name="newPassword"
                    type="password"
                    placeholder="New Password"
                    required
                    onChange={(e) => passwordVerification(e)}
                  />
                  <p className="mobile_fs mb-0">Confirm New Password:</p>
                  <input
                    name="confirmPassword"
                    type="password"
                    placeholder="Confirm New Password"
                    required
                    onChange={(e) => passwordVerification(e)}
                    onKeyDown={handleKeyDown}
                  />
                </form>
              )}
         
                <button
                  className="theme_btn text-center px-4 mt-3"
                  onClick={comparePassword}
                >
                  Reset Password
                </button>
            
              {/* <button
                className="theme_btn text-center px-4 mt-3"
                onClick={comparePassword}
              >
                Reset Password
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmNewPassword;
