import React, { useEffect, useState } from "react";
import "./SignUp.css";
import { Autocomplete, Box, Button, Card, InputAdornment, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Logo from "../../../Components/Images/logo.png";
import { PageTypeEnum } from "../../../Model/Enums/PageTypeEnums";
import { B2BConstants } from "../../../B2BConstants";
import { UserDto, UserDtoInitialValue, UserRegisterModel } from "../../../Model/UserModel";
import { StateService } from "../../../Services/StateService";
import { CityModel, StateModel } from "../../../Model/StateCityModel";
import { Subscription } from "rxjs";
import { CityStore, ProfileStore, StateStore, apiLoadingSubject } from "../../../Store";
import { ValidationService } from "../../../Services/ValidationService";
import { messageConstants } from "../../../StringConstants";
import { ApiCallResponse, PageEventTypeEnum } from "../../../Model/ApiCallResponse";
import { ApiCallState } from "../../../Model/Enums/ApiCallState";
import { dialogHandler } from "../../../utils/AlertDialogHandler";
import ErrorDialog from "../../../Components/Error/ErrorDialog";
import { RegisterClientProps } from "../../../Model/Props/RegisterClientProps";
import { errorDialogHandler } from "../../../utils/ErrorDialogHandler";
import { RoleTypeEnum } from "../../../Model/Enums/RoleTypeEnum";
import { CheckService } from "../../../Services/checkValidationService";
import { ClientInfoService } from "../../../Services/ClientInfoService";
import { userSubUserMappingService } from "../../../Services/UserSubUserMappingService";
import { CityService } from "../../../Services/CityService";
import { showErrorToast, showWarningToast } from "../../../utils/Toastify/ToastifyHandler";

const Register = (props: RegisterClientProps) => {

  const [employeeName, setEmployeeName] = useState<string>("");
  const [organisationName, setOrganisationName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNo, setPhoneNo] = useState<string>("");
  const [gst, setGST] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [pincode, setPincode] = useState<number>(0);

  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [region] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [stateId, setStateId] = useState<number>(1);
  const [country, setCountry] = useState<string>("");

  const [stateList, setStateList] = useState<Array<StateModel>>(new Array<StateModel>());

  const [cityList, setCityList] = useState<Array<CityModel>>(new Array<CityModel>());
  const [iscityLoading, setIsCityLoading] = useState<boolean>(true);

  const [loggedInUserDetails, setLoggedInUserDetails] = useState<UserDto>(UserDtoInitialValue);
  const [disabledRegisterBtn, setDisabledRegisterBtn] = useState<boolean>(false)

  const countryList: string[] = B2BConstants.COUNTRY;

  const stateData = new StateService();
  const cityData = new CityService();
  const clientInfo = new ClientInfoService();
  const userSubUserMapping = new userSubUserMappingService()

  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate(`/${PageTypeEnum.LOGIN}`)
  }

  const navigateToViewAllClients = () => {
    navigate(`/${PageTypeEnum.ALL_CLIENTS}`)
  }

  const navigateToHome = () => {
    if (CheckService.isLoggedIn()) {
      navigate(-1);
    }
    else {
      navigate(-2);
    }
  }

  const textChangeHandlers = {
    EmpName: (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmployeeName(event.target.value);
    },
    OrgName: (event: React.ChangeEvent<HTMLInputElement>) => {
      setOrganisationName(event.target.value);
    },
    Email: (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
    },
    Phone: (event: React.ChangeEvent<HTMLInputElement>) => {
      setPhoneNo(event.target.value);
    },
    GST: (event: React.ChangeEvent<HTMLInputElement>) => {
      setGST(event.target.value);
    },
    Address: (event: React.ChangeEvent<HTMLInputElement>) => {
      setAddress(event.target.value);
    },
    Password: (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(event.target.value);
      correctPasswordAlertHandler(event.target.value)
    },
    Pincode: (event: React.ChangeEvent<HTMLInputElement>) => {
      setPincode(parseInt(event.target.value));
    },

    ConfirmPassword: (event: React.ChangeEvent<HTMLInputElement>) => {
      setConfirmPassword(event.target.value);
    },
    City: (event: any, value: any) => {
      if (value) {
        setCity(value.name);
      }
    },
    
    getCityByStateId: (event: any, value: any) => {
      if (value) {
        setState(value.stateName);
        setStateId(value.id)
        cityData.getCityList(value.id)
      }
    },
    Country: (event: any, value: any) => {
      if (value) {
        setCountry(value.toString());
      }
    },
  };

  const correctPasswordAlertHandler = (enteredText: string) => {
    // const message = passwordValidator(enteredText);
    // alertHandler(message, NotificationTypeEnum.INFO, 'top', 'right')
  }

  const raiseAlert = (message: string) => {
    showWarningToast(message)
  }

  const registerHandler = () => {
    if (!props.client) {
      if (!ValidationService.validateFieldEmpty(employeeName)) {
        raiseAlert(messageConstants.EMPLOYEE_NOT_EMPTY)
      }
      else if (!ValidationService.validateFieldEmpty(organisationName)) {
        raiseAlert(messageConstants.ORGANISATION_NAME_EMPTY);
      }
      else if (!ValidationService.validateFieldEmpty(address)) {
        raiseAlert(messageConstants.ADDRESS_EMPTY);
      }
      else if (!ValidationService.validateGST(gst)) {
        raiseAlert(messageConstants.GST_INVALID);
      }
      else if (!ValidationService.validatePhoneNo(parseInt(phoneNo).toString())) {
        raiseAlert(messageConstants.PHONE_NO_INVALID);
      }
      else if (!ValidationService.validateEmail(email)) {
        raiseAlert(messageConstants.EMAIL_INVALID)
      }
      else if (!ValidationService.validatePincode(pincode.toString())) {
        raiseAlert(messageConstants.PINCODE_INVALID)
      }
      else if (password === "" && confirmPassword === "") {
        raiseAlert(messageConstants.PASSWORD_IS_REQUIRED)
      }
      else if (password !== confirmPassword) {
        raiseAlert(messageConstants.PASSWORD_NOT_MATCHED);
      }
      else { //  everthing fine call api
        const adminRegisterRequestDto: UserRegisterModel = {
          name: organisationName,
          clSpocCompany: employeeName,
          ntSpoc: 1,
          email: email,
          mobile: phoneNo,
          password: password,
          userRoles: RoleTypeEnum.ADMIN,
          active: true,
          gst: gst,
          locationDto: {
            name: organisationName,
            address: address,
            stateName: state,
            region: region,
            pincode: pincode,
            country: country,
            city: city
          },
          billingAddress: `${address};${city};${state};${pincode}`,
          shippingAddress: `${address};${city};${state};${pincode}`
        }

        clientInfo.register(adminRegisterRequestDto, navigateToLogin)
        setDisabledRegisterBtn(true)
      }
    }
    else {
      if (CheckService.isLoggedIn() && CheckService.isAdmin()) {

        apiLoadingSubject.subscribe((response) => {
          if (response.eventType === PageEventTypeEnum.USER_REGISTER) {
            switch (response.apiCallState) {
              case ApiCallState.LOADING:
                setDisabledRegisterBtn(true)
                break;
              case ApiCallState.LOADING_SUCCESS:
                userSubUserMapping.userSubUserMapping(loggedInUserDetails.id, response.data.id)
                setDisabledRegisterBtn(true)
                break;
              case ApiCallState.LOADING_ERROR:
                setDisabledRegisterBtn(false)
                break;
            }
          }
          if (response.eventType === PageEventTypeEnum.SUB_USER_MAPPING_ADD) {
            switch (response.apiCallState) {
              case ApiCallState.LOADING:
                setDisabledRegisterBtn(true)
                break;
              case ApiCallState.LOADING_SUCCESS:
                navigateToViewAllClients()
                setDisabledRegisterBtn(true)
                break;
              case ApiCallState.LOADING_ERROR:
                setDisabledRegisterBtn(false)
                break;
            }

          }
        })
      }

      const clientRegisterRequestDto: UserRegisterModel = {
        name: loggedInUserDetails?.name,
        ntSpoc: 1,
        userRoles: RoleTypeEnum.ADMIN,
        active: true,
        gst: loggedInUserDetails?.gst,
        clSpocCompany: employeeName,
        email: email,
        mobile: phoneNo,
        password: password,
        locationDto: {
          name: loggedInUserDetails?.name,
          address: loggedInUserDetails?.address,
          stateName: loggedInUserDetails?.state,
          region: region,
          pincode: loggedInUserDetails?.pincode,
          country: 'India',
          city: loggedInUserDetails?.city
        },
        billingAddress: `${loggedInUserDetails.address};${loggedInUserDetails.city};${loggedInUserDetails.state};${loggedInUserDetails.pincode}`,
        shippingAddress: `${loggedInUserDetails.address};${loggedInUserDetails.city};${loggedInUserDetails.state};${loggedInUserDetails.pincode}`,
      }

      clientInfo.register(clientRegisterRequestDto, navigateToLogin)

    }
  };

  useEffect(() => {

    var stateSubscriber: Subscription;
    var citySubscriber: Subscription;
    var loggedInClientDetailsSubscriber: Subscription;

    if (CheckService.isLoggedIn()) {
      clientInfo.getLoggedInClientInfo();
    }

    // Loading City List
    if (CityStore.getCityList().length > 0) {
      setCityList(CityStore.getCityList())
    }
    else {
      cityData.getCityList(stateId);
    }

    // Loading State List
    if (StateStore.getStateList().length > 0) {
      setStateList(StateStore.getStateList())
    }
    else {
      stateData.getStateList();
    }

    loggedInClientDetailsSubscriber = apiLoadingSubject.subscribe((response: ApiCallResponse) => {
      if (PageEventTypeEnum.PROFILE === response.eventType) {
        switch (response.apiCallState) {
          case ApiCallState.LOADING:
            break;
          case ApiCallState.LOADING_SUCCESS:
            setLoggedInUserDetails(ProfileStore.getDetails());
            break;
          case ApiCallState.LOADING_ERROR:
            errorDialogHandler(messageConstants.REFRESH)
            break;
        }
      }
    })

    citySubscriber = apiLoadingSubject.subscribe((response: ApiCallResponse) => {
      if (response.eventType === PageEventTypeEnum.CITY_LIST) {
        switch (response.apiCallState) {
          case ApiCallState.LOADING:
            setIsCityLoading(true);
            break;
          case ApiCallState.LOADING_SUCCESS:
            setCityList(CityStore.getCityList());
            setIsCityLoading(false)
            break;
          case ApiCallState.LOADING_ERROR:
            dialogHandler('', true, [], <ErrorDialog errorMessage="Something went Wrong" />)
            break;
        }
      }
    })

    stateSubscriber = apiLoadingSubject.subscribe((response: ApiCallResponse) => {
      if (response.eventType === PageEventTypeEnum.STATE_LIST) {
        switch (response.apiCallState) {
          case ApiCallState.LOADING:
            setIsCityLoading(true);
            break;
          case ApiCallState.LOADING_SUCCESS:
            setStateList(StateStore.getStateList());
            setIsCityLoading(false);
            break;
          case ApiCallState.LOADING_ERROR:
            showErrorToast(messageConstants.FAILED)
            break;
        }
      }
    })

    return (() => {
      if (citySubscriber) {
        citySubscriber.unsubscribe();
      }
      if (stateSubscriber) {
        stateSubscriber.unsubscribe();
      }
      if (loggedInClientDetailsSubscriber) {
        loggedInClientDetailsSubscriber.unsubscribe();
      }
    })
    // eslint-disable-next-line
  }, [])



  return (
    <div>

      <div className="SignUp-container">
        <div id="signUp-logo" onClick={navigateToHome} style={{ cursor: 'pointer' }}>
          <img src={Logo} alt="logo" />
        </div>
        <Card className="SignUp-inner-container">
          <p id="SignUp-title">{props.client ? "Add New  Member" : "Create Account"}</p>
          <div className="User-fields-container">
            <div className="user-field">
              <div className="userField-inner-Container">
                <div className="user-info-field">
                  <TextField
                    required
                    label={"Name of Employee"}
                    placeholder={"Enter Name of Employee"}
                    type={"text"}
                    variant={"outlined"}
                    sx={{ margin: 1, width: "14rem" }}
                    onChange={textChangeHandlers.EmpName}
                  />
                </div>
                <div className="user-info-field">
                  {
                    props.client ?
                      // <div className="disabled_filled">{loggedInUserDetails.name}</div>
                      <TextField
                        required
                        disabled={props.client}
                        value={loggedInUserDetails.name}
                        placeholder={"Enter Organisation Name"}
                        type={"text"}
                        variant={"outlined"}
                        sx={{ margin: 1, width: "14rem" }}
                      />
                      :
                      <TextField
                        required
                        label={"Organisation Name"}
                        disabled={props.client}
                        defaultValue={props.client ? loggedInUserDetails?.name : ""}
                        // value={props.client ? loggedInUserDetails?.name : ""}
                        placeholder={"Enter Organisation Name"}
                        type={"text"}
                        variant={"outlined"}
                        sx={{ margin: 1, width: "14rem" }}
                        onChange={textChangeHandlers.OrgName}
                      />
                  }
                </div>
              </div>
              <div className="userField-inner-Container">
                <div className="user-info-field">
                  <TextField
                    required
                    label={"Email"}
                    placeholder={"Enter Email"}
                    type='email'
                    variant={"outlined"}
                    sx={{ margin: 1, width: "14rem" }}
                    onChange={textChangeHandlers.Email}
                  />
                </div>
                <div className="user-info-field">
                  <TextField
                    required
                    label={"Phone Number"}
                    placeholder={"Enter Phone Number"}
                    type={"text"}
                    variant={"outlined"}
                    inputProps={{
                      maxLength: 10
                    }}
                    sx={{ margin: 1, width: "14rem" }}
                    onChange={textChangeHandlers.Phone}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+91</InputAdornment>
                      ),
                      style: {
                        width: "14rem",
                      },
                    }}
                  />
                </div>
              </div>
              <div className="userField-inner-Container">
                <div className="user-info-field">
                  {
                    props.client ?
                      // <div className="disabled_filled">{loggedInUserDetails.gst}</div>
                      <TextField
                        required
                        disabled={props.client}
                        value={loggedInUserDetails?.gst}
                        type={"text"}
                        placeholder={"Enter GST No."}
                        variant={"outlined"}
                        sx={{ margin: 1, width: "14rem" }}
                        inputProps={{
                          maxLength: 15
                        }}
                      />
                      :
                      <TextField
                        required
                        disabled={props.client}
                        defaultValue={props.client ? loggedInUserDetails?.gst : ""}
                        label={"Gst No."}
                        type={"text"}
                        placeholder={"Enter GST No."}
                        variant={"outlined"}
                        sx={{ margin: 1, width: "14rem" }}
                        onChange={textChangeHandlers.GST}
                        inputProps={{
                          maxLength: 15
                        }}
                      />
                  }
                </div>
                <div className="user-info-field">
                  {
                    props.client ?
                      // <div className="disabled_filled">{loggedInUserDetails.address}</div>
                      <TextField
                        disabled={props.client}
                        value={loggedInUserDetails.address}
                        required
                        type={"text"}
                        placeholder={"Enter House/Building Name or place "}
                        variant={"outlined"}
                        sx={{ margin: 1, width: "14rem" }}
                      />
                      :
                      <TextField
                        disabled={props.client}
                        defaultValue={props.client ? loggedInUserDetails?.address : ""}
                        required
                        label={"Address"}
                        type={"text"}
                        placeholder={"Enter House/Building Name or place "}
                        variant={"outlined"}
                        sx={{ margin: 1, width: "14rem" }}
                        onChange={textChangeHandlers.Address}
                      />
                  }
                </div>
              </div>
              <div className="userField-inner-Container">
                <div className="user-info-field">
                  {
                    props.client ?
                      <div className="disabled_filled">{loggedInUserDetails.state}</div>
                      :
                      <Autocomplete
                        disabled={props.client}
                        // defaultValue={props.client ? loggedInUserDetails?.state : stateList[0].stateName}
                        options={stateList}
                        onChange={textChangeHandlers.getCityByStateId}
                        getOptionLabel={(option) => option.stateName}
                        aria-placeholder="Choose State"
                        renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            {option.stateName}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} label="State" />
                        )}
                        sx={{ margin: 1, width: "14rem" }}
                      />
                  }
                </div>
                <div className="user-info-field" >
                  {
                    props.client ?
                      <div className="disabled_filled">{loggedInUserDetails.city}</div>
                      :
                      <Autocomplete
                        disabled={props.client}
                        // defaultValue={props.client ? loggedInUserDetails?.city : ""}
                        options={cityList}
                        onChange={textChangeHandlers.City}
                        getOptionLabel={(option) => option.name}
                        aria-placeholder="Choose City"
                        renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            {option.name}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} label="City" />
                        )}
                        sx={{ margin: 1, width: "14rem" }}
                      />
                  }

                </div>
              </div>
              <div className="userField-inner-Container">
                <div className="user-info-field">
                  {
                    props.client ?
                      // <div className="disabled_filled">{loggedInUserDetails.pincode}</div>
                      <TextField
                        required
                        disabled={props.client}
                        value={loggedInUserDetails.pincode}
                        type={"text"}
                        placeholder={"Enter Pincode "}
                        variant={"outlined"}
                        sx={{ margin: 1, width: "14rem" }}
                        inputProps={{
                          maxLength: 6
                        }}
                      />
                      :
                      <TextField
                        required
                        disabled={props.client}
                        defaultValue={props.client ? loggedInUserDetails?.pincode : ""}
                        label={"Pincode"}
                        type={"text"}
                        placeholder={"Enter Pincode "}
                        variant={"outlined"}
                        sx={{ margin: 1, width: "14rem" }}
                        onChange={textChangeHandlers.Pincode}
                        inputProps={{
                          maxLength: 6
                        }}
                      />
                  }

                </div>
                <div className="user-info-field">
                  {
                    props.client ?
                      <div className="disabled_filled">{'India'}</div>
                      :
                      <Autocomplete
                        disabled={props.client}
                        defaultValue={props.client ? "India" : ""}
                        options={countryList}
                        onChange={textChangeHandlers.Country}
                        aria-placeholder="Choose Country"
                        renderInput={(params) => (
                          <TextField {...params} label="Country" />
                        )}
                        sx={{ margin: 1, width: "14rem" }}
                      />
                  }

                </div>
              </div>
              <div className="userField-inner-Container">
                <div className="user-info-field">
                  <TextField
                    onClick={() => correctPasswordAlertHandler(password)}
                    required
                    label={"Password"}
                    type={"password"}
                    placeholder={"Enter Password"}
                    variant={"outlined"}
                    sx={{ margin: 1, width: "14rem" }}
                    onChange={textChangeHandlers.Password}
                  />
                </div>
                <div className="user-info-field">
                  <TextField
                    required
                    label={"Confirm Password"}
                    placeholder={"Enter Confirm Password"}
                    type={"password"}
                    sx={{ margin: 1, width: "14rem" }}
                    variant={"outlined"}
                    onChange={textChangeHandlers.ConfirmPassword}
                  />
                </div>

              </div>
            </div>
          </div>
          <div className="user-field">
            <Button
              // sx={{ width: "15vw", margin: "20px" ,color:'red'  }}
              variant={"contained"}
              // color={"info" }
              id={disabledRegisterBtn ? 'disabled register-btn' : 'register-btn'}
              disabled={disabledRegisterBtn}
              onClick={registerHandler}>

              Register
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Register;
