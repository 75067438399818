import { apiCaller } from "./ApiCallService";

export const DownloadFileService = {
    download: (url: string, fileName: string) => {
        apiCaller.getDownloadFromServer(url).then((res) => {
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(res);
            link.download = fileName
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
    }
}