import { useEffect, useState } from "react";
import "./ClientDashboard.css";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import {
  BarChartInitialState,
  BarChartInitialStateModel,
  BarChartoptions,
} from "../../../Components/Graphs/BarChart";
import { B2BSalesDto } from "../../../Model/b2bSalesDto";
import { Chart as ChartJS, registerables } from "chart.js";
import {
  PieChartInitialState,
  PieChartInitialStateModel,
} from "../../../Components/Graphs/PieChart";
import { DashboardService } from "../../../Services/DashboardService";
import { showErrorToast } from "../../../utils/Toastify/ToastifyHandler";
import { ApiCallState } from "../../../Model/Enums/ApiCallState";
import { Subscription } from "rxjs";
import { apiLoadingSubject } from "../../../Store";
import {
  ApiCallResponse,
  PageEventTypeEnum,
} from "../../../Model/ApiCallResponse";
import { DateUtil } from "../../../Services/DateService/dateUtil";
import {
  ProgressBarInitialState,
  ProgressBarInitialStateModel,
  ProgressBarOptions,
} from "../../../Components/Graphs/ProgressChart";
import NewOrderLoading from "../../LoadingComponent/NewOrderLoading";
import { GraphDataService } from "../../../Services/DashboardGraphService";

ChartJS.register(...registerables);

const ClientDashboard = () => {
  const [monthWiseBarData, setMonthWiseBarData] =
    useState<BarChartInitialStateModel>(
      JSON.parse(JSON.stringify(BarChartInitialState))
    );
  const [topSellingPieData, setTopSellingPieData] =
    useState<PieChartInitialStateModel>(
      JSON.parse(JSON.stringify(PieChartInitialState))
    );
  const [topSellingBrand, setTopSellingBrand] =
    useState<ProgressBarInitialStateModel>(
      JSON.parse(JSON.stringify(ProgressBarInitialState))
    );
  const [orderTotals, setOrderTotals] = useState<B2BSalesDto>();
  const [paymentDue, setPaymentDue] = useState<B2BSalesDto>();
  const [isSalesDataLoading, setIsSalesDataLoading] =
  useState<boolean>(true);
  const [isMonthWiseDataLoading, setIsMonthWiseDataLoading] =
    useState<boolean>(true);
  const [isSubCategoryDataLoading, setIsSubCategoryDataLoading] =
    useState<boolean>(true);
  const [grouped, setGrouped] = useState<string>("SUB_CATEGORY");
  const [timePeriod, setTimePeriod] = useState<string>("QUARTERLY");

  const consolidatedData = new DashboardService();
  const graphDataService = new GraphDataService();
  const date = new DateUtil();

  const fetchConsolidatedOrderData = () => {
    consolidatedData.getConsolidatedOrderData();
    const orderSubsriberResponse: Subscription = apiLoadingSubject.subscribe(
      (response: ApiCallResponse) => {
        if (PageEventTypeEnum.CONSOLIDATED_ORDER_DATA === response.eventType) {
          switch (response.apiCallState) {
            case ApiCallState.LOADING:
              setIsSalesDataLoading(true);
              break;
            case ApiCallState.LOADING_SUCCESS:
              setOrderTotals(response.data);
              setIsSalesDataLoading(false);
              break;
            case ApiCallState.LOADING_ERROR:
              showErrorToast(response.data);
              setIsSalesDataLoading(false);
              break;
          }
        }
      }
    );
    return () => {
      if (orderSubsriberResponse) {
        orderSubsriberResponse.unsubscribe();
      }
    };
  };

  const fetchPaymentPendingData = () => {
    consolidatedData.getPaymentDueData();
    const orderSubsriberResponse: Subscription = apiLoadingSubject.subscribe(
      (response: ApiCallResponse) => {
        if (PageEventTypeEnum.PAYMENT_PENDING === response.eventType) {
          switch (response.apiCallState) {
            case ApiCallState.LOADING:
              setIsSalesDataLoading(true);
              break;
            case ApiCallState.LOADING_SUCCESS:
              setPaymentDue(response.data);
              setIsSalesDataLoading(false);
              break;
            case ApiCallState.LOADING_ERROR:
              showErrorToast(response.data);
              setIsSalesDataLoading(false);
              break;
          }
        }
      }
    );
    return () => {
      if (orderSubsriberResponse) {
        orderSubsriberResponse.unsubscribe();
      }
    };
  };

  const fetchMonthWiseData = () => {
    let dateRange;
    if (timePeriod === "YEARLY") {
      dateRange = date.yearSpanDates();
    } else if (timePeriod === "QUARTERLY") {
      dateRange = date.getQuarterlyDates();
    }
  
    if (dateRange) {
      consolidatedData.getMonthWiseData(dateRange.start, dateRange.end);
      const monthWiseBarDataSubscriber: Subscription = apiLoadingSubject.subscribe(
        (response: ApiCallResponse) => {
          if (PageEventTypeEnum.CONSOLIDATED_DATA === response.eventType) {
            switch (response.apiCallState) {
              case ApiCallState.LOADING:
                setIsMonthWiseDataLoading(true);
                break;
              case ApiCallState.LOADING_SUCCESS:
                const monthWiseBarData = graphDataService.prepareMonthWiseBarGraph(response.data);
                setMonthWiseBarData(monthWiseBarData);
                setIsMonthWiseDataLoading(false);
                break;
              case ApiCallState.LOADING_ERROR:
                showErrorToast(response.data);
                setIsMonthWiseDataLoading(false);
                break;
            }
          }
        }
      );
      return () => {
        if (monthWiseBarDataSubscriber) {
          monthWiseBarDataSubscriber.unsubscribe();
        }
      };
    }
  };
  

  const fetchTopSellingResponse = (selectedGroup: string) => {
    const currYearDate = date.yearSpanDates();
    consolidatedData.getTopSelling(currYearDate.start, currYearDate.end, selectedGroup || "");
    const topSellingBrandSubscriber: Subscription = apiLoadingSubject.subscribe(
      (response: ApiCallResponse) => {
        if (PageEventTypeEnum.TOP_SELLING_DATA === response.eventType) {
          switch (response.apiCallState) {
            case ApiCallState.LOADING:
              setIsSubCategoryDataLoading(true);
              break;
            case ApiCallState.LOADING_SUCCESS:
              const topSellingGraphData = graphDataService.prepareTopSellingGraph(response.data);
              setTopSellingBrand(topSellingGraphData);
              setIsSubCategoryDataLoading(false);
              break;
            case ApiCallState.LOADING_ERROR:
              showErrorToast(response.data);
              setIsSubCategoryDataLoading(false);
              break;
          }
        }
      }
    );
    return () => {
      if (topSellingBrandSubscriber) {
        topSellingBrandSubscriber.unsubscribe();
      }
    };
  };

  const fetchTopSellingData = () => {
    const currYearDate = date.yearSpanDates();
    consolidatedData.getTopSellingCategory(currYearDate.start, currYearDate.end);
    const topSellingResponseSubscriber: Subscription =
      apiLoadingSubject.subscribe((response: ApiCallResponse) => {
        if (PageEventTypeEnum.TOP_SELLING_CATEGORY === response.eventType) {
          switch (response.apiCallState) {
            case ApiCallState.LOADING:
              break;
            case ApiCallState.LOADING_SUCCESS:
              const topSellingPieData = graphDataService.prepareTopSellingCategoryPieChart(response.data);
              setTopSellingPieData(topSellingPieData)
              break;
            case ApiCallState.LOADING_ERROR:
              showErrorToast(response.data);
              break;
          }
        }
      });
    return () => {
      if (topSellingResponseSubscriber) {
        topSellingResponseSubscriber.unsubscribe();
      }
    };
  };

  useEffect(() => {
    fetchConsolidatedOrderData();
    // fetchMonthWiseData();
    fetchTopSellingResponse(grouped);
    fetchTopSellingData();
    fetchPaymentPendingData();
  }, [grouped]);

  useEffect(() => {
    fetchMonthWiseData();
  }, [timePeriod]);

  return (
    <>
      {/* <div className="heading-wrapper d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-between">
      </div> */}
      <div className="card-boxes">
        <div className="sales_card">
          <div className="d-xl-block">
            <div className="mb-0 w-100">Total No. Of Order Placed</div>
            <div className="d-flex align-items-center justify-content-center">
            {isSalesDataLoading ? (
              <span className="dot_loader"></span>
              ) : (
             orderTotals?.orders
             )}
            </div>
          </div>
        </div>
        <div className="sales_card">
          <div className="d-xl-block">
            <div className="mb-0 w-100">Total Sales</div>
            <div className="d-flex align-items-center justify-content-center">
              {isSalesDataLoading ? (
                  <span className="dot_loader"></span>
                ) : (
                //@ts-ignore
                Number((!isNaN(orderTotals?.amount) ? (Math.round(orderTotals?.amount)).toFixed(2) : 0)).toLocaleString("en-IN")
              )}
            </div>
          </div>
        </div>
        <div className="sales_card">
          <div className="d-xl-block">
            <div className="mb-0 w-100">Payment Due</div>
            <div className="d-flex align-items-center justify-content-center">
            {isSalesDataLoading ? (
              <h1 className="dot_loader"></h1>
              ) : (
             paymentDue?.orders
             )}            
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard_graphs">
      <div className="dashboard_graph_boxes">
        <div className="graphs">
          <div className="dashboard-boxes-title">
            {/* Month Wise Sales */}
            <select
              className="select-dropdown"
              value={timePeriod}
              onChange={(e) => setTimePeriod(e.target.value)}
              style={{ marginLeft: "10px" }}
            >
              <option value="QUARTERLY">Quarterly Sales</option>
              <option value="YEARLY">Yearly Sales</option>
            </select>
          </div>
          {!isMonthWiseDataLoading ? (
            <Bar data={monthWiseBarData} options={BarChartoptions}></Bar>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "160px",
              }}
            >
              <NewOrderLoading />
            </div>
          )}
        </div>
        </div>
        <div className="dashboard_graph_boxes">
          <div className="graphs">
            <div className="dashboard-boxes-title">
              <select
                className="select-dropdown"
                value={grouped}
                onChange={(e) => setGrouped(e.target.value)}
              >
                <option value="SUB_CATEGORY">Top Selling Subcategory</option>

                <option value="BRAND">Top Selling Brand</option>
              </select>
            </div>
            {!isSubCategoryDataLoading ? (
              <Bar data={topSellingBrand} options={ProgressBarOptions}></Bar>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "160px",
                }}
              >
                <NewOrderLoading />
              </div>
            )}
          </div>
        </div>
        <div className="dashboard_graph_boxes">
          <div className="graphs">
            <div className="dashboard-boxes-title">Top Selling Category</div>
            <Doughnut data={topSellingPieData} id="Piechart"></Doughnut>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientDashboard;
