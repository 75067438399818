import React, { ChangeEvent, useState } from "react";
import {
  showErrorToast,
  showInfoToast,
} from "../../../utils/Toastify/ToastifyHandler";
import { messageConstants } from "../../../StringConstants";
import { NewCatalogueService } from "../../../Services/NewCatalogueService";

interface props {
  updateCart: (event: ChangeEvent<HTMLInputElement>) => void;
}

const UploadOrder = ({ updateCart }: props) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const catalogue = new NewCatalogueService();


  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    // setSelectedFile(file);

    if (file) {
      if (file.type.includes("spreadsheetml") || file.type.includes("excel")) {
        setSelectedFile(file);
      } else {
        showErrorToast(messageConstants.EXCEL_ALLOWED);
      }
    } else {
      showInfoToast(messageConstants.NO_FILES_SELECTED);
    }
  };

  const handleSaveChanges = () => {
    if (selectedFile) {
      const syntheticEvent = {
        target: {
          files: [selectedFile],
        },
      } as unknown as ChangeEvent<HTMLInputElement>;

      updateCart(syntheticEvent);
    }
    else{
      showErrorToast(messageConstants.NO_EXCEL_FILES_SELECTED);
    }
  };

  
  const downloadCatalogueHandler = () => {
    catalogue.downloadClientCatalogue();
  };

  return (
    <>
      <button
        type="button"
        className="btn"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
      >
          <span
          className="theme_btn mt-2 mt-sm-0 ms-sm-2 add-member-button"
        >
          Create Bulk Order
        </span>
        {/* <i className="bi bi-upload" title="Upload Catalogue"></i> */}
      </button>
      <div
        className="modal fade"
        id={"staticBackdrop"}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Upload Catalogue
              </h5>
              <button
                type="button"
                className="cross_btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="bi bi-x-circle-fill"></i>
              </button>
            </div>
            <div className="modal-body">
              <form className="default_form" action="">
                <input
                  className="mb-2"
                  name=""
                  type="file"
                  required
                  onChange={handleFileChange}
                />
              </form>
              {/* <i className="edit_quantity">
                <span className="required">*</span> Only edit quantity column.
              </i> */}
           <i className="bi bi-info-circle cart_icons" title="Only edit column"></i>
                   <button
                      className="btn download_catalog"
                      onClick={() => downloadCatalogueHandler()}
                    >
                      Download My Catalogue
                    </button>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn" data-bs-dismiss="modal">
                Close
              </button>
              <button
                type="button"
                className="btn theme_btn"
                data-bs-dismiss="modal"
                onClick={handleSaveChanges}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadOrder;
