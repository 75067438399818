import { PublishEventService } from "./PublishEventService";
import { PageEventTypeEnum } from "../Model/ApiCallResponse";

export const SearchParamsService = {
  getParams: (): URLSearchParams => {
    return new URLSearchParams(window.location.search);
  },
  setParams: (urlParam: { key: string; value: string }[]) => {
    const publishEventService = new PublishEventService();
    publishEventService.changed(PageEventTypeEnum.URL, urlParam);
  },
  getParamsByKey: (key: string): string => {
    const params = new URLSearchParams(window.location.search).get(key);
    return params != null ? params : "";
  },
  deleteParam: (key: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(key);
    const newUrl = `${window.location.pathname}${searchParams.toString()}`;
    window.history.replaceState({}, "", newUrl);
  },
  deleteFilterParam: (key: string, callback: () => void) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(key);
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState({}, "", newUrl);
    callback();
  }

  
};
