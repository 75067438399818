import { PageEventTypeEnum } from "../Model/ApiCallResponse";
import { B2BSalesDto } from "../Model/b2bSalesDto";
import { ApiResponseStatusEnum } from "../Model/Enums/ApiCallState";
import {
  ApiTypeEnum,
  ParameterEnum,
  SubPathEnum,
} from "../Model/Enums/ApiConstantEnum";
import { OrderStatusEnum } from "../Model/Enums/OrderStatusEnums";
import { PaymentStatuEnum } from "../Model/Enums/PaymentTypeEnum";
import { ApiResponseModel } from "../Model/NutritapResponseModel";
import { messageConstants } from "../StringConstants";
import { errorDialogHandler } from "../utils/ErrorDialogHandler";
import { ApiUrlBuilder } from "../utils/Stringbuilder";
import { showErrorToast } from "../utils/Toastify/ToastifyHandler";
import { apiCaller } from "./ApiCallService";
import { PublishEventService } from "./PublishEventService";

export class DashboardService {
  private publishEvent = new PublishEventService();
  
  getConsolidatedOrderData() {
    const url = new ApiUrlBuilder(ApiTypeEnum.B2B_ORDER)
      .addSubPath(SubPathEnum.CONSOLIDATED)
      .addParameter(ParameterEnum.ORDER_STATUS_LIST, OrderStatusEnum.PENDING)
      .buildURL();
    
    apiCaller
      .getResponseFromServer(url)
      .then((res: ApiResponseModel) => {
        if (!res) {
          showErrorToast(messageConstants.FAILED);
          this.publishEvent.loadingError(PageEventTypeEnum.CONSOLIDATED_DATA);
          return;
        }
        if (res.status === ApiResponseStatusEnum.SUCCESS || res.success) {
          const salesTotalData: B2BSalesDto = {
            amount: 0,
            month: "",
            orders: 0,
            year: 0,
          };
          res.dtoList.forEach((sale: B2BSalesDto) => {
            salesTotalData.amount += sale.amount;
            salesTotalData.orders += sale.orders;
          });
          this.publishEvent.loadingSuccess(
            PageEventTypeEnum.CONSOLIDATED_ORDER_DATA,
            salesTotalData
          );
        } else {
          this.publishEvent.loadingError(PageEventTypeEnum.CONSOLIDATED_ORDER_DATA);
        }
      })
      .catch(() => {
        errorDialogHandler(messageConstants.FAILED);
      });
  }

  getMonthWiseData(fromDate: number, toDate: number) {
    const url = new ApiUrlBuilder(ApiTypeEnum.B2B_ORDER)
      .addSubPath(SubPathEnum.CONSOLIDATED)
      .addParameter(ParameterEnum.ORDER_STATUS_LIST, OrderStatusEnum.COMPLETED)
      .addParameter(ParameterEnum.START_DATE, fromDate)
      .addParameter(ParameterEnum.END_DATE, toDate)
      .buildURL();

    apiCaller
      .getResponseFromServer(url)
      .then((res: ApiResponseModel) => {
        if (!res) {
          showErrorToast(messageConstants.FAILED);
          this.publishEvent.loadingError(PageEventTypeEnum.CONSOLIDATED_DATA);
          return;
        }
        if (res.status === ApiResponseStatusEnum.SUCCESS || res.success) {
          this.publishEvent.loadingSuccess(
            PageEventTypeEnum.CONSOLIDATED_DATA,
            res.dtoList
          );
        } else {
          this.publishEvent.loadingError(PageEventTypeEnum.CONSOLIDATED_DATA);
        }
      })
      .catch(() => {
        errorDialogHandler(messageConstants.FAILED);
      });
  }

  getTopSelling(fromDate: number, endDate: number, grouped: string) {
    const url = new ApiUrlBuilder(ApiTypeEnum.B2B_ORDER)
      .addSubPath(SubPathEnum.TOP_SELLING)
      .addParameter(ParameterEnum.GROUP_BY, grouped)
      .addParameter(ParameterEnum.START_DATE, fromDate)
      .addParameter(ParameterEnum.END_DATE, endDate)
      .buildURL();

    apiCaller
      .getResponseFromServer(url)
      .then((res: ApiResponseModel) => {
        if (!res) {
          showErrorToast(messageConstants.FAILED);
          this.publishEvent.loadingError(PageEventTypeEnum.TOP_SELLING_DATA);
          return;
        }
        if (res.status === ApiResponseStatusEnum.SUCCESS || res.success) {
          this.publishEvent.loadingSuccess(
            PageEventTypeEnum.TOP_SELLING_DATA,
            res.dtoList
          );
        } else {
          this.publishEvent.loadingError(PageEventTypeEnum.TOP_SELLING_DATA);
        }
      })
      .catch(() => {
        errorDialogHandler(messageConstants.FAILED);
      });
  }

  getTopSellingCategory(fromDate: number, endDate: number) {
    const url = new ApiUrlBuilder(ApiTypeEnum.B2B_ORDER)
      .addSubPath(SubPathEnum.TOP_SELLING)
      .addParameter(ParameterEnum.GROUP_BY, "CATEGORY")
      .addParameter(ParameterEnum.START_DATE, fromDate)
      .addParameter(ParameterEnum.END_DATE, endDate)
      .buildURL();

    apiCaller
      .getResponseFromServer(url)
      .then((res: ApiResponseModel) => {
        if (!res) {
          showErrorToast(messageConstants.FAILED);
          this.publishEvent.loadingError(
            PageEventTypeEnum.TOP_SELLING_CATEGORY
          );
          return;
        }
        if (res.status === ApiResponseStatusEnum.SUCCESS || res.success) {
          this.publishEvent.loadingSuccess(
            PageEventTypeEnum.TOP_SELLING_CATEGORY,
            res.dtoList
          );
        } else {
          this.publishEvent.loadingError(
            PageEventTypeEnum.TOP_SELLING_CATEGORY
          );
        }
      })
      .catch(() => {
        errorDialogHandler(messageConstants.FAILED);
      });
  }
  getPaymentDueData() {
    const url = new ApiUrlBuilder(ApiTypeEnum.B2B_ORDER)
      .addSubPath(SubPathEnum.CONSOLIDATED)
      .addParameter(ParameterEnum.ORDER_STATUS_LIST, OrderStatusEnum.DELIVERED)
      .addParameter(ParameterEnum.PAYMENT_STATUS_LIST, PaymentStatuEnum.PENDING)
      .buildURL();
    
    apiCaller
      .getResponseFromServer(url)
      .then((res: ApiResponseModel) => {
        if (!res) {
          showErrorToast(messageConstants.FAILED);
          this.publishEvent.loadingError(PageEventTypeEnum.PAYMENT_PENDING);
          return;
        }
        if (res.status === ApiResponseStatusEnum.SUCCESS || res.success) {
          const salesTotalData: B2BSalesDto = {
            amount: 0,
            month: "",
            orders: 0,
            year: 0,
          };
          res.dtoList.forEach((sale: B2BSalesDto) => {
            salesTotalData.amount += sale.amount;
            salesTotalData.orders += sale.orders;
          });
          this.publishEvent.loadingSuccess(
            PageEventTypeEnum.PAYMENT_PENDING,
            salesTotalData
          );
        } else {
          this.publishEvent.loadingError(PageEventTypeEnum.PAYMENT_PENDING);
        }
      })
      .catch(() => {
        errorDialogHandler(messageConstants.FAILED);
      });
  }
}
