import { B2BConstants } from "../B2BConstants";
import { PageEventTypeEnum } from "../Model/ApiCallResponse";
import { RoleTypeEnum } from "../Model/Enums/RoleTypeEnum";
import BaseOrder from "../Model/OrderModel";
import {
  GenricPaymentDto,
  RazorpayInitiateOrderRequestDto,
  RazorpayInitiateOrderResponseDto,
  RazorpayResponseDto,
  getInitialRazorpayPaymentDto,
} from "../Model/PaymentModel";
import { PrincipleStore, RazorpayOrderIdStore } from "../Store";
import { messageConstants } from "../StringConstants";
import { ApiTypeEnum, SubPathEnum } from "../Model/Enums/ApiConstantEnum";
import { ApiUrlBuilder } from "../utils/Stringbuilder";
import { apiCaller } from "./ApiCallService";
import { OrderService } from "./OrderService";
import { PublishEventService } from "./PublishEventService";
import { showErrorToast } from "../utils/Toastify/ToastifyHandler";

export class PaymentService {
  private publishEventService = new PublishEventService();

  intiateRazorPay(orderResponse: BaseOrder) {
    let totalPayment = orderResponse.billingAmount * 100;
    // let totalPayment = 100;

    const razorpayInitiateOrderRequestDto: RazorpayInitiateOrderRequestDto = {
      amount: Math.round(totalPayment).toFixed(2).toString(),
      orderId: orderResponse.id,
      type: RoleTypeEnum.B2B,
    };
    // const url = `${B2BConstants.BASE_URL}${ApiConstants.INITIATE_RAZORPAY.POST}`
    const pgUrl = new ApiUrlBuilder(ApiTypeEnum.PAYMENT_GATEWAY);
    pgUrl.addSubPath(SubPathEnum.INITIATE);
    pgUrl.addSubPath(SubPathEnum.RAZORPAY);
    pgUrl.addSubPath(SubPathEnum.ORDERS);
    const url = pgUrl.buildURL();
    const body = JSON.stringify(razorpayInitiateOrderRequestDto);

    this.publishEventService.initiated(PageEventTypeEnum.PAYMENT);
    apiCaller
      .postResponseToServer(url, body)
      .then((response: RazorpayInitiateOrderResponseDto) => {
        if (!response.success) {
          this.publishEventService.loadingError(PageEventTypeEnum.PAYMENT);
        } else {
          RazorpayOrderIdStore.setRazorpayOrderId(response.orderId);
          this.publishEventService.loadingSuccess(
            PageEventTypeEnum.PAYMENT,
            orderResponse
          );
        }
      })
      .catch(() => {
        this.publishEventService.loadingError(PageEventTypeEnum.PAYMENT);
      });
  }

  displayRazorPay(orderResponse: BaseOrder) {
    const order = new OrderService();
    const publishEventService = new PublishEventService();
    // const setOrderId = localStorage.setItem("orderId", orderResponse.id.toString())
    // const orderRes = localStorage.setItem("orderResponse", orderResponse.)

    const options = {
      amount: (orderResponse.billingAmount * 100).toString(),
      currency: "INR",
      name: "Nutritap Technologies Pvt. Ltd.",
      description: "Online Transaction",
      image: `${B2BConstants.LOGO}`,
      order_id: RazorpayOrderIdStore.getRazorpayOrderId(),
      handler: async function (razorpayResponse: RazorpayResponseDto) {
        let updateStatusRequestDto: GenricPaymentDto =
          getInitialRazorpayPaymentDto();

        updateStatusRequestDto.razorpayPaymentId =
          razorpayResponse.razorpay_payment_id;
        updateStatusRequestDto.txnId = razorpayResponse.razorpay_order_id;
        updateStatusRequestDto.orderId = orderResponse.id;
        updateStatusRequestDto.error = razorpayResponse.error;
        updateStatusRequestDto.razorpaySignature =
          razorpayResponse.razorpay_signature;
        updateStatusRequestDto.paymentAmount = orderResponse.billingAmount;

        if (razorpayResponse.status_code === 200) {
          publishEventService.loadingSuccess(PageEventTypeEnum.RAZORPAY_PAYMENT, {
            updateStatusRequestDto: updateStatusRequestDto,
            orderResponse: orderResponse,
          });
        } else {
          
          showErrorToast(messageConstants.PAYMENT_NOT_DONE);
        }
      },
      prefill: {
        name: PrincipleStore.getUserName(),
        contact: "+91" + PrincipleStore.getMobileNumber(),
        email: PrincipleStore.getEmail(),
      },

      theme: {
        color: "#81cf1c",
        hide_topbar: true,
      },
      modal: {
        confirm_close: true,
        ondismiss: () => {
          
          let updateStatusRequestDto: GenricPaymentDto =
            getInitialRazorpayPaymentDto();
          updateStatusRequestDto.orderId = orderResponse.id;
          updateStatusRequestDto.error = "Cancelled";
          updateStatusRequestDto.txnId = "Cancelled";
          updateStatusRequestDto.razorpayPaymentId = "Cancelled";
          updateStatusRequestDto.razorpaySignature = "Cancelled";
          order.updatePaymentStatusOfOrder(
            updateStatusRequestDto,
            orderResponse
          );
        },
      },
    };
    // @ts-ignore
    return new window.Razorpay(options);
  }
}
