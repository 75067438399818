import React, {
  useEffect,
  useState,
} from "react";
import { CityModel, StateModel } from "../../../Model/StateCityModel";
import {
  UserDto,
  UserDtoInitialValue,
  UserRegisterModel,
} from "../../../Model/UserModel";
import { B2BConstants } from "../../../B2BConstants";
import { StateService } from "../../../Services/StateService";
import { Subscription } from "rxjs";
import ErrorDialog from "../../../Components/Error/ErrorDialog";
import {
  ApiCallResponse,
  PageEventTypeEnum,
} from "../../../Model/ApiCallResponse";
import { ApiCallState } from "../../../Model/Enums/ApiCallState";
import { CheckService } from "../../../Services/checkValidationService";
import {
  CityStore,
  StateStore,
  apiLoadingSubject,
  ProfileStore,
} from "../../../Store";
import { messageConstants } from "../../../StringConstants";
import { dialogHandler } from "../../../utils/AlertDialogHandler";
import { errorDialogHandler } from "../../../utils/ErrorDialogHandler";
import { RegisterClientProps } from "../../../Model/Props/RegisterClientProps";
import { ValidationService } from "../../../Services/ValidationService";
import { RoleTypeEnum } from "../../../Model/Enums/RoleTypeEnum";
import { useNavigate } from "react-router";
import { CityService } from "../../../Services/CityService";
import { ClientInfoService } from "../../../Services/ClientInfoService";
import { userSubUserMappingService } from "../../../Services/UserSubUserMappingService";
import NewNavbar from "../../../Components/NavBar/NewNavbar";
import { Link } from "react-router-dom";
import {
  LocationRequestDto,
  getInitialLocationModelDto,
} from "../../../Model/CartModel";
import { showErrorToast, showWarningToast } from "../../../utils/Toastify/ToastifyHandler";

const NewSignUp = (props: RegisterClientProps) => {
  const [employeeName, setEmployeeName] = useState<string>("");
  const [organisationName, setOrganisationName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNo, setPhoneNo] = useState<string>("");
  const [gst, setGST] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [pincode, setPincode] = useState<number>(0);

  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [region] = useState<string>("");
  const [stateId, setStateId] = useState<number>(1);
  const [country, setCountry] = useState<string>("");

  const [location, setLocation] = useState<LocationRequestDto>(
    getInitialLocationModelDto()
  );

  const [stateList, setStateList] = useState<Array<StateModel>>(
    new Array<StateModel>()
  );

  const [cityList, setCityList] = useState<Array<CityModel>>(
    new Array<CityModel>()
  );
  const [iscityLoading, setIsCityLoading] = useState<boolean>(true);
  const [isSignInForm, setIsSignInForm] = useState(true);

  const [loggedInUserDetails, setLoggedInUserDetails] =
    useState<UserDto>(UserDtoInitialValue);
  const [disabledRegisterBtn, setDisabledRegisterBtn] =
    useState<boolean>(false);

  const countryList: string[] = B2BConstants.COUNTRY;

  const stateData = new StateService();
  const cityData = new CityService();
  const clientInfo = new ClientInfoService();
  const userSubUserMapping = new userSubUserMappingService();
  const navigate = useNavigate();

  const textChangeHandlers = {
    EmpName: (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmployeeName(event.target.value);
    },
    OrgName: (event: React.ChangeEvent<HTMLInputElement>) => {
      setOrganisationName(event.target.value);
    },
    Email: (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
    },
    Phone: (event: React.ChangeEvent<HTMLInputElement>) => {
      setPhoneNo(event.target.value);
    },
    GST: (event: React.ChangeEvent<HTMLInputElement>) => {
      setGST(event.target.value);
    },
    Address: (event: React.ChangeEvent<HTMLInputElement>) => {
      setAddress(event.target.value);
    },
    Password: (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(event.target.value);
    },
    Pincode: (event: React.ChangeEvent<HTMLInputElement>) => {
      setPincode(parseInt(event.target.value));
    },

    ConfirmPassword: (event: React.ChangeEvent<HTMLInputElement>) => {
      setConfirmPassword(event.target.value);
    },
    City: (event: any) => {
      const value: any = event.target.value;
      if (value) {
        setCity(value);
      }
    },
    getCityByStateId: (event: any) => {
      const select: HTMLSelectElement = event.target;
      setStateId(Number(select.options[select.selectedIndex].id));
      cityData.getCityList(Number(select.options[select.selectedIndex].id));
      setLocation({
        ...location,
        stateName: select.value,
      });
    },
    Country: (event: any) => {
      const value: any = event.target.value;
      if (value) {
        setCountry(value.toString());
      }
    },
  };
  useEffect(() => {
    let stateSubscriber: Subscription;
    let citySubscriber: Subscription;
    let loggedInClientDetailsSubscriber: Subscription;

    if (CheckService.isLoggedIn()) {
      clientInfo.getLoggedInClientInfo();
    }

    // Loading City List
    if (CityStore.getCityList().length > 0) {
      setCityList(CityStore.getCityList());
    } else {
      cityData.getCityList(stateId);
    }

    // Loading State List
    if (StateStore.getStateList().length > 0) {
      setStateList(StateStore.getStateList());
    } else {
      stateData.getStateList();
    }

    loggedInClientDetailsSubscriber = apiLoadingSubject.subscribe(
      (response: ApiCallResponse) => {
        if (PageEventTypeEnum.PROFILE === response.eventType) {
          switch (response.apiCallState) {
            case ApiCallState.LOADING:
              break;
            case ApiCallState.LOADING_SUCCESS:
              setLoggedInUserDetails(ProfileStore.getDetails());
              break;
            case ApiCallState.LOADING_ERROR:
              errorDialogHandler(messageConstants.REFRESH);
              break;
          }
        }
      }
    );

    citySubscriber = apiLoadingSubject.subscribe(
      (response: ApiCallResponse) => {
        if (response.eventType === PageEventTypeEnum.CITY_LIST) {
          switch (response.apiCallState) {
            case ApiCallState.LOADING:
              setIsCityLoading(true);
              break;
            case ApiCallState.LOADING_SUCCESS:
              setCityList(CityStore.getCityList());
              setIsCityLoading(false);
              break;
            case ApiCallState.LOADING_ERROR:
              dialogHandler(
                "",
                true,
                [],
                <ErrorDialog errorMessage="Something went Wrong" />
              );
              break;
          }
        }
      }
    );

    stateSubscriber = apiLoadingSubject.subscribe(
      (response: ApiCallResponse) => {
        if (response.eventType === PageEventTypeEnum.STATE_LIST) {
          switch (response.apiCallState) {
            case ApiCallState.LOADING:
              setIsCityLoading(true);
              break;
            case ApiCallState.LOADING_SUCCESS:
              setStateList(StateStore.getStateList());
              setIsCityLoading(false);
              break;
            case ApiCallState.LOADING_ERROR:
              showErrorToast(messageConstants.FAILED);
              break;
          }
        }
      }
    );

    return () => {
      if (citySubscriber) {
        citySubscriber.unsubscribe();
      }
      if (stateSubscriber) {
        stateSubscriber.unsubscribe();
      }
      if (loggedInClientDetailsSubscriber) {
        loggedInClientDetailsSubscriber.unsubscribe();
      }
    };
    // eslint-disable-next-line
  }, []);

  const raiseAlert = (message: string) => {
    showWarningToast(message);
  };

  const navigateToLogin = () => {
    navigate(`/login`);
  };
  const registerHandler = () => {
    if (!props.client) {
      if (!ValidationService.validateFieldEmpty(employeeName)) {
        raiseAlert(messageConstants.EMPLOYEE_NOT_EMPTY);
      } else if (!ValidationService.validateFieldEmpty(organisationName)) {
        raiseAlert(messageConstants.ORGANISATION_NAME_EMPTY);
      } 
      else if (!ValidationService.validateFieldEmpty(address)) {
        raiseAlert(messageConstants.ADDRESS_EMPTY);
      }
      else if (!ValidationService.validateGST(gst)) {
        raiseAlert(messageConstants.GST_INVALID);
      }
      else if (!ValidationService.validatePhoneNo(parseInt(phoneNo).toString())) {
        raiseAlert(messageConstants.PHONE_NO_INVALID);
      }
      else if (!ValidationService.validateEmail(email)) {
        raiseAlert(messageConstants.EMAIL_INVALID)
      }
      else if (!ValidationService.validatePincode(pincode.toString())) {
        raiseAlert(messageConstants.PINCODE_INVALID)
      }
      else if (password === "" && confirmPassword === "") {
        raiseAlert(messageConstants.PASSWORD_IS_REQUIRED)
      }
      else if (password !== confirmPassword) {
        raiseAlert(messageConstants.PASSWORD_NOT_MATCHED);
      }
      else {
        //  everthing fine call api
        const adminRegisterRequestDto: UserRegisterModel = {
          name: organisationName,
          clSpocCompany: employeeName,
          ntSpoc: 1,
          email: email,
          mobile: phoneNo,
          password: password,
          userRoles: RoleTypeEnum.ADMIN,
          active: true,
          gst: gst,
          locationDto: {
            name: organisationName,
            address: address,
            stateName: location.stateName,
            region: region,
            pincode: pincode,
            country: country,
            city: city,
          },
          billingAddress: `${address};${city};${location.stateName};${pincode}`,
          shippingAddress: `${address};${city};${location.stateName};${pincode}`,
        };

        clientInfo.register(adminRegisterRequestDto, navigateToLogin);
        setDisabledRegisterBtn(true);
      }
    }
  };

  return (
    <div className="container">
      <NewNavbar
        signIn={true}
        account={false}
        cart={true}
        admin={false}
        searchBar={true}
        getFilteredProductsFromNav={() => {}}
      />
      <div className="page_path border-bottom">
        <p>
          <Link to="/">Home</Link>
          <span>
            <i className="bi bi-chevron-right"></i>
          </span>
          Login & Registration
        </p>
      </div>

      <div className="forms_wrpapper paddingBottom paddingTop text-center">
        {/* <!-- Login & Registration Tabs Start --> */}
        <div className="default_tabs paddingBottom">
          <ul
            className="nav nav-pills mb-2 mb-md-3 justify-content-center"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${!isSignInForm ? "active" : ""}`}
                onClick={navigateToLogin}
                id="pills-Login-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-Login"
                type="button"
                role="tab"
                aria-controls="pills-Login"
                aria-selected="true"
              >
                Login
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${isSignInForm ? "active" : ""}`}
                onClick={navigateToLogin}
                id="pills-Registration-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-Registration"
                type="button"
                role="tab"
                aria-controls="pills-Registration"
                aria-selected="false"
              >
                Registration
              </button>
            </li>
          </ul>
          <div
            className="tab-pane fade show active"
            id="pills-Registration"
            role="tabpanel"
            aria-labelledby="pills-Registration-tab"
          >
            <p className="grey_clr mobile_fs mb-0">
              There are many advantages to creating an account: the payment
              process is faster, shipment tracking is possible and much more.
            </p>
            <form
              className="default_form mt-3 mt-md-4"
              action=""
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="row gx-3">
                <div className="col-sm-6">
                  <input
                    className="w-100"
                    type="text"
                    placeholder="Name of Employee *"
                    required
                    onChange={textChangeHandlers.EmpName}
                  />
                </div>
                <div className="col-sm-6">
                  <input
                    className="w-100"
                    type="text"
                    placeholder="Organization Name *"
                    required
                    onChange={textChangeHandlers.OrgName}
                  />
                </div>
                <div className="col-sm-6">
                  <input
                    className="w-100"
                    type="email"
                    placeholder="E-mail Address *"
                    required
                    onChange={textChangeHandlers.Email}
                  />
                </div>
                <div className="col-sm-6">
                  <input
                    className="w-100"
                    type="text"
                    placeholder="Phone Number *"
                    required
                    maxLength={10}
                    onChange={textChangeHandlers.Phone}
                  />
                </div>
                <div className="col-sm-6">
                  <input
                    className="w-100"
                    type="text"
                    placeholder="GST No. *"
                    required
                    maxLength={15}
                    onChange={textChangeHandlers.GST}
                  />
                </div>
                <div className="col-sm-6">
                  <input
                    className="w-100"
                    type="text"
                    placeholder="Address *"
                    required
                    onChange={textChangeHandlers.Address}
                  />
                </div>
                <div className="col-sm-6">
                  <select
                    className="form-select shadow-none border-0 default_pointer_cs"
                    onChange={textChangeHandlers.getCityByStateId}
                  >
                    <option selected>State</option>
                    {stateList.map((item) => {
                      return (
                        <>
                          <option
                            id={item.id.toString()}
                            value={item.stateName}
                          >
                            {item.stateName}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div className="col-sm-6">
                  <select
                    className="form-select shadow-none border-0 default_pointer_cs"
                    onChange={textChangeHandlers.City}
                  >
                    <option selected>City</option>
                    {cityList.map((item) => {
                      return (
                        <>
                          <option>{item.name}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div className="col-sm-6">
                  <input
                    className="w-100"
                    type="text"
                    placeholder="Pincode *"
                    required
                    maxLength={6}
                    onChange={textChangeHandlers.Pincode}
                  />
                </div>
                <div className="col-sm-6">
                  <select
                    className="form-select shadow-none border-0 default_pointer_cs"
                    onChange={textChangeHandlers.Country}
                  >
                    <option selected>Country</option>
                    <option>{countryList}</option>
                  </select>
                </div>
                <div className="col-sm-6">
                  <input
                    className="w-100"
                    type="password"
                    placeholder="Password *"
                    required
                    onChange={textChangeHandlers.Password}
                  />
                </div>
                <div className="col-sm-6">
                  <input
                    className="w-100"
                    type="password"
                    placeholder="Confirm Password *"
                    required
                    onChange={textChangeHandlers.ConfirmPassword}
                  />
                </div>
              </div>
              <p className="mt-2 mb-0 mobile_fs">
                Your personal data will be used to support your experience
                throughout this website, to manage access to your account, and
                for other purposes described in our 
                <Link to="/privacyPolicy" className="anchor_link fs-16">
                  privacy policy.
                </Link>
              </p>
              <button
                className="theme_btn text-center px-4 mt-4"
                onClick={registerHandler}
              >
                Registration
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewSignUp;
