import NewFooter from "../NewFooter/NewFooter";
import t1 from "../../../Components/Images/team/1.jpg";
import t2 from "../../../Components/Images/team/2.jpg";
import t3 from "../../../Components/Images/team/3.jpg";
import t4 from "../../../Components/Images/team/4.jpg";
import t5 from "../../../Components/Images/team/5.jpg";
import t6 from "../../../Components/Images/team/6.jpg";
import ab2 from "../../../Components/Images/ab2.jpg";
import NewNavbar from "../../../Components/NavBar/NewNavbar";
import "./AboutUs.css";
import { HashLink } from "react-router-hash-link";
import { PageTypeEnum } from "../../../Model/Enums/PageTypeEnums";

const AboutUs = () => {
  return (
    <>
      <div className="container">
        <NewNavbar
          signIn={false}
          account={false}
          cart={false}
          searchBar={false}
          admin={false}
          getFilteredProductsFromNav={() => {}}
        />
        <div className="inner_banner about_bg">
          <div className="page_path mt-0">
            <p>
              <HashLink to={`${PageTypeEnum.HOME}#top`}>Home</HashLink>
              <span>
                <i className="bi bi-chevron-right"></i>
              </span>{" "}
              About Us
            </p>
          </div>
          <div className="heading_content_wrap text-white text-center">
            <p className="fs-14 mb-1">About Nutritap</p>
            <h1 className="fw-500">Do you want to know us ?</h1>
            <p className="mb-0 description">
              Let us introduce the furnob to you briefly, so you will have a
              better understanding of our quality.
            </p>
          </div>
        </div>

        <div className="about_content_wrapper paddingTop paddingBottom">
          <div className="row gy-4 gy-lg-0 align-items-center">
            <div className="col-md-6 col-lg-5">
              <div className="about_img">
                <img className="img-fluid w-100" src={ab2} alt="about-img" />
              </div>
            </div>
            <div className="col-md-6 col-lg-7">
              <h2 className="text-uppercase fw-500">ABOUT NUTRITAP</h2>
              <p className="mobile_fs">
                Nutritap is a retail-tech company providing 24X7, neighbourhood
                retail to customers through patented, IoT based retail kiosks.{" "}
              </p>
              <p className="mobile_fs">
                Nutritap is creating alternate retail channels for brand to
                reach their customers directly. NutriTap brings retail products
                on tap through state of the art automated retail machines.{" "}
              </p>
              <p className="mobile_fs">
                Our mission is to make 24X7 neighbourhood retail accessible to
                consumers. Through our innovative, tech-driven and
                anywhere-anytime automated retailing machines, we bring FMCG
                retail to an arm's length of customers.
              </p>
              <HashLink
                className="theme_btn"
                to="https://nutritap.in/contact-us/"
                target="_blank"
              >
                Get in Touch
              </HashLink>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- timeline Start --> */}
      <div className="bg_light_yellow py-4 py-sm-5 px-3">
        <div className="comapny_timeline">
          <h2 className="fw-500 text-uppercase text-center">
            NUTRITAP TIMELINE
          </h2>
          <p className="mobile_fs text-center fs-14">
            We are the pioneers of Vending based alternate retail channels in
            India. We started in 2018 with a vision to make retail easier and
            more accessible to customers and brands by removing the unnecessary
            layers of supply chain nodes and human.
          </p>

          <div className="timeline_wrapper">
            <div className="timeline_line">
              <div className="rightside_content">
                <h5 className="text-uppercase green_clr fw-500 mb-2">
                  FEBRUARY 2018
                </h5>
                <p className="mb-0 fs-14">
                  The vision to make retail easy and accessible for customers
                  led to the birth of Nutritap from a basement floor of a local
                  market in Gurugram. Initially launched vending machine
                  services in Delhi-NCR.
                </p>
              </div>
              <div className="leftside_content">
                <h5 className="text-uppercase green_clr fw-500 mb-2">
                  JANUARY 2019
                </h5>
                <p className="mb-0 fs-14">
                  Services in Western and Southern India launched with the start
                  of Mumbai, Pune and Bangalore operations. Also, seed funding
                  raised from Apoorva Patni, Sameer Khetarpal and senior members
                  of McKinsey & Co, Google etc.
                </p>
              </div>
              <div className="rightside_content">
                <h5 className="text-uppercase green_clr fw-500 mb-2">
                  MARCH 2019
                </h5>
                <p className="mb-0 fs-14">
                  Complete Tech stack launched with in-house suite of hardware,
                  design and User Apps. Also, fastest vending machine player to
                  reach 100 installed machines in just 14 months since
                  inception.
                </p>
              </div>
              <div className="leftside_content">
                <h5 className="text-uppercase green_clr fw-500 mb-2">
                  MAY 2020
                </h5>
                <p className="mb-0 fs-14">
                  Pre-Series fund raise of $ 2 Million from Venture Catalysts,
                  IK Capital and Grip Invest in 75:25 Equity:Debt ratio. Poised
                  to become the largest and India's first full-stack vending
                  machine player.
                </p>
              </div>
              <div className="rightside_content">
                <h5 className="text-uppercase green_clr fw-500 mb-2">
                  OCTOBER 2022
                </h5>
                <p className="mb-0 fs-14">
                  With presence in all tier-1 metro cities of India, Nutritap is
                  now the fastest growing alternate channel retail player in
                  India owning the technology and the distribution expertise for
                  enabling smart, 24X7, automated & unmanned retail.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Timeline End --> */}

      <div className="container">
        {/* <!-- Team Start --> */}
        <div className="paddingTop">
          <div className="max_content">
            <h2 className="fw-500 text-uppercase text-center">
              OUR AWESOME TEAM
            </h2>
            <p className="fs-14 text-center">
              We are the pioneers of Vending based alternate retail channels in
              India. We started in 2018 with a vision to make retail easier and
              more accessible to customers and brands by removing the
              unnecessary layers of supply chain nodes and human intervention.
            </p>
          </div>

          <div className="row gy-2 gx-2 gx-sm-3 gy-sm-3">
            <div className="col-6 col-md-4 col-lg-3">
              <div className="team_item">
                <div className="team_img">
                  <img className="w-100 img-fluid" src={t2} alt="Team" />
                </div>
                <p className="fs-20 mb-0 text-capitalize fw-bold mt-2">
                  Rajesh Kumar
                </p>
                <span className="text-uppercase fs-14">CO-FOUNDER/CEO</span>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3">
              <div className="team_item">
                <div className="team_img">
                  <img className="w-100 img-fluid" src={t3} alt="Team" />
                </div>
                <p className="fs-20 mb-0 text-capitalize fw-bold mt-2">
                  Priyank Tewari
                </p>
                <span className="text-uppercase fs-14">CO-FOUNDER/ CBO</span>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3">
              <div className="team_item">
                <div className="team_img">
                  <img className="w-100 img-fluid" src={t1} alt="Team" />
                </div>
                <p className="fs-20 mb-0 text-capitalize fw-bold mt-2">
                  Padmaja Kumari
                </p>
                <span className="text-uppercase fs-14">HEAD TECHNOLOGY</span>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3">
              <div className="team_item">
                <div className="team_img">
                  <img className="w-100 img-fluid" src={t4} alt="Team" />
                </div>
                <p className="fs-20 mb-0 text-capitalize fw-bold mt-2">
                  Rohit Soni
                </p>
                <span className="text-uppercase fs-14">
                  STRATEGIC ALLIANCES AND DOOH ADVERTISEMENT
                </span>
              </div>
            </div>
            <div className="pos-mid">
              {/* <div className="col-6 col-md-4 col-lg-3">
                <div className="team_item">
                  <div className="team_img">
                    <img className="w-100 img-fluid" src={t5} alt="Team" />
                  </div>
                  <p className="fs-20 mb-0 text-capitalize fw-bold mt-2">
                    Gautam Samnotra
                  </p>
                  <span className="text-uppercase fs-14">
                    VICE PRESIDENT OPERATIONS
                  </span>
                </div>
              </div> */}
              <div className="col-6 col-md-4 col-lg-3">
                <div className="team_item">
                  <div className="team_img">
                    <img className="w-100 img-fluid" src={t6} alt="Team" />
                  </div>
                  <p className="fs-20 mb-0 text-capitalize fw-bold mt-2">
                    Dheeraj Gupta
                  </p>
                  <span className="text-uppercase fs-14">
                    GENERAL MANAGER B2B
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Team End --> */}
      </div>
      <NewFooter />
    </>
  );
};

export default AboutUs;
