import { OrderStatusEnum } from "../Model/Enums/OrderStatusEnums"
import { TrackOrderStatusEnum } from "../Model/Enums/PaymentTypeEnum"
import { getDeliveryStatusClass } from "../Services/statusService"

export const createOrderStatusElement = (orderStatus: OrderStatusEnum) => {
    switch (orderStatus) {
        case OrderStatusEnum.BULK_ORDER : 
            return <span>Bulk</span>
        case OrderStatusEnum.APPROVAL_PENDING:
            return <span>Waiting For Approval</span>
        case OrderStatusEnum.ORDER_APPROVED:
            return <span>Approved</span>
        case OrderStatusEnum.PENDING:
            return <span>Placed</span>
        case OrderStatusEnum.PROCESSING:
            return <span>Processing</span>
        case OrderStatusEnum.SPLIT:
            return <span>Splitted</span>
        case OrderStatusEnum.READY_TO_DELIVER:
            return <span>Ready for delivery</span>
        case OrderStatusEnum.IN_TRANSIT:
            return <span>Dispatched</span>
        case OrderStatusEnum.COMPLETED:
            return <span>Completed</span>
        case OrderStatusEnum.RETURNED:
            return <span>Returned</span>
        case OrderStatusEnum.CANCELLED:
            return <span>Cancelled</span>
        case OrderStatusEnum.PARTIAL_DELIVER:
            return <span>Partial Deliver</span>
        case OrderStatusEnum.REJECTED:
            return <span>Rejected</span>
        case OrderStatusEnum.DELIVERED:
            return <span>Delivered</span>
        case OrderStatusEnum.ACCEPTED:
            return <span>Accepted</span>
    }
}

export const createPaymentStatusElement = (orderStatus: OrderStatusEnum) => {
    switch (orderStatus) {
        case OrderStatusEnum.PENDING :
            return <span style={getDeliveryStatusClass(OrderStatusEnum.PENDING)}>Pending</span>
        case OrderStatusEnum.PAYMENT_DENIED:
            return <span style={getDeliveryStatusClass(OrderStatusEnum.PAYMENT_DENIED)}>Payment Denied</span>
        case OrderStatusEnum.PAYMENT_REQUESTED:
            return <span style={getDeliveryStatusClass(OrderStatusEnum.PAYMENT_REQUESTED)}>Payment Requested</span>
        case OrderStatusEnum.PAYMENT_APPROVED:
            return <span style={getDeliveryStatusClass(OrderStatusEnum.PAYMENT_APPROVED)}>Payment Approved</span>
        case OrderStatusEnum.PAYMENT_DONE:
            return <span style={getDeliveryStatusClass(OrderStatusEnum.PAYMENT_DONE)}>Payment Done</span>
    }
}
export const createTrackOrderStausElement = (orderStatus:TrackOrderStatusEnum) =>{
    switch (orderStatus){
        case TrackOrderStatusEnum.PENDING:
            return <span style={getDeliveryStatusClass(OrderStatusEnum.PENDING)}>Placed</span>
        case TrackOrderStatusEnum.PROCESSING:
            return <span style={getDeliveryStatusClass(OrderStatusEnum.PROCESSING)}>Processing</span>
        case TrackOrderStatusEnum.READY_TO_DELIVER:
             return <span style={getDeliveryStatusClass(OrderStatusEnum.READY_TO_DELIVER)}>Ready for delivery</span>
        case TrackOrderStatusEnum.PARTIAL_DELIVER:
            return <span style={getDeliveryStatusClass(OrderStatusEnum.PARTIAL_DELIVER)}>Partially Delivered</span>
        case TrackOrderStatusEnum.COMPLETED:
            return <span style={getDeliveryStatusClass(OrderStatusEnum.COMPLETED)}>Completed</span>
        case TrackOrderStatusEnum.DELIVERED:
            return <span style={getDeliveryStatusClass(OrderStatusEnum.DELIVERED)}>Delivered</span>
         case TrackOrderStatusEnum.PAYMENT:
            return <span style={getDeliveryStatusClass(OrderStatusEnum.DELIVERED)}>Payment</span>
        case TrackOrderStatusEnum.SPLIT:
            return <span style={getDeliveryStatusClass(OrderStatusEnum.SPLIT)}>Splitted</span>







    }
}