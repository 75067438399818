import { useEffect, useState } from "react";
import "./NewCheckOut.css";
import NewNavbar from "../../../Components/NavBar/NewNavbar";
import NewFooter from "../../PublicView/NewFooter/NewFooter";
import { Subscription } from "rxjs";
import {
  ApiCallResponse,
  PageEventTypeEnum,
} from "../../../Model/ApiCallResponse";
import { LocationRequestDto } from "../../../Model/CartModel";
import { ApiCallState } from "../../../Model/Enums/ApiCallState";
import {
  PrincipleStore,
  apiLoadingSubject,
  LocationStore,
  CartStore,
  RazorpayOrderIdStore,
} from "../../../Store";
import { messageConstants } from "../../../StringConstants";
import { useNavigate } from "react-router-dom";
import { LocationService } from "../../../Services/LocationService";
import { UserLocationMappingService } from "../../../Services/UserLocationMappingService";
import { HashLink } from "react-router-hash-link";
import AddNewAddressDialogBox from "./AddNewAddressDialogBox";
import { OrderService } from "../../../Services/OrderService";
import BaseOrder from "../../../Model/OrderModel";
import { PaymentTypeEnum } from "../../../Model/Enums/PaymentTypeEnum";
import { GenricPaymentDto } from "../../../Model/PaymentModel";
import { PaymentService } from "../../../Services/PaymentService";
import { B2BConstants } from "../../../B2BConstants";
import { showErrorToast } from "../../../utils/Toastify/ToastifyHandler";

const NewCheckOut = () => {
  const [userAddressList, setUserAddressList] = useState<LocationRequestDto[]>(
    new Array<LocationRequestDto>()
  );
  // const [isAddressLoading, setIsAddressLoading] = useState(true);
  const [selectedAddressId, setSelectedAddressId] = useState<string>("");
  const addedProducts = Array.from(CartStore.getCart().values());
  const [selectedAddress, setSelectedAddress] = useState<LocationRequestDto>();

  const [paymentOption, setPaymentOption] = useState<PaymentTypeEnum>(
    PaymentTypeEnum.NONE
  ); //state for payment options

  const [isPaymentGatewayLoading , setIsPaymentGatewayLoading] = useState<boolean>(true);

  const [isHover, setIsHover] = useState<any>(null); //state for edit and delete options visibility

  const [addressToShow, setAddressToShow] = useState(4); //state for show more show less function

  const [poNumber, setPoNumber] = useState<string>("");
  const [remarks, setRemarks] = useState<string>("");
  const [proceedButtonDisabled, setProceedButtonDisabled] = useState(false);

  const navigate = useNavigate();

  let totalDiscountedSum: number = 0;
  let totalSum: number = 0;
  let gstAmount: number = 0;

  const locationService = new LocationService();

  useEffect(() => {}, [paymentOption]);

  const userLocationMappingService = new UserLocationMappingService();

  
  const currentUserId = PrincipleStore.getUserId();

  //@ts-ignore
  const isExistingClients = B2BConstants.EXISTING_CLIENTS.includes(currentUserId);

  useEffect(() => {
    if (addedProducts.length < 1) {
      navigateToProductPage();
    }
    const userId = PrincipleStore.getUserId();
    let id = userId;
    if (id) {
      userLocationMappingService.getAllLocation(id);
    }
    let subscriber: Subscription = apiLoadingSubject.subscribe(
      (response: ApiCallResponse) => {
        if (PageEventTypeEnum.PAYMENT === response.eventType) {
          switch (response.apiCallState) {
            case ApiCallState.INITIATED:
              setIsPaymentGatewayLoading(true);
              break;

            case ApiCallState.LOADING:
              setIsPaymentGatewayLoading(true);
              break;

            case ApiCallState.LOADING_SUCCESS:
              const paymentService = new PaymentService();
              paymentService.displayRazorPay(response.data).open();
              setIsPaymentGatewayLoading(false);
              break;

            case ApiCallState.LOADING_ERROR:
              setIsPaymentGatewayLoading(false);
              break;
          }
        }
        if (PageEventTypeEnum.RAZORPAY_PAYMENT === response.eventType) {
          switch (response.apiCallState) {
            case ApiCallState.LOADING:
              setIsPaymentGatewayLoading(true);
              break;

            case ApiCallState.LOADING_SUCCESS:
              const paymentDto: GenricPaymentDto = response.data.updateStatusRequestDto;
              const orderResponse: BaseOrder = response.data.orderResponse;
              const order = new OrderService().updatePaymentStatusOfOrder(
                paymentDto,
                orderResponse,
                navigateToOrderPage,
                navigateToHomePage
              );
              CartStore.deleteCart();
              RazorpayOrderIdStore.deleteRazorPayOrderId();
              setIsPaymentGatewayLoading(false);
              break;

            case ApiCallState.LOADING_ERROR:
              setIsPaymentGatewayLoading(false);
              showErrorToast(messageConstants.PAYMENT_NOT_DONE);
              break;
          }
        }
      }
    );

    let locationDetailsSubscriber: Subscription = apiLoadingSubject.subscribe(
      (response: ApiCallResponse) => {
        if (PageEventTypeEnum.LOCATIONS === response.eventType) {
          switch (response.apiCallState) {
            case ApiCallState.LOADING:
              // setIsAddressLoading(true);
              break;
            case ApiCallState.LOADING_SUCCESS:
              LocationStore.getAllLocation();
              setUserAddressList(LocationStore.getAllLocation());
              // setIsAddressLoading(false);
              break;
            case ApiCallState.LOADING_ERROR:
              showErrorToast(messageConstants.REFRESH);
              // setIsAddressLoading(false);
              break;
            case ApiCallState.RELOAD:
              if (id) {
                userLocationMappingService.getAllLocation(id);
              }
              break;
          }
        }
      }
    );

    return () => {
      if (locationDetailsSubscriber) {
        locationDetailsSubscriber.unsubscribe();
      }
      if (subscriber) {
        subscriber.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {}, [userAddressList]);

  const handleAddressChange = (event: any) => {
    setSelectedAddressId(event.target.value);
  };

  const addressExtracting = () => {
    const result = userAddressList.filter((item) => {
      return item.id === Number(selectedAddressId);
    });
    setSelectedAddress(result[0]);
  };
  useEffect(() => {
    addressExtracting();
  }, [selectedAddressId]);

  // Placing Order starts

  //checks whether the payment option is online or on credit
  const paymentOptionHandler = (event: any) => {
    if (event.target.value === "online-payment") {
      setPaymentOption(PaymentTypeEnum.RAZORPAY);
    } else if (event.target.value === "on-credit") {
      setPaymentOption(PaymentTypeEnum.CASH);
    }
  };

  //navigates to order page
  const navigateToOrderPage = (id: number) => {
    navigate(`/OrderDetails/${id}`);
  };

  //navigates to home page
  const navigateToHomePage = () => {
    navigate("/");
  };

  //navigates to product page
  const navigateToProductPage = () => {
    navigate("/product");
  };

  //sets the PO number
  const settingPoNumber = (event: any) => {
    setPoNumber(event.target.value);
  };

  //sets the remarks
  const settingRemarks = (event: any) => {
    setRemarks(event.target.value);
  };

  //places the order according to conditions
  const placeOrderHandler = () => {
    if (!selectedAddressId) {
      showErrorToast(messageConstants.ADDRESS_FIELD_EMPTY);
    } else if (paymentOption === PaymentTypeEnum.NONE) {
      showErrorToast(messageConstants.SELECT_PAYMENT_OPTION);
    } else if (selectedAddress) {
      const createOrderDto = new OrderService().createOrderFromCartItems(
        paymentOption,
        selectedAddress.locationId,
        poNumber,
        remarks
      );

      new OrderService().createOrder(
        createOrderDto,
        navigateToOrderPage,
        navigateToHomePage,
        paymentOption
      );

      setProceedButtonDisabled(true);
    }
  };
  // Placing Order Ends

  const showMore = () => {
    // Expand Address
    setAddressToShow(userAddressList.length);
  };

  const showLess = () => {
    // Collapse Address
    setAddressToShow(4);
  };

  // delete selected address
  const deleteAddress = (id: number) => {
    if (id !== null) {
      userLocationMappingService.deleteLocation(id);
    }
  };

  // cancels the order by deleting whole cart and navigating to product page
  const cancelOrderHandler = () => {
    CartStore.deleteCart();
    navigateToProductPage();
  };

  return (
    <>
      <div className="container">
        <NewNavbar
          signIn={true}
          account={false}
          cart={true}
          admin={false}
          searchBar={true}
          getFilteredProductsFromNav={() => {}}
        />
        <div className="page_path border-bottom breadCrumb">
          <p>
            <HashLink to="/">Home</HashLink>
            <span>
              <i className="bi bi-chevron-right"></i>
            </span>
            <HashLink to="/product">Shop</HashLink>
            <span>
              <i className="bi bi-chevron-right"></i>
            </span>
            <HashLink to="/newCart">Cart</HashLink>
            <span>
              <i className="bi bi-chevron-right"></i>
            </span>
            <HashLink to="/newCheckOut">Checkout</HashLink>
          </p>
        </div>

        <div className="row mt-4 mt-md-5 row-wrapper">
          <div className="col-xl-6">
            <div className="address_wrapper h-100">
              <div>
                <label className="shipping-address">
                  <div className="shipping_add">
                    <p className="mb-1 fw-bold">Shipping Address</p>
                    <div className="border-bottom"></div>
                  </div>

                  {userAddressList
                    .slice(0, addressToShow)
                    .map((item, index) => {
                      return (
                        <div
                          className="address_wrap border-bottom"
                          key={index}
                          onMouseEnter={() => {
                            setIsHover(index);
                          }}
                          onMouseLeave={() => setIsHover(null)}
                        >
                          <label className="label_wrapper">
                            <input
                              type="radio"
                              id={`option-${index}`}
                              value={item.id}
                              checked={selectedAddressId === item.id.toString()}
                              onChange={handleAddressChange}
                              readOnly
                            />

                            <span className="fw-500">
                              {`${item.userName}, ${item.userMobile}`}
                            </span>

                            {isHover === index && (
                              <span className="edit_delete_options">
                                <i
                                  className="bi bi-pencil-fill"
                                  data-bs-toggle="modal"
                                  data-bs-target={
                                    "#staticBackdrop_edit_" + item.id
                                  }
                                ></i>
                                <i
                                  className="bi bi-trash"
                                  onClick={() => deleteAddress(item.id)}
                                ></i>
                              </span>
                            )}

                            <br />
                            <div className="sub_address_wrap">
                              {` ${item.name}, ${item.address}, ${item.city}, ${item.stateName}, ${item.pincode}`}
                            </div>
                          </label>
                          {
                            <AddNewAddressDialogBox
                              location={JSON.parse(JSON.stringify(item))}
                              isEdit={true}
                              service={locationService.editLocation}
                            />
                          }
                        </div>
                      );
                    })}
                </label>
              </div>
              {userAddressList.length > 4 ? (
                addressToShow < 5 ? (
                  <div className="show_btn">
                    <button className="anchor_link" onClick={showMore}>
                      Show More <i className="bi bi-chevron-down fw-500"></i>
                    </button>
                  </div>
                ) : (
                  <div className="show_btn">
                    <button
                      className="anchor_link"
                      style={{ marginBottom: "15px" }}
                      onClick={showLess}
                    >
                      Show Less <i className="bi bi-chevron-up fw-500"></i>
                    </button>
                  </div>
                )
              ) : (
                <></>
              )}
            </div>
            <div className="shippingBtn_wrap">
              <HashLink className="anchor_link fw-500" to="/newCart#top">
                <i className="bi bi-chevron-left me-1"></i>Return to Cart
              </HashLink>
              {
                <AddNewAddressDialogBox
                  isEdit={false}
                  service={locationService.addSingleLocation}
                />
              }
            </div>
          </div>
          <div className="col-xl-6">
            <div className="totalPrice_wrap bg_grey h-100">
              <div className="product-wrapper">
                {addedProducts.map((item, index) => {
                  totalDiscountedSum +=
                    item.product.cost *
                    (item.applicableDiscount / 100) *
                    item.qty;

                  totalSum += item.product.cost * item.qty;
                  gstAmount +=
                    item.product.cost *
                    (1 - item.applicableDiscount / 100) *
                    (item.gst / 100) *
                    item.qty;

                  return (
                    <HashLink
                      to="#"
                      className="added_productItem d-flex align-items-center justify-content-between added_prod"
                      key={index}
                    >
                      <div className="d-flex align-items-center justify-content-start me-2">
                        <div className="productItem_count">
                          <div className="cartProduct_img">
                            <img
                              className="w-100 img-fluid"
                              src={item.product.photo}
                              alt="Product"
                            />
                          </div>
                          <span>{item.qty}</span>
                        </div>
                        <div>
                          <p className="fw-500 mb-0 added_productName">
                            {item.product.pname}
                          </p>
                        </div>
                      </div>
                      <p className="mb-0">
                        ₹ 
                        {(item.product.cost * item.qty).toLocaleString("en-In")}
                      </p>
                    </HashLink>
                  );
                })}
              </div>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <p className="mb-0">Subtotal</p>
                <p className="mb-0 text-end">
                  ₹ {totalSum.toLocaleString("en-IN")}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <p className="mb-0">Discount</p>
                <p className="mb-0 text-end">
                  ₹ {totalDiscountedSum.toLocaleString("en-IN")}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <p className="mb-0">GST (included)</p>
                <p className="mb-0 text-end">
                  ₹ {gstAmount.toLocaleString("en-IN")}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <p className="mb-0">Platform Fee</p>
                <p className="mb-0 text-end">Free</p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <p className="mb-0">Handling Fee</p>
                <p className="mb-0 text-end">Free</p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <p className="mb-0">Shipping Address</p>
                <p className="mb-0 text-end grey_clr fs-12">
                  {selectedAddress
                    ? `${selectedAddress?.name}, ${selectedAddress?.address}, ${selectedAddress?.city}, ${selectedAddress?.stateName}, ${selectedAddress?.pincode}`
                    : ""}
                </p>
              </div>

              <div className="total_price mb-2">
                <p className="fw-500 mb-0">Total</p>
                <p className="fw-500 mb-0">
                  ₹ {Number((totalSum - totalDiscountedSum).toFixed(2)).toLocaleString("en-IN")}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-3 total_wrp">
                <p className="mb-0 fw-500">Mode Of Payment</p>
                {isExistingClients || PrincipleStore.getIsPoClient() ? (
                  <div>
                    <label
                      className="radio_btn_wrap"
                      hidden={PrincipleStore.getIsPoClient()}
                    >
                      <input
                        type="radio"
                        name="radioGroup"
                        value="online-payment"
                        onClick={paymentOptionHandler}
                      />
                      Online Payment
                    </label>
                    <label className="radio_btn_wrap">
                      <input
                        type="radio"
                        name="radioGroup"
                        value="on-credit"
                        onClick={paymentOptionHandler}
                      />
                      On Credit
                    </label>
                  </div>
                ) : (
                  <div>
                    <label className="radio_btn_wrap">
                      <input
                        type="radio"
                        name="radioGroup"
                        value="online-payment"
                        onClick={paymentOptionHandler}
                      />
                      Online Payment
                    </label>
                  </div>
                )}
              </div>
              <form className="default_form " action="">
                <input
                  type="text"
                  placeholder="PO Number(Optional)"
                  className="po_number_input_field"
                  onChange={settingPoNumber}
                />
                <input
                  type="text"
                  placeholder="Remarks(Optional)"
                  className="remarks_input_field"
                  onChange={settingRemarks}
                />
              </form>
              <div className="button-wrapper">
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop_cancel"
                  className="cancel_order_btn theme_btn"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="proceed_btn theme_btn"
                  onClick={placeOrderHandler}
                  disabled={proceedButtonDisabled}
                >
                  Proceed
                </button>
              </div>
            </div>

            {/* modal for cancelling the order */}
            <div
              className="modal fade"
              id="staticBackdrop_cancel"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex={-1}
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">
                      Cancel Order
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    Are you sure you want to cancel your order?
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-success"
                      data-bs-dismiss="modal"
                      onClick={cancelOrderHandler}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* modal for cancelling the order */}
          </div>
        </div>
      </div>
      <NewFooter />
    </>
  );
};

export default NewCheckOut;
