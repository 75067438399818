import React, { useEffect, useState } from "react";
import "./NewLogin.css";
import NewNavbar from "../../../Components/NavBar/NewNavbar";
import NewFooter from "../NewFooter/NewFooter";
import { PageEventTypeEnum } from "../../../Model/ApiCallResponse";
import { ApiCallState } from "../../../Model/Enums/ApiCallState";
import { RoleTypeEnum } from "../../../Model/Enums/RoleTypeEnum";
import { PrincipleResponse } from "../../../Model/NutritapResponseModel";
import { apiCaller } from "../../../Services/ApiCallService";
import {
  PrincipleStore,
  apiLoadingSubject,
  CatalogueStore,
} from "../../../Store";
import { messageConstants } from "../../../StringConstants";
import { useNavigate } from "react-router";
import NewSignUp from "./NewSignUp";
import { ApiUrlBuilder } from "../../../utils/Stringbuilder";
import { ApiTypeEnum, SubPathEnum } from "../../../Model/Enums/ApiConstantEnum";
import { Link } from "react-router-dom";
import {logFirebaseEvent, setFirebaseUserId} from "../../../Firebase/Analytics";
import {EventName} from "../../../Firebase/Firebase";
import { SearchParamsService } from "../../../Services/SearchParamsService";
import { showErrorToast, showWarningToast } from "../../../utils/Toastify/ToastifyHandler";
import { ClientInfoService } from "../../../Services/ClientInfoService";

const NewLogin = () => {
  const [isSignInForm, setIsSignInForm] = useState(true);
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  // const [message, setMessage] = useState<string>("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassWord, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const navigateToCategoryPage = () => {
    navigate("/product");
  };

  useEffect(() => {
    const storedUsername = localStorage.getItem("rememberedUsername");
    if (storedUsername) {
      setUserName(storedUsername);
      setRememberMe(true);
    }
  }, []);
  const textChangeHandler = {
    userName: (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserName(event.target.value);
    },
    password: (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(event.target.value);
    },
  };
  const loginHandler = async () => {
    // const url = `${B2BConstants.BASE_URL}${ApiConstants.USER.LOGIN}`
    const userUrl = new ApiUrlBuilder(ApiTypeEnum.USERS);
    userUrl.addSubPath(SubPathEnum.ADMIN_LOGIN);
    const url = userUrl.buildURL();
    const body = JSON.stringify({
      username: userName,
      password: password,
    });

     apiCaller.postResponseToServer(url, body).then((res) => {
      if (!res) {
        return;
      }
      const result: PrincipleResponse = res;
      if (result.success) {
        PrincipleStore.setPrinciple(result);
        apiLoadingSubject.next({
          apiCallState: ApiCallState.LOADING_SUCCESS,
          eventType: PageEventTypeEnum.ROLE,
        });
        setFirebaseUserId(result.userId + '_' + result.role);
        logFirebaseEvent("login" as EventName, {event_category: 'LOGIN', description : result.userId + "_" + result.role});
        while (PrincipleStore.getUserName() === null) {
          PrincipleStore.getRole();
        }
        const clientInfoService = new ClientInfoService;
        clientInfoService.getLoggedInClientInfo();
        CatalogueStore.setCatalogue(null);
        if (
          result.role === RoleTypeEnum.ADMIN ||
          result.role === RoleTypeEnum.CLIENT
        ) {
          navigateToCategoryPage();
        }
      } else {
        if (
          userName.trim() === "" &&
          password.trim() === "" &&
          !userName &&
          !password
        ) {
          showWarningToast(messageConstants.USER_NAME_AND_PASS);
        } else if (userName.trim() === "" && !userName) {
          showWarningToast(messageConstants.USER_NAME_NOT_EMPTY);
        } else if (password.trim() === "" && !password) {
          showWarningToast(messageConstants.PASSWORD_NOT_EMPTY);
        } else {
          showErrorToast(messageConstants.PASSWORD_IS_WRONG);
        }
      }
    }).catch((err) => {
        console.log(err)
      showErrorToast("Something went wrong!")
    });
    if (rememberMe) {
      localStorage.setItem("rememberedUsername", userName);
    } else {
      localStorage.removeItem("rememberedUsername");
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const navigateToRegister = () => {
    navigate("/register");
    scrollToTop();
  };

  const getSearchedValuefromNav = (searchedProduct: string) => {
    navigate(`/product`);

    SearchParamsService.setParams([
      { key: "sCid", value: "" },
      { key: "brandIds", value: "" },
      { key: "productName", value: searchedProduct },
    ]);
  };
  return (
    <>
      <div className="container">
        <NewNavbar
          signIn={true}
          account={false}
          cart={true}
          admin={false}
          searchBar={true}
          getFilteredProductsFromNav={getSearchedValuefromNav}
        />
        <div className="page_path border-bottom">
          <p>
            <Link to="/">Home</Link>
            <span>
              <i className="bi bi-chevron-right"></i>
            </span>{" "}
            Login & Registration
          </p>
        </div>

        <div className="forms_wrpapper paddingBottom paddingTop text-center">
          {/* <!-- Login & Registration Tabs Start --> */}
          <div className="default_tabs paddingBottom">
            <ul
              className="nav nav-pills mb-2 mb-md-3 justify-content-center"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${isSignInForm ? "active" : ""}`}
                  onClick={() => setIsSignInForm(true)}
                  id="pills-Login-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-Login"
                  type="button"
                  role="tab"
                  aria-controls="pills-Login"
                  aria-selected="true"
                >
                  Login
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${!isSignInForm ? "active" : ""}`}
                  onClick={navigateToRegister}
                  id="pills-Registration-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-Registration"
                  type="button"
                  role="tab"
                  aria-controls="pills-Registration"
                  aria-selected="false"
                >
                  Registration
                </button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              {isSignInForm ? (
                <div
                  className="tab-pane fade show active"
                  id="pills-Login"
                  role="tabpanel"
                  aria-labelledby="pills-Login-tab"
                >
                  <p className="grey_clr mobile_fs mb-0">
                    If you have an account, sign in with your username or email
                    address.
                  </p>
                  <form
                    className="default_form mt-3 mt-md-4"
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <input
                      name=""
                      type="text"
                      placeholder="Username or E-mail Address *"
                      required
                      onChange={textChangeHandler.userName}
                      value={userName}
                    />
                    <input
                      name=""
                      type="password"
                      placeholder="Password *"
                      required
                      onChange={textChangeHandler.password}
                      value={password}
                    />

                    <div className="remember_para text-start">
                      <div className="ps-0 d-flex align-items-center form-check fs-14">
                        <label className="form-check-label grey_clr remember-btn">
                          <input
                            type="checkbox"
                            className="form-check-input shadow-none me-2"
                            id="rememberMe"
                            onChange={() => setRememberMe(!rememberMe)}
                          />
                          Remember me
                        </label>
                      </div>
                      <Link className="anchor_link" to="/forgetPassword">
                        Forget Password?
                      </Link>
                    </div>
                    {/* <div className="login-field" style={{ color: "red" }}>
                      <i>{showWarningToast.{}}</i>
                    </div> */}

                    <button
                      className="theme_btn text-center px-4 mt-4 mt-md-5"
                      onClick={loginHandler}
                    >
                      Login
                    </button>
                  </form>
                </div>
              ) : (
                <NewSignUp client={false} />
              )}
            </div>
          </div>
          {/* <!-- Login & Registration Tabs End --> */}
        </div>
      </div>
      <NewFooter />
    </>
  );
};

export default NewLogin;
