export interface UserDto {
    active: boolean;
    businessAddress: string;
    clientSpocCompany: string;
    readonly createdBy: number;
    readonly createdByName: string;
    readonly createdOn: number;
    email: string;
    gst: string;
    readonly id: number;
    latitude: number;
    location: number;
    longitude: number;
    mobile: string;
    readonly modifiedBy: string;
    modifiedByName: string;
    readonly modifiedOn: number;
    name: string;
    ntSpoc: number;
    password: string;
    roiModel: string;
    role: string;
    city: string;
    state: string;
    address: string;
    shippingAddress: string;
    pincode: number;
    locationName: string;
}


export interface UserRegisterModel {
    shippingAddress: string,
    billingAddress: string,
    name: string,
    clSpocCompany: string,
    ntSpoc: number,
    email: string,
    mobile: string,
    password: string,
    userRoles: string,
    active: boolean,
    gst: string,
    locationDto: AddressDto,
}


export interface AddressDto {
    name: string,
    address: string,
    stateName: string,
    region: string,
    pincode: number,
    country: string,
    city: string,
}

export const UserDtoInitialValue: UserDto = {
    active: false,
    businessAddress: "",
    clientSpocCompany: "",
    createdBy: 0,
    createdByName: "",
    createdOn: 0,
    email: "",
    gst: "",
    id: 0,
    latitude: 0,
    location: 0,
    longitude: 0,
    mobile: "",
    modifiedBy: "",
    modifiedByName: "",
    modifiedOn: 0,
    name: "",
    ntSpoc: 0,
    password: "",
    roiModel: "",
    role: "",
    city: "",
    state: "",
    address: "",
    shippingAddress: "",
    pincode: 0,
    locationName: ""
}

export interface PasswordUpdateProps {
    reset?: boolean,
    change?: boolean,
    forced?: boolean,
    verify?: boolean,
}