export const PieChartInitialState: PieChartInitialStateModel = {
    labels: [],
    datasets: [
      {
        label: "Sales (In ₹)",
        data: [],
        backgroundColor: ["rgb(54, 162, 235)", "#13A753", "rgb(30% 20% 50%)"],
      },
    ],
  };
  
  export interface PieChartInitialStateModel {
    labels: any[];
    datasets: {
      label: string;
      backgroundColor: any[];
      data: any[];
    }[];
  }