import { PublishEventService } from "./PublishEventService";
import { ApiUrlBuilder } from "../utils/Stringbuilder";
import {
  ApiTypeEnum,
  ParameterEnum,
  SubPathEnum,
} from "../Model/Enums/ApiConstantEnum";
import { PageEventTypeEnum } from "../Model/ApiCallResponse";
import { apiCaller } from "./ApiCallService";
import { createMessage } from "../CreateMessage";
import { CatalogueModel } from "../Model/CatalogueModel";
import { CatalogueStore, PrincipleStore } from "../Store";
import { errorDialogHandler } from "../utils/ErrorDialogHandler";
import { SearchParamsService } from "./SearchParamsService";
import { showErrorToast } from "../utils/Toastify/ToastifyHandler";
import FileSaver from "file-saver";
import ExcelJS from "exceljs";
import { ExcelHeaderEnum } from "../Model/Enums/ExcelHeaderEnum";

export class NewCatalogueService {
  private publishEvent = new PublishEventService();

  getCatalogue(searchParams?: URLSearchParams, isDefaultCatalogue?: boolean) {
    this.publishEvent.loading(PageEventTypeEnum.CATALOGUE);
    // SearchParamsService.setParams({key : "sCid", value : "3"})
    const catalogueUrl = new ApiUrlBuilder(ApiTypeEnum.CATALOGUE);
    this.publishEvent.loading(PageEventTypeEnum.CATALOGUE);
    // Client Logged Out
    if (PrincipleStore.getToken() === null || isDefaultCatalogue) {
      catalogueUrl.addSubPath(SubPathEnum.GET_DEFAULT);
      catalogueUrl.addParameter(ParameterEnum.IS_DEFAULT_CATALOGUE, "true");
    } else {
      // Client Logged In
      catalogueUrl.addSubPath(SubPathEnum.GET);
    }
    if (searchParams) {
      searchParams.forEach((value, key) => {
        catalogueUrl.addParameter(key, value);
      });
    } else {
      SearchParamsService.getParams().forEach((value, key) => {
        catalogueUrl.addParameter(key, value);
      });
    }
    const url = catalogueUrl.buildURL();
    return apiCaller
      .getResponseFromServer(url)
      .then((res) => {
        if (!res) {
          return showErrorToast(createMessage.fetchingFailMessage());
        }
        let catalogue: CatalogueModel = res;
        if (catalogue.catalogueResponse) {
          catalogue.responseMap = new Map(
            Object.entries(catalogue.responseMap)
          );
          catalogue.catalogueResponse.forEach((product) => {
            if (
              catalogue.responseMap &&
              catalogue.responseMap.get(product.productId.toString())
            ) {
              //@ts-ignore
              product.discount = catalogue.responseMap.get(
                product.productId.toString()
              );
            } else {
              product.discount = 0;
            }
          });

          if (PrincipleStore.getIsPoClient() && catalogue.productAndQty) {
            catalogue.productAndQty = new Map(
              Object.entries(catalogue.productAndQty)
            );
            catalogue.catalogueResponse.forEach((product) => {
              if (catalogue.productAndQty.get(product.productId.toString())) {
                product.productQty = catalogue.productAndQty.get(
                  product.productId.toString()
                );
              } else {
                product.productQty = {
                  qty: 0,
                  pCode: "",
                };
              }
            });
          }

          CatalogueStore.setCatalogue(catalogue);
        } else {
          errorDialogHandler();
        }
        this.publishEvent.loadingSuccess(PageEventTypeEnum.CATALOGUE);
        return catalogue.catalogueResponse;
      })
      .catch((err) => {
        console.log(err);
        this.publishEvent.loadingError(PageEventTypeEnum.CATALOGUE);
      });
  }

  downloadExcelHandler(catalogue: CatalogueModel) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    const catalogueTableHeaderForExcel = [
      ExcelHeaderEnum.PID,
      ExcelHeaderEnum.PRODUCT_CODE,
      ExcelHeaderEnum.CATEGORY,
      ExcelHeaderEnum.SUBCATEGORY,
      ExcelHeaderEnum.BRAND,
      ExcelHeaderEnum.PRODUCT_NAME,
      ExcelHeaderEnum.PHOTO,
      ExcelHeaderEnum.COST,
      ExcelHeaderEnum.GST_Excl_Amount,
      ExcelHeaderEnum.DISCOUNT,
      ExcelHeaderEnum.GST,
      ExcelHeaderEnum.AVAILABLE_QTY,
      ExcelHeaderEnum.QUANTITY,
    ];
    const headerRow = worksheet.addRow(catalogueTableHeaderForExcel);
    headerRow.font = { bold: true };

    worksheet.columns.forEach((column, index) => {
      let columnWidth = 20; // default width
      switch (index) {
        case 1:
          columnWidth = 30;
          break;
        case 2:
          columnWidth = 30;
          break;
          case 3:
            columnWidth = 40;
            break;
        case 4:
          columnWidth = 20;
          break;
        case 5:
          columnWidth = 55;
          break;
        case 9:
          columnWidth = 25;
          break;
        case 10:
            columnWidth = 15;
          break;
        default:
          break;
      }
      column.width = columnWidth;
    });

    catalogue.catalogueResponse.forEach((product) => {
      worksheet.addRow(
        [
        product.productId,
        product.productQty?.pCode,
        product.categoryName,
        product.subCategoryName,
        product.brandName,
        product.productName,
        product.photo,
        product.cost,
        ((product.cost *
          (1 - (!isNaN(product.discount) ? product.discount : 0) / 100)) /
        (1 + product.gst / 100)).toFixed(2),
        product.discount.toFixed(2),
        product.gst,
        product.productQty?.qty,
        0,
      ]);
    });
    worksheet.getColumn(1).hidden = true;
    worksheet.getColumn(7).hidden = true;
    worksheet.getColumn(10).hidden = true;
    {
      !PrincipleStore.getIsPoClient() &&
        (worksheet.getColumn(12).hidden = true);
    }
    {
      !PrincipleStore.getIsPoClient() && (worksheet.getColumn(2).hidden = true);
    }

    worksheet.eachRow({ includeEmpty: true }, (row) => {
      row.eachCell({ includeEmpty: true }, (cell) => {
        cell.protection = { locked: true };
      });
    });

    // unlock the "Qty" column
    worksheet.getColumn(13).eachCell((cell) => {
      cell.protection = { locked: false };
    });

    worksheet.protect("", {
      selectLockedCells: true,
      selectUnlockedCells: true,
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      FileSaver.saveAs(blob, `${catalogue.catalogueName}.xlsx`);
    });
  }

  downloadClientCatalogue() {
    const catalogueUrl = new ApiUrlBuilder(ApiTypeEnum.CATALOGUE);
    // this.publishEvent.loading(PageEventTypeEnum.CATALOGUE);
    catalogueUrl.addSubPath(SubPathEnum.GET);
    catalogueUrl.addParameter(ParameterEnum.IS_DOWNLOAD, true);

    const url = catalogueUrl.buildURL();
    return apiCaller
      .getResponseFromServer(url)
      .then((res) => {
        if (!res) {
          return showErrorToast(createMessage.fetchingFailMessage());
        }
        let catalogue: CatalogueModel = res;
        if (catalogue.catalogueResponse) {
          catalogue.responseMap = new Map(
            Object.entries(catalogue.responseMap)
          );
          catalogue.catalogueResponse.forEach((product) => {
            if (
              catalogue.responseMap &&
              catalogue.responseMap.get(product.productId.toString())
            ) {
              //@ts-ignore
              product.discount = catalogue.responseMap.get(
                product.productId.toString()
              );
            } else {
              product.discount = 0;
            }
          });

          if (PrincipleStore.getIsPoClient() && catalogue.productAndQty) {
            catalogue.productAndQty = new Map(
              Object.entries(catalogue.productAndQty)
            );
            catalogue.catalogueResponse.forEach((product) => {
              if (catalogue.productAndQty.get(product.productId.toString())) {
                product.productQty = catalogue.productAndQty.get(
                  product.productId.toString()
                );
              } else {
                product.productQty = {
                  qty: 0,
                  pCode: "",
                };
              }
            });
          }
          CatalogueStore.setCatalogue(catalogue);
          this.downloadExcelHandler(catalogue);
        } else {
          errorDialogHandler();
        }
        this.publishEvent.loadingSuccess(PageEventTypeEnum.CATALOGUE);
        return catalogue.catalogueResponse;
      })
      .catch((err) => {
        console.log(err);
        this.publishEvent.loadingError(PageEventTypeEnum.CATALOGUE);
      });
  }
}
