import React, { useEffect, useState } from "react";
import "./NewCart.css";
import NewNavbar from "../../../Components/NavBar/NewNavbar";
import NewFooter from "../../PublicView/NewFooter/NewFooter";
import {
  CartItem,
  OrderExcelDto,
  transformData,
} from "../../../Model/CartModel";
import { CartStore, PrincipleStore } from "../../../Store";
import { messageConstants } from "../../../StringConstants";
import { PageEventTypeEnum } from "../../../Model/ApiCallResponse";
import { PublishEventService } from "../../../Services/PublishEventService";
import ShoppingCart from "../../../Components/Images/cart.png";
import { HashLink } from "react-router-hash-link";
import { PageTypeEnum } from "../../../Model/Enums/PageTypeEnums";
import { AvailableQty } from "../../../Components/Styled-Components/Styled";
import { useNavigate } from "react-router";
import { B2BConstants } from "../../../B2BConstants";
import { CheckService } from "../../../Services/checkValidationService";
import {
  showErrorToast,
  showInfoToast,
  showSuccessToast,
  showWarningToast,
} from "../../../utils/Toastify/ToastifyHandler";
import { readExcelService } from "../../../Services/ReadExcelService";
import { ProductEntity } from "../../../Model/CatalogueModel";
import { NewCatalogueService } from "../../../Services/NewCatalogueService";
import UploadOrder from "./UploadOrder";
import { ExcelHeaderEnum } from "../../../Model/Enums/ExcelHeaderEnum";
import { SearchParamsService } from "../../../Services/SearchParamsService";

const NewCart = () => {
  const [addedProducts, setAddedProducts] = useState<CartItem[]>(
    new Array<CartItem>()
  );
  // const cartMap = CartStore.getCart();
  const [paymentTotal, setPaymentTotal] = useState<number>(0);
  const [gstAmount, setGstAmount] = useState<number>(0);
  const [cart, setCart] = useState<Map<string, CartItem>>(CartStore.getCart);

  // const catalogueProducts = new Array<ProductModel>();
  // const index = 0;
  const maximumProductAmount = 10000;
  // const quantity = cart.get(catalogueProducts[index]?.productId.toString())?.qty || 0;
  const excelService = new readExcelService();
  const catalogue = new NewCatalogueService();

  const navigate = useNavigate();

  // navigate to checkout if logged in if not, redirect to login page
  const navigateToCheckout = () => {
    if (CheckService.isLoggedIn()) {
      navigate(`/newCheckout`);
    } else {
      showErrorToast(messageConstants.NOT_LOGGED_IN);
      navigate(`/login`);
    }
    window.scrollTo(0, 0);
  };

  // const changeItemHandler = (
  //   e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  //   item: CartItem
  // ) => {
  //   let quantity = parseInt(e.target.value);
  //   if (/^\d*\.?\d*$/.test(e.target.value)) {
  //     if (!e.target.value || e.target.value.trim() === "") {
  //       showWarningToast(messageConstants.CANNOT_EMPTY);
  //     } else {
  //       quantity = parseInt(e.target.value);
  //     }
  //   } else {
  //     showErrorMessage("Invalid Input");
  //   }
  //   if (isNaN(quantity)) {
  //     quantity = 1;
  //   }
  //   if (quantity > 0) {
  //     if (
  //       PrincipleStore.getIsPoClient() &&
  //       item.avlQty &&
  //       quantity > item.avlQty
  //     ) {
  //       quantity = item.avlQty;
  //       showWarningToast(
  //         messageConstants.CANNOT_EXCEED_MAX_LIMIT(item.avlQty)
  //       );
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 1000);
  //     } else {
  //       if (quantity > 10000) {
  //         quantity = 10000;
  //         showWarningToast(messageConstants.CANNOT_EXCEED_MAX_LIMIT(10000));
  //         setTimeout(() => {
  //           window.location.reload();
  //         }, 1000);
  //       }
  //     }

  //     const cartItem: CartItem = { ...item, qty: quantity };
  //     cartMap.set(item.product.pid.toString(), cartItem);
  //     setAddedProducts(Array.from(cartMap.values()));
  //     CartStore.setCart(cartMap);
  //   }
  // };

  // useEffect(() => {
  //   calculateQtyAndAmount();
  // }, [cart.size, cart, addedProducts]);

  const calculateQtyAndAmount = () => {
    let total: number = 0;
    // let noOfItems: number = 0;
    let GSTAmount: number = 0;
    addedProducts.forEach((item) => {
      total +=
        item.product?.cost *
        (1 - item.applicableDiscount / 100) *
        (!isNaN(item.qty) && item.qty ? item.qty : 1);
      // noOfItems += !isNaN(item.qty) && item.qty ? item.qty : 1;
      GSTAmount +=
        item.product?.cost *
        (1 - item.applicableDiscount / 100) *
        (item.gst / 100) *
        (!isNaN(item.qty) && item.qty ? item.qty : 1);
    });
    setPaymentTotal(total);
    setGstAmount(GSTAmount);
  };

  useEffect(() => {
    const publishEventService = new PublishEventService();

    setAddedProducts(Array.from(cart.values()));
    calculateQtyAndAmount();
    CartStore.setCart(cart);
    publishEventService.changed(PageEventTypeEnum.CART);
  }, [cart, addedProducts]);

  // adds a product(quantity + 1) to the cart in cart page
  const handleAdd = (productId: number) => {
    let key = productId.toString();
    const existingProduct = cart.get(key);

    if (existingProduct && !PrincipleStore.getIsPoClient()) {
      if (existingProduct.qty >= maximumProductAmount) {
        showInfoToast(
          messageConstants.CANNOT_EXCEED_MAX_LIMIT(maximumProductAmount)
        );
      } else if (existingProduct.qty < maximumProductAmount) {
        existingProduct.qty += 1;
        cart.set(key, existingProduct);
        const cartMap: Map<string, CartItem> = new Map(cart);
        setCart(cartMap);
        CartStore.setCart(cartMap);
      }
    } else if (
      existingProduct &&
      existingProduct.avlQty &&
      existingProduct.avlQty - existingProduct.qty > 0
    ) {
      existingProduct.qty += 1;
      cart.set(key, existingProduct);
      const cartMap: Map<string, CartItem> = new Map(cart);
      setCart(cartMap);
      CartStore.setCart(cartMap);
    }
  };

  // deletes a product(quantity - 1) from cart in cart page
  const handleMinus = (productId: number) => {
    let key = productId.toString();
    const existingProduct = cart.get(key);
    if (existingProduct && existingProduct.qty > 0) {
      existingProduct.qty -= 1;
      if (existingProduct.qty === 0) {
        cart.delete(key);
      } else {
        cart.set(key, existingProduct);
      }
    }
    const cartMap: Map<string, CartItem> = new Map(cart);
    setCart(cartMap);
  };

  // deletes a whole product from cart in cart page
  const handleDeleteProduct = (productId: number) => {
    cart.delete(productId.toString());
    const cartMap: Map<string, CartItem> = new Map(cart);
    setCart(cartMap);
  };

  // can change quantity of cart item in cart page according to user input
  const handleOnQuantityChange = (
    productId: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (Number(e.target.value) > maximumProductAmount) {
      showInfoToast(
        messageConstants.CANNOT_EXCEED_MAX_LIMIT(maximumProductAmount)
      );
      return;
    }
    let key = productId.toString();
    const newQty = Number(e.target.value);
    if (newQty === 0) {
      cart.delete(key);
    } else {
      const existingProduct = cart.get(key);
      if (existingProduct && !PrincipleStore.getIsPoClient()) {
        existingProduct.qty = newQty;
        cart.set(key, existingProduct);
      } else if (
        existingProduct &&
        existingProduct.avlQty &&
        existingProduct.avlQty - newQty >= 0
      ) {
        existingProduct.qty = newQty;
        cart.set(key, existingProduct);
      } else if (
        existingProduct &&
        existingProduct.avlQty &&
        newQty > existingProduct.avlQty
      ) {
        showInfoToast(
          messageConstants.CANNOT_EXCEED_MAX_LIMIT(existingProduct?.avlQty)
        );
        return;
      }
    }
    const cartMap: Map<string, CartItem> = new Map(cart);
    setCart(cartMap);
  };

  // deletes whole cart products in cart page
  const deleteAllCart = () => {
    CartStore.deleteCart();
    const cartMap: Map<string, CartItem> = new Map();
    setCart(cartMap);
  };

  const currentUserId = PrincipleStore.getUserId();

  //@ts-ignore
  const isExistingClients = B2BConstants.EXISTING_CLIENTS.includes(currentUserId);

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file: Blob = event.target.files![0];
    const data: any = await excelService.getReadExcelData(file);
    const excelData: OrderExcelDto[] = transformData(data);
    const expectedHeaders = [
      ExcelHeaderEnum.PID,
      ExcelHeaderEnum.CATEGORY,
      ExcelHeaderEnum.SUBCATEGORY,
      ExcelHeaderEnum.BRAND,
      ExcelHeaderEnum.PRODUCT_NAME,
      ExcelHeaderEnum.PHOTO,
      ExcelHeaderEnum.COST,
      ExcelHeaderEnum.GST_Excl_Amount,
      ExcelHeaderEnum.DISCOUNT,
      ExcelHeaderEnum.GST,
      ExcelHeaderEnum.QUANTITY,
    ];
    if (PrincipleStore.getIsPoClient() === true) {
      expectedHeaders.splice(9, 0, ExcelHeaderEnum.AVAILABLE_QTY);
    }

    const uploadedHeaders = Object.keys(data[0]);

    const headersMatch = expectedHeaders.every((header: any) =>
      uploadedHeaders.includes(header)
    );
    if (!headersMatch) {
      showErrorToast(messageConstants.HEADERS_NOT_MATCH);
      return;
    }

    const newCart: Map<string, CartItem> = new Map(cart);
    const newUploadedData: CartItem[] = [];

    excelData.forEach((item: OrderExcelDto) => {
      if (item.qty > maximumProductAmount) {
        showWarningToast(
          messageConstants.CANNOT_EXCEED_MAX_LIMIT(maximumProductAmount)
        );
        return;
      }
      else if (PrincipleStore.getIsPoClient() && item.avlQty <= 0) {
        showWarningToast(messageConstants.OUT_OF_STOCK(item.pname));
        
      } else if (PrincipleStore.getIsPoClient() && item.qty > item.avlQty) {
        showWarningToast(messageConstants.MORE_THAN_AVL_QTY);
        
      }
      else if (item.qty > 0) {
        const product: ProductEntity = {
          pid: item.pid,

          photo: item.photo,
          pname: item.pname,
          cost: item.cost,
        };
        const cartItem: CartItem = {
          product: product,
          qty: item.qty,
          avlQty: item.avlQty - (cart.get(item.pid.toString())?.qty || 0),
          discountedPrice: Number(
            (
              item.cost *
              (1 - (!isNaN(item.discount) ? item.discount : 0) / 100)
            ).toFixed(2)
          ),
          applicableDiscount: item.discount,
          gst: item.gst || 0,
        };
        newUploadedData.push(cartItem);
        newCart.set(product.pid.toString(), cartItem);
      }
    });
    if (newUploadedData.length === 0) {
      showErrorToast(messageConstants.UPDATE_QUANTITY_OF_ITEMS);

    } else {
      showSuccessToast(messageConstants.FILE_UPLOADED);
    }
    setCart(newCart);
    setAddedProducts(newUploadedData);
  };

  const navigateToProduct = () => {
    navigate(`/product?`);
    SearchParamsService.setParams([
      { key: "selectedScid", value: "" },
      { key: "selectedCid", value: "" },
      { key: "sCid", value: "" },
      { key: "category", value: "" },
      { key: "brandIds", value: "" },
    ]);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="container">
        <NewNavbar
          signIn={true}
          account={false}
          cart={true}
          admin={false}
          searchBar={true}
          getFilteredProductsFromNav={() => {}}
        />
        <div className="page_path border-bottom d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-between">
          <p>
            <HashLink to="/">Home</HashLink>
            <span>
              <i className="bi bi-chevron-right"></i>
            </span>
            <HashLink to="/product">Shop</HashLink>
            <span>
              <i className="bi bi-chevron-right"></i>
            </span>
            <HashLink to="/newCart">Cart</HashLink>
          </p>
          <span className="cart-icons">
            {PrincipleStore.getToken() && (
              <>
                {/* <i
                  className="bi bi-arrow-down-circle cart_icons"
                  title="Download My Catalogue"
                  onClick={() => downloadCatalogueHandler()}
                ></i> */}
                <UploadOrder updateCart={handleFileUpload} />
              </>
            )}
          </span>
        </div>
        {cart.size !== 0 ? (
          <div className="container mt-4">
            <div className="row table_wrapper">
              <div className="col-7 table-scroll">
                <table className="default_table table table-hover cart_table ">
                  <thead className="table_head">
                    <tr className="green_clr">
                      <th scope="col">Product</th>
                      <th scope="col">Price (₹)</th>
                      <th scope="col">Quantity</th>
                      {PrincipleStore.getIsPoClient() && (
                        <th>Available Quantity</th>
                      )}
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {addedProducts?.map((item, index) => {
                      return (
                        <tr className="prod-details-wrapper" key={index}>
                          <td>
                            <div className="d-flex align-items-center justify-content-start">
                              <div className="cartProduct_img">
                                <img
                                  className="w-100 img-fluid"
                                  src={item.product?.photo}
                                  alt="Product"
                                />
                              </div>
                              <div className="">
                                <p className="fw-500 mb-0">
                                  {item.product?.pname}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="fw-500">
                            {Number(
                              (
                                item.product?.cost *
                                (1 -
                                  (!isNaN(item.applicableDiscount)
                                    ? item.applicableDiscount
                                    : 0) /
                                    100)
                              ).toFixed(2)
                            ).toLocaleString("en-IN")}
                          </td>
                          <td>
                            <div className="quantity-number me-2 me-sm-3">
                              <span className="minus">
                                <i
                                  className="bi bi-dash-lg"
                                  onClick={() => handleMinus(item.product.pid)}
                                ></i>
                              </span>
                              <input
                                className="text-center"
                                type="number"
                                min={0}
                                max={10000}
                                value={
                                  cart.get(item.product?.pid.toString())?.qty
                                }
                                onChange={(e) => {
                                  handleOnQuantityChange(item.product.pid, e);
                                }}
                              />
                              <span className="plus">
                                <i
                                  className="bi bi-plus-lg"
                                  onClick={() => handleAdd(item.product.pid)}
                                ></i>
                              </span>
                            </div>
                          </td>
                          {PrincipleStore.getIsPoClient() && item.avlQty && (
                            <td className="avilableQuanity">
                              <AvailableQty
                                //@ts-ignore
                                maxQty={item.avlQty}
                                currQty={
                                  cart.get(item.product.pid.toString())?.qty ||
                                  0
                                }
                              >
                                {item.avlQty -
                                  (cart.get(item.product.pid.toString())?.qty ||
                                    0)}
                              </AvailableQty>
                            </td>
                          )}
                          <td>
                            <div
                              className="delete_productBtn"
                              onClick={() => {
                                handleDeleteProduct(item.product.pid);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                fill="red"
                                className="bi bi-trash"
                                viewBox="0 0 32 32"
                              >
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                              </svg>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="col mt-5 cart_total_wrapper">
                <div className="coupon_wrap mt-3">
                  <div className="d-flex align-items-center justify-content-start"></div>

                  <div className="border_r20 cart_total_wrap px-3 px-sm-4 py-4">
                    <div className="border-bottom mb-3 pb-3 w-100">
                      <p className="fw-bold mb-0">Cart Totals</p>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <p className="mb-0">Subtotal</p>
                      <p className="mb-0 text-end">
                        {Number(paymentTotal.toFixed(2)).toLocaleString(
                          "en-IN"
                        )}
                      </p>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <p className="mb-0">GST (included)</p>
                      <p className="mb-0 text-end">
                        {Number(gstAmount.toFixed(2)).toLocaleString("en-IN")}
                      </p>
                    </div>
                    {/* <div className="d-flex align-items-center justify-content-between mb-3">
                      <p className="mb-0">Delivery</p>
                      <p className="mb-0 text-end grey_clr">Free</p>
                    </div> */}

                    <div className="total_price">
                      <p className="fw-bold mb-0">Total</p>
                      <p className="fw-bold mb-0">
                        {`₹ ${Number(paymentTotal.toFixed(2)).toLocaleString(
                          "en-IN"
                        )}`}
                      </p>
                    </div>

                    {isExistingClients || paymentTotal > 10000 ? (
                      <button
                        onClick={navigateToCheckout}
                        className="theme_btn w-100 mt-3"
                      >
                        Go To Checkout
                      </button>
                    ) : (
                      <>
                        <div className="d-flex align-items-center justify-content-between mt-1">
                          <p className="mb-0">
                            {`* Add items worth ₹ ${Number(
                              10000 - paymentTotal
                            ).toFixed(2)} more for proceeding to checkout.`}
                          </p>
                        </div>
                        <button
                          disabled
                          className="theme_btn w-100 mt-3 disabled_button"
                        >
                          Go To Checkout
                        </button>
                      </>
                    )}
                  </div>
                </div>

                <div className="d-flex align-items-end justify-content-end mt-4 btn_grp">
                  <HashLink to={`/${PageTypeEnum.CATEGORY}#top`}>
                    <button
                      type="button"
                      className="btn fs-14 px-4 fw-500 clear_btn"
                    >
                      Add More Products
                    </button>
                  </HashLink>
                  <button
                    type="button"
                    className="btn fs-14 px-4 fw-500 clear_btn"
                    onClick={deleteAllCart}
                  >
                    Clear All
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="cart_empty">
              <img src={ShoppingCart} alt="Shopping-Cart" />
                <button
                  type="button"
                  className="btn fs-14 px-4 fw-500 clear_btn"
                  onClick={() =>navigateToProduct()}
                >
                  Add More Products
                </button>
            </div>
          </>
        )}
      </div>

      <NewFooter />
    </>
  );
};

export default NewCart;
