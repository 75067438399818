export enum ApiCallState {
    NONE,
    LOADING,
    LOADING_SUCCESS,
    DATA_LOADED,
    LOADING_ERROR,
    CHANGED,
    INITIATED,
    UNAUTHORISED,
    RELOAD,
    CLICKED
}

export enum ApiResponseStatusEnum{
    SUCCESS ='Success',
    FAILURE ='failure',
}