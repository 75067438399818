import "./NewCategoryLoader.css";
const NewCategoryLoader = () => {
  return (
    <>
      <div className="skeleton">
        <div className="s-line first"></div>
        <div></div>
        <div className="s-line third"></div>
        <div></div>

        <div className="s-line third"></div>
        <div></div>
      </div>
      <div className="skeleton">
        <div className="s-line first"></div>
        <div></div>
        <div className="s-line third"></div>
        <div></div>

        <div className="s-line third"></div>
        <div></div>
      </div>
    </>
  );
};

export default NewCategoryLoader;
