import React, { Fragment, useEffect, useState } from "react";
import "./AllMembersView.css";
import { Subscription } from "rxjs";
import { PageEventTypeEnum } from "../../../Model/ApiCallResponse";
import { ApiCallState } from "../../../Model/Enums/ApiCallState";
import { UserDto } from "../../../Model/UserModel";
import { ClientInfoService } from "../../../Services/ClientInfoService";
import { apiLoadingSubject } from "../../../Store";
import { messageConstants } from "../../../StringConstants";
import { HashLink } from "react-router-hash-link";
import noDataFound from "../../../Components/Images/NoData.jpeg";
import { showErrorToast } from "../../../utils/Toastify/ToastifyHandler";
import TableLoading from "../../LoadingComponent/TableLoading";

const AllMembersView = () => {
  const [allClientList, setAllClientList] = useState<Array<UserDto>>([]);
  const [isAllClientListLoading, setIsAllClientListLoading] =
    useState<boolean>(true);
  // const [openDropdown, setOpenDropdown] = useState<number | null>(null);
  // const [points, setPoints] = useState({ x: 0, y: 0 });
  let allClientListSubscriber: Subscription;

  const clientInfo = new ClientInfoService();

  useEffect(() => {
    clientInfo.getAllClientList();

    allClientListSubscriber = apiLoadingSubject.subscribe((response) => {
      if (PageEventTypeEnum.CLIENT_LIST === response.eventType) {
        switch (response.apiCallState) {
          case ApiCallState.LOADING:
            setIsAllClientListLoading(true);
            break;
          case ApiCallState.LOADING_SUCCESS:
            setAllClientList(response.data);
            setIsAllClientListLoading(false);
            break;
          case ApiCallState.LOADING_ERROR:
            showErrorToast(messageConstants.FAILED);
            setIsAllClientListLoading(false);
        }
      }
    });

    return () => {
      if (allClientListSubscriber) allClientListSubscriber.unsubscribe();
    };
  }, []);

  const deleteMemberHandler = (id: number) => {
    clientInfo.deleteMember(id);
  };

  return (
    <>
      <div className="heading-wrapper d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-between">
        <p className="fw-bold mb-2 mb-sm-0 me-3 me-sm-0">Members</p>

        <HashLink
          className="theme_btn mt-2 mt-sm-0 ms-sm-3 add-member-button"
          to="/add-members"
        >
          Add Member
        </HashLink>
      </div>

      <div className="table-responsive mt-4 col-7 table-fixed scrollbar table-wrapper">
        {isAllClientListLoading ? (
          <TableLoading />
        ) : allClientList?.length > 0 ? (
          <table className=" table">
            <thead>
              <tr className="green_clr">
                <th scope="col">Name</th>
                <th scope="col">Email Address</th>
                <th scope="col">Mobile No.</th>
                <th scope="col">Address</th>
                <th scope="col">Role</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {allClientList &&
                allClientList.map((item, index) => (
                  <Fragment key={index}>
                    <tr key={item.id}>
                      <td>{item.clientSpocCompany}</td>
                      <td>{item.email}</td>
                      <td>{item.mobile}</td>
                      <td>{item.address}</td>
                      <td>{item.role ? "Staff" : ""}</td>
                      <td>
                        <button
                          className=" p-1 btn btn-danger rounded m-1"
                          onClick={() => deleteMemberHandler(item.id)}
                        >
                          Disable
                        </button>
                      </td>
                    </tr>
                  </Fragment>
                ))}
            </tbody>
          </table>
        ) : (
          <div className="image-pos">
            <img className="no-data-found" src={noDataFound} alt="" />
          </div>
        )}
      </div>
    </>
  );
};

export default AllMembersView;
