export const ProgressBarInitialState: ProgressBarInitialStateModel = {
  labels: [],
  datasets: [
    {
      label: "Sales (In ₹)",
      data: [],
      barThickness: 7,
      backgroundColor: [
        "rgb(54, 162, 235)",
        "#13A753",
        "rgb(30% 20% 50%)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 205, 86, 0.7)",
        "rgba(153, 102, 255, 0.7)",
      ],
      borderRadius: 8,
    },
  ],
};

export interface ProgressBarInitialStateModel {
  labels: any[];
  datasets: {
    label: string;
    barThickness: number;
    backgroundColor: any[];
    data: any[];
    borderRadius?: number;
  }[];
}
export const ProgressBarOptions: {} = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 1,
      barThickness: 7,
    },
  },
  responsive: true,
};
