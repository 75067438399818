import React, { ChangeEvent, useState } from "react";
import { GetInTouchDto } from "../../../Model/GetInTouchDto";
import { ClientInfoService } from "../../../Services/ClientInfoService";
import { ValidationService } from "../../../Services/ValidationService";
import { showErrorToast } from "../../../utils/Toastify/ToastifyHandler";
import { messageConstants } from "../../../StringConstants";

interface DiwaliBannerPopUpProps {
  isOpen: boolean;
  onClose: () => void;
}

const DiwaliBannerPopUp: React.FC<DiwaliBannerPopUpProps> = ({
  isOpen,
  onClose,
}) => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");

  const onInputChangeHandler = {
    name: (event: React.ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
    },
    email: (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
    },
    subject: (event: React.ChangeEvent<HTMLInputElement>) => {
      setSubject(event.target.value);
    },
    message: (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setMessage(event.target.value);
    },
    mobile: (event: React.ChangeEvent<HTMLInputElement>) => {
      setMobile(event.target.value);
    },
  };
  const sendDetailsHandler = () => {
    if (!ValidationService.validatePhoneNo(parseInt(mobile).toString())) {
      showErrorToast(messageConstants.PHONE_NO_INVALID);
    }
    else if (!ValidationService.validateEmail(email)) {
      showErrorToast(messageConstants.EMAIL_INVALID)
    }
    else {
    const getInTouchRequest: GetInTouchDto = {
      name: name,
      email: email,
      mobile: mobile,
      subject: subject,
      comments: message,
    };
    const clientInfo = new ClientInfoService();
    clientInfo.sendDetailsToServer(getInTouchRequest);
  };
  }
  if (!isOpen) return null;

  return (
    <>
      <div
        className={`modal fade ${isOpen ? "show" : ""}`}
        id={"staticBackdrop"}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        // aria-hidden="true"
        aria-hidden={!isOpen}
        style={
          isOpen
            ? { display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }
            : {}
        }
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Get In Touch!
              </h5>
              <button
                type="button"
                className="cross_btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              >
                <i className="bi bi-x-circle-fill"></i>
              </button>
            </div>
            <div className="modal-body">
              <form className="default_form" action="">
                <div className="row gy-3 gx-3">
                  <div className="col-sm-6">
                    <label className="w-100 fw-500 mb-1">Your Name*</label>
                    <input
                      className="w-100"
                      id="name"
                      type="text"
                      placeholder=""
                      required
                      value={name}
                      onChange={onInputChangeHandler.name}
                    />
                  </div>
                  <div className="col-sm-6">
                    <label className="w-100 fw-500 mb-1">Your Email*</label>
                    <input
                      className="w-100"
                      id="email"
                      type="email"
                      placeholder=""
                      required
                      value={email}
                      onChange={onInputChangeHandler.email}
                    />
                  </div>
                  <div className="col-12">
                    <label className="w-100 fw-500 mb-1">Contact Number*</label>
                    <input
                      className="w-100"
                      id="subject"
                      type="text"
                      placeholder=""
                      required
                      value={mobile}
                      onChange={onInputChangeHandler.mobile}
                    />
                  </div>
                  <div className="col-12">
                    <label className="w-100 fw-500 mb-1">Subject*</label>
                    <input
                      className="w-100"
                      id="subject"
                      type="text"
                      placeholder=""
                      required
                      value={subject}
                      onChange={onInputChangeHandler.subject}
                    />
                  </div>
                  <div className="col-12">
                    <label className="w-100 fw-500 mb-1">Your Message*</label>
                    <textarea
                      className="w-100"
                      name="textarea"
                      id="textarea"
                      value={message}
                      onChange={onInputChangeHandler.message}
                    ></textarea>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn"
                data-bs-dismiss="modal"
                onClick={onClose}
              >
                Close
              </button>
              <button
                type="button"
                className="btn theme_btn"
                data-bs-dismiss="modal"
                onClick={() => {
                  sendDetailsHandler();
                  onClose();
                }}
              >
                Send Message
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DiwaliBannerPopUp;
