import { useEffect, useState } from 'react'
import noDataFound from '../Images/NoDataFound.jpeg'
import './NoDataFound.css'
import { CatalogueStore } from '../../Store'
import { CatalogueService } from '../../Services/CatalogueService'
import { Heading } from '../Styled-Components/Cardstyle'
import { PageTypeEnum } from '../../Model/Enums/PageTypeEnums'
import { useLocation } from 'react-router'



const NoDataFound = (props: { haveSearched: boolean }) => {

    const catalogueService = new CatalogueService()
    const [categoryName, setCategoryName] = useState<string>('');
    const [isCategoryPage, setIsCategoryPage] = useState<boolean>(false);

    const location = useLocation();
    useEffect(() => {
        if (location.pathname.includes(PageTypeEnum.CATEGORY)) {
            setIsCategoryPage(true)
        }
        setCategoryName(CatalogueStore.getSelectedCategoryName())
    }, [location.pathname])

    return (
        <div className='No_Data_Found_Container'>
            {
                catalogueService.checkDesiredCategoryInCatalogue(CatalogueStore.getSelectedCategoryName()) && isCategoryPage ?
                    <div className='no_category_present'>
                        <Heading>{`Contact admin to add ${categoryName ?? ''} products in your catalogue `}</Heading>
                        <img height={'500px'} width={'500px'} alt='No Data Found' src={noDataFound} />
                    </div>
                    :
                    <img height={'500px'} width={'500px'} alt='No Data Found' src={noDataFound} />
            }

        </div>
    )
}

export default NoDataFound