import React from "react";
import "./TableLoading.css"

const TableLoading = () => {
  return (
    <div>
      <table className="tg">
        <tr>
          <th className="tg-cly1">
            <div className="line"></div>
          </th>
          <th className="tg-cly1">
            <div className="line"></div>
          </th>
          <th className="tg-cly1">
            <div className="line"></div>
          </th>
          <th className="tg-cly1">
            <div className="line"></div>
          </th>
          <th className="tg-cly1">
            <div className="line"></div>
          </th>
          <th className="tg-cly1">
            <div className="line"></div>
          </th>
          <th className="tg-cly1">
            <div className="line"></div>
          </th>
          <th className="tg-cly1">
            <div className="line"></div>
          </th>
        </tr>
        <tr>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
        </tr>
        <tr>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
        </tr>
        <tr>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
        </tr>
        <tr>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
          <td className="tg-cly1">
            <div className="line"></div>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default TableLoading;
