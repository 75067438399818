import { apiCaller } from "./ApiCallService";
import { ApiResponseModel } from "../Model/NutritapResponseModel";
import { errorDialogHandler } from "../utils/ErrorDialogHandler";
import { messageConstants } from "../StringConstants";
import { PurchaseOrderRequestDto } from "../Model/PurchaseOrderModel";
import { PublishEventService } from "./PublishEventService";
import { PageEventTypeEnum } from "../Model/ApiCallResponse";
import { ApiResponseStatusEnum } from "../Model/Enums/ApiCallState";
import { PurchaseOrderStore } from "../Store";
import { createMessage } from "../CreateMessage";
import { DownloadFileService } from "./DownloadFileService";
import { ApiUrlBuilder } from "../utils/Stringbuilder";
import { ApiTypeEnum, ParameterEnum, SubPathEnum } from "../Model/Enums/ApiConstantEnum";
import { showErrorToast, showSuccessToast } from "../utils/Toastify/ToastifyHandler";
export class PurchaseOrderService {

    private publishEvent = new PublishEventService();

    getAllPurchaseOrder() {
        // const url = `${B2BConstants.BASE_URL}${ApiConstants.PURCHASE_ORDER.GET_ALL}`;
        const purchaseOrderUrl = new ApiUrlBuilder(ApiTypeEnum.PURCHASE_ORDER)
        purchaseOrderUrl.addSubPath(SubPathEnum.GET_ALL)
        const url = purchaseOrderUrl.buildURL();

        apiCaller.getResponseFromServer(url)
            .then((res: ApiResponseModel) => {
                if (!res) {
                    showErrorToast(messageConstants.FAILED);
                    this.publishEvent.loadingError(PageEventTypeEnum.PURCHASE_ORDER);
                    return;
                }
                if (res.status === ApiResponseStatusEnum.SUCCESS || res.success) {
                    PurchaseOrderStore.setAllPurchaseOrder(res.dtoList);
                    this.publishEvent.loadingSuccess(PageEventTypeEnum.PURCHASE_ORDER);
                } else {
                    this.publishEvent.loadingError(PageEventTypeEnum.PURCHASE_ORDER);
                }
            })
            .catch(() => {
                errorDialogHandler(messageConstants.FAILED);
            });
    }

    approveOrRejectPO(poId: number, isApprove: boolean, remark?: string) {
       
        const approveOrRejectPO = {
            purchaseOrderId: poId,
            approve: isApprove,
            remarks: remark || 'Approved'
        };
        // const url = `${B2BConstants.BASE_URL}${ApiConstants.PURCHASE_ORDER.APPROVE_PO}purchaseOrderId=${poId}&isApprove=${isApproved}&remarks=${remark}`
        const purchaseOrderUrl = new ApiUrlBuilder(ApiTypeEnum.PURCHASE_ORDER)
        purchaseOrderUrl.addSubPath(SubPathEnum.APPROVE_PO)
        const url = purchaseOrderUrl.buildURL();
        const body = JSON.stringify(approveOrRejectPO);
        apiCaller.postResponseToServer(url, body)
            .then((res: ApiResponseModel) => {
                if (!res) {
                    return showErrorToast(createMessage.fetchingFailMessage())
                }

                if (res.status === ApiResponseStatusEnum.SUCCESS || res.success) {
                    showSuccessToast(isApprove ? messageConstants.APPROVE_ORDER : messageConstants.REJECT_ORDER)
                    this.publishEvent.loadingSuccess(PageEventTypeEnum.PENDING_PO)
                    this.publishEvent.reloadPage(PageEventTypeEnum.PURCHASE_ORDER)
                }
                else {
                    this.publishEvent.loadingError(PageEventTypeEnum.PENDING_PO, res.message)
                }
            })
            .catch(() => {
                this.publishEvent.loadingError(PageEventTypeEnum.PENDING_PO, messageConstants.REFRESH_PAGE)
            })
    }

    uploadPurchaseOrder(newPurchaseOrder: PurchaseOrderRequestDto, navigateToPurchaseOrderPage?: Function) {
        // const url = `${B2BConstants.BASE_URL}${ApiConstants.PURCHASE_ORDER.UPLOAD}`
        const purchaseOrderUrl = new ApiUrlBuilder(ApiTypeEnum.PURCHASE_ORDER)
        purchaseOrderUrl.addSubPath(SubPathEnum.GENERATE_PO)
        const url = purchaseOrderUrl.buildURL();
        const formData = new FormData();
        if (newPurchaseOrder.purchaseOrderDto && newPurchaseOrder.purchaseOrder && newPurchaseOrder.purchaseOrderDoc) {
            formData.append('purchaseOrderDto', JSON.stringify(newPurchaseOrder.purchaseOrderDto));
            formData.append('purchaseOrderDoc', newPurchaseOrder.purchaseOrderDoc);
            formData.append('purchaseOrder', newPurchaseOrder.purchaseOrder);
        }

        const body = formData
        apiCaller.postResponseToServer(url, body, true).then((res: ApiResponseModel) => {
            if (!res) {
                return errorDialogHandler(messageConstants.FAILED)
            }
            if (res.status === ApiResponseStatusEnum.SUCCESS || res.success) {
                showSuccessToast(res.message)
                this.publishEvent.reloadPage(PageEventTypeEnum.PURCHASE_ORDER)
                if(navigateToPurchaseOrderPage){
                    setTimeout(()=>{
                        navigateToPurchaseOrderPage();
                    },1000)
                }
            }
            else {
                showErrorToast(res.message)
            }
        }).catch(() => {
            this.publishEvent.loadingError(PageEventTypeEnum.PURCHASE_ORDER)
        })
    }

    downloadExistingPurchaseOrder(id?: number, doc?: boolean) {
        const generateUrl = () => {
            const purchaseOrderUrl = new ApiUrlBuilder(ApiTypeEnum.PURCHASE_ORDER)
            if (doc) {
                purchaseOrderUrl.addSubPath(SubPathEnum.DOWNLOAD_PURCHASE_ORDER_DOC)
                if (id) {
                    purchaseOrderUrl.addParameter(ParameterEnum.PURCHASE_ORDER_ID, id)
                }
                return purchaseOrderUrl.buildURL()
            }
            else {
                purchaseOrderUrl.addSubPath(SubPathEnum.DOWNLOAD_PURCHASE_ORDER)
                if (id) {
                    purchaseOrderUrl.addParameter(ParameterEnum.PURCHASE_ORDER_ID, id)
                }
                return purchaseOrderUrl.buildURL()
            }
        }

        if (doc) {
            DownloadFileService.download(generateUrl(), "PurchaseOrderDoc.pdf")
        } else {
            DownloadFileService.download(generateUrl(), "PurchaseOrder.xlsx")
        }
    }
}