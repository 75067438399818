export enum ExcelHeaderEnum {
  PID = "Pid",
  PRODUCT_CODE = "Product Code",
  PRODUCT_NAME = "Product Name",
  PHOTO = "Photo",
  COST = "MRP",
  DISCOUNT = "Discount",
  CATEGORY = "Category",
  SUBCATEGORY = "SubCategory",
  BRAND = "Brand",
  GST = "GST (%)",
  AVAILABLE_QTY = "Available Qty",
  QUANTITY = "Qty",
  GST_Excl_Amount = "GST Excl. Amount"
}
