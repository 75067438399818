export class DateUtil {

    private date = new Date();

    getTodayDate() {

        var day = this.date.getDate().toString();
        if (parseInt(day) <= 9) {
            day = `0${day}`
        }
        var month = (this.date.getMonth() + 1).toString();
        if (parseInt(month) <= 9) {
            month = `0${month}`
        }
        var year = this.date.getFullYear().toString();
        var currentdate = `${year}-${month}-${day}`
        return currentdate;
    }

    getNextYearDate() {
        var day = this.date.getDate().toString();
        if (parseInt(day) <= 9) {
            day = `0${day}`
        }
        var month = (this.date.getMonth() + 1).toString();
        if (parseInt(month) <= 9) {
            month = `0${month}`
        }
        let year = this.date.getFullYear().toString();
        let currentdate = `${parseInt(year) + 1}-${month}-${day}`
        return currentdate;
    }

    getDateFromEpoch(epoch: number) {
        const date = new Date(epoch);
        let shortDate = date.toString();
        shortDate = shortDate.substring(0, 16);
        return shortDate;
    }

    addOneDay(epoch: number) {
        const date = new Date(epoch);
        return date.setTime(date.getTime() + 86400000);
    }

    calculateExpectedDeliveryDate(inputDate: number) {
        // Parse the input date
        const date = new Date(inputDate);

        if (date.getDay() > 2) {
            date.setDate(date.getDate() + 5);
        } else {
            date.setDate(date.getDate() + 4);
        }
        const formattedDate = date.toString().substring(0, 16)

        // DateUtil.getDateFromEpoch(parseInt(formattedDate))
        return formattedDate;
    }
    currMonthDates() {
        var today = new Date();
        today.setDate(1);
        const startDate = this.fromDateToNumber(today);
      
        today = new Date();
        today.setMonth(today.getMonth() + 1);
        today.setDate(0);
        const endDate = this.endDateToNumber(today);
      
        return {
          start: startDate,
          end: endDate,
        };
      }
    yearSpanDates() {
        let today = new Date();
        today.setDate(1);
        today.setMonth(today.getMonth() - 12);
      
        const startDate = this.fromDateToNumber(today);
      
        today = new Date();
        today.setDate(0);
        const endDate = this.endDateToNumber(today);
      
        return {
          start: startDate,
          end: endDate,
        };
      }
      fromDateToNumber = (date: Date) => {
        date.setHours(0, 0, 0, 0); // Set time to the beginning of the day
        return date.getTime()
      };
      
      endDateToNumber = (date: Date) => {
        date.setHours(23, 59, 59, 999); // Set time to the end of the day
        return date.getTime()
      };
      getQuarterlyDates() {
        const year = this.date.getFullYear();
        const currentMonth = this.date.getMonth() + 1;
        let startMonth, endMonth;
    
        if (currentMonth <= 3) {
          startMonth = 0; // January
          endMonth = 2;   // March
        } else if (currentMonth <= 6) {
          startMonth = 3; // April
          endMonth = 5;   // June
        } else if (currentMonth <= 9) {
          startMonth = 6; // July
          endMonth = 8;   // September
        } else {
          startMonth = 9; // October
          endMonth = 11;  // December
        }
    
        const startDate = new Date(year, startMonth, 1);
        const endDate = new Date(year, endMonth + 1, 0);
    
        return {
          start: this.fromDateToNumber(startDate),
          end: this.endDateToNumber(endDate)
        };
      }


}
