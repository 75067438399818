import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Subscription } from "rxjs";
import {
  ApiCallResponse,
  PageEventTypeEnum,
} from "../../../Model/ApiCallResponse";
import { ApiCallState } from "../../../Model/Enums/ApiCallState";
import { OrderStatusEnum } from "../../../Model/Enums/OrderStatusEnums";
import { OrderDisplayDto } from "../../../Model/OrderModel";
import { DateUtil } from "../../../Services/DateService/dateUtil";
import { OrderService } from "../../../Services/OrderService";
import { ClientAllOrderStore, apiLoadingSubject } from "../../../Store";
import { messageConstants } from "../../../StringConstants";
import NewApprovePendingOrder from "./NewApprovePendingOrder";
import "./NewOrder.css";
import NewOrderHistory from "./NewOrderHistory";
import noDataFound from "../../../Components/Images/NoData.jpeg";
import { showErrorToast } from "../../../utils/Toastify/ToastifyHandler";
import TableLoading from "../../LoadingComponent/TableLoading";

const NewOrder = () => {
  const navigate = useNavigate();
  const [clientAllOrders, setClientAllOrders] = useState<OrderDisplayDto[]>(
    new Array<OrderDisplayDto>()
  );
  const [isClientOrderLoading, setIsClientAllOrderLoading] =
    React.useState<boolean>(true);
  const [isPendingOrder, setIsPendingOrder] = useState<number | null>(0);

  const orderService = new OrderService();
  const date = new DateUtil();

  const getClientAllOrders = () => {
    orderService.getClientAllOrders();
  };

  const toggleStatus = (index: number) => {
    setIsPendingOrder((prevState) => (prevState === index ? null : index));
  };

  useEffect(() => {
    let orderSubscriber: Subscription;

    getClientAllOrders();
    orderSubscriber = apiLoadingSubject.subscribe(
      (response: ApiCallResponse) => {
        if (PageEventTypeEnum.ALL_ORDER === response.eventType) {
          switch (response.apiCallState) {
            case ApiCallState.LOADING:
              setIsClientAllOrderLoading(true);
              break;
            case ApiCallState.LOADING_SUCCESS:
              setClientAllOrders(ClientAllOrderStore.getOrder());
              setIsClientAllOrderLoading(false);
              break;
            case ApiCallState.LOADING_ERROR:
              showErrorToast(response.data ?? messageConstants.REFRESH_PAGE);
              break;

            case ApiCallState.RELOAD:
              getClientAllOrders();
          }
        }
      }
    );

    return () => {
      if (orderSubscriber) {
        orderSubscriber.unsubscribe();
      }
    };
  }, []);
  const navigateToOrderDetail = (orderId: number) => {
    if (!isClientOrderLoading) {
      navigate(`/OrderDetails/${orderId}`);
    }
  };
  const orderStatus = ["Open Orders", "Approved Orders", "Order History"];
  return (
    <>
      <div className="heading-wrap mt-3">
        <p className="fw-bold mb-2 mb-sm-0 me-3 me-sm-0">Orders</p>

        <div className="d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-between">
          <div className="page_path border-bottom">
            <ul
              className="nav nav-pills mb-2 mb-md-3"
              id="pills-tab"
              role="tablist"
            >
              {orderStatus.map((item, index) => {
                return (
                  <li className="nav-item px-2" key={index}>
                    <span
                      role="button"
                      className={
                        isPendingOrder === index
                          ? "text-success text-decoration-underline"
                          : ""
                      }
                      onClick={() => toggleStatus(index)}
                    >
                      {item}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      {isPendingOrder === 0 && (
        <>
          <div className="table-responsive mt-4 col-7 table-fixed table-wrap">
            {isClientOrderLoading ? (
              <TableLoading />
            ) : clientAllOrders.length > 0 ? (
              <table className="default_table table table-hover ">
                <thead className="Ordertable_head">
                  <tr className="green_clr">
                    <th scope="col"> Order ID</th>
                    <th scope="col"> Ordered Date</th>
                    <th scope="col">Expected Delivery Date</th>
                    <th scope="col">Email</th>
                    <th scope="col">Status</th>
                    <th scope="col">Billing Amount</th>
                    <th scope="col">Payment Status</th>
                    <th scope="col">Order Details</th>
                  </tr>
                </thead>
                <tbody>
                  {clientAllOrders.map((item, index) => {
                    if (
                      item.orderStatus === OrderStatusEnum.APPROVAL_PENDING ||
                      item.orderStatus === OrderStatusEnum.COMPLETED
                    ) {
                      return false;
                    }
                    return (
                      <tr key={index}>
                        <td className="fs-14">{item.orderIdentifier}</td>
                        <td className="fs-14">
                          {date.getDateFromEpoch(item.createdOn)}
                        </td>
                        <td className="fs-14">
                          {date.calculateExpectedDeliveryDate(item.createdOn)}
                        </td>
                        <td className="fs-14 fw-500">{item.createdByEmail}</td>
                        <td className="fs-14 fw-500">
                          {item.orderStatus === "PENDING"
                            ? "Placed"
                            : item.orderStatus}
                        </td>
                        <td className="fs-14 ">
                          {`₹ ${(+item.billingAmount.toFixed(2)).toLocaleString(
                            "en-IN"
                          )}`}
                        </td>
                        <td className="fs-14">{item.paymentStatus}</td>
                        {item.orderStatus === OrderStatusEnum.CANCELLED ? (
                          <td>NA</td>
                        ) : (
                          <td className="table-hover">
                            <span
                              role="button"
                              onClick={() => navigateToOrderDetail(item.id)}
                              className="text-primary"
                            >
                              View Order
                            </span>
                          </td>
                        )}

                        <td></td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className="image-pos">
                <img className="no-data-found" src={noDataFound} alt="" />
              </div>
            )}
          </div>
        </>
      )}
      {isPendingOrder === 1 ? (
        <>
          <NewApprovePendingOrder />
        </>
      ) : (
        <> {isPendingOrder === 2 && <NewOrderHistory />} </>
      )}
    </>
  );
};

export default NewOrder;
