export const BarChartInitialState : BarChartInitialStateModel = {
    labels: [],
    datasets: [
        {
            label: "Sales (In ₹)",
            data: [],
            backgroundColor: "#13A753",
            barThickness: 10,
            borderColor: "#13A753",
            borderWidth: 1,
            borderRadius:10,
        },
    ],
}

export interface BarChartInitialStateModel {
    labels: any[]
    datasets: {
        barThickness: number,
        backgroundColor: string,
        borderColor: string,
        data: any[],
        borderWidth: number,
        borderRadius?:number,
        label: string
    }[],
}

export const BarChartState : BarChartInitialStateDto = {
    labels: [],
    datasets: [
        {
            label: "Sold Quantity",
            data: [],
            backgroundColor: "#13A753",
            barThickness: 10,
            borderColor: "#13A753",
            borderWidth: 1,
            borderRadius: 10,
        },
    ],
}

export interface BarChartInitialStateDto {
    labels: any[]
    datasets: {
        barThickness: number,
        backgroundColor: string,
        borderColor: string,
        data: any[],
        borderWidth: number,
        borderRadius?:number,
        label: string
    }[],
}





export const BarChartoptions: {} = {
    indexAxis: 'x',
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            display: false,
        },
        title: {
            display: true,

        },
    },
};
