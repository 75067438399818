import { createMessage } from "../CreateMessage"
import { PageEventTypeEnum } from "../Model/ApiCallResponse"
import { ApiResponseStatusEnum } from "../Model/Enums/ApiCallState"
import { ApiResponseModel } from "../Model/NutritapResponseModel"
import {  StateModel } from "../Model/StateCityModel"
import {  StateStore } from "../Store"
import { ApiTypeEnum, ParameterEnum, SubPathEnum } from "../Model/Enums/ApiConstantEnum"
import { ApiUrlBuilder } from "../utils/Stringbuilder"
import { apiCaller } from "./ApiCallService"
import { PublishEventService } from "./PublishEventService"
import { showErrorToast } from "../utils/Toastify/ToastifyHandler"

export class StateService {

    private publishEvent = new PublishEventService();

    getStateList() {
        this.publishEvent.loading(PageEventTypeEnum.STATE_LIST)
        const stateUrl = new ApiUrlBuilder(ApiTypeEnum.STATE)
        stateUrl.addSubPath(SubPathEnum.GET_ALL)
        stateUrl.addParameter(ParameterEnum.ACTIVE, true)
        const url = stateUrl.buildURL()
        // const url = `${B2BConstants.BASE_URL}${ApiConstants.STATE.GET_ALL}`
        apiCaller.getResponseFromServer(url)
            .then((res: ApiResponseModel) => {
                if (!res) {
                    showErrorToast(createMessage.fetchingFailMessage())
                    this.publishEvent.loadingError(PageEventTypeEnum.CITY_LIST)
                }
                if (res.success || res.status === ApiResponseStatusEnum.SUCCESS) {
                    const stateList: StateModel[] = res.dtoList
                    StateStore.setStateList(stateList)
                    this.publishEvent.loadingSuccess(PageEventTypeEnum.STATE_LIST)
                }
            })
            .catch(() => {
                showErrorToast(createMessage.fetchingFailMessage())
                this.publishEvent.loadingError(PageEventTypeEnum.STATE_LIST)
            })
    }
}