import { createMessage } from "../CreateMessage";
import { OrderStatusEnum } from "../Model/Enums/OrderStatusEnums";
import { ApiResponseModel } from "../Model/NutritapResponseModel";
import { OrderItemsEntity } from "../Model/OrderItems";
import BaseOrder, {
  OrderDisplayDto,
  OrderDto,
  OrderEntity,
  SubOrderDto,
} from "../Model/OrderModel";
import {
  ApprovedOrderStore,
  InTransitOrderStore,
  ApprovalPendingOrderStore,
  CompletedOrderStore,
  OrderStore,
  InProcessingOrderStore,
  SplittedOrderStore,
  ClientAllOrderStore,
  CartStore,
  PrincipleStore,
} from "../Store";
import { dialogHandler } from "../utils/AlertDialogHandler";
import { apiCaller } from "./ApiCallService";
import { CreateOrderDto } from "../Model/OrderModel";
import { messageConstants } from "../StringConstants";
import {
  PaymentStatuEnum,
  PaymentTypeEnum,
} from "../Model/Enums/PaymentTypeEnum";
import { PageEventTypeEnum } from "../Model/ApiCallResponse";
import { ApiResponseStatusEnum } from "../Model/Enums/ApiCallState";
import { PaymentService } from "./PaymentService";
import { GenricPaymentDto } from "../Model/PaymentModel";
import { PublishEventService } from "./PublishEventService";
import { downloadExcel } from "react-export-table-to-excel";
import { DateUtil } from "./DateService/dateUtil";
import { CartItem } from "../Model/CartModel";
import { errorDialogHandler } from "../utils/ErrorDialogHandler";
import { ColorEnum } from "../Model/Props/DialogProps";
import { ApiUrlBuilder } from "../utils/Stringbuilder";
import {
  ApiTypeEnum,
  ParameterEnum,
  SubPathEnum,
} from "../Model/Enums/ApiConstantEnum";
import { DownloadFileService } from "./DownloadFileService";
import { showErrorToast, showSuccessToast } from "../utils/Toastify/ToastifyHandler";

export class OrderService {
  private publishEvent = new PublishEventService();
  private date = new DateUtil();

  getAllApprovalPending() {
    const orderUrl = new ApiUrlBuilder(ApiTypeEnum.B2B_ORDER);
    this.publishEvent.loading(PageEventTypeEnum.PENDING_ORDER);
    orderUrl.addSubPath(SubPathEnum.GET_ALL);
    orderUrl.addParameter(
      ParameterEnum.ORDER_STATUS,
      OrderStatusEnum.APPROVAL_PENDING
    );

    const url = orderUrl.buildURL();

    apiCaller
      .getResponseFromServer(url)
      .then((res: ApiResponseModel) => {
        if (!res) {
          return showErrorToast(createMessage.fetchingFailMessage());
        }

        if (res.status === ApiResponseStatusEnum.SUCCESS || res.success) {
          const allApprovalPendingOrder: Array<OrderDisplayDto> = res.dtoList;
          ApprovalPendingOrderStore.setOrder(allApprovalPendingOrder);
          this.publishEvent.loadingSuccess(PageEventTypeEnum.PENDING_ORDER);
        } else {
          this.publishEvent.loadingError(
            PageEventTypeEnum.PENDING_ORDER,
            res.message
          );
        }
      })
      .catch(() => {
        this.publishEvent.loadingError(
          PageEventTypeEnum.PENDING_ORDER,
          messageConstants.REFRESH_PAGE
        );
      });
  }

  createOrder(
    order: CreateOrderDto,
    navigateToPastOrderPage: any,
    navigateToHome: any,
    paymentMode: PaymentTypeEnum
  ) {
    const orderUrl = new ApiUrlBuilder(ApiTypeEnum.B2B_ORDER);
    orderUrl.addSubPath(SubPathEnum.SAVE);
    const url = orderUrl.buildURL();
    const body = JSON.stringify(order);
    apiCaller
      .postResponseToServer(url, body)
      .then((res: ApiResponseModel) => {
        if (!res) {
          return showErrorToast(createMessage.fetchingFailMessage());
        }

        if (res.message === ApiResponseStatusEnum.SUCCESS) {
          const orderResponse: BaseOrder = res.dto;

          if (paymentMode === PaymentTypeEnum.CASH) {
            dialogHandler(
              messageConstants.ORDER_PLACED,
              true,
              [
                {
                  text: messageConstants.VIEW_ORDER,
                  color: ColorEnum.SUCCESS,
                  onClickHandler: () =>
                    navigateToPastOrderPage(orderResponse.id),
                },
                {
                  text: messageConstants.RETURN_HOME,
                  color: ColorEnum.PRIMARY,
                  onClickHandler: navigateToHome,
                },
              ],
              `${messageConstants.CURRENT_PLACED_ORDER_ID} ${orderResponse.orderIdentifier}`
            );
            CartStore.deleteCart();
          } else if (paymentMode === PaymentTypeEnum.RAZORPAY) {
            const payment = new PaymentService();
            payment.intiateRazorPay(orderResponse);
          }
          this.publishEvent.loadingSuccess(
            PageEventTypeEnum.ORDER_CREATED,
            res.dto
          );
        } else if (res.message === ApiResponseStatusEnum.FAILURE) {
          errorDialogHandler(messageConstants.ORDER_PLACED_FAILED);
        }
      })
      .catch(() => {
        showErrorToast(messageConstants.CREATE_ORDER_ERROR);
      });
  }

  createOrderFromCartItems(
    paymentMode: PaymentTypeEnum,
    shippedToId: number,
    remarks?: string,
    purchaseNo?: string
  ) {
    const clientId = PrincipleStore.getUserId();
    const orderItems: Array<OrderItemsEntity> = new Array<OrderItemsEntity>();
    Array.from(CartStore.getCart().values()).forEach((cartItem: CartItem) => {
      const orderItem: OrderItemsEntity = {
        product: cartItem.product.pid,
        productPrice: 0,
        qty: cartItem.qty,
        orderStatus: OrderStatusEnum.IN_TRANSIT,
        discount: 0,
        gst: 0,
      };
      orderItems.push(orderItem);
    });

    const createOrderDto: CreateOrderDto = {
      gst: 0,
      totalAmount: 0,
      discountAmount: 0,
      billingAmount: 0,
      client: clientId,
      remarks: remarks || "",
      orderItems: orderItems,
      orderStatus: OrderStatusEnum.APPROVAL_PENDING,
      orderIdentifier: "",
      paymentMode: paymentMode,
      deliveredDate: 0,
      paymentStatus: PaymentStatuEnum.PENDING,
      approvalStatus: OrderStatusEnum.APPROVAL_PENDING,
      shippedTo: shippedToId,
      purchaseNo: purchaseNo,
    };
    return createOrderDto;
  }

  updateOrder(updatedOrderDetail: OrderEntity) {
    const orderUrl = new ApiUrlBuilder(ApiTypeEnum.B2B_ORDER);
    orderUrl.addSubPath(SubPathEnum.UPDATE);
    const url = orderUrl.buildURL();
    const body = JSON.stringify(updatedOrderDetail);

    apiCaller
      .postResponseToServer(url, body)
      .then((res: ApiResponseModel) => {
        if (!res) {
          this.publishEvent.loadingError(PageEventTypeEnum.ORDER_UPDATE);
        }

        if (res.success || res.status === ApiResponseStatusEnum.SUCCESS) {
          showSuccessToast(res.message);
          this.publishEvent.loadingSuccess(PageEventTypeEnum.ORDER_UPDATE);
          this.publishEvent.reloadPage(PageEventTypeEnum.ORDER_BY_ID);
        } else {
          this.publishEvent.loadingError(
            PageEventTypeEnum.ORDER_UPDATE,
            res.message
          );
        }
      })
      .catch(() => {
        this.publishEvent.loadingError(PageEventTypeEnum.ORDER_UPDATE);
      });
  }

  updatePaymentStatusOfOrder(
    paymentDetails: GenricPaymentDto,
    orderResponse: BaseOrder,
    navigateToPastOrderPage?: any,
    navigateToHome?: any
  ) {
    const orderUrl = new ApiUrlBuilder(ApiTypeEnum.B2B_ORDER);
    orderUrl.addSubPath(SubPathEnum.UPDATE_PAYMENT_STATUS);
    // const url = `${B2BConstants.BASE_URL}${ApiConstants.ORDER.UPDATE_PAYMENT_STATUS}`
    const url = orderUrl.buildURL();
    const body = JSON.stringify(paymentDetails);
    apiCaller.postResponseToServer(url, body).then((res: ApiResponseModel) => {
      if (!res) {
        return showErrorToast(messageConstants.TRY_AGAIN);
      }

      if (res.success || res.status === ApiResponseStatusEnum.SUCCESS) {
        showSuccessToast(messageConstants.ORDER_PLACED);
        // this.publishEvent.loadingSuccess(PageEventTypeEnum.RAZORPAY_PAYMENT, {
        //   orderResponse: orderResponse,
        //   razorpayResponse: paymentDetails,
        // });
        dialogHandler(
          messageConstants.ORDER_PLACED,
          true,
          [
            {
              text: messageConstants.VIEW_ORDER,
              color: ColorEnum.SUCCESS,
              onClickHandler: () => navigateToPastOrderPage(orderResponse.id),
            },
            {
              text: messageConstants.RETURN_HOME,
              color: ColorEnum.PRIMARY,
              onClickHandler: navigateToHome,
            },
          ],
          `${messageConstants.CURRENT_PLACED_ORDER_ID} ${orderResponse.orderIdentifier}`
        );
        CartStore.deleteCart();
      } else if (paymentDetails.error === "Cancelled") {
        showErrorToast(messageConstants.PAYMENT_NOT_DONE);
      } else {
        showErrorToast(messageConstants.PAYMENT_STATUS_NOT_UPDATED);
      }
    });
  }

  dtoToOrderConverter(
    order: OrderDto,
    status: OrderStatusEnum,
    approvalStatus: OrderStatusEnum
  ) {
    const orderItems: Array<OrderItemsEntity> = order.orderItems.map(
      (orderItem) => {
        const orderItemEntity = {
          ...orderItem,

          product: orderItem.product.pid,
        };
        return orderItemEntity;
      }
    );

    const orderEntity: OrderEntity = {
      ...order,
      orderItems: orderItems,
      orderStatus: status,
      client: order.client.id,
      approvalStatus: approvalStatus,
      deliveredDate: Date.now(),
      paymentMode: PaymentTypeEnum.CASH,
      shippedTo: order.shippedTo,
    };
    return orderEntity;
  }
  deleteOrder(orderId: number) {
    const orderUrl = new ApiUrlBuilder(ApiTypeEnum.B2B_ORDER);
    orderUrl.addSubPath(SubPathEnum.DELETE);
    orderUrl.addSubPath(orderId);
    // const url = `${B2BConstants.BASE_URL}${ApiConstants.ORDER.DELETE_ORDER}${orderId}`;
    const url = orderUrl.buildURL();

    apiCaller
      .deleteResponseFromServer(url)
      .then((res: ApiResponseModel) => {
        if (!res) {
          return showErrorToast(createMessage.fetchingFailMessage());
        }
        if (res.success || res.status === ApiResponseStatusEnum.SUCCESS) {
          showSuccessToast(messageConstants.REJECT_ORDER);
          this.publishEvent.reloadPage(PageEventTypeEnum.PENDING_ORDER);
        } else {
          showErrorToast(messageConstants.TRY_AGAIN);
        }
      })
      .catch(() => {
        showErrorToast(messageConstants.TRY_AGAIN);
      });
  }

  approveOrder(orderId: number) {
    const orderUrl = new ApiUrlBuilder(ApiTypeEnum.B2B_ORDER);
    orderUrl.addSubPath(SubPathEnum.APPROVE_STATUS);
    orderUrl.addSubPath(orderId);
    // const url = `${B2BConstants.BASE_URL}${ApiConstants.ORDER.APPROVE_ORDER}${orderId}`
    const url = orderUrl.buildURL();
    const body = {};
    apiCaller
      .postResponseToServer(url, body)
      .then((res: ApiResponseModel) => {
        if (!res) {
          return showErrorToast(createMessage.fetchingFailMessage());
        }
        if (res.success || res.status === ApiResponseStatusEnum.SUCCESS) {
          showSuccessToast(messageConstants.APPROVE_ORDER);
          this.publishEvent.reloadPage(PageEventTypeEnum.ALL_ORDER);
          this.publishEvent.reloadPage(PageEventTypeEnum.PENDING_ORDER);
        } else {
          showErrorToast(messageConstants.TRY_AGAIN);
        }
      })
      .catch(() => {
        showErrorToast(messageConstants.TRY_AGAIN);
      });
  }
  getAllApprovedOrder() {
    const orderUrl = new ApiUrlBuilder(ApiTypeEnum.B2B_ORDER);
    this.publishEvent.loading(PageEventTypeEnum.APPROVED_ORDER);
    orderUrl.addSubPath(SubPathEnum.GET_ALL);
    orderUrl.addParameter(
      ParameterEnum.ORDER_STATUS,
      OrderStatusEnum.ORDER_APPROVED
    );
    // const url = `${B2BConstants.BASE_URL}${ApiConstants.ORDER.GET_ALL_Approved}`
    const url = orderUrl.buildURL();

    apiCaller
      .getResponseFromServer(url)
      .then((res: ApiResponseModel) => {
        if (!res) {
          return showErrorToast(createMessage.fetchingFailMessage());
        }
        if (res.success || res.status === ApiResponseStatusEnum.SUCCESS) {
          const allOrder: Array<OrderDto> = res.dtoList;
          ApprovedOrderStore.setOrder(allOrder);
          this.publishEvent.loadingSuccess(PageEventTypeEnum.APPROVED_ORDER);
        } else {
          this.publishEvent.loadingError(
            PageEventTypeEnum.APPROVED_ORDER,
            res.message
          );
        }
      })
      .catch(() => {
        this.publishEvent.loadingError(
          PageEventTypeEnum.APPROVED_ORDER,
          messageConstants.REFRESH_PAGE
        );
      });
  }
  getProcessingOrders() {
    const orderUrl = new ApiUrlBuilder(ApiTypeEnum.B2B_ORDER);
    this.publishEvent.loading(PageEventTypeEnum.PROCESSING_ORDER);
    // const url = `${B2BConstants.BASE_URL}${ApiConstants.ORDER.GET_ALL_PROCESSING}`
    orderUrl.addSubPath(SubPathEnum.GET_ALL);
    orderUrl.addParameter(
      ParameterEnum.ORDER_STATUS,
      OrderStatusEnum.PROCESSING
    );
    const url = orderUrl.buildURL();

    apiCaller
      .getResponseFromServer(url)
      .then((res: ApiResponseModel) => {
        if (!res) {
          return showErrorToast(createMessage.fetchingFailMessage());
        }
        if (res.success || res.status === ApiResponseStatusEnum.SUCCESS) {
          const allOrder: Array<OrderDto> = res.dtoList;
          InProcessingOrderStore.setOrder(allOrder);
          this.publishEvent.loadingSuccess(PageEventTypeEnum.PROCESSING_ORDER);
        } else {
          this.publishEvent.loadingError(
            PageEventTypeEnum.PROCESSING_ORDER,
            res.message
          );
        }
      })
      .catch(() => {
        this.publishEvent.loadingError(
          PageEventTypeEnum.PROCESSING_ORDER,
          messageConstants.REFRESH_PAGE
        );
      });
  }
  getAll_IN_TRANSIT_Order() {
    const orderUrl = new ApiUrlBuilder(ApiTypeEnum.B2B_ORDER);
    this.publishEvent.loading(PageEventTypeEnum.IN_TRANSIT_ORDER);

    orderUrl.addSubPath(SubPathEnum.GET_ALL);
    orderUrl.addParameter(ParameterEnum.ORDER_STATUS, OrderStatusEnum.PENDING);
    // const url = `${B2BConstants.BASE_URL}${ApiConstants.ORDER.GET_ALL_IN_TRANSIT}`
    const url = orderUrl.buildURL();

    apiCaller
      .getResponseFromServer(url)
      .then((res: ApiResponseModel) => {
        if (!res) {
          return showErrorToast(createMessage.fetchingFailMessage());
        }
        if (res.success || res.status === ApiResponseStatusEnum.SUCCESS) {
          const allOrder: Array<OrderDto> = res.dtoList;

          InTransitOrderStore.setOrder(allOrder);
          this.publishEvent.loadingSuccess(PageEventTypeEnum.IN_TRANSIT_ORDER);
        } else {
          this.publishEvent.loadingError(
            PageEventTypeEnum.IN_TRANSIT_ORDER,
            res.message
          );
        }
      })
      .catch(() => {
        this.publishEvent.loadingError(
          PageEventTypeEnum.IN_TRANSIT_ORDER,
          messageConstants.REFRESH_PAGE
        );
      });
  }

  getAllCompletedOrder() {
    const orderUrl = new ApiUrlBuilder(ApiTypeEnum.B2B_ORDER);
    this.publishEvent.loading(PageEventTypeEnum.COMPLETED_ORDER);
    // const url = `${B2BConstants.BASE_URL}${ApiConstants.ORDER.GET_ALL_COMPLETED}`
    orderUrl.addSubPath(SubPathEnum.GET_ALL);
    orderUrl.addParameter(
      ParameterEnum.ORDER_STATUS,
      OrderStatusEnum.COMPLETED
    );
    const url = orderUrl.buildURL();

    apiCaller
      .getResponseFromServer(url)
      .then((res: ApiResponseModel) => {
        if (!res) {
          return showErrorToast(createMessage.fetchingFailMessage());
        }
        if (res.success || res.status === ApiResponseStatusEnum.SUCCESS) {
          const allOrder: Array<OrderDisplayDto> = res.dtoList;
          CompletedOrderStore.setOrder(allOrder);
          this.publishEvent.loadingSuccess(PageEventTypeEnum.COMPLETED_ORDER);
        } else {
          this.publishEvent.loadingError(
            PageEventTypeEnum.COMPLETED_ORDER,
            res.message
          );
        }
      })
      .catch(() => {
        this.publishEvent.loadingError(
          PageEventTypeEnum.COMPLETED_ORDER,
          messageConstants.REFRESH_PAGE
        );
      });
  }

  getOrderByOrderId(orderId: number) {
    const orderUrl = new ApiUrlBuilder(ApiTypeEnum.B2B_ORDER);
    orderUrl.addSubPath(SubPathEnum.GET);
    orderUrl.addSubPath(orderId);
    // const url = `${B2BConstants.BASE_URL}${ApiConstants.ORDER.GET_ORDER_BY_ID}${orderId}`
    const url = orderUrl.buildURL();

    apiCaller
      .getResponseFromServer(url)
      .then((res: ApiResponseModel) => {
        if (!res) {
          showErrorToast(createMessage.fetchingFailMessage());
          return;
        }

        if (res.success || res.status === ApiResponseStatusEnum.SUCCESS) {
          const orderDetail: OrderDto = res.dto;
          if (orderDetail) OrderStore.setOrderById(orderDetail);
          else {
            errorDialogHandler(messageConstants.NO_ORDER_FOUND);
            return;
          }
          this.publishEvent.loadingSuccess(PageEventTypeEnum.ORDER_BY_ID);
        } else {
          this.publishEvent.loadingError(
            PageEventTypeEnum.ORDER_BY_ID,
            res.message
          );
        }
      })
      .catch(() => {
        this.publishEvent.loadingError(
          PageEventTypeEnum.ORDER_BY_ID,
          messageConstants.TRY_AGAIN
        );
      });
  }

  getSplittedOrders() {
    const orderUrl = new ApiUrlBuilder(ApiTypeEnum.B2B_ORDER);
    this.publishEvent.loading(PageEventTypeEnum.SPLITTED_ORDER);
    orderUrl.addSubPath(SubPathEnum.GET_ALL);
    orderUrl.addParameter(ParameterEnum.ORDER_STATUS, OrderStatusEnum.SPLIT);
    // const utl = `${B2BConstants.BASE_URL}${ApiConstants.ORDER.GET_ALL_Splitted}`
    const url = orderUrl.buildURL();
    apiCaller
      .getResponseFromServer(url)
      .then((res: ApiResponseModel) => {
        if (!res) {
          return showErrorToast(createMessage.fetchingFailMessage());
        }
        if (res.success || res.status === ApiResponseStatusEnum.SUCCESS) {
          const allsplittedOrder: Array<OrderDto> = res.dtoList;
          SplittedOrderStore.setOrder(allsplittedOrder);
          this.publishEvent.loadingSuccess(PageEventTypeEnum.SPLITTED_ORDER);
        } else {
          this.publishEvent.loadingError(
            PageEventTypeEnum.SPLITTED_ORDER,
            res.message
          );
        }
      })
      .catch(() => {
        this.publishEvent.loadingError(
          PageEventTypeEnum.SPLITTED_ORDER,
          messageConstants.REFRESH_PAGE
        );
      });
  }

  getClientAllOrders() {
    const orderUrl = new ApiUrlBuilder(ApiTypeEnum.B2B_ORDER);
    this.publishEvent.loading(PageEventTypeEnum.ALL_ORDER);
    // const url = `${B2BConstants.BASE_URL}${ApiConstants.ORDER.GET_ALL}`
    orderUrl.addSubPath(SubPathEnum.GET_ALL);
    const url = orderUrl.buildURL();
    apiCaller
      .getResponseFromServer(url)
      .then((res: ApiResponseModel) => {
        if (!res) {
          return showErrorToast(createMessage.fetchingFailMessage());
        }
        if (res.success || res.status === ApiResponseStatusEnum.SUCCESS) {
          const clientAllOrder: Array<OrderDisplayDto> = res.dtoList;
          ClientAllOrderStore.setOrder(clientAllOrder);
          this.publishEvent.loadingSuccess(PageEventTypeEnum.ALL_ORDER);
        } else {
          this.publishEvent.loadingSuccess(
            PageEventTypeEnum.ALL_ORDER,
            res.message
          );
        }
      })
      .catch(() => {
        this.publishEvent.loadingError(
          PageEventTypeEnum.ALL_ORDER,
          messageConstants.REFRESH_PAGE
        );
      });
  }

  createBulkOrder(orderDetail: SubOrderDto) {
    const orderUrl = new ApiUrlBuilder(ApiTypeEnum.B2B_ORDER);
    orderUrl.addSubPath(SubPathEnum.CREATE_SUB_ORDER);
    const url = orderUrl.buildURL();
    // const url = `${B2BConstants.BASE_URL}${ApiConstants.ORDER.BULK}`
    const body = JSON.stringify(orderDetail);

    apiCaller
      .postResponseToServer(url, body)
      .then((res: ApiResponseModel) => {
        if (!res) {
          return showErrorToast(createMessage.fetchingFailMessage());
        }
        if (res.success || res.status === ApiResponseStatusEnum.SUCCESS) {
          showSuccessToast(res.message);
        } else {
          showErrorToast(res.message);
        }
      })
      .catch(() => {
        errorDialogHandler(messageConstants.FAILED);
      });
  }

  downloadExcelHandler(orders: OrderDto) {
    const orderDetailsDownloader = [
      "Order Id",
      "Ordered Date",
      "Product Name",
      "Product Quantity",
      "Product Price",
      "Discounted Amount",
      "GST Excl.",
      "GST",
      "Total Price",
      "Sub-Category",
      "Category",
    ];

    //  {((product.cost * (1 - (!isNaN(props.discount) ? props.discount : 0) / 100))-GSTAmount).toFixed(2) }
    const body = orders.orderItems.map((product) => {
      const discountedProductPrice: number = parseFloat(
        (
          product.productPrice -
          (product.productPrice * product.discount) / 100
        ).toFixed(2)
      );
      const GSTAmount = (product.productPrice * product.gst) / 100;
      return [
        orders.orderIdentifier,
        this.date.getDateFromEpoch(orders.createdOn),
        product.product.pname,
        product.qty,
        product.productPrice,
        discountedProductPrice,
        // (product.productPrice-GSTAmount ).toFixed(2),
        (
          product.productPrice *
            (1 - (!isNaN(product.discount) ? product.discount : 0) / 100) -
          GSTAmount
        ).toFixed(2),
        product.gst + "%",
        discountedProductPrice * product.qty,
        product.product.cid?.name,
        product.product.cid?.category.category,
      ];
    });
    downloadExcel({
      fileName: orders.orderIdentifier,
      sheet: "Order History Details",
      tablePayload: {
        header: orderDetailsDownloader,
        //@ts-ignore
        body: body,
      },
    });
  }

  downloadOrderByOrderId(orderId: number) {
    const orderUrl = new ApiUrlBuilder(ApiTypeEnum.B2B_ORDER);
    orderUrl.addSubPath(SubPathEnum.GET);
    orderUrl.addSubPath(orderId);
    const url = orderUrl.buildURL();

    apiCaller
      .getResponseFromServer(url)
      .then((res: ApiResponseModel) => {
        if (!res) {
          showErrorToast(createMessage.fetchingFailMessage());
          return;
        }

        if (res.success || res.status === ApiResponseStatusEnum.SUCCESS) {
          const orderDetail: OrderDto = res.dto;
          OrderStore.setOrderById(orderDetail);
          const order = new OrderService();
          order.downloadExcelHandler(orderDetail);
          this.publishEvent.loadingSuccess(PageEventTypeEnum.ORDER_BY_ID);
        } else {
          this.publishEvent.loadingError(
            PageEventTypeEnum.ORDER_BY_ID,
            res.message
          );
        }
      })
      .catch(() => {
        this.publishEvent.loadingError(
          PageEventTypeEnum.ORDER_BY_ID,
          messageConstants.TRY_AGAIN
        );
      });
  }

  downloadExcelFormat() {
    const orderUrl = new ApiUrlBuilder(ApiTypeEnum.B2B_ORDER);
    orderUrl.addSubPath(SubPathEnum.DOWNLOAD_SAMPLE_ORDER);
    const url = orderUrl.buildURL();
    // const url = `${B2BConstants.BASE_URL}${ApiConstants.ORDER.DOWNLOAD_EXCEL_FORMAT}`
    DownloadFileService.download(url, "SamplePurchaseOrder.xlsx");
  }
}
