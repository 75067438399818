export enum PositionEnum {
    MAIN = "MAIN",
    MAIN_CAT_1 = "MAIN_CAT_1",
    MAIN_CAT_2 = "MAIN_CAT_2",
    MAIN_CAT_3 = "MAIN_CAT_3",
    SUB_CAT_1 = "SUB_CAT_1",
    SUB_CAT_2 = "SUB_CAT_2",
    SUB_CAT_3 = "SUB_CAT_3",
    BRAND_1 = "BRAND_1",
    BRAND_2 = "BRAND_2",
}