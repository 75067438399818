import { Table, TableBody, TableCell, TableRow, TextField } from '@mui/material'
import React, { useState } from 'react';
import './Password.css'
import logo from '../../../Components/Images/logo.png'
import { cssConstants } from '../../../B2BcssConstants';
import { useNavigate } from 'react-router';
import { PageTypeEnum } from '../../../Model/Enums/PageTypeEnums';
import { UserService } from '../../../Services/UserService';
import { B2BConstants } from '../../../B2BConstants';
import { SecondaryButton, SuccessButton, TertiaryButton } from '../../../Components/Styled-Components/Buttons/Button';
import { PasswordUpdateProps } from '../../../Model/UserModel';

const Password = (props: PasswordUpdateProps) => {

    const [oldPassword, setOldPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [verificationCode, setVerificationCode] = useState<string>('');

    const userData = new UserService();


    const textChangeHandlers = {
        getOldPassword: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setOldPassword(event.target.value)
        },
        getNewPassword: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setNewPassword(event.target.value)
        },
        getConfirmPassword: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setConfirmPassword(event.target.value)
        },
        getEmail: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setEmail(event.target.value)
        },
        getVerificationCode: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setVerificationCode(event.target.value)
        },
    }

    const navigate = useNavigate()
    const navigateToLogin = () => {
        navigate(`/${PageTypeEnum.LOGIN}`);
    }
    const navigateToVerify = () => {
        navigate(`/${PageTypeEnum.VERIFICATION_CODE}`);
    }
    const naviagteToForceChangePassword = () => {
        navigate(`/${PageTypeEnum.FORCED_CHANGE_PASSWORD}`);
    }
    const navigateToCategoryPage = () => {
        navigate(`/${PageTypeEnum.CATEGORY}`)
    }

    const backToPreviousPageHandler = () => {
        navigate(-1);
    }

    const changePasswordHandler = () => {
        userData.changePassword(oldPassword, newPassword, confirmPassword, navigateToLogin);
    }
    const forceChangePasswordHandler = () => {
        userData.changePassword(localStorage.getItem(B2BConstants.TEMP_PASS) || "", newPassword, confirmPassword, navigateToCategoryPage);
    }

    const resetPasswordHandler = () => {
        userData.resetPassword(email, navigateToVerify)
    }
    const VerifyUserHandler = () => {
        userData.forcedLogin(email, verificationCode, naviagteToForceChangePassword)
    }

    const changePasswordAdaptor = [
        {
            label: 'Old Password',
            onchange: textChangeHandlers.getOldPassword,
            type: 'password',
            placeholder: 'Enter old password',
        },
        {
            label: 'New Password',
            onchange: textChangeHandlers.getNewPassword,
            type: 'password',
            placeholder: 'Enter new password'
        },
        {
            label: 'Confirm Password',
            onchange: textChangeHandlers.getConfirmPassword,
            type: 'password',
            placeholder: 'Enter confirm password'
        },
    ]

    const resetPasswordAdaptor = [
        {
            label: 'Registered Email',
            onchange: textChangeHandlers.getEmail,
            type: 'email',
            placeholder: 'Enter registered email'
        },
    ]

    const forcedChangeAdaptor = [
        {
            label: 'New Password',
            onchange: textChangeHandlers.getNewPassword,
            type: 'password',
            placeholder: 'Enter new password'
        },
        {
            label: 'Confirm Password',
            onchange: textChangeHandlers.getConfirmPassword,
            type: 'password',
            placeholder: 'Enter confirm password'
        },

    ]

    const verificatonAdaptor = [
        {
            label: 'Verification Code',
            onchange: textChangeHandlers.getVerificationCode,
            type: 'text',
            placeholder: 'Enter Verification Code'
        },
    ]


    return (
        <div className='password_container'>
            <div className='password_inner_container'>
                <div className='logo'>
                    <img src={logo} alt='Nutritap' />
                    <h1>
                        {
                            props.reset ? "Reset Password" : <></>
                        }
                        {
                            props.change ? "Change Password" : <></>
                        }
                        {
                            props.forced ? "Update Password" : <></>
                        }
                        {
                            props.verify ? "Verify User" : <></>
                        }
                    </h1>
                </div>

                <Table>
                    <TableBody sx={{ border: 'none' }}>
                        {
                            props.reset ?
                                resetPasswordAdaptor.map((item) => {
                                    return (
                                        <TableRow>
                                            <TableCell>{item.label}</TableCell>
                                            <TableCell>
                                                <TextField
                                                    onChange={item.onchange}
                                                    variant='filled'
                                                    type={item.type}
                                                    placeholder={item.placeholder} />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                :
                                <></>
                        }
                        {

                            props.change ?
                                changePasswordAdaptor.map((item) => {
                                    return (
                                        <TableRow>
                                            <TableCell>{item.label}</TableCell>
                                            <TableCell>
                                                <TextField
                                                    onChange={item.onchange}
                                                    variant='filled'
                                                    type={item.type}
                                                    placeholder={item.placeholder} />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                :
                                <></>

                        }
                        {
                            props.forced ?
                                forcedChangeAdaptor.map((item) => {
                                    return (
                                        <TableRow>
                                            <TableCell>{item.label}</TableCell>
                                            <TableCell>
                                                <TextField
                                                    onChange={item.onchange}
                                                    variant='filled'
                                                    type={item.type}
                                                    placeholder={item.placeholder} />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                :
                                <></>
                        }
                        {
                            props.verify ?
                                verificatonAdaptor.map((item) => {
                                    return (
                                        <TableRow>
                                            <TableCell>{item.label}</TableCell>
                                            <TableCell>
                                                <TextField
                                                    onChange={item.onchange}
                                                    variant='filled'
                                                    type={item.type}
                                                    placeholder={item.placeholder} />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                :
                                <></>

                        }



                    </TableBody>
                </Table>
                {
                    props.reset ?
                        <>
                            <SecondaryButton sx={cssConstants.forgetPasswordBtn}
                                variant='contained'
                                color='primary'
                                onClick={resetPasswordHandler}>
                                Reset
                            </SecondaryButton>
                            <TertiaryButton color='secondary' onClick={backToPreviousPageHandler}>
                                Cancel
                            </TertiaryButton>
                        </>
                        :
                        <></>
                }
                {
                    props.change ?
                        <>
                            <SecondaryButton
                                sx={cssConstants.forgetPasswordBtn}
                                variant='contained'
                                color='primary'
                                onClick={changePasswordHandler}>
                                Proceed
                            </SecondaryButton>
                            <TertiaryButton

                                variant='outlined'
                                onClick={backToPreviousPageHandler}>
                                Back
                            </TertiaryButton>
                        </>
                        : <></>
                }
                {
                    props.forced ?
                        <SecondaryButton sx={cssConstants.forgetPasswordBtn}
                            variant='contained'
                            color='primary'
                            onClick={forceChangePasswordHandler}>
                            Proceed
                        </SecondaryButton>
                        : <></>
                }
                {
                    props.verify ?
                        <SuccessButton sx={cssConstants.forgetPasswordBtn}
                            variant='contained'
                            color='primary'
                            onClick={VerifyUserHandler}>
                            Verify
                        </SuccessButton>
                        : <></>
                }


            </div>
        </div>
    )
}

export default Password
