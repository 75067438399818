import { useEffect, useState } from "react";
import { apiLoadingSubject, BrandStore, CatalogueStore } from "../../../Store";
import { BrandListModel } from "../../../Model/CategoryModel";
import { PageEventTypeEnum } from "../../../Model/ApiCallResponse";
import { ApiCallState } from "../../../Model/Enums/ApiCallState";
import { SearchParamsService } from "../../../Services/SearchParamsService";

const NewBrandList = () => {
  const [selectedBrands, setSelectedBrands] = useState<Set<number>>(
    new Set<number>()
  );
  const [brandList, setBrandList] = useState<BrandListModel[]>(
    new Array<BrandListModel>()
  );
  const [brandsToShow, setBrandsToShow] = useState(10);

  const subscribeBrandApi = () => {
    apiLoadingSubject.subscribe((response) => {
      if (
        response.eventType === PageEventTypeEnum.CATALOGUE &&
        response.apiCallState === ApiCallState.LOADING_SUCCESS
      ) {
        setBrandList(
          CatalogueStore.getCatalogue()?.brandResponse ||
            new Array<BrandListModel>()
        );

        // Checked brand via URL params
        const selectedBrandParams = SearchParamsService.getParamsByKey(
          "brandIds"
        )
          .split(",")
          .reduce((accumulator, currentValue) => {
            const num = Number(currentValue.trim()); // Convert each string element to a number after trimming whitespace
            if (!isNaN(num) && num > 0 && !selectedBrands.has(num)) {
              // Check if the conversion resulted in a valid number (not NaN)
              accumulator.add(num); // Push the number to the accumulator array
            }
            return accumulator; // Return the accumulator for the next iteration
          }, new Set<number>());

        // Set if size more than 0
        if (selectedBrandParams.size > 0) {
          setSelectedBrands(selectedBrandParams);
        }
      }
    });
  };

  useEffect(() => {
    subscribeBrandApi();
  }, []);

  useEffect(() => {}, [selectedBrands]);

  const handleBrandCheckboxChange = (brand: number) => {
    setSelectedBrands((prevState) => {
      let curState = new Set<number>(prevState);
      if (curState.has(brand)) {
        curState.delete(brand);
      } else {
        curState.add(brand);
      }
      // Update URL params when brand is checked or unchecked
      SearchParamsService.setParams([
        { key: "brandIds", value: Array.from(curState.values()).join(",") },
      ]);
      return curState;
    });
    window.scrollTo(0,0)
  };
  const showmore = () => {
    setBrandsToShow(brandList.length);
  };

  const showless = () => {
    setBrandsToShow(10);
  };

  return (
    <div className="filter_wrapper big_device_filterWrapper">
      <div className="accordion" id="filterAccordion">
        <div className="accordion-item">
          <h2 className="accordion-header" id="Filter6">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#Filter_collapse_1"
              aria-expanded="true"
              aria-controls="Filter_collapse_1"
            >
              Shop By Brands
            </button>
          </h2>
          <div
            id="Filter_collapse_1"
            className="accordion-collapse collapse show"
            aria-labelledby="Filter1"
          >
            <div className="accordion-body">
              {brandList.slice(0, brandsToShow).map((brand) => (
                brand.id && (
                <div className="form-check" key={brand.id}>
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="st1"
                      onChange={() => handleBrandCheckboxChange(brand.id)}
                      checked={selectedBrands.has(brand.id)}
                    />
                    {brand.name}
                  </label>
                </div>
                )
              ))}
            </div>
            {brandList.length > 10 ? (
              brandsToShow < 11 ? (
                <div className="show_btn">
                  <button className="anchor_link" onClick={showmore}>
                    View More <i className="bi bi-chevron-down fw-500"></i>
                  </button>
                </div>
              ) : (
                <div className="show_btn">
                  <button
                    className="anchor_link"
                    style={{ marginBottom: "15px" }}
                    onClick={showless}
                  >
                    View Less <i className="bi bi-chevron-up fw-500"></i>
                  </button>
                </div>
              )
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewBrandList;
