import { useEffect } from 'react'
import './PaymentGateway.css';
import { PaymentService } from '../../../Services/PaymentService'
import { useNavigate } from 'react-router'
import { ApiCallState } from '../../../Model/Enums/ApiCallState'
import { CartStore, RazorpayOrderIdStore, apiLoadingSubject } from '../../../Store'
import { ApiCallResponse, PageEventTypeEnum } from '../../../Model/ApiCallResponse'
import { PageTypeEnum } from '../../../Model/Enums/PageTypeEnums'
import { dialogHandler } from '../../../utils/AlertDialogHandler'
import { messageConstants } from '../../../StringConstants'
import PaymentStatus from '../../../Components/Dialog/PaymentStatus'
import { PaymentDetailsDto } from '../../../Model/PaymentModel'
import { ShoppingBagTwoTone } from '@mui/icons-material';
import { StyledHeading } from '../../../Components/Styled-Components/Cardstyle';
import { ColorEnum } from '../../../Model/Props/DialogProps';
import { ClientInfoService } from '../../../Services/ClientInfoService';
import { showErrorToast } from '../../../utils/Toastify/ToastifyHandler';

const PaymentGateway = () => {

    // const callInitatePayment = () => {
    //     orderService.createOrder(CartStore.getCreatedOrderDto(), () => { }, () => { }, PaymentTypeEnum.RAZORPAY)
    //     // PaymentService.IntiateRazorPay()
    // }
    const clientInfo = new ClientInfoService();

    const navigate = useNavigate();
    const navigateToCatagory = () => {
        navigate(`/${PageTypeEnum.CATEGORY}`);
    };

    const navigateToPastOrderPage = () => {
        navigate(`/${PageTypeEnum.ORDERS}`);
    };

    const navigateToCart = () => {
        navigate(`/${PageTypeEnum.CART}`)
    }

    useEffect(() => {
        clientInfo.getLoggedInClientInfo();
        let subscriber: PushSubscription
        apiLoadingSubject.subscribe((response: ApiCallResponse) => {

            if (PageEventTypeEnum.PAYMENT === response.eventType) {
                switch (response.apiCallState) {
                    case ApiCallState.LOADING_SUCCESS:
                        const payment = new PaymentService()
                        payment.displayRazorPay(response.data).open();
                        break;
                    case ApiCallState.INITIATED:
                        break;

                    case ApiCallState.LOADING_ERROR:
                        break;
                }
            }

            if (PageEventTypeEnum.RAZORPAY_PAYMENT === response.eventType) {
                switch (response.apiCallState) {
                    case ApiCallState.LOADING_SUCCESS:
                        const paymentDetails: PaymentDetailsDto = response.data
                        navigateToCart()
                        CartStore.deleteCart()
                        RazorpayOrderIdStore.deleteRazorPayOrderId()
                        setTimeout(() => {
                            dialogHandler(messageConstants.ORDER_PLACED, true, [
                                {
                                    text: messageConstants.VIEW_ORDER,
                                    color: ColorEnum.SUCCESS,
                                    onClickHandler: navigateToPastOrderPage,
                                },
                                {
                                    text: messageConstants.RETURN_HOME,
                                    color: ColorEnum.PRIMARY,
                                    onClickHandler: navigateToCatagory,
                                },
                            ],
                                <PaymentStatus details={paymentDetails} />);
                        }, 5000)
                        break;

                    case ApiCallState.LOADING_ERROR:
                        showErrorToast('Something went wrong')
                        navigateToCart()
                        break;
                }
            }

        })


        return () => {
            if (subscriber) subscriber.unsubscribe();
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div className='payemnt_page'>
            <div className="payment_page_inner_box">
                {/* <img src={rajorpayImg} alt="Razorpay" />
                <Button variant="contained" onClick={() => callInitatePayment()}>PAY NOW </Button> */}
                <StyledHeading>This is a transaction window Do not close or go back at this stage.</StyledHeading>
                <ShoppingBagTwoTone />
            </div>
        </div>
    )
}

export default PaymentGateway
