import { Navigate, Route, Routes } from "react-router";
import { PageTypeEnum } from "../../Model/Enums/PageTypeEnums";
import Logout from "../../Views/PublicView/Logout/Logout";
import { CheckService } from "../../Services/checkValidationService";
import NewBody from "../../Views/PublicView/NewBody/NewBody";
import NewLogin from "../../Views/PublicView/NewLogin/NewLogin";
import NewCart from "../../Views/PrivateView/NewCart/NewCart";
import NewOrder from "../../Views/PrivateView/NewOrders/NewOrder";
import NewPurchaseOrder from "../../Views/PrivateView/NewPurchaseOrder/NewPurchaseOrder";
import NewCheckOut from "../../Views/PrivateView/NewCheckOut/NewCheckOut";
import NewMyAccount from "../../Views/PrivateView/NewMyAccount/NewMyAccount";
import AddNewPurchaseOrder from "../../Views/PrivateView/NewPurchaseOrder/AddNewPurchaseOrder";
import NewProduct from "../../Views/PublicView/NewProduct/NewProduct";
import NewOrderDetails from "../../Views/PrivateView/NewOrders/NewOrderDetails";
import AllMembersView from "../../Views/PrivateView/NewMembers/AllMembersView";
import AddMembers from "../../Views/PrivateView/NewMembers/AddMembers";
import HowItWorks from "../../Views/PublicView/StaticPages/HowItWorks";
import AboutUs from "../../Views/PublicView/StaticPages/AboutUs";
import PrivacyPolicy from "../../Views/PublicView/StaticPages/PrivacyPolicy";
import RefundPolicy from "../../Views/PublicView/StaticPages/RefundPolicy";
import TermsConditions from "../../Views/PublicView/StaticPages/Terms&Conditions";
import NewSignUp from "../../Views/PublicView/NewLogin/NewSignUp";
import ConfirmNewPassword from "../../Views/PublicView/NewForgetPassword/ConfirmNewPassword";
import NewForgetPassword from "../../Views/PublicView/NewForgetPassword/NewForgetPassword";
import Faq from "../../Views/PublicView/StaticPages/Faq";
import NewProfile from "../../Views/PrivateView/NewMyAccount/NewProfile";
import ShippingAddress from "../../Views/PrivateView/NewCheckOut/ShippingAddress";
import ClientDashboard from "../../Views/PrivateView/ClientDashboard/ClientDashboard";

const PublicRoutes = () => {
  // const navigate = useNavigate()

  // const NavigateToLogin = ()=>{
  //     navigate(`${PageTypeEnum.LOGIN}`)
  // }
  // const logout = () => {
  //     PrincipleStore.deleteUser();
  //     CartStore.deleteCart();
  //     ProfileStore.deleteProfile();
  //     ApprovalPendingOrderStore.deleteOrderDetails();
  //     NavigateToLogin();
  //     window.location.reload()

  // }
  // useEffect(() => {
  //     const apiEventSubscriber = apiLoadingSubject.subscribe(
  //         (event)=>{
  //         if(PageEventTypeEnum.PRINCIPLE === event.eventType){
  //           if(event.apiCallState === ApiCallState.UNAUTHORISED){
  //             logout()
  //             showErrorMessage(Message.maxDeviceLimit)

  //           }
  //         }

  //       })
  //       return ()=>{
  //         apiEventSubscriber.unsubscribe();
  //       }
  // })

  return (
    <>
      <Routes>
        <Route path={PageTypeEnum.HOME} element={<NewBody/>} />
        <Route path="/login" element={<NewLogin />} />
        <Route path="/register" element={<NewSignUp />} />
        <Route path="/forgetPassword" element={<NewForgetPassword />} />
        <Route path="/reset" element={<ConfirmNewPassword reset={true} />} />
        <Route path="/forced" element={<ConfirmNewPassword forced={true} />} />
        <Route path="/change" element={<ConfirmNewPassword change={true} />} />
        <Route path="/product" element={<NewProduct />} />
        <Route path="/newCart" element={<NewCart />} />
        {/* <Route path="/newOrder" element={<NewOrder />} /> */}
        <Route path="/OrderDetails/:orderId" element={<NewOrderDetails />} />
        {/* <Route path="/newPo" element={<NewPurchaseOrder />} /> */}
        <Route path="/newCheckOut" element={<NewCheckOut />} />
        <Route path="/newMyAccount" element={<NewMyAccount />}>
        <Route path="dashboard" element={<ClientDashboard />} />
        <Route path="clientdashboard" element={<ClientDashboard />} />
          <Route path="" element={<NewProfile />} />
          <Route path="orders" element={<NewOrder />} />
          <Route path="allMembersView" element={<AllMembersView />} />
          <Route path="newPo" element={<NewPurchaseOrder />} />
          <Route path="shippingAddress" element={<ShippingAddress />} />
        </Route>
        <Route path="/addNewPo" element={<AddNewPurchaseOrder />} />
        <Route path={`/${PageTypeEnum.LOGOUT}`} element={<Logout />} />
        {/*<Route path="/contactUs" element={<NewContactUs />} />*/}
        {/* <Route path="/all-members-view" element={<AllMembersView />} /> */}
        <Route path="/add-members" element={<AddMembers />} />
        <Route path="/terms&Conditions" element={<TermsConditions />} />
        <Route path="/refundPolicy" element={<RefundPolicy />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/howitworks" element={<HowItWorks />} />
        <Route path="/faq" element={<Faq />} />

       

        {/* <Route path={PageTypeEnum.REGISTER} element={<Register client={false} />} />
                <Route path={PageTypeEnum.LOGOUT} element={<Logout />} />
                <Route path={PageTypeEnum.CATEGORY} element={<NewCategoryPage />} />
                <Route path={PageTypeEnum.RESET_PASSWORD} element={<Password reset={true} />} />
                <Route path={PageTypeEnum.FORCED_CHANGE_PASSWORD} element={<Password forced={true} />} />
                <Route path={PageTypeEnum.VERIFICATION_CODE} element={<Password verify={true} />} />
                <Route path={PageTypeEnum.OFFERS} element={<FestivalOffers />} /> */}
        <>
          {!CheckService.isLoggedIn() ? (
            <Route
              path="*"
              element={<Navigate to={PageTypeEnum.ACCESS_DENIED} />}
            />
          ) : (
            <></>
          )}
        </>
        
      </Routes>
      {/* <Routes>
            <Route path={PageTypeEnum.ACCESS_DENIED} element={<AccessDenied delay={5} to={to} />} />
            <Route path={PageTypeEnum.HOME} element={<Home />} />
            <Route path={PageTypeEnum.LOGIN} element={<Login />} />
            <Route path={PageTypeEnum.REGISTER} element={<Register client={false} />} />
            <Route path={PageTypeEnum.LOGOUT} element={<Logout />} />
            <Route path={PageTypeEnum.CATEGORY} element={<NewCategoryPage />} />
            <Route path={PageTypeEnum.RESET_PASSWORD} element={<Password reset={true} />} />
            <Route path={PageTypeEnum.FORCED_CHANGE_PASSWORD} element={<Password forced={true} />} />
            <Route path={PageTypeEnum.VERIFICATION_CODE} element={<Password verify={true} />} />
            <Route path={PageTypeEnum.OFFERS} element={<FestivalOffers />} />
            <>
                {
                    !CheckService.isLoggedIn() ?
                        <Route path='*' element={<Navigate to={PageTypeEnum.ACCESS_DENIED} />} />
                        : <></>
                }
            </>


        </Routes> */}
    </>
  );
};

export default PublicRoutes;
