import React from "react";
import NewNavbar from "../../../Components/NavBar/NewNavbar";
import NewFooter from "../../PublicView/NewFooter/NewFooter";
import "./NewMyAccount.css";
import { CatalogueStore, PrincipleStore, ProfileStore } from "../../../Store";
import { Outlet, useNavigate } from "react-router";
import { PageTypeEnum } from "../../../Model/Enums/PageTypeEnums";
import { HashLink } from "react-router-hash-link";
import userImg from "../../../Components/Images/User.png";
import { CheckService } from "../../../Services/checkValidationService";

const NewMyAccount = () => {
  const adminName = ProfileStore.getClientSpocName();
  const navigate = useNavigate();
  const navigateHandler = {
    toDashboard:(event: React.MouseEvent<HTMLElement>) =>{
      navigate("/newMyAccount/clientdashboard");
    },
    toAccountDetails:(event: React.MouseEvent<HTMLElement>) =>{
      navigate("/newMyAccount");
    },
    toOrders:(event: React.MouseEvent<HTMLElement>) =>{
      navigate("/newMyAccount/orders");
    },
    toAllMembersView:(event: React.MouseEvent<HTMLElement>) =>{
      navigate("/newMyAccount/allMembersView");
    },
    toPurchaseOrder:(event: React.MouseEvent<HTMLElement>) =>{
      navigate("/newMyAccount/newPo");
    },
    toShippingAddress:(event: React.MouseEvent<HTMLElement>) =>{
      navigate("/newMyAccount/shippingAddress");
    },
    toLogOut: (event: React.MouseEvent<HTMLElement>) => {
      CatalogueStore.setCatalogue(null);
      navigate(`/${PageTypeEnum.LOGOUT}`);
    },
  };

  return (
    <>
      <div className="container">
        <NewNavbar
          signIn={true}
          account={false}
          cart={true}
          admin={false}
          searchBar={true}
          getFilteredProductsFromNav={() => {}}
        />
        <div className="page_path border-bottom">
          <p>
            <HashLink to="/">Home</HashLink>
            <span>
              <i className="bi bi-chevron-right"></i>
            </span>
            My Account
          </p>
        </div>

        <div className="mt-4">
          <div className="profile_wrap">
            <div className="profile_img">
              <img
                className="img-fluid w-100 user_img"
                src={userImg}
                alt="Profile"
              />
            </div>
            <div className="profile_name">
              <p className="grey_clr mb-1">Welcome back,</p>
              <p className="fw-bold text-capitalize mb-0">{adminName}</p>
            </div>
          </div>
        </div>

        <div className="mt-4 mt-md-5">
          <div className="d-md-flex align-items-start">
            <div
              className="myAccount_links_wrap nav flex-column nav-pills mb-4 mb-md-0 me-md-3"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <button
                className="nav-link active"
                id="v-pills-Dashboard-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-Dashboard"
                type="button"
                role="tab"
                aria-controls="v-pills-Dashboard"
                aria-selected="true"
                onClick={navigateHandler.toAccountDetails}
              >
                Account Details
              </button>

              <button
                className="nav-link"
                id="v-pills-clientDashboard-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-clientDashboard"
                type="button"
                role="tab"
                aria-controls="v-pills-clientDashboard"
                aria-selected="true"
                onClick={navigateHandler.toDashboard}
              >
                Dashboard
              </button>

              <button
                className="nav-link"
                id="v-pills-Orders-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-Orders"
                type="button"
                role="tab"
                aria-controls="v-pills-Orders"
                aria-selected="false"
                onClick={navigateHandler.toOrders}
              >
                Orders
              </button>
              {CheckService.isAdmin() && (
                <button
                  className="nav-link"
                  id="v-pills-Members-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-Members"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-Members"
                  aria-selected="false"
                  onClick={navigateHandler.toAllMembersView}
                >
                  Members
                </button>
              )}
              {PrincipleStore.getIsPoClient() && (
                <button
                  className="nav-link"
                  id="v-pills-Downloads-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-Downloads"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-Downloads"
                  aria-selected="false"
                  onClick={navigateHandler.toPurchaseOrder}
                >
                  Purchase Order
                </button>
              )}
              <button
                className="nav-link"
                id="v-pills-Addresses-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-Addresses"
                type="button"
                role="tab"
                aria-controls="v-pills-Addresses"
                aria-selected="false"
                onClick={navigateHandler.toShippingAddress}
              >
                Addresses
              </button>

              <button
                className="nav-link"
                id="v-pills-Logout-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-Logout"
                type="button"
                role="tab"
                aria-controls="v-pills-Logout"
                aria-selected="false"
                onClick={navigateHandler.toLogOut}
              >
                Logout
              </button>
            </div>
            <div className="tab-content ps-lg-4" id="v-pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="v-pills-Dashboard"
                role="tabpanel"
                aria-labelledby="v-pills-Dashboard-tab"
              >
                <Outlet/>
              </div>
          </div>
          </div>
        </div>
      </div>
      <NewFooter />
    </>
  );
};

export default NewMyAccount;
