import { ActionTypeEnum } from "./Model/Enums/ActionTypeEnum"
import { messageConstants } from "./StringConstants"

export const createMessage = {
    successMessage: (actionType: ActionTypeEnum, ) => {
        return (`${actionType} Successfully`)
    },
    errorMessage: (actionType: ActionTypeEnum) => {
        return (`Error Occured while ${actionType}`)
    },
    fetchingFailMessage: () => {
        return messageConstants.FAILED;
    },
    textLengthMessage : () => {
        return messageConstants.TEXT_LENGTH;
    },
    copyText : () => {
        return messageConstants.COPIED;
    }
}