import * as ReactDOM from "react-dom/client";
import Alerts from "../Components/Mui-Components/B2Balerts";
import { NotificationTypeEnum } from "../Model/Enums/NotificationTypeEnums";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

type verticalType = "top" | "bottom";
type horizontalType = "center" | "right" | "left";

export const alertHandler = (
  message: string | ReactJSXElement,
  severity: NotificationTypeEnum,
  vertical: verticalType,
  horizontal: horizontalType,
  duration?: number
) => {
  const element = document.getElementById("alert") as HTMLDivElement;
  const root = ReactDOM.createRoot(element);

  root.render(
    <Alerts
      severity={severity}
      message={message}
      open={true}
      vertical={vertical}
      horizontal={horizontal}
      duration={
        typeof message === "string" && message.length > 25 ? 6000 : duration
      }
    />
  );
};

export const showSuccessMessage = (message: string | ReactJSXElement) => {
  alertHandler(message, NotificationTypeEnum.SUCCESS, "bottom", "center", 3000);
};

export const showErrorMessage = (message: string | ReactJSXElement) => {
  alertHandler(message, NotificationTypeEnum.ERROR, "bottom", "center", 3000);
};

export const showWarningMessage = (message: string | ReactJSXElement) => {
  alertHandler(message, NotificationTypeEnum.WARNING, "bottom", "center", 3000);
};

export const showInfoMessage = (message: string | ReactJSXElement) => {
  alertHandler(message, NotificationTypeEnum.INFO, "bottom", "center", 3000);
};
