import {
    BarChartInitialState,
    BarChartInitialStateModel,
  } from "../Components/Graphs/BarChart";
  import {
    PieChartInitialState,
    PieChartInitialStateModel,
  } from "../Components/Graphs/PieChart";
  import {
    ProgressBarInitialState,
    ProgressBarInitialStateModel,
  } from "../Components/Graphs/ProgressChart";
  import { B2BSalesDto, TopSellingDataDto } from "../Model/b2bSalesDto";
  
  export class GraphDataService {
    prepareMonthWiseBarGraph(monthWise: B2BSalesDto[]) {
      const monthBarData: BarChartInitialStateModel = JSON.parse(
        JSON.stringify(BarChartInitialState)
      );
      monthBarData.labels = monthWise.map((monthWise) => {
        return monthWise.month + "'" + monthWise.year;
      });
      monthBarData.datasets[0].data = monthWise.map((monthWise) => {
        return monthWise.amount;
      }); 
      return monthBarData;
    }
  
    prepareTopSellingGraph(topSellingCategory: TopSellingDataDto[]) {
      const topSellingGraphData: ProgressBarInitialStateModel = JSON.parse(
        JSON.stringify(ProgressBarInitialState)
      );
      topSellingGraphData.labels = topSellingCategory
        ?.slice(0, 10)
        .map((topCategory) => {
          return topCategory.details;
        });
      topSellingGraphData.datasets[0].data = topSellingCategory.map(
        (topCategory) => {
          return topCategory.value;
        }
      );
      return topSellingGraphData;
    }
  
    prepareTopSellingCategoryPieChart(topSellingCategory: TopSellingDataDto[]) {
      const topCategoryPieData: PieChartInitialStateModel = JSON.parse(
        JSON.stringify(PieChartInitialState)
      );
      topCategoryPieData.labels = topSellingCategory.map((topCategory) => {
        return topCategory.details;
      });
      topCategoryPieData.datasets[0].data = topSellingCategory.map(
        (topCategory) => {
          return topCategory.value;
        }
      );
      return topCategoryPieData;
    }
  }
  
  