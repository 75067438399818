import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import {
  ApiCallResponse,
  PageEventTypeEnum,
} from "../../../Model/ApiCallResponse";
import { ApiCallState } from "../../../Model/Enums/ApiCallState";
import { PurchaseOrderDto } from "../../../Model/PurchaseOrderModel";
import { PurchaseOrderService } from "../../../Services/PurchaseOrderService";
import { apiLoadingSubject, PurchaseOrderStore } from "../../../Store";
import { messageConstants } from "../../../StringConstants";
import { showErrorMessage } from "../../../utils/AlertHandler";
import "./NewPo.css";
import { HashLink } from "react-router-hash-link";
import NewPurchaseOrderRow from "./NewPurchaseOrderRow";
import noDataFound from "../../../Components/Images/NoData.jpeg";
import TableLoading from "../../LoadingComponent/TableLoading";

const NewPurchaseOrder = () => {
  const [poData, setPOData] = useState<PurchaseOrderDto[]>(
    new Array<PurchaseOrderDto>()
  );
  const [isPoLoading, setIsPoLoading] = useState(true);
  const purchaseOrderData = new PurchaseOrderService();

  const getAllPurchaseOrders = () => {
    purchaseOrderData.getAllPurchaseOrder();
  };

  useEffect(() => {
    getAllPurchaseOrders();
    let poSubscriber: Subscription = apiLoadingSubject.subscribe(
      (response: ApiCallResponse) => {
        if (PageEventTypeEnum.PURCHASE_ORDER === response.eventType) {
          switch (response.apiCallState) {
            case ApiCallState.LOADING:
              setIsPoLoading(true);
              break;
            case ApiCallState.LOADING_SUCCESS:
              PurchaseOrderStore.getAllPurchaseOrder();
              setPOData(PurchaseOrderStore.getAllPurchaseOrder());
              setIsPoLoading(false);
              break;
            case ApiCallState.LOADING_ERROR:
              showErrorMessage(messageConstants.REFRESH);
              setIsPoLoading(false);
              break;
            case ApiCallState.RELOAD:
              getAllPurchaseOrders();
          }
        }
      }
    );

    return () => {
      if (poSubscriber) {
        poSubscriber.unsubscribe();
      }
    };
  }, []);

  return (
    <>
      <div className="d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-between">
        <p className="fw-bold mb-2 mb-sm-0 me-3 me-sm-0">Purchase Order</p>
        <HashLink to={"/addNewPo"}>
          <button className="theme_btn justify-content-between px-4 ">
            Add Purchase Order
          </button>
        </HashLink>
      </div>
      <div className="table-responsive mt-4 col-7 table-fixed scrollbar">
        {isPoLoading ? (
          <TableLoading />
        ) : poData.length > 0 ? (
          <table className="table table-hover">
            <thead>
              <tr className="green_clr">
                <th scope="col">PO No.</th>
                <th scope="col">PO Date</th>
                <th scope="col">Valid Till</th>
                <th scope="col">Created By</th>
                <th scope="col">Order Amount</th>
                <th scope="col">GST Excl Amount</th>
                <th scope="col">Status</th>
                <th scope="col">Download PO</th>
                <th scope="col">ERP Doc</th>
              </tr>
            </thead>
            <tbody>
              {poData?.map((item) => {
                return <NewPurchaseOrderRow poDetails={item} key={item.id} />;
              })}
            </tbody>
          </table>
        ) : (
          <div className="image-pos">
            <img className="no-data-found" src={noDataFound} alt="" />
          </div>
        )}
      </div>
    </>
  );
};

export default NewPurchaseOrder;
