import React, { useState } from "react";
import { UserService } from "../../../Services/UserService";
import { messageConstants } from "../../../StringConstants";
import NewFooter from "../NewFooter/NewFooter";
import Verification from "./Verification";
import './NewForgetPassword.css';
import { Link } from "react-router-dom";
import { showWarningToast } from "../../../utils/Toastify/ToastifyHandler";

const NewForgetPassword = () => {
  const [email, setEmail] = useState<string>("");
  const [verification, setVerification] = useState<boolean>(false);
  

  const userService = new UserService();


  const isValidEmail = (email: string) => {
    let regexEmail = /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/;
    if (!email || !regexEmail.test(email)) {
      return false;
    } else {
      return true;
    }
  };
  const textChangeHandlers = {
    getEmail: (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setEmail(event.target.value);
    },
  };

  const resetPasswordHandler = (e: any) => {
    e.preventDefault();
    let regexEmail = /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/;
    if (!email || !regexEmail.test(email) || !isValidEmail(email)) {
      setVerification(false);
      showWarningToast(messageConstants.EMAIL_INVALID);
    } else {
    //   setVerification(true);
      userService.resetPassword(email, setVerification);
    }
  };

  return (
    <>
      <div className="container bg-white">
        <div className="page_path border-bottom">
          <p>
            <Link to="/">Home</Link>
              <i className="bi bi-chevron-right"></i>
            Forget Password
          </p>
        </div>

        <div className="forms_wrpapper paddingBottom paddingTop text-center"></div>
        {!verification ? (
          <div className="default_tabs paddingBottom bg-white">
            <ul
              className="nav nav-pills mb-2 mb-md-3 justify-content-center"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-forgetPass-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-forgetPass"
                  type="button"
                  role="tab"
                  aria-controls="pills-forgetPass"
                  aria-selected="true"
                >
                  Forget Password
                </button>
              </li>
            </ul>

            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active content-center"
                id="pills-forgetPass"
                role="tabpanel"
                aria-labelledby="pills-forgetPass-tab"
              >
                <p className="mobile_fs mb-0">
                  Lost your password? Please enter your email
                  address. You will receive a OTP to create a new password.
                </p>
                <form className="default_form_email mt-3 mt-md-4" onSubmit={(e)=>e.preventDefault()}>
                  <input
                    name=""
                    type="email"
                    placeholder="Enter your registered Email"
                    required
                    onChange={textChangeHandlers.getEmail}
                  />
                </form>
                <button
                  className="theme_btn text-center px-4 mt-3"
                  onClick={resetPasswordHandler}
                >
                  Reset Password
                </button>
              </div>
            </div>
          </div>
        ) : (
          <>
            <Verification email={email} />
          </>
        )}
      </div>
      <NewFooter />
    </>
  );
};

export default NewForgetPassword;
