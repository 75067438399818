import { PageEventTypeEnum } from "../Model/ApiCallResponse";
import { ApiCallState } from "../Model/Enums/ApiCallState";
import NavigateConst from "../NavigateConstant";
import { PrincipleStore, TokenActions, apiLoadingSubject } from "../Store";
import { showInfoToast } from "../utils/Toastify/ToastifyHandler";
import { NavigateService } from "./NavigateService";
import { PublishEventService } from "./PublishEventService";

enum HttpMethodType {
  GET = "GET",
  POST = "POST",
  DELETE = "DELETE",
}
let controller = new AbortController();

const makeApiCall = async (
  url: string,
  type: HttpMethodType,
  _body?: any
): Promise<any> => {
  let request: RequestInit = {
    method: type,
    mode: "cors",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `${PrincipleStore.getToken()}`,
      // "ngrok-skip-browser-warning": "69420",
    },
    signal: controller.signal,
  };

  if (HttpMethodType.POST === type) {
    request = { ...request, body: _body };
  }

  const response = await fetch(url, request);

  const publishEvent = new PublishEventService();

  if (response.status === 401) {
    publishEvent.unauthorised(PageEventTypeEnum.PRINCIPLE);
    NavigateService(NavigateConst.To_Home());
    return;
  }

  const data = await response.json();
  return data;
};

const uploadApiCall = async (
  url: string,
  type: HttpMethodType,
  _body?: any
): Promise<any> => {
  const token = TokenActions.getCompleteToken();

  let request: RequestInit = {
    method: type,
    mode: "cors",
    //@ts-ignore
    headers: {
      Authorization: token,
      // "ngrok-skip-browser-warning": "69420",
    },
  };

  if (HttpMethodType.POST === type) {
    request = { ...request, body: _body };
  }

  const response = await fetch(url, request);

  if (response.status === 401) {
    apiLoadingSubject.next({
      apiCallState: ApiCallState.UNAUTHORISED,
      eventType: PageEventTypeEnum.PRINCIPLE,
    });
    NavigateService(NavigateConst.To_Login());
    return;
  }

  const data = await response.json();
  return data;
};

const downloadFileFromServer = async (
  url: string,
  type: HttpMethodType,
  _body?: any
): Promise<any> => {
  const token = TokenActions.getCompleteToken();

  let request: RequestInit = {
    method: type,
    mode: "cors",
    // @ts-ignore
    headers: {
      Accept: "application/octet-stream",
      Authorization: token ? token : "",
      // "ngrok-skip-browser-warning": "69420",
    },
  };

  if (HttpMethodType.POST === type) {
    request = { ...request, body: _body };
  }

  const response = await fetch(url, request);

  if (response.status === 401) {
    apiLoadingSubject.next({
      apiCallState: ApiCallState.UNAUTHORISED,
      eventType: PageEventTypeEnum.PRINCIPLE,
    });
    NavigateService(NavigateConst.To_Login());
    return;
  }
  let blob = await response.blob();
  return blob;
};

class ApiCallService {
  async getResponseFromServer(url: string): Promise<any> {
    return makeApiCall(url, HttpMethodType.GET);
  }
  async postResponseToServer(
    url: string,
    _body: any,
    upload?: boolean
  ): Promise<any> {
    if (upload) return uploadApiCall(url, HttpMethodType.POST, _body);
    else return makeApiCall(url, HttpMethodType.POST, _body);
  }
  async deleteResponseFromServer(url: string): Promise<any> {
    return makeApiCall(url, HttpMethodType.DELETE);
  }
  async getDownloadFromServer(url: string): Promise<any> {
    return downloadFileFromServer(url, HttpMethodType.GET);
  }
  cancelApiCall() {
    controller.abort();
    showInfoToast("Aborted");
  }
}
export const apiCaller = new ApiCallService();
