import { CardContent, CardMedia, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import styled from "styled-components";

export const ProductCard = styled.div`
   width: auto;

   @media (max-width:600px){
        width: 45vw;
        margin-top: 10px ;
        margin-bottom: 0;
   }
   @media screen and (min-width: 601px) and (max-width: 900px){
    width:27vw;
    
   }

`

export const ProductCardContainer = styled.div`
display: grid;
grid-template-columns: repeat(4,auto);
gap: 10px;

   @media (max-width: 600px){
    display: grid;
        grid-template-columns: repeat(2,auto);
        gap: 18px
}
@media screen and (min-width : 601px) and (max-width :1100px){
    grid-template-columns: repeat(3,auto);

}


`
export const Styledcard = styled(Card)`
background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
   transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    overflow: hidden;
    // max-height: 40vh;
    min-height: 35vh;
    transition: border-color 0.2s;
    border: 2px solid transparent;
  
    @media (max-width : 760px){
        max-height:30vh
    }
    @media screen and (min-width:761px) and (max-width:1366px){
     min-height :39vh;
    }

`
export const StyledBox = styled(Box)`
display: flex;
justify-Content: center;
align-Items: center;
max-Height: 9vh;
object-Fit: contain;
position: relative;
@media screen and (min-width : 601px) and (max-width :1000px){
    transform:scale(.7)
}

`

export const StyledCardMedia = styled(CardMedia)`
min-Width: 10vh;
max-Width: 18vh;
object-Fit: contain;
transform: scale(1);
padding-top:16px;
 
@media (max-width:600px){
    transform:scale(1);
}
 @media screen and (min-width : 601px) and (max-width : 1100px){
  max-height: 12vh;
 }
`
export const ProductImageContainer =styled.div `
position: relative;
`

export const ProductImg = styled.div`
transform :scale(.5)
`
export const StyledCardContent = styled(CardContent)`
padding:  30px;

@media screen and (min-width: 601px) and (max-width: 1100px){
    
        padding: 0px 16px;
        height:20px;

}`
 export const StyledTypography = styled(Typography)`
  overflow:hidden;
  display:flex;
  justify-content:space-between

`

export const StyledHeading = styled.div`
font-size: 24px;
    font-weight: 500;
    @media screen and (min-width:601px) and (max-width:1300px){
        padding-left:3.5vw;
        // padding-top:4vh;
    }

    @media (max-width:600px){
       display:none;
    }
`

export const Heading = styled(StyledHeading)`
font-size: 30px;
    font-weight: 500;
    font-family : 'Monospace';
    @media screen and (min-width:601px) and (max-width:1300px){
        padding-left:3.5vw;
        // padding-top:4vh;
    }

    @media (max-width:600px){
       display:block;
       font-size : 16px;
       margin-top: 5vh;
    }
`