import { cssConstants } from "../B2BcssConstants";
import { OrderStatusEnum } from "../Model/Enums/OrderStatusEnums";

export const getDeliveryStatusClass = (status: OrderStatusEnum): Record<string, string> => {
    switch (status) {
    case OrderStatusEnum.APPROVAL_PENDING:
      return cssConstants.deliveryStatusColorCoding.APPROVAL_PENDING;
    case OrderStatusEnum.PENDING:
      return cssConstants.deliveryStatusColorCoding.PENDING;
    case OrderStatusEnum.ORDER_APPROVED:
      return cssConstants.deliveryStatusColorCoding.ORDER_APPROVED;
    case OrderStatusEnum.IN_TRANSIT:
      return cssConstants.deliveryStatusColorCoding.IN_TRANSIT;
    case OrderStatusEnum.DELIVERED:
      return cssConstants.deliveryStatusColorCoding.DELIVERED;
    case OrderStatusEnum.PAYMENT_DENIED:
      return cssConstants.deliveryStatusColorCoding.PAYMENT_DENIED;
    case OrderStatusEnum.PAYMENT_REQUESTED:
      return cssConstants.deliveryStatusColorCoding.PAYMENT_REQUESTED;
    case OrderStatusEnum.PAYMENT_APPROVED:
      return cssConstants.deliveryStatusColorCoding.PAYMENT_APPROVED;
    case OrderStatusEnum.PAYMENT_DONE:
      return cssConstants.deliveryStatusColorCoding.PAYMENT_DONE;
    case OrderStatusEnum.COD:
      return cssConstants.deliveryStatusColorCoding.COD;
    case OrderStatusEnum.UNKNOWN:
      return cssConstants.deliveryStatusColorCoding.UNKNOWN;
    case OrderStatusEnum.COMPLETED:
      return cssConstants.deliveryStatusColorCoding.COMPLETED;
    case OrderStatusEnum.CANCELLED:
      return cssConstants.deliveryStatusColorCoding.CANCELLED;
    case OrderStatusEnum.RETURNED:
      return cssConstants.deliveryStatusColorCoding.RETURNED;
    case OrderStatusEnum.PROCESSING:
      return cssConstants.deliveryStatusColorCoding.PROCESSING;
    case OrderStatusEnum.SPLIT :
      return cssConstants.deliveryStatusColorCoding.SPLIT;
    case OrderStatusEnum.READY_TO_DELIVER :
      return cssConstants.deliveryStatusColorCoding.READY_TO_DELIVER;
    case OrderStatusEnum.PARTIAL_DELIVER :
      return cssConstants.deliveryStatusColorCoding.PARTIAL_DELIVER;
    case OrderStatusEnum.REJECTED: 
    return cssConstants.deliveryStatusColorCoding.REJECTED;
    case OrderStatusEnum.ACCEPTED:
      return cssConstants.deliveryStatusColorCoding.ACCEPTED;

    default:
      return cssConstants.deliveryStatusColorCoding.DEFAULT;
  }
};
