import { Button, css, styled } from "@mui/material";
import { themeColors } from "../../../B2BcssConstants";

const BaseButton = styled(Button)`
  color: black;
  height: 35px;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: capitalize;
  margin-top: 1em;
  padding: 0.25em 1em;
  font-size: 0.875rem;
  font-weight: 500;
  font-size: 0.875rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
`;

export const PrimaryButton = styled(BaseButton)`


  ${(props) => {

    if (props.disabled) {
      return css`
        &&{
            margin-bottom : 30px;
            /* height : 25px; */
            background-color: #a8a3a3;
            color: grey;
        }`
    }

    switch (props?.variant) {
      case "outlined":
        return css`
                && {
                    color: ${themeColors.primary}
                    border-color :  ${themeColors.primary}
                    background-color :#fcf9f0;
                    margin : 0.5%;
                    opacity : 60;
                    width:100%;           
                    &:hover {
                        background-color:${themeColors.primaryHover}
                        color:white;
                    }
                }
            `;
      case "text":
        return css`
            && {
                color: ${themeColors.primary}           
            &:hover {
                // border:1px solid ${themeColors.primaryHover}
                background-color: ${themeColors.primaryHover}
                color:white;
            }
        }`;
      default:
        return css`
                && {
                    background-color: ${themeColors.primary}
            color: white;
            border-color : ${themeColors.primary}           
            &:hover {
                background-color:${themeColors.primaryHover}
            }
          }
        `;
    }
  }}
`;

export const SecondaryButton = styled(BaseButton)`
  ${(props) => {
    switch (props.value) {
      case 'true':
        return css`
        &&{
                    background-color:${themeColors.secondaryHover} 
                    color : white;
                    &:hover {
                        color:white;
                        background-color:${themeColors.secondaryHover}
                    }
                }
        `
    }
    switch (props?.variant) {
      case "contained":
        return css`
                &&{
                    background-color: ${themeColors.secondaryHover}
                    color : white;
                    &:hover {
                        color:white;
                        background-color:${themeColors.secondaryHover} ;
                    }
                }`;

      case "text":
        return css`
                && {
                    color: ${themeColors.secondary}           
                &:hover {
                    background-color: ${themeColors.secondaryHover}
                    color:white;
                }
            }`;

      default:
        // return css`
        //             &&{
        //                 color: ${themeColors.secondary}   
        //                 border : 1px solid ${themeColors.secondary}
        //                 margin : 0.5%;


        //                 &:hover{
        //                     color:white;
        //                     background-color:${themeColors.secondaryHover} 
        //                 }

        //             }
        //             `;
        return css`
          border: none;
          outline: none;
          background-color: ${themeColors.secondary} 
          padding: 10px 20px;
          font-size: 12px;
          font-weight: 700;
          color: #fff;
          border-radius: 5px;
          transition: all ease 0.1s;
          box-shadow: 0px 5px 0px 0px ${themeColors.secondaryHover}
        
    :active{
    transform: translateY(5px);
    box-shadow: 0px 0px 0px 0px #a29bfe;
   }
    :hover{
    color: white;
    background-color: ${themeColors.secondary};
    }
        `
    }
  }}
  padding: 10px 20px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  border-radius: 5px;
  transition: all ease 0.1s;
`;

export const TertiaryButton = styled(BaseButton)`
  ${(props) => {
    switch (props?.variant) {
      case "contained":
        return css`
                &&{
                    background-color: ${themeColors.tertiary} 
                    color : white;
                    & : hover{
                        color:black;
                        background-color:${themeColors.tertiaryHover} ;
                    }
                }`;

      case "outlined":
        return css`
                &&{
                    border : 1px solid ${themeColors.tertiary} 
                    color : ${themeColors.tertiary}
                    & : hover{
                        color:black;
                        background-color:${themeColors.tertiaryHover} ;
                    }
                }`;

      default:
        return css`
                    && {
                        color: ${themeColors.tertiary}
                                  
                    &:hover {
                        border:1px solid ${themeColors.tertiaryHover}
                        background-color: ${themeColors.tertiaryHover}
                        color:black;
                    }
                }`;
    }
  }}
`;

export const ErrorButton = styled(BaseButton)`
  color: white;

  ${(props) => {
    switch (props?.variant) {
      case "contained":
        return css`
                && {
                    color : white;
                    background-color : red;
                    margin: 3px;
                    &:hover {
                        background-color : #e29595(47, 222, 102);
                    }   
                }`;
      case "outlined":
        return css`
                &&{
                    border : 1px solid red;
                    color :red;
                    margin: 3px;
                }
                &:hover{
                    color:white;
                    background-color: red ;
                }
            `;
      case 'text':
        return css`
            &&{
                  color: red
            }                  
            &:hover {
                        border:1px solid red;
                        background-color: red;
                        color:white;
                    }
          `
    }
  }}

`;
export const SuccessButton = styled(BaseButton)`
  ${(props) => {
    switch (props?.variant) {
      case "contained":
        return css`
                && {
                    color : white;
                    background-color : green;
                    margin: 3px;
                    &:hover {
                        background-color : rgb(47, 222, 102);
                    }   
                }`;
      case "outlined":
        return css`
                &&{
                    border : 1px solid green;
                    color :green;
                    margin: 3px;
                }
                & : hover{
                    color:white;
                    background-color:green ;
                }
                `;
    }
  }}
`;

export const AddToCartButton = styled(PrimaryButton)`

    ${(props) => {
    if (props.disabled) {
      return css`
           &&{
                    margin-bottom : 30px;
                    height : 25px;
                    background-color: #a8a3a3;
                    color: grey;
                }
      `
    }
    switch (props.variant) {
      case 'outlined':
        return css`
                &&{
                    margin-bottom : 30px;
                    height : 25px;
                }
                `
    }


  }}
`

export default BaseButton;
