import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { DialogProps, DialogButton } from "../../Model/Props/DialogProps";
import { useState } from "react";
import { cssConstants } from "../../B2BcssConstants";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Slide timeout={{ exit: 1000 }} direction="down" ref={ref} {...props} />
  );
});

const AlertDialog = (props: DialogProps) => {
  const [open, setOpen] = useState<boolean>(props.open);

  const handleClose = () => {
    setOpen(false);
  };

  const handleButtonClick = (onClick: () => void) => {
    onClick();
    handleClose();
  };

  return (
    <div className="header-controls">
      {/* <ClickAwayListener onClickAway={handleClickAway}> */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth={false}
      >
        <DialogTitle className="dialog-title nutri-secondary-color">
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={cssConstants.dialogContent}
            id="alert-dialog-slide-description"
          >
            {props.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {props.buttons.map((button: DialogButton, index: number) => (
            <Button
              key={index}
              color={button.color}
              variant="contained"
              onClick={() => handleButtonClick(button.onClickHandler)}
            >
              {button.text}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
      {/* </ClickAwayListener> */}
    </div>
  );
};

export default AlertDialog;
