import * as ReactDOM from 'react-dom/client';
import AlertDialog from '../Components/Mui-Components/Dialog';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { DialogButton } from '../Model/Props/DialogProps';

export const dialogHandler = (
  title: string,
  open: boolean,
  buttons: DialogButton[],
  content: ReactJSXElement | string,
) => {
  const element = document.getElementById('alert') as HTMLDivElement;
  const root = ReactDOM.createRoot(element);
  root.render(
    <AlertDialog
      title={title}
      open={open}
      buttons={buttons}
      content={content}
    />
  );
};
