import "./NewBody.css";
import NewHome from "../Home/NewHome";
import NewCategory from "../NewCategory/NewCategory";
import NewNavbar from "../../../Components/NavBar/NewNavbar";
import NewBigProductSlider from "../NewBigProductSlider/NewBigProductSlider";
import NewStationaryProduct from "../NewStationaryProducts/NewStationaryProduct";
import NewFooter from "../NewFooter/NewFooter";
import NewPantry from "../NewPantry/NewPantry";
import NewHowItWorks from "../NewHowItWorks/NewHowItWorks";
import NewHouseKeeping from "../NewHouseKeeping/NewHouseKeeping";
import { SearchParamsService } from "../../../Services/SearchParamsService";
import { useNavigate } from "react-router";
import AllProducts from "../NewCategory/AllProducts";

const NewBody = () => {
  const navigate = useNavigate();

  const getSearchedValuefromNav = (searchedProduct: string) => {
    navigate(`/product`);

    SearchParamsService.setParams([
      { key: "sCid", value: "" },
      { key: "brandIds", value: "" },
      { key: "productName", value: searchedProduct },
    ]);
  };

  return (
    <>
      <div className="container">
        <NewNavbar
          signIn={true}
          account={false}
          cart={true}
          admin={false}
          searchBar={true}
          getFilteredProductsFromNav={getSearchedValuefromNav}
        />
        <NewHome />
        <NewCategory />
        <AllProducts/>
        {/* <NewBanner /> */}
        <NewBigProductSlider />
        {/* <AllProducts/> */}
        <NewStationaryProduct />
      </div>
      <NewPantry />
      <NewHouseKeeping />
      <NewHowItWorks />
      <NewFooter />
    </>
  );
};
export default NewBody;
