export enum PaymentTypeEnum {
    PAYTM = 'PAYTM',
    RAZORPAY = 'RAZORPAY',
    CASH = 'CASH',
    NONE='NONE',
}
export enum PaymentStatuEnum{
    SUCCESS='SUCCESS',
    FAILURE='FAILURE',
    PENDING='PENDING',
    UNKNOWN='UNKNOWN'
}
export enum TrackOrderStatusEnum{
PENDING='PENDING',
PROCESSING='PROCESSING',
SPLIT = 'SPLIT',
READY_TO_DELIVER='READY_TO_DELIVER',
DELIVERED = 'DELIVERED',
PARTIAL_DELIVER = 'PARTIAL_DELIVER',
PAYMENT = 'PAYMENT',
COMPLETED = 'COMPLETED'
}
