import { useEffect, useState } from "react";
import companyLogo from "../../../Components/Images/Mascot.png";
import { PageEventTypeEnum } from "../../../Model/ApiCallResponse";
import { ProductModel } from "../../../Model/CatalogueModel";
import { ApiCallState } from "../../../Model/Enums/ApiCallState";
import { OrderItemsDto } from "../../../Model/OrderItems";
import { OrderDto } from "../../../Model/OrderModel";
import { CatalogueService } from "../../../Services/CatalogueService";
import { CheckService } from "../../../Services/checkValidationService";
import {
  CatalogueStore,
  PrincipleStore,
  apiLoadingSubject,
} from "../../../Store";
import { messageConstants } from "../../../StringConstants";
import { CartItem } from "../../../Model/CartModel";
import {
  showErrorToast,
  showInfoToast,
} from "../../../utils/Toastify/ToastifyHandler";
import NewOrderLoading from "../../LoadingComponent/NewOrderLoading";

interface props {
  clientId: number;
  orderDetail: OrderDto;
  newlyAddedProduct: Map<string, CartItem>;
  addNewProductsHandler: CallableFunction;
  saveUpdateOrder: CallableFunction;
}
const AddNewProductDialogBox = ({
  clientId,
  orderDetail,
  addNewProductsHandler,
  newlyAddedProduct,
  saveUpdateOrder,
}: props) => {
  const [products, setProducts] = useState<Array<ProductModel>>();
  const [newAddedProduct, setNewAddedProducts] = useState<
    Map<string, OrderItemsDto>
  >(new Map());
  const [isProductLoading, setIsProductLoading] = useState<boolean>(false);
  const [isProductFound, setIsProductFound] = useState<boolean>(false);
  const [searchProductName , setSearchProductName] = useState<string>("");
  const maximumProductAmount = 50000;

  const filterProducts = (event: any) => {
    if (event.keyCode === 13 && clientId ) {
      const catalogueService = new CatalogueService();
      catalogueService.getAllProductsBySearch(event.target.value, clientId);
      setIsProductFound(true);
    } else{
      setIsProductFound(false);
    }
  };

  const searchProductByName = (event: any) =>{
    setSearchProductName(event.target.value)
  }

  const filterProductsByClick = () =>{
    if(clientId){
      const catalogueService = new CatalogueService();
      catalogueService.getAllProductsBySearch(searchProductName, clientId);
      setIsProductFound(true);
    } else {
      setIsProductFound(false);
    }
  }
  useEffect(() => {
    apiLoadingSubject.subscribe((event) => {
      if (event.eventType === PageEventTypeEnum.PRODUCTS) {
        switch (event.apiCallState) {
          case ApiCallState.INITIATED:
            setIsProductLoading(false);
            break;
          case ApiCallState.LOADING:
            setIsProductLoading(true);
            break;
          case ApiCallState.LOADING_SUCCESS:
            setIsProductLoading(false);
            setProducts(CatalogueStore.getSearchedProducts());
            break;
          case ApiCallState.LOADING_ERROR:
            setIsProductLoading(false);
            showErrorToast(messageConstants.TRY_AGAIN);
            break;
        }
      }
    });
  }, []);

  const addToCart = (product: ProductModel) => {
    const cartItems: CartItem = {
      gst: product.gst,
      discountedPrice: product.cost - product.discount,
      product: {
        pid: product.productId,
        photo: product.photo,
        pname: product.productName,
        cost: product.cost,
      },
      applicableDiscount: product.discount,
      qty: 1,
    };
    addNewProductsHandler(product.productId.toString(), cartItems);
  };

  const handleAdd = (productId: number) => {
    let key = productId.toString();
    const existingProduct = newlyAddedProduct.get(key);

    if (existingProduct && !PrincipleStore.getIsPoClient()) {
      if (existingProduct.qty >= maximumProductAmount - existingProduct.qty) {
        showInfoToast(
          messageConstants.CANNOT_EXCEED_MAX_LIMIT(maximumProductAmount)
        );
      } else if (
        existingProduct.qty <
        maximumProductAmount - existingProduct.qty
      ) {
        existingProduct.qty += 1;
        newlyAddedProduct.set(key, existingProduct);
      }
    } else if (
      existingProduct &&
      existingProduct.avlQty &&
      existingProduct.avlQty - existingProduct.qty > 0 &&
      maximumProductAmount - existingProduct.qty > 0
    ) {
      existingProduct.qty += 1;
      newlyAddedProduct.set(key, existingProduct);
    }
    const cartMap: Map<string, OrderItemsDto> = new Map(newAddedProduct);
    setNewAddedProducts(cartMap);
  };

  const handleMinus = (productId: number) => {
    let key = productId.toString();
    const existingProduct = newlyAddedProduct.get(key);
    if (existingProduct && existingProduct.qty > 0) {
      existingProduct.qty -= 1;

      if (existingProduct.qty === 0) {
        newlyAddedProduct.delete(key);
      } else {
        newlyAddedProduct.set(key, existingProduct);
      }
    }
    const cartMap: Map<string, OrderItemsDto> = new Map(newAddedProduct);
    setNewAddedProducts(cartMap);
  };

  const handleOnQuantityChange = (
    productId: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let key = productId.toString();
    const existingProduct = newlyAddedProduct.get(key);
    const newQty = Number(e.target.value);

    if (Number(e.target.value) > maximumProductAmount) {
      showInfoToast(
        messageConstants.CANNOT_EXCEED_MAX_LIMIT(maximumProductAmount)
      );
      return;
    }

    if (newQty <= 0) {
      newAddedProduct.delete(key);
    } else {
      const existingProduct = newlyAddedProduct.get(key);
      if (existingProduct) {
        existingProduct.qty = newQty;
        newlyAddedProduct.set(key, existingProduct);
      }
    }

    const cartMap: Map<string, OrderItemsDto> = new Map(newAddedProduct);
    setNewAddedProducts(cartMap);
  };

  const addNewProduct = () => {
    saveUpdateOrder(orderDetail);
  };

  return (
    <>
      {/* Modal/Pop up starts */}
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Add New Product
              </h5>
              <button
                type="button"
                className="close cross_btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="bi bi-x-circle-fill"></i>
                {/* <span aria-hidden="true">&times;</span> */}
              </button>
            </div>
            <div
              className="modal-body "
              style={{ maxHeight: "60vh", overflowY: "scroll" }}
            >
              <div className="default_form">
                <input
                  className="mb-2"
                  name=""
                  type="text"
                  placeholder=" Search here"
                  onChange={searchProductByName}
                  onKeyDown={filterProducts}
                  required
                />
              </div>
              <div className="row row-cols-2 justify-content-center">
                {isProductLoading ? (
                  <NewOrderLoading />
                ) : products && products.length > 0 ? (
                  products.map((item, index) => {
                    const GSTAmount = (item.cost * item.gst) / 100;
                    return (
                      <>
                        <div
                          className="card py-2"
                          style={{ width: "18rem" }}
                          key={index}
                        >
                          <div className="d-flex justify-content-center">
                            <img
                              src={item.photo}
                              className="card-img-top"
                              onError={(
                                e: React.SyntheticEvent<HTMLImageElement, Event>
                              ) => {
                                e.currentTarget.src = `${companyLogo}`;
                              }}
                              alt="..."
                              style={{
                                height: "100px",
                                width: "100px",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                          <div className="card-body ">
                            <p className="card-text mb-1">
                              {CheckService.shortString(item.productName, 18)}
                            </p>
                            <p className=" product_current_price mb-1">
                              {`MRP ₹ ${item.cost.toFixed(2)}`}{" "}
                            </p>

                            {item.categoryName &&
                            item.categoryName.toLowerCase() === "pantry" &&
                            item.weight ? (
                              <p className="mb-1">{item.printedWeight}</p>
                            ) : (
                              <></>
                            )}

                            <p className="mb-1">
                              ₹
                              {(
                                item.cost *
                                (1 -
                                  (!isNaN(item.discount) ? item.discount : 0) /
                                    100)
                              ).toFixed(2)}
                              incl. GST <br /> ₹
                              {(item.cost - GSTAmount).toFixed(2)}&nbsp;excl.
                              GST
                            </p>
                          </div>

                          {newlyAddedProduct.get(item.productId.toString()) &&
                          (newlyAddedProduct.get(item.productId.toString())
                            ?.qty || 0) > 0 ? (
                            <>
                              <button className="addCart_btn bordered_btn w-100">
                                <i
                                  className="bi bi-dash-lg"
                                  onClick={() => handleMinus(item.productId)}
                                ></i>
                                <input
                                  type="number"
                                  className="add-to-cart-input"
                                  min={0}
                                  max={10000}
                                  value={
                                    newlyAddedProduct.get(
                                      item.productId.toString()
                                    )?.qty
                                  }
                                  onChange={(e) => {
                                    handleOnQuantityChange(item.productId, e);
                                  }}
                                />

                                <i
                                  className="bi bi-plus-lg"
                                  onClick={() => handleAdd(item.productId)}
                                ></i>
                              </button>
                            </>
                          ) : (
                            <button
                              className="addCart_btn bordered_btn w-100"
                              onClick={() => addToCart(item)}
                            >
                              Add to cart
                            </button>
                          )}
                        </div>
                      </>
                    );
                  })
                ) : (
                  !isProductFound && (
                    <div className="no-product-found-text">
                      No such product found, please try again!!
                    </div>
                  )
                )}
              </div>
            </div>

            <div className="modal-footer">
              <button className="btn" data-bs-dismiss="modal">
                Close
              </button>
              {
                //@ts-ignore
                products?.length > 0 ? (
                  <button
                    type="button"
                    className="btn theme_btn"
                    data-bs-dismiss="modal"
                    onClick={() => addNewProduct()}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn theme_btn"
                    onClick={filterProductsByClick}
                  >
                    Search
                  </button>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewProductDialogBox;
