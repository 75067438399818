import {PageEventTypeEnum} from "../Model/ApiCallResponse";
import {ApiResponseStatusEnum} from "../Model/Enums/ApiCallState";
import {ApiTypeEnum, SubPathEnum} from "../Model/Enums/ApiConstantEnum";
import {BannerModel} from "../Model/HomePageDto/BannerModel";
import {ApiResponseModel} from "../Model/NutritapResponseModel";
import {BannerStore} from "../Store";
import {messageConstants} from "../StringConstants";
import {errorDialogHandler} from "../utils/ErrorDialogHandler";
import {ApiUrlBuilder} from "../utils/Stringbuilder";
import {apiCaller} from "./ApiCallService";
import {PublishEventService} from "./PublishEventService";
import {PositionEnum} from "../Model/Enums/PositionEnum";
import { showErrorToast } from "../utils/Toastify/ToastifyHandler";

export class BannerService {
    private publishEvent = new PublishEventService();

    getBanner() {
        const brandUrl = new ApiUrlBuilder(ApiTypeEnum.BANNER);
        this.publishEvent.loading(PageEventTypeEnum.BANNER);
        brandUrl.addSubPath(SubPathEnum.GET);
        const url = brandUrl.buildURL();

        apiCaller
            .getResponseFromServer(url)
            .then((res: ApiResponseModel) => {
                if (!res) {
                    showErrorToast(messageConstants.FAILED);
                    this.publishEvent.loadingError(PageEventTypeEnum.BANNER);
                    return;
                }
                if (res.status === ApiResponseStatusEnum.SUCCESS || res.success) {
                    const bannerList: BannerModel[] = res.dtoList;
                    const bannerPositionMap = new Map<PositionEnum, BannerModel[]>()
                    bannerList.forEach((banner: BannerModel) => {
                        if (bannerPositionMap.has(banner.position)) {
                            let banners = bannerPositionMap.get(banner.position);
                            //@ts-ignore
                            banners.push(banner);
                            //@ts-ignore
                            bannerPositionMap.set(banner.position, banners)
                        } else {
                            bannerPositionMap.set(banner.position, new Array<BannerModel>(banner))
                        }
                    })
                    BannerStore.setBanner(res.dtoList);
                    BannerStore.setBannerPositionMap(bannerPositionMap);
                    this.publishEvent.loadingSuccess(PageEventTypeEnum.BANNER);
                } else {
                    this.publishEvent.loadingError(PageEventTypeEnum.BANNER);
                }
            })
            .catch(() => {
                errorDialogHandler(messageConstants.FAILED);
            });
    }
}
