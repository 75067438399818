import { PageEventTypeEnum } from "../Model/ApiCallResponse";
import { ApiCallState } from "../Model/Enums/ApiCallState";
import { apiLoadingSubject } from "../Store";

export class PublishEventService {
    loading(eventName: PageEventTypeEnum, data?: any) {
        apiLoadingSubject.next({
            apiCallState: ApiCallState.LOADING,
            eventType: eventName,
            data: data
        })
    }

    loadingSuccess(eventName: PageEventTypeEnum, data?: any) {
        apiLoadingSubject.next({
            apiCallState: ApiCallState.LOADING_SUCCESS,
            eventType: eventName,
            data: data
        })
    }

    loadingError(eventName: PageEventTypeEnum, data?: any) {
        apiLoadingSubject.next({
            apiCallState: ApiCallState.LOADING_ERROR,
            eventType: eventName,
            data: data
        })
    }

    reloadPage(eventName: PageEventTypeEnum, data?: any) {
        apiLoadingSubject.next({
            apiCallState: ApiCallState.RELOAD,
            eventType: eventName,
            data: data
        })
    }

    clicked(eventName: PageEventTypeEnum) {
        apiLoadingSubject.next({
            apiCallState: ApiCallState.CLICKED,
            eventType: eventName,
        })
    }

    dataLoaded(eventName: PageEventTypeEnum, data?: any) {
        apiLoadingSubject.next({
            apiCallState: ApiCallState.DATA_LOADED,
            eventType: eventName,
            data: data
        })
    }

    changed(eventName: PageEventTypeEnum, data?: any) {
        apiLoadingSubject.next({
            apiCallState: ApiCallState.CHANGED,
            eventType: eventName,
            data: data
        })
    }

    initiated(eventName: PageEventTypeEnum) {
        apiLoadingSubject.next({
            apiCallState: ApiCallState.INITIATED,
            eventType: eventName,
        })
    }

    unauthorised(eventName: PageEventTypeEnum) {
        apiLoadingSubject.next({
            apiCallState: ApiCallState.UNAUTHORISED,
            eventType: eventName,
        })
    }
}
