import { createMessage } from "../CreateMessage"
import { PageEventTypeEnum } from "../Model/ApiCallResponse"
import { CatalogueModel, CategoriesListModel, ProductModel, SubCategoriesListModel } from "../Model/CatalogueModel"
import { CatalogueStore, CategoryStore, PrincipleStore, apiLoadingSubject } from "../Store"
import { ApiCallState } from "../Model/Enums/ApiCallState"
import { apiCaller } from "./ApiCallService"
import { messageConstants } from "../StringConstants"
import { ApiResponseModel } from "../Model/NutritapResponseModel"
import { PublishEventService } from "./PublishEventService"
import { errorDialogHandler } from "../utils/ErrorDialogHandler"
import { ApiUrlBuilder } from "../utils/Stringbuilder"
import { ApiTypeEnum, ParameterEnum, SubPathEnum } from "../Model/Enums/ApiConstantEnum"
import { showErrorToast } from "../utils/Toastify/ToastifyHandler"

export class CatalogueService {

  private publishEvent = new PublishEventService();

  getCatalogue(isDefaultCatalogue:boolean) {
    const catalogueUrl = new ApiUrlBuilder(ApiTypeEnum.CATALOGUE);
    this.publishEvent.loading(PageEventTypeEnum.NEW_CATALOGUE)
    catalogueUrl.addSubPath(SubPathEnum.GET)
    // catalogueUrl.addParameter(ParameterEnum.SORT_DIRECTION, 'ASC')
    // catalogueUrl.addParameter(ParameterEnum.DEFAULT_CATALOGUE, isDefaultCatalogue)

    const url = catalogueUrl.buildURL()

    apiCaller.getResponseFromServer(url)
      .then((res) => {
        if (!res) {
          return showErrorToast(createMessage.fetchingFailMessage())
        }

        const catalogue: CatalogueModel = res
        if (catalogue.catalogueResponse) {
          CatalogueStore.setCatalogue(catalogue)
        } else {
          errorDialogHandler();
        }
        this.publishEvent.loadingSuccess(PageEventTypeEnum.NEW_CATALOGUE)
      })
  }

  getCatalogueByUserID(isDefaultCatalogue:boolean) {
    const catalogueUrl = new ApiUrlBuilder(ApiTypeEnum.CATALOGUE);
    this.publishEvent.loading(PageEventTypeEnum.CATALOGUE)
    catalogueUrl.addSubPath(SubPathEnum.GET)
    catalogueUrl.addParameter(ParameterEnum.SORT_COLUMN, 'pid')
    // catalogueUrl.addParameter(ParameterEnum.DEFAULT_CATALOGUE, isDefaultCatalogue)
    // const url = `${B2BConstants.BASE_URL}${ApiConstants.CATALOGUE.GET_CLIENT_CATELOGUE_ID}${ApiConstants.CATALOGUE.CLIENT_ID}${userId}${ApiConstants.CATALOGUE.SUB_CATEGORY_ID}${cId}`
    const url = catalogueUrl.buildURL()

    apiCaller.getResponseFromServer(url)
      .then((res) => {
        if (!res) {
          return showErrorToast(createMessage.fetchingFailMessage())
        }

        const catalogue: CatalogueModel = res
        if (catalogue.catalogueResponse) {
          CatalogueStore.setCatalogue(catalogue)
        } else {
          errorDialogHandler();
        }
        this.publishEvent.loadingSuccess(PageEventTypeEnum.CATALOGUE)
      })
  }

  getDefaultCatalogue(id: number) {
    const catalogueUrl = new ApiUrlBuilder(ApiTypeEnum.CATALOGUE);
    this.publishEvent.loading(PageEventTypeEnum.CATALOGUE)
    catalogueUrl.addSubPath(SubPathEnum.GET_DEFAULT_CATALOGUE)
    catalogueUrl.addParameter(ParameterEnum.SORT_COLUMN, 'pid')
    catalogueUrl.addParameter(ParameterEnum.CID, id)
    const url = catalogueUrl.buildURL()
    // const url = `${B2BConstants.BASE_URL}${ApiConstants.CATALOGUE.DEFAULT}${id}`
    CatalogueStore.setCatalogue(null)
    apiCaller.getResponseFromServer(url)
      .then((res: CatalogueModel) => {
        if (!res) {
          return showErrorToast(createMessage.fetchingFailMessage())
        }
        CatalogueStore.setCatalogue(res)
        this.publishEvent.loadingSuccess(PageEventTypeEnum.CATALOGUE)
      })
      .catch(() => {
        showErrorToast(messageConstants.CATALOGUE_LOAD_FAILURE)
      })
  }

  getDefaultCatalogues(id1: number, id: number) {
    const catalogueUrl = new ApiUrlBuilder(ApiTypeEnum.CATALOGUE);
    this.publishEvent.loading(PageEventTypeEnum.CATALOGUE)
    catalogueUrl.addSubPath(SubPathEnum.GET_DEFAULT_CATALOGUE)
    catalogueUrl.addParameter(ParameterEnum.SORT_COLUMN, 'pid')
    // catalogueUrl.addParameter(ParameterEnum.CAT_ID, id1)
    catalogueUrl.addParameter(ParameterEnum.CID, id)
    const url = catalogueUrl.buildURL()
    // const url = `${B2BConstants.BASE_URL}${ApiConstants.CATALOGUE.DEFAULT}${id}`
    CatalogueStore.setCatalogue(null)
    apiCaller.getResponseFromServer(url)
      .then((res: CatalogueModel) => {
        if (!res) {
          return showErrorToast(createMessage.fetchingFailMessage())
        }
        CatalogueStore.setCatalogue(res)
        this.publishEvent.loadingSuccess(PageEventTypeEnum.CATALOGUE)
      })
      .catch(() => {
        showErrorToast(messageConstants.CATALOGUE_LOAD_FAILURE)
      })
  }
  getPathForAllProducts(productName: string, clientId?: number) {
    const catalogueUrl = new ApiUrlBuilder(ApiTypeEnum.CATALOGUE)
    // let baseUrl = `${B2BConstants.BASE_URL}`
    if (PrincipleStore.getToken()) {
      if (clientId) {
        catalogueUrl.addSubPath(SubPathEnum.GET_CATALOGUE_PRODUCTS_BY_NAME)
        catalogueUrl.addParameter(ParameterEnum.CLIENT_ID, clientId)
        catalogueUrl.addParameter(ParameterEnum.PRODUCT_NAME, productName)
        return catalogueUrl.buildURL()

        // baseUrl = baseUrl + `${ApiConstants.CATALOGUE.GET_PRODUCTS}${ApiConstants.CATALOGUE.CLIENT_ID}${clientId}${ApiConstants.CATALOGUE.PRODUCT_NAME}${productName}`
      }
      else {
        catalogueUrl.addSubPath(SubPathEnum.GET_ALL_CATALOGUE_PRODUCTS_BY_NAME)
        catalogueUrl.addParameter(ParameterEnum.PRODUCT_NAME, productName)
        return catalogueUrl.buildURL()
        // baseUrl = baseUrl + `${ApiConstants.CATALOGUE.GET_ALL_PRODUCTS}${ApiConstants.CATALOGUE.PRODUCT_NAME}${productName}`
      }
    }
    else {
      catalogueUrl.addSubPath(SubPathEnum.GET_ALL_CATALOGUE_PRODUCTS_BY_NAME)
      catalogueUrl.addParameter(ParameterEnum.PRODUCT_NAME, productName)
      return catalogueUrl.buildURL()
      // baseUrl = baseUrl + `${ApiConstants.CATALOGUE.GET_ALL_PRODUCTS}${ApiConstants.CATALOGUE.PRODUCT_NAME}${productName}`
    }
    // return baseUrl
  }

  getAllProductsBySearch(productName: string, clientId?: number) {
    this.publishEvent.loading(PageEventTypeEnum.PRODUCTS)

    const catalogue = new CatalogueService()
    apiCaller.getResponseFromServer(catalogue.getPathForAllProducts(productName, clientId))
      .then((res) => {
        if (!res && res.catalogueResponse.length ) {
          this.publishEvent.loadingError(PageEventTypeEnum.PRODUCTS)
          return showErrorToast(createMessage.fetchingFailMessage())
        }

        const productList: CatalogueModel = res.dto
        CatalogueStore.setSearchedProducts(productList.catalogueResponse)
        CatalogueStore.setDiscountMap(productList.responseMap)
        this.publishEvent.loadingSuccess(PageEventTypeEnum.PRODUCTS)

        // CatalogueStore.setCatalogue(productList)
        // apiLoadingSubject.next({
        //   apiCallState: ApiCallState.LOADING_SUCCESS,
        //   eventType: PageEventTypeEnum.CATALOGUE,
        // })

      })
  }

  getSubCategoryIdByCategoryName(desiredCategoryName: string) {

    const subCategoriesList = CategoryStore.getCategoryList();
    let subCategoryId: number | undefined = undefined
    if (subCategoriesList) {
      subCategoriesList.filter((item) => {
        if (item.category === desiredCategoryName) {
          subCategoryId = item.subCategories[0].id
          return true
        } else {
          return false
        }
      })
    }
    CategoryStore.setSubCategoryId(subCategoryId || NaN)
  }

  getDefaultProducts() {
    let catalogue: CatalogueModel | null = CatalogueStore.getCatalogue()
    let category: ProductModel[] = new Array<ProductModel>()

    let defaultCategoryName: string;
    if (catalogue && catalogue.catalogueResponse && catalogue.catalogueResponse[0] && catalogue.catalogueResponse[0].categoryName) {

      defaultCategoryName = catalogue?.catalogueResponse[0].categoryName
      if (defaultCategoryName) {
        for (const product of catalogue?.catalogueResponse || []) {
          if (product.categoryName === defaultCategoryName) {
            category.push(product);
          }
        }
      }
    }
    return category
  }

  getPathForCategoriesList(clientId?: number) {
    if (PrincipleStore.getToken()) {
      if (clientId) {
        const categoryUrl = new ApiUrlBuilder(ApiTypeEnum.CATEGORIES);
        categoryUrl.addSubPath(SubPathEnum.GET)
        categoryUrl.addParameter(ParameterEnum.CLIENT_ID, clientId)
        return categoryUrl.buildURL()
      }
      else {
        const categoryUrl = new ApiUrlBuilder(ApiTypeEnum.CATEGORIES);
        categoryUrl.addSubPath(SubPathEnum.GET_ALL)
        return categoryUrl.buildURL()
      }
    }
    else {
      const categoryUrl = new ApiUrlBuilder(ApiTypeEnum.CATEGORIES);
      categoryUrl.addSubPath(SubPathEnum.GET_ALL)
      return categoryUrl.buildURL()
    }
  }

  getCategoriesList(clientId?: number) {
    const catalogue = new CatalogueService()
    apiCaller.getResponseFromServer(catalogue.getPathForCategoriesList(clientId))
      .then((res: ApiResponseModel) => {

        if (!res) {
          this.publishEvent.loadingError(PageEventTypeEnum.CATEGORIES)
          return showErrorToast(messageConstants.TRY_AGAIN)
        }

        const categoryList: Array<CategoriesListModel> = res.dtoList
        const subcategoryName : SubCategoriesListModel = res.dto
        CategoryStore.setCategoryList(categoryList)
        CategoryStore.setSubCategoryName(subcategoryName)

        

        const subCategoryList = categoryList[0]
        if (subCategoryList && subCategoryList.subCategories && subCategoryList.subCategories[0]) {
          CategoryStore.setDefaultSubCategory(categoryList[0].subCategories[0].id)
        }
        if (PrincipleStore.getToken() !== null) {
          if (clientId) {
            apiLoadingSubject.next({
              apiCallState: ApiCallState.LOADING_SUCCESS,
              eventType: PageEventTypeEnum.CATEGORIES,
            })
          } else {
            apiLoadingSubject.next({
              apiCallState: ApiCallState.LOADING_SUCCESS,
              eventType: PageEventTypeEnum.DEFAULT_CATEGORIES,
            })
          }
        }
        else {
          apiLoadingSubject.next({
            apiCallState: ApiCallState.LOADING_SUCCESS,
            eventType: PageEventTypeEnum.DEFAULT_CATEGORIES,
          })
        }

      })
  }

  getCategoryProducts(name: string) {
    let catalogue: CatalogueModel | null = CatalogueStore.getCatalogue()
    let category: ProductModel[] = new Array<ProductModel>()
    if (name) {
      for (const product of catalogue?.catalogueResponse || []) {
        if (product.categoryName === name) {
          category.push(product);
        }
      }
    }
    return category
  }

  getProductsByDesiredCategoryName(desiredCategoryName: string) {
    const originalCatalogue: CatalogueModel | null = CatalogueStore.getCatalogue();

    let desiredProducts: ProductModel[] | undefined;

    desiredProducts = originalCatalogue?.catalogueResponse.filter((item) => {
      return item.categoryName === desiredCategoryName
    })

    let defaultDesiredProducts: ProductModel[] | undefined;
    if (desiredProducts) {
      let newPantryProducts = desiredProducts;
      defaultDesiredProducts = desiredProducts?.filter((item) => {
        return item.subCategoryName === newPantryProducts[0].subCategoryName;
      });
    }
    return {
      all: desiredProducts,
      default: defaultDesiredProducts
    }
  }

  checkDesiredCategoryInCatalogue(desiredCategoryName: string) {
    const catalogue = new CatalogueService()
    return catalogue.getProductsByDesiredCategoryName(desiredCategoryName).default?.length === 0
  }

  getSubCategoryProducts(name: string) { // not in use
    let catalogue: CatalogueModel | null = CatalogueStore.getCatalogue()
    let category: ProductModel[] = new Array<ProductModel>();
    if (name) {
      const productList = catalogue?.catalogueResponse ?? [];
      for (const product of productList) {
        if (product.subCategoryName === name) {
          category.push(product);
        }
      }
    }

    return category
  }

  getSearchedBrandName(brandName: string, allBrands: Array<string>) {
    let searchedBrands: string[] = new Array<string>()
    allBrands.forEach((item: string) => {
      if (item && brandName && item.toLowerCase().includes(brandName.toLowerCase())) {
        searchedBrands.push(item)
      }
    })
    return searchedBrands
  }

  getBrandsofSubCategory(catalogue: CatalogueModel | undefined | null, subCategory: string) {
    const brands: string[] = [];
    catalogue?.catalogueResponse.forEach((product: ProductModel) => {
      if (product.brandName && product.subCategoryName === subCategory && !brands.includes(product.brandName)) {
        brands.push(product.brandName);
      }
    });
    return brands;
  }

  getSearchedBrandNavbar(searchedBrand: string, originalCatalogue: CatalogueModel | null) {
    if (!originalCatalogue) {
      return [];
    }

    const brandProducts: ProductModel[] = originalCatalogue.catalogueResponse.filter(product =>
      product.brandName.toLowerCase().trim() === searchedBrand.toLowerCase().trim()
    );

    // Return the updated filtered products directly
    return brandProducts;
  }

  //search by brand
  getSearchedBrands(searchedBrands: string[], originalCatalogue: CatalogueModel | null) {
    if (searchedBrands.length === 0) {
      return originalCatalogue?.catalogueResponse
    }
    if (!originalCatalogue) {
      return [];
    }

    const brandProducts: ProductModel[] = originalCatalogue.catalogueResponse.filter(product =>
      searchedBrands.some((brand) => {

        return (product.brandName || "").toLowerCase().trim() === brand.toLowerCase().trim()
      }
      )
    );
    // Return the updated filtered products directly
    return brandProducts;
  }

  getSearchedCategory(searchedCategory: string, originalCatalogue: CatalogueModel | null) {
    const catalogue: CatalogueModel | null = originalCatalogue;
    const categoryProducts: ProductModel[] = [];

    if (catalogue) {
      for (const product of catalogue.catalogueResponse) {
        if (product.categoryName.toLowerCase().trim() === searchedCategory.toLowerCase().trim()) {
          categoryProducts.push(product);
        }
      }
    }

    return categoryProducts;
  }

  //search by sub category
  getSearchedSubCategory(searchedSubCategory: string, originalCatalogue: CatalogueModel | null) {
    const catalogue: CatalogueModel | null = originalCatalogue;
    const subCategoryProducts: ProductModel[] = [];

    if (catalogue) {
      for (const product of catalogue.catalogueResponse) {
        if (product.subCategoryName.toLowerCase().trim() === searchedSubCategory.toLowerCase().trim()) {
          subCategoryProducts.push(product);
        }
      }
    }

    return subCategoryProducts;

  }

  //search by product
  getSearchedProducts(searchedProduct: string, originalCatalogue: CatalogueModel | null) {
    const catalogue: CatalogueModel | null = originalCatalogue;
    const matchingProducts: ProductModel[] = [];
    if (catalogue) {
      for (const product of catalogue.catalogueResponse) {
        if (product.productName.toLowerCase().trim().includes(searchedProduct.toLowerCase().trim())) {
          matchingProducts.push(product);
        }
      }
    }

    return matchingProducts;
  }
}