import {useEffect, useState} from "react";
import "./NewProduct.css";
import {PageEventTypeEnum} from "../../../Model/ApiCallResponse";
import {CategoriesListModel, SubCategoriesListModel} from "../../../Model/CatalogueModel";
import {ApiCallState} from "../../../Model/Enums/ApiCallState";
import {apiLoadingSubject, CategoryStore,} from "../../../Store";
import {messageConstants} from "../../../StringConstants";
import {errorDialogHandler} from "../../../utils/ErrorDialogHandler";
import {CatalogueService} from "../../../Services/CatalogueService";
import {SearchParamsService} from "../../../Services/SearchParamsService";
import NewCategoryLoader from "../../LoadingComponent/NewCategoryLoader";

const NewCategory = () => {
    const [subCategoriesList, setSubCategoriesList] = useState<Array<CategoriesListModel>>(new Array<CategoriesListModel>());
    const [selectedSubCategory, setSelectedSubCategory] = useState<number>();
    const [categoriesLoading, setIsCategoriesLoading] = useState(true);

    

    useEffect(() => {
        const catalogueData = new CatalogueService();
        catalogueData.getCategoriesList();
        apiLoadingSubject.subscribe((response) => {
            if (
                response.eventType === PageEventTypeEnum.CATEGORIES ||
                response.eventType === PageEventTypeEnum.DEFAULT_CATEGORIES
            ) {
                switch (response.apiCallState) {
                    case ApiCallState.LOADING:
                        setIsCategoriesLoading(true);
                        break;

                    case ApiCallState.LOADING_SUCCESS:
                        const categories = CategoryStore.getCategoryList();
                        setSubCategoriesList(categories);
                        setIsCategoriesLoading(false);
                        break;

                    case ApiCallState.LOADING_ERROR:
                        setIsCategoriesLoading(false);
                        errorDialogHandler(messageConstants.CATALOGUE_LOAD_FAILURE);
                        break;
                }
            }
        });
    }, []);


    const categoryChangeHandler = (subCategory: SubCategoriesListModel) => {
        SearchParamsService.setParams([{key: "cid", value: ''},{key: "brandIds", value: ''},{key: "sCid", value: subCategory.id.toString()},
            {key: "selectedScid", value: subCategory.name.toString()},
            { key: "selectedCid", value: "" },
            { key: "category", value: "" },


        ]);
        setSelectedSubCategory(subCategory.id)
    };

    return (
        <>     

            <div className="filter_wrapper big_device_filterWrapper">
                <div className="accordion" id="filterAccordion">
                    {!categoriesLoading ? (
                        subCategoriesList.map((category) => (
                            <div className="accordion-item" key={category.id}>
                                <h2 className="accordion-header" id={`Filter${category.id}`}>
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#Filter_collapse_${category.id}`}
                                        aria-expanded="true"
                                        aria-controls={`Filter_collapse_${category.id}`}
                                    >
                                        {category.category}
                                    </button>
                                </h2>
                                <div
                                    id={`Filter_collapse_${category.id}`}
                                    className="accordion-collapse collapse"
                                    aria-labelledby={`Filter${category.id}`}
                                >
                                    <div className="accordion-body">
                                        {category.subCategories.map((subItem) => (
                                            <div className="form-check" key={subItem.id}>
                                                <label className="form-check-label">
                                                    <input
                                                        className="form-check-input"
                                                        name="sub-category"
                                                        type="radio"
                                                        id={`st${subItem.id}`}
                                                        onChange={() => categoryChangeHandler(subItem)}
                                                        value={subItem.id}
                                                        defaultValue={selectedSubCategory}
                                                    />
                                                    {subItem.name}
                                                </label>
                                            </div>
                                        ))}
                                    </div>  
                                </div>
                            </div>
                        ))
                    ) : (
                        <NewCategoryLoader/>
                    )}
                </div>
            </div>
        </>
    );
};

export default NewCategory;
