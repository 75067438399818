import { createMessage } from "../CreateMessage";
import { PageEventTypeEnum } from "../Model/ApiCallResponse";
import { ApiResponseStatusEnum } from "../Model/Enums/ApiCallState";
import GetInTouchRequestDto from "../Model/FestivalModel";
import { ApiResponseModel } from "../Model/NutritapResponseModel";
import { UserDto, UserRegisterModel } from "../Model/UserModel";
import { ProfileStore } from "../Store";
import { messageConstants } from "../StringConstants";
import { ApiTypeEnum, SubPathEnum } from "../Model/Enums/ApiConstantEnum";
import { ApiUrlBuilder } from "../utils/Stringbuilder";
import { apiCaller } from "./ApiCallService";
import { PublishEventService } from "./PublishEventService";
import { CheckService } from "./checkValidationService";
import {
  showErrorToast,
  showSuccessToast,
  showWarningToast,
} from "../utils/Toastify/ToastifyHandler";

export class ClientInfoService {
  private publishEventService = new PublishEventService();

  getLoggedInClientInfo() {
    const clientInfoUrl = new ApiUrlBuilder(ApiTypeEnum.CLIENT_INFO);
    clientInfoUrl.addSubPath(SubPathEnum.GET_LOGIN_CLIENT);
    const url = clientInfoUrl.buildURL();
    // const url = `${B2BConstants.BASE_URL}${ApiConstants.USER.LOGGED_IN_CLIENT_INFO}`
    apiCaller.getResponseFromServer(url).then((res: ApiResponseModel) => {
      if (!res) {
        return showErrorToast(createMessage.fetchingFailMessage());
      }

      const clientLoggedInfo: UserDto = res.dto;

      ProfileStore.setDetails(clientLoggedInfo);
      this.publishEventService.loadingSuccess(PageEventTypeEnum.PROFILE);
    });
  }

  addClientNewAddress(userDetails: UserDto) {
    const clientInfoUrl = new ApiUrlBuilder(ApiTypeEnum.CLIENT_INFO);
    clientInfoUrl.addSubPath(SubPathEnum.UPDATE);
    // const url = `${B2BConstants.BASE_URL}${ApiConstants.ACCOUNT.ADD_NEW_ADDRESSES}`
    const url = clientInfoUrl.buildURL();
    const body = JSON.stringify(userDetails);
    apiCaller.postResponseToServer(url, body).then((res: ApiResponseModel) => {
      if (!res) {
        return showErrorToast(createMessage.fetchingFailMessage());
      }

      if (res.success || res.status === ApiResponseStatusEnum.SUCCESS) {
        return showSuccessToast(res.message);
      } else {
        return showErrorToast(res.errors[0]);
      }
    });
  }

  sendDetailsToServer(data: GetInTouchRequestDto) {
    const clientInfoUrl = new ApiUrlBuilder(ApiTypeEnum.CLIENT_INFO);
    clientInfoUrl.addSubPath(SubPathEnum.GET_IN_TOUCH);
    // const url = `${B2BConstants.BASE_URL}${ApiConstants.FESTIVAL.GET_IN_TOUCH}`
    const url = clientInfoUrl.buildURL();
    const body = JSON.stringify(data);

    const raiseAlert = (message: string) => {
      showWarningToast(message);
    };
    apiCaller.postResponseToServer(url, body).then((res: ApiResponseModel) => {
      if (!res) {
        return showErrorToast(messageConstants.FAILED);
      }
      if (res.status === ApiResponseStatusEnum.SUCCESS || res.success) {
        showSuccessToast(messageConstants.DETAIL_SENT_SUCCESSFULLY);
      } else if (res.errors) {
        raiseAlert(res.errors[0]);
      } else {
        showErrorToast(messageConstants.FAILED);
      }
    });
  }

  getAllClientList() {
    this.publishEventService.loading(PageEventTypeEnum.CLIENT_LIST);
    const clientsUrl = new ApiUrlBuilder(ApiTypeEnum.CLIENT_INFO);
    clientsUrl.addSubPath(SubPathEnum.GET_USER_SUB_USER);
    const url = clientsUrl.buildURL();
    // const url = `${B2BConstants.BASE_URL}${ApiConstants.USER.GET_ALL_CLIENT_LIST}`
    apiCaller
      .getResponseFromServer(url)
      .then((res: ApiResponseModel) => {
        if (!res) {
          return showErrorToast(messageConstants.FAILED);
        } else {
          const clientData: Array<UserDto> = res.dtoList;
          this.publishEventService.loadingSuccess(
            PageEventTypeEnum.CLIENT_LIST,
            clientData
          );
        }
      })
      .catch(() => {
        this.publishEventService.loadingError(PageEventTypeEnum.CLIENT_LIST);
      });
  }

  register(
    userRequestDto: UserRegisterModel,
    navigateToLogin: CallableFunction
  ) {
    const clientInfoUrl = new ApiUrlBuilder(ApiTypeEnum.CLIENT_INFO);
    clientInfoUrl.addSubPath(SubPathEnum.REGISTER);
    this.publishEventService.loading(PageEventTypeEnum.USER_REGISTER);
    // const url = `${B2BConstants.BASE_URL}${ApiConstants.USER.REGISTER}`
    const url = clientInfoUrl.buildURL();
    const body = JSON.stringify(userRequestDto);
    apiCaller.postResponseToServer(url, body).then((res: ApiResponseModel) => {
      if (!res) {
        return;
      }
      if (res.status === ApiResponseStatusEnum.SUCCESS || res.success) {
        if (!CheckService.isLoggedIn()) {
          showSuccessToast(res.message);
          navigateToLogin();
        }

        if (CheckService.isAdmin()) {
          navigateToLogin();
        }
        this.publishEventService.loadingSuccess(
          PageEventTypeEnum.USER_REGISTER,
          res.dto
        );
      } else if (
        res.status === ApiResponseStatusEnum.FAILURE ||
        parseInt(res.status) === 400
      ) {
        this.publishEventService.loadingError(PageEventTypeEnum.USER_REGISTER);

        if (res.errors) {
          showErrorToast(res.errors[0]);
        } else {
          showErrorToast(res.message);
        }
      }
    });
  }
  deleteMember(id: number) {
    const deleteUrl = new ApiUrlBuilder(ApiTypeEnum.CLIENT_INFO);
    deleteUrl.addSubPath(SubPathEnum.DELETE);
    deleteUrl.addSubPath(id);
    const url = deleteUrl.buildURL();
    apiCaller
      .deleteResponseFromServer(url)
      .then((res: ApiResponseModel) => {
        if (!res) {
          return showErrorToast(createMessage.fetchingFailMessage());
        }
        if (res.success || res.status === ApiResponseStatusEnum.SUCCESS) {
          showSuccessToast(messageConstants.DELETE_MEMBER);
          this.publishEventService.reloadPage(PageEventTypeEnum.CLIENT_LIST);
        } else {
          showErrorToast(messageConstants.TRY_AGAIN);
        }
      })
      .catch(() => {
        showErrorToast(messageConstants.TRY_AGAIN);
      });
  }
}
