import PublicRoutes from "./Public/PublicRoute";
import PrivateRoutes from "./Private/PrivateRoute";
import { SearchParamsWrapper } from "../Components/Wrapper/SearchParamsWrapper";

const NewRoutes = () => {
  return (
    <>
      <SearchParamsWrapper />
      <PublicRoutes />
      <PrivateRoutes />
    </>
  );
};

export default NewRoutes;
