import { createMessage } from "../CreateMessage";
import { PageEventTypeEnum } from "../Model/ApiCallResponse";
import { ApiResponseStatusEnum } from "../Model/Enums/ApiCallState";
import { ApiResponseModel } from "../Model/NutritapResponseModel";
import { CityModel } from "../Model/StateCityModel";
import { CityStore } from "../Store";
import {
  ApiTypeEnum,
  ParameterEnum,
  SubPathEnum,
} from "../Model/Enums/ApiConstantEnum";
import { ApiUrlBuilder } from "../utils/Stringbuilder";
import { apiCaller } from "./ApiCallService";
import { PublishEventService } from "./PublishEventService";
import { showErrorToast } from "../utils/Toastify/ToastifyHandler";

export class CityService {
  private publishEvent = new PublishEventService();

  getCityList(stateId: number) {
    this.publishEvent.loading(PageEventTypeEnum.CITY_LIST);
    const cityUrl = new ApiUrlBuilder(ApiTypeEnum.CITY);
    cityUrl.addSubPath(SubPathEnum.GET_ALL);
    cityUrl.addParameter(ParameterEnum.STATE_ID, stateId);
    cityUrl.addParameter(ParameterEnum.IS_ACTIVE, true);

    const url = cityUrl.buildURL();
    apiCaller
      .getResponseFromServer(url)
      .then((res: ApiResponseModel) => {
        if (!res) {
          showErrorToast(createMessage.fetchingFailMessage());
          this.publishEvent.loadingError(PageEventTypeEnum.CITY_LIST);
        }
        if (res.success || res.status === ApiResponseStatusEnum.SUCCESS) {
          const cities: Array<CityModel> = res.dtoList;
          CityStore.setCityList(cities);
          this.publishEvent.loadingSuccess(PageEventTypeEnum.CITY_LIST);
        } else {
          this.publishEvent.loadingError(PageEventTypeEnum.CITY_LIST);
        }
      })
      .catch(() => {
        showErrorToast(createMessage.fetchingFailMessage());
        this.publishEvent.loadingError(PageEventTypeEnum.CITY_LIST);
      });
  }
}
