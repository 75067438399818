import { OrderStatusEnum } from "./Enums/OrderStatusEnums";


export enum PurchaseOrderType {
    ITEM_WISE = "ITEM_WISE",
    AMOUNT_WISE = "AMOUNT_WISE",
}

export interface PurchaseOrderDto {
    id?: number;
    poNo: string;
    expDate: number;
    poDate: number;
    purchaseOrderType: PurchaseOrderType;
    totalAmount?: number;
    remainingAmount?: number;
    createdBy?: string;
    createdByName?: string;
    items?: PODetailsDto;
    status?: OrderStatusEnum;
    remarks?: string;
    amountGstExcl?:number;
}

export interface PurchaseOrderRequestDto {
    purchaseOrderDto: PurchaseOrderDto;
    purchaseOrderDoc: File | null;
    purchaseOrder: File | null;
}

export interface PODetailsDto {
    remainingQty: number;
}