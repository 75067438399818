import React, { useEffect, useState } from "react";
// import "./NewForgetPassword.css";
import "./Verification.css";
import ConfirmNewPassword from "./ConfirmNewPassword";
import { messageConstants } from "../../../StringConstants";
import { UserService } from "../../../Services/UserService";
import { showWarningToast } from "../../../utils/Toastify/ToastifyHandler";
interface props {
  email: string;
}

const Verification = ({ email }: props) => {
  let otpLength = 4;
  const [otp, setOtp] = useState<string[]>([]);
  const [otpVerification, setOtpVerification] = useState<boolean>(false);

  const [isActive, setIsActive] = useState<boolean>(true);
  const [countdown, setCountdown] = useState<number>(30);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      checkOtp();
    }
  };

  const userData = new UserService();

  const updateOtp = (index: number, target: any) => {
    const { value } = target;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    const nextSibling = target.nextElementSibling;
    if (nextSibling && value.trim().length > 0) {
      nextSibling.focus();
    }
  };

  const joinedOtp = otp.join("");

  const checkOtp = () => {
    if (joinedOtp.length === 4) {
      userData.forcedLogin(email, joinedOtp, setOtpVerification);
      // setOtpVerification(true);
    } else {
      showWarningToast(messageConstants.WRONG_VERIFICATION_CODE);
      setOtpVerification(false);
    }
  };

  useEffect(() => {
    setIsActive(false);
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (!isActive && countdown > 0) {
      // Start the timer if the button is not active
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    // Clear the timer when the component unmounts or when isActive becomes true
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [isActive]);

  const handleResendClick = () => {
    userData.resetPassword(email);

    // Deactivate the button and start the countdown
    setIsActive(false);
  };

  useEffect(() => {
    // Reset the countdown and activate the button after the countdown reaches 0
    if (countdown === 0) {
      setIsActive(true);
      setCountdown(30); // Reset the countdown time
    }
  }, [countdown]);

  return (
    <>
      <div className="container">
        {/* <div className="forms_wrpapper paddingBottom text-center"></div> */}
        {!otpVerification ? (
          <div className="default_tabs paddingBottom">
            <ul
              className="nav nav-pills mb-2 mb-md-3 justify-content-center"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-forgetPass-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-forgetPass"
                  type="button"
                  role="tab"
                  aria-controls="pills-forgetPass"
                  aria-selected="true"
                >
                  Verify with OTP
                </button>
              </li>
            </ul>

            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-forgetPass"
                role="tabpanel"
                aria-labelledby="pills-forgetPass-tab"
              >
                <div className="btn-center">
                  <p className="grey_clr mobile_fs mb-0">Enter your OTP</p>
                </div>
                <div className="otp-container">
                  {Array.from({ length: otpLength }, (_, index) => (
                    <input
                      key={index}
                      type="number"
                      className="otp-input"
                      maxLength={1}
                      required
                      onChange={(e) => updateOtp(index, e.target)}
                      onKeyDown={handleKeyDown}
                    />
                  ))}
                </div>
                <div className="btn-center">
                  <button
                    className="theme_btn default_form px-4 mt-3"
                    onClick={checkOtp}
                  >
                    Proceed
                  </button>
                  {isActive ? (
                    <button
                      className="theme_btn default_form px-4 mt-3"
                      onClick={handleResendClick}
                    >
                      Resend OTP
                    </button>
                  ) : (
                    <button
                      className="theme_btn default_form px-4 mt-3"
                      disabled
                    >
                      {`Resend OTP (${countdown}s)`}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <ConfirmNewPassword forced={true}/>
          </>
        )}
      </div>
    </>
  );
};

export default Verification;
