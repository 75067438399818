import { RoleTypeEnum } from "./Enums/RoleTypeEnum"
import BaseOrder from "./OrderModel"

export interface BaseResponse {
    success: boolean
    message: string
    errors: ErrorDetails
}

interface ErrorDetails {
    timestamp: string
    status: number
    errors: Array<string>
    object: Array<any>
}

export interface RazorpayInitiateOrderResponseDto extends BaseResponse {
    orderId: string
}

export interface RazorpayInitiateOrderRequestDto {
    amount: string;
    orderId: number;
    type : RoleTypeEnum.B2B
}

export interface GenricPaymentDto {
    paymentAmount: number
    razorpaySignature: string
    orderId: number;
    txnId: string;
    razorpayPaymentId: string;
    error?: string;
}

export interface PaymentDetailsDto {
    orderResponse: BaseOrder,
    razorpayResponse: GenricPaymentDto
}

export const initialGenricPaymentDto: GenricPaymentDto = {
    orderId: 0,
    txnId: "",
    razorpayPaymentId: "",
    error: "", 
    razorpaySignature: "",
    paymentAmount: 0
}

export interface RazorpayResponseDto {
    error: string | undefined,
    razorpay_order_id: string,
    razorpay_payment_id: string,
    razorpay_signature: string,
    txnid: string;
    status_code: number;
}

export const InitialRazorpayResponseDto: RazorpayResponseDto = {
    error: undefined,
    razorpay_order_id: "",
    razorpay_payment_id: "",
    razorpay_signature: "",
    txnid: "",
    status_code: 0
}

export const getInitialRazorpayResponseDto = (): RazorpayResponseDto => {
    return JSON.parse(JSON.stringify(InitialRazorpayResponseDto));
};

export const getInitialRazorpayPaymentDto = (): GenricPaymentDto => {
    return JSON.parse(JSON.stringify(initialGenricPaymentDto));
};