export enum PageTypeEnum {
  ADD_NEW_USER = "add-member",
  ACCOUNT = "account",
  ADDRESS = "address",
  ADMIN = "/",
  ALL_ORDERS = "orders/all",
  APPROVAL = "approval",
  CART = "cart",
  CATEGORY = "product",
  CHANGE_PASSWORD = "changepassword",
  FORCED_CHANGE_PASSWORD = "forcedchangepassword",
  VERIFICATION_CODE = "verification",
  HOME = "/",
  LOGIN = "login",
  LOGOUT = "logout",
  NOTIFICATIONS = "notifications",
  ORDERS = "orders",
  ORDER_DETAILS = "orders/:orderid",
  PENDING = "pending",
  PROFILE = "profile",
  RESET_PASSWORD = "resetpassword",
  REGISTER = "register",
  SETTINGS = "settings",
  OFFERS = "offers",
  ALL_CLIENTS = "all-clients",
  ACCESS_DENIED = "access-denied",
  ERROR = "error",
  DASHBOARD = "dashboard",
  CHECKOUT = "checkout",
  PURCHASE_ORDER = "purchaseorder",
  BANNER = "banner",
}
