import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Bounce, ToastOptions, Zoom, toast } from "react-toastify";

const toastProperties: ToastOptions<unknown> | undefined = {
    position: 'bottom-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce
}

export const showSuccessToast = (message: string | ReactJSXElement) => {
    toast.success(message, toastProperties)
}

export const showErrorToast = (message: string | ReactJSXElement) => {
    toast.error(message, toastProperties)
}

export const showWarningToast = (message: string | ReactJSXElement) => {
    toast.warning(message, toastProperties)
}

export const showInfoToast = (message: string | ReactJSXElement) => {
    toast.info(message, toastProperties)
}

export const showPromise = (promise: Promise<unknown> | (() => Promise<unknown>)) => {

    toast.promise(promise, {
        pending: {
            render() {
                return "please wait"
            },
        },
        error: {
            render() {
                return "Oops... Network lost"
            },
        },
        success: {
            render(){
                return "Done!!!"
            }
        }
    })
}