class MessageConstant {
  readonly SUCCESS = "Success !!!";
  readonly PROCEED = "Proceed";
  readonly FAILED = "Fetching Data Failed";
  readonly CATALOGUE_LOAD_FAILURE =
    "Catalogue Loading Error= Please contact NutriTap Admin!";
  readonly TEXT_LENGTH = "Enter atleast 3 characters to search";
  readonly COPIED = "Order ID copied!";
  readonly DELETE_PRODUCT = "Do you really want to delete this item?";
  readonly CONFIRM_DELETE = "Confirm Delete!";
  readonly ORDER_PLACED = "Order Placed Successfully";
  readonly ORDER_PLACED_FAILED = "Your Order is not placed kindly refresh";
  readonly CURRENT_PLACED_ORDER_ID = "Your order id is :";
  readonly VIEW_ORDER = "View Order";
  readonly RETURN_HOME = "Return To Home";
  readonly CONFIRM_BUTTON = "Confirm";
  readonly CANCEL_BUTTON = "Cancel";
  readonly HOUSE_NO_EMPTY = "House Number cannot be empty";
  readonly AREA_EMPTY = "Area cannot be empty";
  readonly HOUSE_NO_INVALID = "House No contain special character";
  readonly STATE_INVALID = "State cannot contain special character";
  readonly COUNTRY_INVALID = "Country cannot contain special character";
  readonly CITY_INVALID = "City cannot contain special character";
  readonly AREA_INVALID = "Area contain special character";
  readonly PINCODE_EMPTY = "Pincode is Empty";
  readonly GST_INVALID = "Gst No. is invalid";
  readonly PHONE_NO_INVALID = "Mobile Number is Invalid.";
  readonly PASSWORD_IS_REQUIRED = "password is required";
  readonly PASSWORD_NOT_EMPTY =
    "Password is mandatory field, if you dont remember, kindly click forget password!";
  readonly PASSWORD_NOT_MATCHED = "Password does not matched";
  readonly PASSWORD_IS_WRONG = "User can not login, wrong password";
  readonly EMAIL_INVALID = "Email is invalid";
  readonly EMAIL_IS_REQUIRED = "Email ID is mandatory field!";
  readonly ADDRESS_EMPTY = "Address is empty";
  readonly EMPLOYEE_NOT_EMPTY = "Employee name is empty";
  readonly ORGANISATION_NAME_EMPTY = "Organisation name is empty";
  readonly PINCODE_INVALID =
    "Pincode cannot contain special character and be more than six";
  readonly APPROVE_ORDER = "Order approved";
  readonly REJECT_ORDER = "Approval rejected";
  readonly DATE_RANGE_SAME = "Dates are same";
  readonly SAME_PASSWORD = "old and new password canot be same";
  readonly CONFIRM_AND_NEW_PASSWORD =
    "Confirm password does not match new password; please recheck!";
  readonly CANNOT_EMPTY = "These Input fields cannot be empty";
  readonly RESET_PASSWORD =
    "Verification Code generated. Please check your mailbox!";
  readonly REFRESH_PAGE = "Kindly Refresh Page. Error Occured";
  readonly TRY_AGAIN = "Please Try Again;Error Ocurred";
  readonly CREATE_ORDER_ERROR =
    "Error occured while placing order; please retry!";
  readonly WRONG_VERIFICATION_CODE =
    "Wrong Verification Code, please try again!";
  readonly NOT_LOGGED_IN = "You Are Not logged-in. Please Sign-in First!";
  readonly REFRESH = "Something Went wrong !!,please reload";
  readonly USER_NAME_AND_PASS = "Username & Password are mandatory fields!";
  readonly USER_NAME_NOT_EMPTY = "Username is mandatory field!";
  readonly SELECT_PAYMENT_OPTION = "Select payment option!";
  readonly CART_EMPTY = "Your Cart is Empty; Add Atleast 1 item";
  readonly CONFIRM_CANCEL_ORDER = "Are you sure want to cancel this order";
  readonly ERROR_COPYING = "Error copying to clipboard";
  readonly CANNOT_EXCEED_MAX_LIMIT = (quantity: number) =>
    `Can not exceed available quantity of ${quantity}`;
  readonly CANNOT_EXCEED_MIN_LIMIT = "Quantity cannot be less than zero";
  readonly CANNOT_EXCEED_MAX_QUANTITY = "Cannot Exceed Max Quantity";
  readonly CANNOT_EXCEED_MIN_QUANTITY = "Cannot Exceed Min Quantity";
  readonly CLIENT_ADDED = "new member is added successfully";
  readonly DETAIL_SENT_SUCCESSFULLY = "Details sent successfully!";
  readonly MAX_DEVICE_LIMIT =
    "Too many people are using this account right now; please log out from other users or devices to continue.";
  readonly SHOW_PAGE_NAME = (currentPageName: string) =>
    `You are already on ${currentPageName} page`;
  readonly EDIT_ORDER = "Edit Order";
  readonly NOTHING_TO_UPDATE = "Nothing to update";
  readonly ORDER_UPDATE_SUCCESS = "Order Updated Successfully";
  readonly RE_ORDER = "Items are processed in Cart for Reorder";
  readonly VIEW_DETAILS_FIRST = "Please View Products Details first";
  readonly PAYMENT_NOT_DONE =
    "Your Payment is not Completed , Any amount Deducted will be refunded in 3 working days";
  readonly PAYMENT_STATUS_NOT_UPDATED = "Payment is done but not updated";
  readonly DELETE_ADDRESS = "Do you really want to delete this address?";
  readonly BULK_ORDER = "Generate Bulk Order";
  readonly EXCEL_ALLOWED =
    "File format not supported; only Excel format is allowed";
  readonly PDF_ALLOWED =
    "File format not supported; only Pdf format is allowed";
  readonly NO_FILES_SELECTED = "selected file is removed";
  readonly OUT_OF_STOCK = (productName: string) =>
    `${productName} is Out Of Stock`;
  readonly NO_ORDER_FOUND = "No Order Details found";
  readonly ADDRESS_FIELD_EMPTY = "Please Select An Address.";
  readonly NO_EXCEL_FILES_SELECTED = "Please Select An Excel File.";
  readonly HEADERS_NOT_MATCH = "Uploaded file headers do not match the expected format."
  readonly UPDATE_QUANTITY_OF_ITEMS = "Please update the quantity first."
  readonly MIN_AMOUNT_ORDER =
    "Add items worth 10,000 for proceeding to checkout.";
  readonly DELETE_MEMBER = "Member deleted";
  readonly FILE_UPLOADED = "File Uploaded Successfully."
  readonly MORE_THAN_AVL_QTY = "Quantity cannot be more than available Quantity."
}

export const messageConstants = new MessageConstant();
