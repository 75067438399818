import { Pagination, css, styled } from "@mui/material";

export const StyledPagination = styled(Pagination)`
    ${(props) => {
        if (props.page) {
            return css`
            display: flex;
            justify-content: center;
            align-items: left;
        `
        }
    }}
`

export const Wrapper = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const RequiredField = styled('span')`
font-size: 20px;
     ${(props: { isEntered: boolean }) => {
        switch (props.isEntered) {
            case true:
                return css`color: green;`
            case false:
                return css`color: red;`
        }
    }}`

export const AvailableQty = styled('p')`
    font-weight: 500;

    ${(props: { maxQty: number; currQty: number }) => {
        if (props.maxQty - props.currQty === 0) {
            return css`
                color: red;
            `
        }
        else
            if (props.maxQty - props.currQty < (props.maxQty / 8)) {
                return css`
                    color: #2a97bc;
                `
            }
            else
                if (props.maxQty - props.currQty < (props.maxQty / 5)) {
                    return css`
                    color: blue;
                `
                }
                else {
                    return css`
                        color: green;
                    `
                }
    }}

    @media (min-width: 2560px) {
        font-size: 16px;
    }
    @media (min-width: 1440px) and (max-width: 2559px) {
        font-size: 16px;
    }
    @media (min-width: 1024px) and (max-width: 1439px) {
        font-size: 15px;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
        font-size: 15px;
    }
    @media (min-width: 425px) and (max-width: 767px) {
        font-size: 14px;
    }
    @media (min-width: 375px) and (max-width: 424px) {
        font-size: 12px;
    }
    @media (min-width: 320px) and (max-width: 374px) {
        font-size: 12px;
        font-weight: 400;
    }
`