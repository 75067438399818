import { OrderStatusEnum } from "../Model/Enums/OrderStatusEnums";
import { RoleTypeEnum } from "../Model/Enums/RoleTypeEnum";
import { OrderDto } from "../Model/OrderModel";
import { PrincipleStore } from "../Store";

export const CheckService = {
    checkEditBtnForOrderIsVisible: (role: string, orderStatus: OrderStatusEnum) => {
        if (role === RoleTypeEnum.ADMIN) {
            return (orderStatus === OrderStatusEnum.APPROVAL_PENDING || orderStatus === OrderStatusEnum.PENDING || orderStatus === OrderStatusEnum.REJECTED)
        }
        else {
            return (orderStatus === OrderStatusEnum.APPROVAL_PENDING || orderStatus === OrderStatusEnum.REJECTED)
        }
    },
    checkDownloadBtnForOrderIsVisible: (orderStatus: OrderStatusEnum) => {
        return orderStatus === OrderStatusEnum.DELIVERED || orderStatus === OrderStatusEnum.COMPLETED
    },
    checkBulkOrderButtonIsVisible: (orderStatus: OrderStatusEnum, role?: string) => {
        return orderStatus === OrderStatusEnum.BULK_ORDER || orderStatus === OrderStatusEnum.PENDING
    },
    isLoggedIn: () => {
        // @ts-ignore
        return PrincipleStore.getToken() !== null && PrincipleStore.getToken().trim().length > 0;
    },
    isAdmin: () => {
        return PrincipleStore.getRole() === RoleTypeEnum.ADMIN
    },
    shortString: (valueToBeChanged: string, maxValueAllowed: number) => {
        if (valueToBeChanged.length > maxValueAllowed) {
            return valueToBeChanged.substring(0, maxValueAllowed) + '...';
        }
        else {
            return valueToBeChanged
        }
    },
    showTooltipText: (orderDetails: OrderDto | undefined, showProductDropdown: boolean) => {
        if (orderDetails?.childOrder && orderDetails.childOrder?.length > 0) {
            return showProductDropdown ? 'Hide Sub Orders' : 'View Sub Orders'
        }
        else {
            return showProductDropdown ? 'Hide Product Details' : 'View Product Details'
        }

    }
}
