// @ts-ignore
import Video from "./video.mp4";
import './NewHowItWorks.css'
import { Link } from 'react-router-dom';
const NewHowItWorks = () => {
  return (
    <div>
        <div className="mt-4 mt-lg-5 bg_light_yellow py-4 py-lg-5">
        <div className="container">
            <div className="row gy-4 gy-lg-0">
                <div className="col-lg-6">
                    <div className="map_wrap">
                    <video  autoPlay muted loop playsInline>
                        <source className="w-100 img-fluid"  src={Video} type="video/mp4"/>
                        </video>
                        {/* <img className="w-100 img-fluid" src={map} alt="map" /> */}
                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="work_process_wrap">
                        <div>
                            <h4 className="fw-bold  black_clr mb-4">How it works</h4>
                            <div className="work_process">
                                <div className="workProcess_item">
                                    <h4 className="fw-bold  black_clr">01.</h4>
                                    <h2 className="text-uppercase green_clr fw-bold">ORDER</h2>
                                </div>
                                <div className="workProcess_item">
                                    <h4 className="fw-bold  black_clr">02.</h4>
                                    <h2 className="text-uppercase green_clr fw-bold">Track</h2>
                                </div>
                                <div className="workProcess_item">
                                    <h4 className="fw-bold  black_clr">03.</h4>
                                    <h2 className="text-uppercase green_clr fw-bold">Manage</h2>
                                </div>
                            </div>
                        </div>

                        <div className="row gy-3 gy-md-0 mt-4 mt-lg-0">
                            <div className="col-sm-6 col-xxl-4">
                                <div className="connect_info">
                                    <i className="bi bi-telephone-fill fs-3 mb-2"></i>
                                    <Link className="text-decoration-none" to="#"><h4 className="fw-500 mb-0">+91 72176 47998</h4></Link>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xxl-4">
                                <div className="connect_info">
                                    <i className="bi bi-envelope-fill fs-3 mb-1"></i>
                                    <Link to="" className="text-decoration-none" onClick={() => window.location.href = 'mailto:info@nutritap.in'}><h4 className="fw-500 mb-0">info@nutritap.in</h4></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default NewHowItWorks
