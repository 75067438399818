import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { ApiCallResponse, PageEventTypeEnum } from "../../../Model/ApiCallResponse";
import { ApiCallState } from "../../../Model/Enums/ApiCallState";
import { apiLoadingSubject, OrderStore } from "../../../Store";
import { messageConstants } from "../../../StringConstants";
import { errorDialogHandler } from "../../../utils/ErrorDialogHandler";
import { OrderDto } from "../../../Model/OrderModel";
import { OrderService } from "../../../Services/OrderService";
import { useParams } from "react-router";
import CompleteOrderDetail from "./CompleteOrderDetail";
import NewNavbar from "../../../Components/NavBar/NewNavbar";
import NewFooter from "../../PublicView/NewFooter/NewFooter";
import { showErrorToast } from "../../../utils/Toastify/ToastifyHandler";

const NewOrderDetails = () => {
    const { orderId } = useParams()
    const [completeOrderDetails, setCompleteOrderDetails] = useState<OrderDto>();
    const [childOrderState, setChidOrderState] = useState<number | null>(0)

    const orderService = new OrderService();
    const toggleTab = (index: number) => {
        setChidOrderState((prevState) => prevState === index ? null : index)
    };

    useEffect(() => {
        let fullOrderDetailsSubscriber: Subscription;
        if (orderId) {
            orderService.getOrderByOrderId(Number(orderId));

            fullOrderDetailsSubscriber = apiLoadingSubject.subscribe((response: ApiCallResponse) => {
                if (PageEventTypeEnum.ORDER_BY_ID === response.eventType) {
                    switch (response.apiCallState) {
                        case ApiCallState.LOADING:
                            break;
                        case ApiCallState.LOADING_SUCCESS:
                            if (OrderStore.getOrderById().id === Number(orderId)) {
                                if (OrderStore.getOrderById()) {
                                    setCompleteOrderDetails(OrderStore.getOrderById());
                                } else {
                                    errorDialogHandler(messageConstants.NO_ORDER_FOUND)
                                }
                            }
                            break;
                        case ApiCallState.LOADING_ERROR:
                            showErrorToast(messageConstants.REFRESH);

                            break;
                        case ApiCallState.RELOAD:
                            orderService.getOrderByOrderId(Number(orderId));

                    }
                }
            })
        }
        window.dispatchEvent(new Event('resize'));

        return (() => {
            if (fullOrderDetailsSubscriber) {
                fullOrderDetailsSubscriber.unsubscribe()
            }
        })
    }, []);

    const orderTags = ['Main Order A', 'Sub Order B', 'Sub Order C']
    return (
        <><div className="container">
            <NewNavbar
                signIn={true}
                account={false}
                cart={true}
                admin={false}
                searchBar={true}
                getFilteredProductsFromNav={() => { } } />

            {/* if child order is not present */}
            {completeOrderDetails && completeOrderDetails?.childOrder && completeOrderDetails.childOrder.length === 0 &&
                <CompleteOrderDetail orderDetail={completeOrderDetails}
                    Address={`${completeOrderDetails.shippedToName}, ${completeOrderDetails.shippedToAddress}`}
                    orderId={completeOrderDetails.orderIdentifier}
                    email={completeOrderDetails.createdByEmail} 
                    billingAmount={completeOrderDetails.billingAmount}/>}
            {/* if childOrder is Present */}
            {completeOrderDetails?.childOrder && completeOrderDetails.childOrder.length > 0 &&
                <>
                    <div className="page_path border-bottom">
                        <ul
                            className="nav nav-pills mb-2 mb-md-3"
                            id="pills-tab"
                            role="tablist"
                        >
                            {orderTags.map((item, index) => {
                                return (
                                    <li className="nav-item px-2">
                                        <span role='button'
                                            className={childOrderState === index ? 'text-success text-decoration-underline' : ""}
                                            onClick={() => toggleTab(index)}>{item}</span>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>

                    {childOrderState === 0 && completeOrderDetails?.childOrder ?
                        <>
                            {completeOrderDetails &&
                                <CompleteOrderDetail
                                    orderDetail={completeOrderDetails}
                                    Address={`${completeOrderDetails.shippedToName}, ${completeOrderDetails.shippedToAddress}`}
                                    orderId={completeOrderDetails.orderIdentifier}
                                    email={completeOrderDetails.createdByEmail} 
                                    billingAmount={completeOrderDetails.billingAmount}/>}
                        </>
                        :
                        <> {childOrderState === 1 ?


                            <>
                                <CompleteOrderDetail
                                    orderDetail={completeOrderDetails.childOrder[0]}
                                    Address={`${completeOrderDetails.shippedToName}, ${completeOrderDetails.shippedToAddress}`}
                                    orderId={completeOrderDetails.orderIdentifier}
                                    email={completeOrderDetails.createdByEmail} 
                                    billingAmount={completeOrderDetails.billingAmount}/>
                            </>
                            :
                            <></>}
                            {childOrderState === 2 ?
                                <>
                                    <CompleteOrderDetail
                                        orderDetail={completeOrderDetails.childOrder[1]}
                                        Address={`${completeOrderDetails.shippedToName}, ${completeOrderDetails.shippedToAddress}`}
                                        orderId={completeOrderDetails.orderIdentifier}
                                        email={completeOrderDetails.createdByEmail} 
                                        billingAmount={completeOrderDetails.billingAmount}/>
                                </> : <></>}
                        </>}
                </>}
        </div><NewFooter /></>
    )


}
export default NewOrderDetails