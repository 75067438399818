import { Box, Table, TableCell, TableRow } from '@mui/material'
import React from 'react'
import { PaymentDetailsDto } from '../../Model/PaymentModel'

const PaymentStatus = (props: { details: PaymentDetailsDto }) => {
    return (
        <Box>
            <Table>
                <TableRow>
                    <TableCell>Order Id : </TableCell>
                    <TableCell>{props.details.orderResponse.orderIdentifier}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Payment Id : </TableCell>
                    <TableCell>{props.details.razorpayResponse.razorpayPaymentId}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Transaction Id : </TableCell>
                    <TableCell>{props.details.razorpayResponse.txnId}</TableCell>
                </TableRow>
            </Table>
        </Box>
    )
}

export default PaymentStatus
