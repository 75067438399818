export const themeColors = {
    primary : '#C79e2f;',
    primaryHover : '#b08000;',

    secondary : '#0b5676;',
    secondaryHover : '#2080b0;',

    tertiary : '#C79E2F;',
    tertiaryHover : '#E7D9B5;'
}

const generateDeliveryStatusCSS = (backgroundColor: string, color: string,) => ({
    
    
    fontWeight: 'bold',
    // textAlign: 'center',
    // borderRadius: '5px',
    // // width: 'fit-content',
    // padding: '0.3vw 1.5vw'
});

export const cssConstants = {
    deliveryStatusColorCoding: {
        PENDING : generateDeliveryStatusCSS('rgb(45, 176, 16)','white'),
        APPROVAL_PENDING: generateDeliveryStatusCSS('orange', 'white'),
        ORDER_APPROVED: generateDeliveryStatusCSS('teal', 'white'),
        IN_TRANSIT: generateDeliveryStatusCSS('yellow', 'black'),
        PROCESSING: generateDeliveryStatusCSS('rgb(100, 90 ,212)', 'white'),
        DELIVERED: generateDeliveryStatusCSS('rgb(110, 110, 186)', 'white'),
        PAYMENT_DENIED: generateDeliveryStatusCSS('red', 'white'),
        PAYMENT_REQUESTED: generateDeliveryStatusCSS('lavender', 'black'),
        PAYMENT_APPROVED: generateDeliveryStatusCSS('teal', 'white'),
        PAYMENT_DONE: generateDeliveryStatusCSS('green', 'white'),
        COD: generateDeliveryStatusCSS('rgb(89, 134, 89)', 'white'),
        UNKNOWN: generateDeliveryStatusCSS('grey', 'white'),
        COMPLETED: generateDeliveryStatusCSS('green', 'white'),
        CANCELLED: generateDeliveryStatusCSS('red', 'white'),
        RETURNED: generateDeliveryStatusCSS('pink', 'white'),
        SPLIT : generateDeliveryStatusCSS('yellow','black'),
        READY_TO_DELIVER :generateDeliveryStatusCSS('lightblue','black'),
        PARTIAL_DELIVER :generateDeliveryStatusCSS('lightblue','black'),
        REJECTED :generateDeliveryStatusCSS('red','white'),
        ACCEPTED :generateDeliveryStatusCSS ('teal','white'),
        DEFAULT: generateDeliveryStatusCSS('', 'black'),
    },

    treeViewCss: {
        width: '19.8vw',
        height: '33vh',
        overflowY: 'auto',
        overflowX: 'hidden',
    },

    treeItemCss: {
        '& .MuiTreeItem-label': {
            padding: '8px 1',
            textAlign: 'left',
        },
    },

    dialogContent: {
        // paddingTop: '7%'
    },

    allOrdersTabsContainer: {
        marginTop: '-0.5%',
        width: '100%'
    },

    allOrdersTabs: {
        borderBottom: 1,
        borderColor: 'divider',
        width: 'max-content',
        backgroundColor: 'white'
    },

    forgetPasswordBtn: { mt: '7%',mb:'3%', fontSize: '15px' },

    catalogueCard: {
        maxHeight: "35vh",
        minHeight: "35vh",
        transition: "border-color 0.2s", 
        "&:hover": {
        borderColor: "gold", 
        },
        border: "2px solid transparent", 
    },

    menuPaperPropsStyle : {
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      },
      accountMenu_MenuItem : {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }


}