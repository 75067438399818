import { useEffect, useState } from "react";
import { NewCatalogueService } from "../../../Services/NewCatalogueService";
import { ProductModel } from "../../../Model/CatalogueModel";
import { Carousel, OverlayTrigger, Tooltip } from "react-bootstrap";
import logo from "../../../Components/Images/Head.png";
import "./AllProducts.css";
import { CheckService } from "../../../Services/checkValidationService";
import { useNavigate } from "react-router";
import { SearchParamsService } from "../../../Services/SearchParamsService";

const AllProductsCaraousel = () => {
  const [isCatalogueLoading, setIsCatalogueLoading] = useState<boolean>(true);

  const [catalogueResponse, setCatalogueResponse] = useState<ProductModel[]>(
    []
  );
  const navigate = useNavigate();
  const catalogueData = new NewCatalogueService();

  const getDefaultProducts = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("pageSize", "30");

    catalogueData.getCatalogue(searchParams, true).then((res) => {
      setCatalogueResponse(res || new Array<ProductModel>());
      setIsCatalogueLoading(false);
    });
  };
  useEffect(() => {
    getDefaultProducts();
  }, []);

  const productsToShow = JSON.parse(JSON.stringify(catalogueResponse));

  const productsGroups = [];
  if (productsToShow) {
    for (let i = 0; i < productsToShow.length; i += 6) {
      productsGroups.push(productsToShow.slice(i, i + 6));
    }
  }

  const navigateToAllProducts = () => {
    navigate(`/product`);
    SearchParamsService.setParams([
      { key: "isDefaultCatalogue", value: "true" },
      { key: "selectedScid", value: "" },
      { key: "selectedCid", value: "" },
      { key: "category", value: "" },
    ]);
    window.scrollTo(0, 0);
  };

  return (
    <div className="product_slider swiper paddingTop paddingBottom">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h4 className="fw-bold  black_clr mb-0">Newly Added Products</h4>
        {/* <button className="bordered_btn ms-3" onClick={navigateToAllProducts}>
          View More
        </button> */}
        <button className="animated-view-more ms-3" onClick={navigateToAllProducts}>
          View More
        </button>
      </div>
      <div className="swiper-wrapper">
        <Carousel
          controls={false}
          className="carousel"
          pause={"hover"}
          data-bs-theme="dark"
        >
          {productsGroups.map((group, index) => (
            <Carousel.Item key={index}>
              <div className="row mt-4">
                {
                  //@ts-ignore
                  group.map((product, idx) => (
                    <div className="col" key={idx}>
                      <a className="slideProduct_item">
                        <img
                          className="img-fluid w-100"
                          src={product.photo}
                          alt="Product-img"
                          onError={(
                            e: React.SyntheticEvent<HTMLImageElement, Event>
                          ) => {
                            e.currentTarget.src = `${logo}`;
                          }}
                        />
                        <p className="slideProduct_name mb-0">
                          <OverlayTrigger
                            placement={"top"}
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{product.productName}</Tooltip>}
                          >
                            <span className="product_name">
                              {CheckService.shortString(
                                product.productName,
                                18
                              )}
                            </span>
                          </OverlayTrigger>
                        </p>
                      </a>
                    </div>
                  ))
                }
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>

      <div className="swiper-pagination"></div>
    </div>
  );
};

export default AllProductsCaraousel;
