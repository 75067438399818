import React, { Fragment, useEffect, useState } from "react";
import NewHome from "../Home/NewHome";
import { PageEventTypeEnum } from "../../../Model/ApiCallResponse";
import { ProductModel } from "../../../Model/CatalogueModel";
import { ApiCallState } from "../../../Model/Enums/ApiCallState";
import {
  apiLoadingSubject,
  CartStore,
  CatalogueStore,
  PrincipleStore,
} from "../../../Store";
import { messageConstants } from "../../../StringConstants";
import { errorDialogHandler } from "../../../utils/ErrorDialogHandler";
import { CheckService } from "../../../Services/checkValidationService";
import placeHolder from "../../../Components/Images/Head.png";
import { PublishEventService } from "../../../Services/PublishEventService";
import { CatalogueService } from "../../../Services/CatalogueService";
import { CartItem } from "../../../Model/CartModel";
import NewBrandList from "./NewBrandList";
import NewCategory from "./NewCategory";
import { HashLink } from "react-router-hash-link";
import NewNavbar from "../../../Components/NavBar/NewNavbar";
import NewFooter from "../NewFooter/NewFooter";
import NewLoading from "../../LoadingComponent/NewLoading";
import NoDataFound from "../../../Components/NoDataFound/NoDataFound";
import { NewCatalogueService } from "../../../Services/NewCatalogueService";
import { SearchParamsService } from "../../../Services/SearchParamsService";
import { AvailableQty } from "../../../Components/Styled-Components/Styled";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { showInfoToast } from "../../../utils/Toastify/ToastifyHandler";
import {generateFCMToken, requestNotificationPermission} from "../../../Firebase/Messaging";

const NewProduct = () => {
  const [catalogueProducts, setCatalogueProducts] = useState<
    Array<ProductModel>
  >(new Array<ProductModel>());
  const [isCatalogueLoading, setIsCatalogueLoading] = useState<boolean>(true);

  const [isDataFound, setIsDataFound] = useState<boolean>(false);
  const [catalogueType, setCatalogueType] = useState<number>(
    SearchParamsService.getParamsByKey("isDefaultCatalogue") &&
      SearchParamsService.getParamsByKey("isDefaultCatalogue") === "true"
      ? 1
      : 0
  );

  const [isVisible, setIsVisible] = useState(false);
  const [cart, setCart] = useState<Map<string, CartItem>>(CartStore.getCart);
  const [index, setIndex] = useState<number>(0);
  const maximumProductAmount = 50000;

  useEffect(() => {
    const publishEventService = new PublishEventService();
    CartStore.setCart(cart);
    publishEventService.changed(PageEventTypeEnum.CART);
  }, [cart]);

  const catalogueService = new CatalogueService();

  useEffect(() => {
    const newCatalogueDataService = new NewCatalogueService();
    newCatalogueDataService.getCatalogue();
    const subscriber = apiLoadingSubject.subscribe((response) => {
      if (response.eventType === PageEventTypeEnum.CATALOGUE) {
        switch (response.apiCallState) {
          case ApiCallState.LOADING:
            setIsCatalogueLoading(true);
            break;

          case ApiCallState.LOADING_SUCCESS:
            const categories = CatalogueStore.getCatalogue();
            const productList =
              categories?.catalogueResponse || new Array<ProductModel>();
            if (productList.length > 0) {
              setCatalogueProducts(productList);
              setIsDataFound(true);
            } else {
              setCatalogueProducts(new Array<ProductModel>());
              setIsDataFound(false);
            }

            setIsCatalogueLoading(false);
            break;

          case ApiCallState.LOADING_ERROR:
            setIsCatalogueLoading(false);
            errorDialogHandler(messageConstants.CATALOGUE_LOAD_FAILURE);
            break;
          case ApiCallState.RELOAD:
            newCatalogueDataService.getCatalogue();
            break;
        }
      }
    });

    return () => {
      if (subscriber) {
        subscriber.unsubscribe();
      }
    };
  }, []);

  const addToCart = (product: ProductModel) => {
    if (
      PrincipleStore.getIsPoClient() &&
      product.productQty &&
      product.productQty.qty === 0
    ) {
      return;
    }
    const cartItems: CartItem = {
      gst: product.gst,
      discountedPrice: product.cost - product.discount,
      product: {
        pid: product.productId,
        photo: product.photo,
        pname: product.productName,
        cost: product.cost,
      },
      avlQty: PrincipleStore.getIsPoClient()
        ? product?.productQty?.qty
        : maximumProductAmount,
      applicableDiscount: product.discount,
      qty: 1,
    };
    cart.set(product.productId.toString(), cartItems);
    const cartMap: Map<string, CartItem> = new Map(cart);
    setCart(cartMap);
    CartStore.setCart(cartMap);
  };

  const handleAdd = (productId: number) => {
    let key = productId.toString();
    const existingProduct = cart.get(key);

    if (existingProduct && !PrincipleStore.getIsPoClient()) {
      if (existingProduct.qty >= maximumProductAmount) {
        showInfoToast(
          messageConstants.CANNOT_EXCEED_MAX_LIMIT(maximumProductAmount)
        );
      } else if (existingProduct.qty < maximumProductAmount) {
        existingProduct.qty += 1;
        cart.set(key, existingProduct);
        const cartMap: Map<string, CartItem> = new Map(cart);
        setCart(cartMap);
        CartStore.setCart(cartMap);
      }
    } else if (
      existingProduct &&
      existingProduct.avlQty &&
      existingProduct.avlQty - existingProduct.qty > 0
    ) {
      existingProduct.qty += 1;
      cart.set(key, existingProduct);
      const cartMap: Map<string, CartItem> = new Map(cart);
      setCart(cartMap);
      CartStore.setCart(cartMap);
    }
  };

  const handleMinus = (productId: number) => {
    let key = productId.toString();
    const existingProduct = cart.get(key);
    if (existingProduct && existingProduct.qty > 0) {
      existingProduct.qty -= 1;

      if (existingProduct.qty === 0) {
        cart.delete(key);
      } else {
        cart.set(key, existingProduct);
      }
    }
    const cartMap: Map<string, CartItem> = new Map(cart);
    setCart(cartMap);
    CartStore.setCart(cartMap);
  };

  const handleOnQuantityChange = (
    productId: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (Number(e.target.value) > maximumProductAmount) {
      showInfoToast(
        messageConstants.CANNOT_EXCEED_MAX_LIMIT(maximumProductAmount)
      );
      return;
    }
    let key = productId.toString();
    const newQty = Number(e.target.value);

    if (newQty <= 0) {
      cart.delete(key);
    } else {
      const existingProduct = cart.get(key);
      if (existingProduct && !PrincipleStore.getIsPoClient()) {
        existingProduct.qty = newQty;
        cart.set(key, existingProduct);
      } else if (
        existingProduct &&
        existingProduct.avlQty &&
        existingProduct.avlQty - newQty >= 0
      ) {
        existingProduct.qty = newQty;
        cart.set(key, existingProduct);
      } else if (
        existingProduct &&
        existingProduct.avlQty &&
        newQty > existingProduct.avlQty
      ) {
        showInfoToast(
          messageConstants.CANNOT_EXCEED_MAX_LIMIT(existingProduct?.avlQty)
        );
        return;
      }
    }
    const cartMap: Map<string, CartItem> = new Map(cart);
    setCart(cartMap);
    CartStore.setCart(cartMap);
  };

  const catalogueSelectHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (PrincipleStore.getToken() !== null) {
      switch (parseInt(e.target.value)) {
        case 1:
          SearchParamsService.setParams([
            { key: "sCid", value: "" },
            { key: "isDefaultCatalogue", value: "true" },
            { key: "selectedScid", value: "" },
            { key: "selectedCid", value: "" },
          ]);
          catalogueService.getCategoriesList();

          break;
        case 0:
          SearchParamsService.setParams([
            { key: "sCid", value: "" },
            { key: "isDefaultCatalogue", value: "false" },
            { key: "selectedScid", value: "" },
            { key: "selectedCid", value: "" },

          ]);
          const userId = PrincipleStore.getUserId();

          catalogueService.getCategoriesList(userId);

          break;
      }
    }
    setCatalogueType(parseInt(e.target.value));
  };

  const getSearchedValuefromNav = (searchedProduct: string) => {
    SearchParamsService.setParams([
      { key: "cid", value: "" },
      { key: "sCid", value: "" },
      { key: "brandIds", value: "" },
      { key: "productName", value: searchedProduct },
    ]);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition =
        window.scrollY || document.documentElement.scrollTop;
      setIsVisible(scrollPosition > 500); // Show button after 500px scroll
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove event listener on unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // useEffect(() => {
  //   generateFCMToken()
  // }, []);

  return (
    <>
      <div className="container">
        <NewNavbar
          signIn={true}
          account={false}
          cart={true}
          catalogueType={catalogueType}
          admin={false}
          searchBar={true}
          getFilteredProductsFromNav={getSearchedValuefromNav}
        />
        <div className="row">
          <div className="page_path">
            <p>
              <HashLink to="/">Home</HashLink>
              <span>
                <i className="bi bi-chevron-right"></i>
              </span>
              <HashLink to="/product">Shop</HashLink>
            </p>
          </div>

          <div className="col-xl-3">
            {/*{SearchParamsService.getParamsByKey("selectedScid") && (*/}
            {/*  <>*/}
            {/*    <h6>Filters</h6>*/}
            {/*    <p className="filters">*/}
            {/*    {SearchParamsService.getParamsByKey("selectedBrand")}*/}

            {/*      {SearchParamsService.getParamsByKey("selectedScid")}*/}
            {/*      <i*/}
            {/*        className="bi bi-x cross_btn"  onClick={() => {*/}
            {/*          SearchParamsService.deleteFilterParam("selectedScid", () => {*/}
            {/*            setIndex(prevIndex => prevIndex + 1);*/}
            {/*          });*/}
            {/*        }}*/}
            {/*      >*/}
            {/*      </i>*/}
            {/*    </p>*/}
            {/*  </>*/}
            {/*)}*/}
            {PrincipleStore.getToken() ? (
              <>
                <h6>Filter By Catalogue</h6>
                <div className="form-check">
                  <label className="form-check-label">
                    My Catalogue
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      value={0}
                      checked={catalogueType === 0}
                      onChange={catalogueSelectHandler}
                    />
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label">
                    All Products
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      value={1}
                      checked={catalogueType === 1}
                      onChange={catalogueSelectHandler}
                    />
                  </label>
                </div>
              </>
            ) : (
              <></>
            )}

            <NewCategory />

            <NewBrandList />
          </div>
          <div className="col-xl-9">
            <div className="hero_slider productPage_slider">
              <NewHome />
            </div>
            {/*{SearchParamsService.getParamsByKey("selectedScid")  || SearchParamsService.getParamsByKey("selectedCid") */}
            {/*|| SearchParamsService.getParamsByKey("category") ? (*/}
            {/*<div className="shortBy_wrapper">*/}
            {/*  <p className="fw-500 grey_clr mb-0">*/}
            {/*      Showing Results for {SearchParamsService.getParamsByKey("selectedScid")}*/}
            {/*      {SearchParamsService.getParamsByKey("selectedCid")}*/}
            {/*      {SearchParamsService.getParamsByKey("category")}*/}

            {/*  </p>*/}
            {/*</div>*/}
            {/*) : (*/}
            {/*  <></>*/}
            {/*)}*/}
            <div className="row gx-2 gy-2 gx-sm-3 gy-sm-3">
              {isCatalogueLoading ? (
                <NewLoading numberOfCards={50} />
              ) : !isDataFound ? (
                <div>
                  <NoDataFound haveSearched={false} />
                </div>
              ) : (
                catalogueProducts.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <div className="col-6 col-lg-3">
                        <div className="product_item mob">
                          {PrincipleStore.getIsPoClient() && (
                            <p className="availableQuantity">
                              <AvailableQty
                                maxQty={item?.productQty?.qty}
                                currQty={
                                  cart.get(item?.productId?.toString())?.qty ||
                                  0
                                }
                              >
                                Avl:
                                {item?.productQty?.qty
                                  ? item?.productQty?.qty -
                                    (cart.get(item?.productId?.toString())
                                      ?.qty || 0)
                                  : 0}
                              </AvailableQty>
                            </p>
                          )}
                          <HashLink to="" className="product_img">
                            <img
                              className="img-fluid w-100"
                              src={item.photo}
                              alt="Product-img"
                              onError={(
                                e: React.SyntheticEvent<HTMLImageElement, Event>
                              ) => {
                                e.currentTarget.src = `${placeHolder}`;
                              }}
                            />
                          </HashLink>
                          <div className="product_content">
                            <OverlayTrigger
                              placement={"top"}
                              delay={{ show: 250, hide: 400 }}
                              overlay={<Tooltip>{item.productName}</Tooltip>}
                            >
                              <p className="product_name">
                                {CheckService.shortString(item.productName, 18)}
                              </p>
                            </OverlayTrigger>

                            <p className="printedWeight">
                              {item.categoryName &&
                              item.categoryName.toLowerCase() === "pantry" &&
                              item.weight ? (
                                item.printedWeight
                              ) : (
                                <></>
                              )}
                            </p>
                            <p className="my-1">
                              <span className="excl-gst-price">
                                {item.gst > 0 && (
                                  <>
                                    {"₹ "}
                                    {Number(
                                      (
                                        (item.cost *
                                          (1 -
                                            (!isNaN(item.discount)
                                              ? item.discount
                                              : 0) /
                                              100)) /
                                        (1 + item.gst / 100)
                                      ).toFixed(2)
                                    ).toLocaleString("en-IN")}{" "}
                                    <span className="excl-gst-text">
                                      excl. GST
                                    </span>
                                  </>
                                )}
                              </span>
                            </p>
                            <p className="my-1 product_current_price">
                              <span className="mrp-text">
                                MRP
                                {item.discount > 0 ? (
                                  <s>
                                    {` ₹ ${Number(
                                      item.cost.toFixed(2)
                                    ).toLocaleString("en-IN")}`}{" "}
                                    &nbsp;
                                  </s>
                                ) : (
                                  <>
                                    {` ₹ ${Number(
                                      item.cost.toFixed(2)
                                    ).toLocaleString("en-IN")}`}{" "}
                                    &nbsp;
                                  </>
                                )}
                              </span>{" "}
                              <span className="mrp-text">
                                {item.discount > 0 && (
                                  <>
                                    ₹{" "}
                                    {Number(
                                      (
                                        item.cost *
                                        (1 -
                                          (!isNaN(item.discount)
                                            ? item.discount
                                            : 0) /
                                            100)
                                      ).toFixed(2)
                                    ).toLocaleString("en-IN")}
                                    &nbsp;<br></br>
                                  </>
                                )}
                              </span>
                            </p>
                          </div>
                          {cart.get(item.productId.toString()) &&
                          (cart.get(item.productId.toString())?.qty || 0) >
                            0 ? (
                            <>
                              <button className="addCart_btn bordered_btn w-100">
                                <i
                                  className="bi bi-dash-lg"
                                  onClick={() => handleMinus(item.productId)}
                                ></i>
                                <input
                                  type="number"
                                  className="add-to-cart-input"
                                  value={
                                    cart.get(item.productId.toString())?.qty
                                  }
                                  onChange={(e) => {
                                    handleOnQuantityChange(item.productId, e);
                                  }}
                                />
                                <i
                                  className="bi bi-plus-lg"
                                  onClick={() => handleAdd(item.productId)}
                                ></i>
                              </button>
                            </>
                          ) : (
                            <>
                              {/* <button
                                className="addCart_btn bordered_btn w-100"
                                onClick={() => addToCart(item)}
                                disabled={
                                  PrincipleStore.getIsPoClient() &&
                                  item?.productQty?.qty === undefined
                                }
                              >
                                Add to cart
                              </button> */}
                              <button
                                className="CartBtn w-100"
                                onClick={() => addToCart(item)}
                                disabled={
                                  PrincipleStore.getIsPoClient() &&
                                  item?.productQty?.qty === undefined
                                }
                              >
                                <span className="IconContainer">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="1em"
                                    viewBox="0 0 576 512"
                                    fill="#FFFFFF"
                                    className="cart"
                                  >
                                    <path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"></path>
                                  </svg>
                                </span>
                                {/* Add to Cart */}
                                <span className="text">Add to Cart</span>
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </Fragment>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <!--BACK TO TOP BUTTON--> */}
      <button
        className={`${isVisible ? "back-to-top-button" : "hiddenBtn"}`}
        onClick={scrollToTop}
      >
        <svg viewBox="0 0 384 512" className="svgIcon">
          <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"></path>
        </svg>
      </button>

      <NewFooter />
    </>
  );
};

export default NewProduct;
