import {useEffect, useState} from "react";
import {apiLoadingSubject} from "../../Store";
import {PageEventTypeEnum} from "../../Model/ApiCallResponse";
import {ApiCallState} from "../../Model/Enums/ApiCallState";
import {PublishEventService} from "../../Services/PublishEventService";
import { useNavigate } from "react-router";

interface URLParams {
    key: string;
    value: string;
}

export const SearchParamsWrapper = () => {
    const [params, setParams] = useState<URLSearchParams>(new URLSearchParams(window.location.search));
    const navigate = useNavigate()
    
    useEffect(() => {
        apiLoadingSubject.subscribe((response) => {
            const publishEventService = new PublishEventService()
            if (response.eventType === PageEventTypeEnum.URL && response.apiCallState === ApiCallState.CHANGED) {
                response.data.forEach((param: URLParams) => {
                    if (param?.value?.trim() === '') {
                        params.delete(param.key)
                    } else {
                        params.set(param.key, param.value)
                    }
                })
                const options = {
                    pathname: window.location.pathname,
                    search: `?${params.toString()}`,
                  };
                setParams(params)
                navigate(options, { replace: true })
                publishEventService.reloadPage(PageEventTypeEnum.CATALOGUE);
            }
        });
    }, [navigate, params])
    return (<></>)
}