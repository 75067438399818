import { B2BConstants } from "../B2BConstants";
import { ApiTypeEnum, ParameterEnum, SubPathEnum } from "../Model/Enums/ApiConstantEnum";

export class ApiUrlBuilder {
    private baseUrl: string;
    private segments: string[];
    private hasParameters: boolean;

    constructor(baseUrl: ApiTypeEnum) {
        this.baseUrl = baseUrl;
        this.segments = [baseUrl];
        this.hasParameters = false;
    }

    public addSubPath(subPath: SubPathEnum | number): ApiUrlBuilder {
        if (subPath) {
            // Add '/' only if it's not the first subpath
            if (this.segments.length > 1) {
                this.segments.push('/');
            }
            this.segments.push(subPath.toString());
        }
        return this;
    }

    public addParameter(parameterName: ParameterEnum | string, parameterValue: string | number | boolean): ApiUrlBuilder {
        if (parameterValue) {
            if (!this.hasParameters) {
                // If no parameters have been added yet, add '?'
                this.segments.push('?');
                this.hasParameters = true;
            } else {
                // If parameters have been added, use '&'
                this.segments.push('&');
            }
            this.segments.push(`${parameterName}=${parameterValue}`);
        }
        return this;
    }

    public buildURL(): string {
        return B2BConstants.BASE_URL + this.segments.join('');
    }
}