import ErrorIcon from '@mui/icons-material/Error';
import './ErrorDialog.css'
const ErrorDialog = (props: { errorMessage?: string }) => {
    return (
        <div className='error_dialog'>
            <div>
                <ErrorIcon sx={{ fontSize: '4rem' }} color='error' fontSize='large' />
            </div>
            <div>
                <h3 color='red'>{props.errorMessage ?? "Something went Wrong"} &#128532;</h3>
            </div>
        </div>
    )
}

export default ErrorDialog
