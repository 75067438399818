export enum ApiTypeEnum {
    CATALOGUE = 'catalogue/',
    USERS = 'users/',
    CATEGORIES = 'categories/',
    CLIENT_INFO = 'clientinfo/',
    USER_SUB_USER_MAPPING = 'user-subuser-mapping/',
    B2B_ORDER = 'b2b-order/',
    STATE = 'state/',
    CITY = 'city/',
    PAYMENT_GATEWAY = 'pg/',
    USER_LOCATION_MAPPING = 'user-location-mapping/',
    LOCATION = 'location/',
    PURCHASE_ORDER = 'purchase-order/',
    BANNER = 'banner/'
}

export enum SubPathEnum {
    GET = 'get',
    GET_DEFAULT = 'get-default',
    ALL = 'all',
    GET_ALL = 'get-all',
    GET_ACTIVE = 'get-active',
    GET_ALL_PRODUCTS = 'get-all-product',
    ADMIN_LOGIN = 'admin-login',
    RESET_PASSWORD = 'reset-password',
    UPDATE_PASSWORD = 'update-password',
    REGISTER = 'register',
    GET_LOGIN_CLIENT = 'get-login-client',
    SAVE = 'save',
    UPDATE = 'update',
    DELETE = 'delete',
    GET_USER_SUB_USER = 'get-user-subUser',
    GET_DEFAULT_CATALOGUE = 'get-default-catalogue',
    GET_ALL_CATALOGUE_PRODUCTS_BY_NAME = 'get-all-catalogue-products-by-name',
    GET_CATALOGUE_PRODUCTS_BY_NAME = 'get-catalogue-products-by-name',
    CONSOLIDATED = 'consolidated',
    CREATE_SUB_ORDER = 'create-sub-order',
    APPROVE_STATUS = 'approve-status',
    UPDATE_PAYMENT_STATUS = 'update-payment-status/razorpay',
    RAZORPAY = 'razorpay',
    DOWNLOAD_SAMPLE_ORDER = 'download-sample-order',
    GET_IN_TOUCH = 'get-in-touch',
    INITIATE = 'initiate',
    ORDERS = 'orders',
    GENERATE_PO = 'generate-po',
    DOWNLOAD_PURCHASE_ORDER = 'download-purchase-order',
    APPROVE_PO = 'approve-po',
    DOWNLOAD_PURCHASE_ORDER_DOC = 'download-purchase-order-doc',
    TOP_SELLING = 'top-selling'
}

export enum ParameterEnum {
    PAGE_NUMBER = "pageNumber",
    PAGE_SIZE = "pageSize",
    SORT_COLUMN = "sortColumn",
    SORT_DIRECTION = "sortDirection",
    CID = "cid",
    COMPANY_NAME = 'companyName',
    PRODUCT_NAME = 'productName',
    CLIENT_ID = 'clientId',
    SUB_CATEGORY_ID = 'cid',
    ID = 'id',
    ORDER_STATUS = 'orderStatus',
    ACTIVE = 'Active',
    IS_ACTIVE = 'isActive',
    ROLE = 'role',
    userID = 'userId',
    STATE_ID = 'stateId',
    PURCHASE_ORDER_ID = "purchaseOrderId",
    IS_DEFAULT_CATALOGUE = "isDefaultCatalogue",
    IS_DOWNLOAD = 'isDownload',
    START_DATE = 'startDate',
    END_DATE = 'endDate',
    ORDER_STATUS_LIST = 'orderStatusList',
    PAYMENT_STATUS_LIST = 'paymentStatusList',
    GROUP_BY = 'groupBy'
}