import "./NewLoading.css";

interface loadingProps {
  numberOfCards: number;
}
const NewLoading = (props: loadingProps ) => {
  return (
    <div className="cards">
      <div className="row">
      {Array(props.numberOfCards)
        .fill("")
        .map((element, index) => (
          <div className="col-6 col-lg-3" key={index}>
          <div className="card is-loading">
            <div className="image"></div>
            <div className="content">
              <h2>{""}</h2>
              <p></p>
            </div>
          </div>
          </div>
        ))}
        </div>
    </div>
  );
};

export default NewLoading;
