import React, { Fragment, useEffect, useState } from "react";
import { Subscription } from "rxjs";
import {
  ApiCallResponse,
  PageEventTypeEnum,
} from "../../../Model/ApiCallResponse";
import { ApiCallState } from "../../../Model/Enums/ApiCallState";
import { CityStore, StateStore, apiLoadingSubject } from "../../../Store";
import { messageConstants } from "../../../StringConstants";
import { errorDialogHandler } from "../../../utils/ErrorDialogHandler";
import { StateModel, CityModel } from "../../../Model/StateCityModel";
import { CityService } from "../../../Services/CityService";
import { StateService } from "../../../Services/StateService";
import {
  AddEditLocationDialogProps,
  LocationRequestDto,
  getInitialLocationModelDto,
} from "../../../Model/CartModel";

const AddNewAddressDialogBox = (props: AddEditLocationDialogProps) => {
  const [stateList, setStateList] = useState<Array<StateModel>>(
    new Array<StateModel>()
  );

  const [cityList, setCityList] = useState<Array<CityModel>>(
    new Array<CityModel>()
  );

  // const [isCityLoading, setIsCityLoading] = useState<boolean>(true);
  // const [isStateLoading, setIsStateLoading] = useState<boolean>(true);
  const [location, setLocation] = useState<LocationRequestDto>(
    props.location || getInitialLocationModelDto()
  );
  

  
  const cityService = new CityService();

  // const [stateId, setStateId] = useState<number>(1);

  // takes input from various input fields and handles them accordingly
  const onInputChangeHandler = {
    name: (event: React.ChangeEvent<HTMLInputElement>) => {
      setLocation({
        ...location,
        name: event.target.value,
      });
    },
    address: (event: React.ChangeEvent<HTMLInputElement>) => {
      setLocation({
        ...location,
        address: event.target.value,
      });
    },
    pincode: (event: React.ChangeEvent<HTMLInputElement>) => {
      setLocation({
        ...location,
        pincode: event.target.value,
      });
    },
    city: (event: any) => {
      setLocation({
        ...location,
        city: event.target.value,
      });
    },
    getCityByStateId: (event: any) => {
      const select: HTMLSelectElement = event.target;
      // setStateId(Number(select.options[select.selectedIndex].id));
      cityService.getCityList(Number(select.options[select.selectedIndex].id));
      setLocation({
        ...location,
        stateName: select.value,
      });
    },
    country: (event: any) => {
      setLocation({
        ...location,
        country: event.target.value,
      });
    },
  };

  const apiCallHandler = () => {
    if (!props.isEdit) {
      props.service(location);
    } else {
      const newLocation: LocationRequestDto = {
        ...location,
        locationId: location.locationId,
        id: location.locationId,
      };
      props.service(newLocation);
    }
  };

  useEffect(() => {
    let stateSubscriber: Subscription;
    let citySubscriber: Subscription;
    const stateService = new StateService();

    // Loading State List
    if (StateStore.getStateList().length > 0) {
      setStateList(StateStore.getStateList());
    } else {
      stateService.getStateList();
    }

    stateSubscriber = apiLoadingSubject.subscribe(
      (response: ApiCallResponse) => {
        if (PageEventTypeEnum.STATE_LIST === response.eventType) {
          switch (response.apiCallState) {
            case ApiCallState.LOADING:
              // setIsStateLoading(true);
              break;
            case ApiCallState.LOADING_SUCCESS:
              setStateList(StateStore.getStateList());
              // setIsStateLoading(false);
              break;
            case ApiCallState.LOADING_ERROR:
              errorDialogHandler(messageConstants.REFRESH);
              break;
          }
        }
      }
    );

    citySubscriber = apiLoadingSubject.subscribe(
      (response: ApiCallResponse) => {
        if (PageEventTypeEnum.CITY_LIST === response.eventType) {
          switch (response.apiCallState) {
            case ApiCallState.LOADING:
              // setIsCityLoading(true);
              break;
            case ApiCallState.LOADING_SUCCESS:
              setCityList(CityStore.getCityList());
              // setIsCityLoading(false);
              break;
            case ApiCallState.LOADING_ERROR:
              errorDialogHandler(messageConstants.REFRESH);
              break;
          }
        }
      }
    );

    return () => {
      if (citySubscriber) {
        citySubscriber.unsubscribe();
      }
      if (stateSubscriber) {
        stateSubscriber.unsubscribe();
      }
    };
  }, []);

  return (
    <>
      {/* Modal/Pop up starts */}
      {props.isEdit ? (
        <i
          data-bs-toggle="modal"
          data-bs-target={"#staticBackdrop_edit_" + location.id}
        ></i>
      ) : (
        <button
          type="button"
          className="btn theme_btn"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
        >
          Add New Address
        </button>
      )}

      <div
        className="modal fade"
        id={
          props.isEdit
            ? "staticBackdrop_edit_" + props.location?.id
            : "staticBackdrop"
        }
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Address
              </h5>
              <button
                type="button"
                className="cross_btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="bi bi-x-circle-fill"></i>
              </button>
            </div>
            <div className="modal-body">
              <form className="default_form" action="">
                <input
                  className="mb-2"
                  name=""
                  type="text"
                  placeholder="House No./Building *"
                  onChange={onInputChangeHandler.name}
                  value={location.name}
                  required
                />
                <input
                  className="mb-2"
                  name=""
                  type="text"
                  placeholder="Area/Locality *"
                  onChange={onInputChangeHandler.address}
                  value={location.address}
                  required
                />
                <div className="details_wrapper">
                  <select
                    className="mb-2"
                    onChange={onInputChangeHandler.getCityByStateId}
                    defaultValue={"State"}
                  >
                    <option disabled>State</option>
                    {stateList.map((item) => {
                      return (
                        item.active && (
                          <Fragment key={item.id}>
                            <option
                              id={item.id.toString()}
                              value={item.stateName}
                            >
                              {item.stateName}
                            </option>
                          </Fragment>
                        )
                      );
                    })}
                  </select>
                  <select
                    className="mb-2"
                    required
                    onChange={onInputChangeHandler.city}
                    defaultValue={"City"}
                  >
                    <option disabled>City</option>
                    {cityList.map((item) => {
                      return (
                        <Fragment key={item.id}>
                          <option>{item.name}</option>
                        </Fragment>
                      );
                    })}
                  </select>
                </div>
                <div className="details_wrapper">
                  <select
                    className="mb-2"
                    name=""
                    required
                    onChange={onInputChangeHandler.country}
                    defaultValue={"Country"}
                  >
                    <option disabled>Country</option>
                    <option>India</option>
                  </select>
                  <input
                    className="mb-2"
                    name=""
                    type="text"
                    placeholder="Pincode *"
                    maxLength={6}
                    onChange={onInputChangeHandler.pincode}
                    value={location.pincode}
                    required
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn" data-bs-dismiss="modal">
                Close
              </button>
              <button
                type="button"
                className="btn theme_btn"
                onClick={apiCallHandler}
                data-bs-dismiss="modal"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewAddressDialogBox;
