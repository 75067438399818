import React, { useEffect, useState } from "react";
import "./NewStationary.css";
import { useNavigate } from "react-router";
import { PageEventTypeEnum } from "../../../Model/ApiCallResponse";
import { CartStore } from "../../../Store";
import { messageConstants } from "../../../StringConstants";
import { ProductModel } from "../../../Model/CatalogueModel";
import NewLoading from "../../LoadingComponent/NewLoading";
import { Carousel, OverlayTrigger, Tooltip } from "react-bootstrap";
import { CartItem } from "../../../Model/CartModel";
import logo from "../../../Components/Images/Head.png";
import { NewCatalogueService } from "../../../Services/NewCatalogueService";
import { PublishEventService } from "../../../Services/PublishEventService";
import { CheckService } from "../../../Services/checkValidationService";
import StationaryBanner from "./StationaryBanner";
import { SearchParamsService } from "../../../Services/SearchParamsService";
import { showInfoToast } from "../../../utils/Toastify/ToastifyHandler";

const NewStationaryProducts = () => {
  const navigate = useNavigate();
  const [catalogueResponse, setCatalogueResponse] = useState<ProductModel[]>(
    []
  );
  const [isCatalogueLoading, setIsCatalogueLoading] = useState<boolean>(true);
  const [cart, setCart] = useState<Map<string, CartItem>>(CartStore.getCart);
  const maximumProductAmount = 50000;

  const [hoveredIndex, setHoveredIndex] = useState<number>(-1);

  const publishEventService = new PublishEventService();

  useEffect(() => {
    CartStore.setCart(cart);
    publishEventService.changed(PageEventTypeEnum.CART);
  }, [cart]);

  const catalogueData = new NewCatalogueService();

  const getDefaultProducts = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("cid", "3");
    searchParams.set("pageSize", "12");

    catalogueData.getCatalogue(searchParams, true).then((res) => {
      setCatalogueResponse(res || new Array<ProductModel>());
      setIsCatalogueLoading(false);
    });
  };

  useEffect(() => {
    getDefaultProducts();
  }, []);
  const productsToShow = catalogueResponse;

  const productsGroups = [];
  if (productsToShow) {
    for (let i = 0; i < productsToShow.length; i += 3) {
      productsGroups.push(productsToShow.slice(i, i + 3));
    }
  }

  const handleAdd = (productId: number) => {
    let key = productId.toString();
    const existingProduct = cart.get(key);

    if (existingProduct) {
      if (existingProduct.qty >= maximumProductAmount) {
        showInfoToast(messageConstants.CANNOT_EXCEED_MAX_LIMIT(maximumProductAmount));
      } else if (existingProduct.qty < maximumProductAmount) {
        existingProduct.qty += 1;
        cart.set(key, existingProduct);
        const cartMap: Map<string, CartItem> = new Map(cart);
        setCart(cartMap);
        CartStore.setCart(cartMap);
      }
    }
  };

  const handleMinus = (productId: number) => {
    let key = productId.toString();
    const existingProduct = cart.get(key);
    if (existingProduct && existingProduct.qty > 0) {
      existingProduct.qty -= 1;
      if (existingProduct.qty === 0) {
        cart.delete(key);
      } else {
        cart.set(key, existingProduct);
      }
    }
    const cartMap: Map<string, CartItem> = new Map(cart);
    setCart(cartMap);
    CartStore.setCart(cartMap);
  };

  const addToCart = (product: ProductModel) => {
    const cartItems: CartItem = {
      gst: product.gst,
      discountedPrice: product.cost - product.discount,
      product: {
        pid: product.productId,
        photo: product.photo,
        pname: product.productName,
        cost: product.cost,
      },
      applicableDiscount: product.discount,
      qty: 1,
    };
    cart.set(product.productId.toString(), cartItems);
    const cartMap: Map<string, CartItem> = new Map(cart);
    setCart(cartMap);
    CartStore.setCart(cartMap);
  };

  const handleOnQuantityChange = (
    productId: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (Number(e.target.value) > maximumProductAmount) {
      showInfoToast(messageConstants.CANNOT_EXCEED_MAX_LIMIT(maximumProductAmount));
      return;
    }
    let key = productId.toString();
    const newQty = Number(e.target.value);
    if (newQty <= 0) {
      cart.delete(key);
    } else {
      const existingProduct = cart.get(key);
      if (existingProduct) {
        existingProduct.qty = newQty;
        cart.set(key, existingProduct);
      }
    }

    const cartMap: Map<string, CartItem> = new Map(cart);
    setCart(cartMap);
    CartStore.setCart(cartMap);
  };

  const navigateToProduct = (id: number) => {
    navigate(`/product`);
    SearchParamsService.setParams([
      { key: "cid", value: id.toString() },
      { key: "sCid", value: "" },
      { key: "brandIds", value: "" },
      { key: "selectedScid", value: "" },
      { key: "productName", value: "" },
      { key: "selectedCid", value: "Stationery Products" },
      { key: "category", value: "" },
    ]);
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div className="mt-4 mt-md-5 paddingBottom">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h4 className="fw-bold  black_clr mb-0">Stationery Products</h4>
            <button
              className="animated-view-more ms-3"
              onClick={() => navigateToProduct(3)}
            >
              View More
            </button>
          </div>

          <div className="row gy-md-3 gy-lg-0 gy-2 align-items-center">
            <div className="col-12 col-lg-8">
              <div className="row gx-2 gy-2 gx-sm-3 gy-sm-3">
                {isCatalogueLoading ? (
                  <NewLoading numberOfCards={3} />
                ) : (
                  <Carousel
                    controls={false}
                    indicators={false}
                    className="carousel"
                    pause={"hover"}
                    style={{ direction: "ltr" }}
                  >
                    {productsGroups.map((group, index) => (
                      <Carousel.Item key={index}>
                        <div className="row">
                          {group.map((product, idx) => (
                            <div className="col-4" key={idx}>
                              <div className="product_item">
                                <a className="product_img">
                                  <img
                                    className="img-fluid w-100"
                                    src={product.photo}
                                    alt="Product-img"
                                    onError={(
                                      e: React.SyntheticEvent<
                                        HTMLImageElement,
                                        Event
                                      >
                                    ) => {
                                      e.currentTarget.src = `${logo}`;
                                    }}
                                  />
                                </a>
                                <div className="product_content">
                                  <p
                                    className="product_name"
                                    onMouseEnter={() => setHoveredIndex(idx)}
                                    onMouseLeave={() => setHoveredIndex(-1)}
                                  >
                                    <OverlayTrigger
                                      placement={"top"}
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip>{product.productName}</Tooltip>
                                      }
                                    >
                                      <span className="product_name">
                                        {CheckService.shortString(
                                          product.productName,
                                          18
                                        )}
                                      </span>
                                    </OverlayTrigger>
                                  </p>
                                  <span className="printedWeight">
                                    {product.categoryName &&
                                    product.categoryName.toLowerCase() ===
                                      "pantry" &&
                                    product.weight ? (
                                      product.printedWeight
                                    ) : (
                                      <></>
                                    )}
                                  </span>

                                  <p className="my-1">
                                    <span className="excl-gst-price">
                                      {product.gst > 0 && (
                                        <>
                                          {"₹ "}
                                          {Number(
                                            (
                                              product.cost *
                                                (1 -
                                                  (!isNaN(product.discount)
                                                    ? product.discount
                                                    : 0) /
                                                    100) -
                                              product.cost *
                                                (1 - product.discount / 100) *
                                                (product.gst / 100)
                                            ).toFixed(2)
                                          ).toLocaleString("en-IN")}
                                          &nbsp;
                                          <span className="excl-gst-text">
                                            excl. GST
                                          </span>
                                        </>
                                      )}
                                    </span>
                                  </p>
                                  <p className="my-1 product_current_price">
                                    <span className="mrp-text">
                                      MRP
                                      {product.discount > 0 ? (
                                        <s>
                                          {` ₹ ${Number(
                                            product.cost.toFixed(2)
                                          ).toLocaleString("en-IN")}`}{" "}
                                          &nbsp;
                                        </s>
                                      ) : (
                                        <>
                                          {` ₹ ${Number(
                                            product.cost.toFixed(2)
                                          ).toLocaleString("en-IN")}`}{" "}
                                          &nbsp;
                                        </>
                                      )}
                                      {product.discount > 0 && (
                                        <>
                                          ₹{" "}
                                          {Number(
                                            (
                                              product.cost *
                                              (1 -
                                                (!isNaN(product.discount)
                                                  ? product.discount
                                                  : 0) /
                                                  100)
                                            ).toFixed(2)
                                          ).toLocaleString("en-IN")}
                                          &nbsp;<br></br>
                                        </>
                                      )}
                                    </span>
                                  </p>
                                </div>
                                {cart.get(product.productId.toString()) &&
                                (cart.get(product.productId.toString())?.qty ||
                                  0) > 0 ? (
                                  <>
                                    <button className="addCart_btn bordered_btn w-100">
                                      <i
                                        className="bi bi-dash-lg"
                                        onClick={() =>
                                          handleMinus(product.productId)
                                        }
                                      ></i>
                                      <input
                                        type="number"
                                        className="add-to-cart-input"
                                        min={0}
                                        max={10000}
                                        value={
                                          cart.get(product.productId.toString())
                                            ?.qty
                                        }
                                        onChange={(e) => {
                                          handleOnQuantityChange(
                                            product.productId,
                                            e
                                          );
                                          // setQuantity(parseInt(e.target.value));
                                        }}
                                      />
                                      <i
                                        className="bi bi-plus-lg"
                                        onClick={() =>
                                          handleAdd(product.productId)
                                        }
                                      ></i>
                                    </button>
                                  </>
                                ) : (
                                  // <button
                                  //   className="addCart_btn bordered_btn w-100"
                                  //   onClick={() => addToCart(product)}
                                  // >
                                  //   Add to cart
                                  // </button>
                                  <button
                                    className="CartBtn w-100"
                                    onClick={() => addToCart(product)}
                                  >
                                    <span className="IconContainer">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="1em"
                                        viewBox="0 0 576 512"
                                        fill="#FFFFFF"
                                        className="cart"
                                      >
                                        <path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"></path>
                                      </svg>
                                    </span>
                                    {/* Add to Cart */}
                                    <span className="text">Add to Cart</span>
                                  </button>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                )}
              </div>
            </div>
            <div className="col-12 col-lg-4 d-none d-lg-block">
              <StationaryBanner />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewStationaryProducts;
