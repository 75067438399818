import { createMessage } from "../CreateMessage";
import { PageEventTypeEnum } from "../Model/ApiCallResponse";
import { LocationRequestDto } from "../Model/CartModel";
import { ActionTypeEnum } from "../Model/Enums/ActionTypeEnum";
import { ApiResponseStatusEnum } from "../Model/Enums/ApiCallState";
import { RoleTypeEnum } from "../Model/Enums/RoleTypeEnum";
import { ApiResponseModel } from "../Model/NutritapResponseModel";
import { PrincipleStore } from "../Store";
import { messageConstants } from "../StringConstants";
import { errorDialogHandler } from "../utils/ErrorDialogHandler";
import { ApiTypeEnum, SubPathEnum } from "../Model/Enums/ApiConstantEnum";
import { ApiUrlBuilder } from "../utils/Stringbuilder";
import { apiCaller } from "./ApiCallService";
import { PublishEventService } from "./PublishEventService";
import { UserLocationMappingService } from "./UserLocationMappingService";
import { CheckService } from "./checkValidationService";
import { showErrorToast, showSuccessToast } from "../utils/Toastify/ToastifyHandler";

export class LocationService {
  private userId: number | undefined = PrincipleStore.getUserId();
  publishEvent = new PublishEventService();

  addSingleLocation(newLocationData: LocationRequestDto): void {
    // const url = `${B2BConstants.BASE_URL}${ApiConstants.LOCATION.ADD}`;
    const locationUrl = new ApiUrlBuilder(ApiTypeEnum.LOCATION);
    locationUrl.addSubPath(SubPathEnum.SAVE);
    const url = locationUrl.buildURL();
    const body = JSON.stringify(newLocationData);
    apiCaller
      .postResponseToServer(url, body)
      .then((res: ApiResponseModel) => {
        if (!res) {
          showErrorToast(messageConstants.FAILED);
        }
        if (res.status === ApiResponseStatusEnum.SUCCESS || res.success) {
          showSuccessToast(createMessage.successMessage(ActionTypeEnum.ADD));
          const savedLocation: LocationRequestDto = res.dto;
          let location: LocationRequestDto = {
            active: savedLocation.active,
            id: 0,
            locationId: savedLocation.id,
            role: CheckService.isAdmin()
              ? PrincipleStore.getRole() === RoleTypeEnum.ADMIN
                ? RoleTypeEnum.ADMIN
                : RoleTypeEnum.CLIENT
              : RoleTypeEnum.CLIENT,
            userId: this.userId,
            address: savedLocation.address,
            state: savedLocation.stateName,
            city: savedLocation.city,
            pincode: savedLocation.pincode,
            name: savedLocation.name,
            isActive: savedLocation.isActive,
            stateName: savedLocation.state,
            country: savedLocation.country,
            region: savedLocation.region,
            userName: savedLocation.userName,
            userMobile: savedLocation.userMobile,
          };
          if (location) {
            const userLocationMap = new UserLocationMappingService();
            userLocationMap.addNewLocationMapping(location);
          }
        } else {
          showErrorToast(createMessage.errorMessage(ActionTypeEnum.ADD));
        }
      })
      .catch((err) => {
        errorDialogHandler(messageConstants.FAILED);
      });
  }

  editLocation(newLocationData: LocationRequestDto): void {
    // const url = `${B2BConstants.BASE_URL}${ApiConstants.LOCATION.EDIT}`;
    const locationUrl = new ApiUrlBuilder(ApiTypeEnum.LOCATION);
    locationUrl.addSubPath(SubPathEnum.UPDATE);
    const url = locationUrl.buildURL();
    const body = JSON.stringify(newLocationData);
    apiCaller
      .postResponseToServer(url, body)
      .then((res: ApiResponseModel) => {
        if (!res) {
          showErrorToast(messageConstants.FAILED);
        }
        if (res.status === ApiResponseStatusEnum.SUCCESS || res.success) {
          showSuccessToast(createMessage.successMessage(ActionTypeEnum.EDIT));
          let publishEvent = new PublishEventService();
          publishEvent.reloadPage(PageEventTypeEnum.LOCATIONS);
        } else {
          showErrorToast(createMessage.errorMessage(ActionTypeEnum.EDIT));
        }
      })
      .catch((e) => {
        errorDialogHandler(messageConstants.FAILED);
      });
  }
}
