import { ApiCallState } from "./Enums/ApiCallState";

export interface ApiCallResponse {
    apiCallState: ApiCallState;
    eventType: PageEventTypeEnum;
    data?: any;
}




export enum PageEventTypeEnum {
    NONE,
    CART,
    CATALOGUE,
    NEW_CATALOGUE,
    CATEGORIES,
    DEFAULT_CATEGORIES,
    SUBCATEGORY_ID,
    ALL_ORDER,
    APPROVED_ORDER,
    PROCESSING_ORDER,
    SPLITTED_ORDER,
    PENDING_ORDER,
    COMPLETED_ORDER,
    IN_TRANSIT_ORDER,
    ROLE,
    SUB_CATEGORIES_PRODUCTS,
    USER_REGISTER,
    PROFILE,
    ADDRESS,
    STATE_LIST,
    CITY_LIST,
    CATALOGUE_ID,
    ORDER_BY_ID,
    SELECTED_ORDER_ID,
    USER_SUB_USER_MAPPING,
    CLIENT_LIST,
    PAYMENT,
    RAZORPAY_PAYMENT,
    ORDER,
    PRODUCTS,
    PRINCIPLE,
    ORDER_UPDATE,
    SPLIT_ACTION_ON_ORDER,
    UPDATE_BTN,
    UPDATE_DIALOG,
    ORDER_REJECTED,
    ORDER_CREATED,
    LOCATIONS,
    SUBMIT_BTN,
    PURCHASE_ORDER,
    PENDING_PO,
    SUB_USER_MAPPING_ADD,
    LOGIN,
    BANNER,
    URL,
    CATALOGUE_DOWNLOAD_FAILED,
    CONSOLIDATED_ORDER_DATA,
    CONSOLIDATED_DATA,
    TOP_SELLING_DATA,
    TOP_SELLING_CATEGORY,
    PAYMENT_PENDING
}