export enum OrderStatusEnum {
    APPROVAL_PENDING = "APPROVAL_PENDING",
    ORDER_APPROVED = "ORDER_APPROVED",
    APPROVED = "APPROVED",
    ORDER_APPROVED_BY_CHECKER = "APPROVED",
    IN_TRANSIT = "IN_TRANSIT",
    DELIVERED = "DELIVERED",
    UNKNOWN = "UNKNOWN",
    COMPLETED = "COMPLETED",
    CANCELLED = "CANCELLED",
    RETURNED = "RETURNED",
    PENDING = 'PENDING',
    PROCESSING = 'PROCESSING',
    SPLIT = 'SPLIT',
    READY_TO_DELIVER = 'READY_TO_DELIVER',
    PAYMENT_DENIED = "PAYMENT_DENIED",
    PAYMENT_REQUESTED = "PAYMENT_REQUESTED",
    PAYMENT_APPROVED = "PAYMENT_APPROVED",
    PAYMENT_DONE = "PAYMENT_DONE",
    COD = "PAYMENT_DONE",
    PARTIAL_DELIVER = 'PARTIAL_DELIVER',
    REJECTED = "REJECTED",
    BULK_ORDER = "BULK_ORDER",
    ACCEPTED = "ACCEPTED"
}