import Carousel from "react-material-ui-carousel";
import { PositionEnum } from "../../../Model/Enums/PositionEnum";
import { BannerStore } from "../../../Store";

const PantryBanner = () => {
  return (
    <div className="row">
      <div className="col-12">
        <Carousel
          autoPlay={true}
          swipe={true}
        //   navButtonsAlwaysVisible={false}
          animation={"fade"}
          interval={3000}
          duration={1000}
          indicators={false}
        >
          {BannerStore.getBannerPositionMap(PositionEnum.SUB_CAT_2).map(
            (item, ind) => (
              <div className="pp_product" key={ind}>
                <a>
                  <img
                    className="w-100 img-fluid"
                    src={item.bannerUrl}
                    alt="Product-img"
                  />
                </a>
              </div>
            )
          )}
        </Carousel>
      </div>
    </div>
  );
};

export default PantryBanner;
