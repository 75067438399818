// const generalRegex = /[`! @#$%^&*()_+\-=\[\]{};':"\\|,.<>?]/;
/* eslint-disable no-useless-escape */

const pincodeRegex = /^[0-9]{6}$/;

const phoneNoRegex = /^[0-9]{10}$/;

const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

const specialCharacterRegex = /[`!@#$%^&*()_+=\[\]{};':"\\|.<>?]/

const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/

const validateQuantityRegex = /^\d+$/;

export const ValidationService = {
    validateSpecialCharacter: (inputValue: string) => {
        if (inputValue.length > 50) {
            return false;
        }
        const ans = specialCharacterRegex.test(inputValue);
        return ans;
    },
    validatePincode: (inputValue: string) => {
        if (inputValue.length !== 6) return false;
        return pincodeRegex.test(inputValue); // return true if pincode is six digit;
    },
    validatePhoneNo: (inputValue: string) => {
        return phoneNoRegex.test(inputValue);
    },
    validateGST: (inputValue: string) => {
        return gstRegex.test(inputValue)
    },
    validateEmail: (inputValue: string) => {
        return emailRegex.test(inputValue);
    },
    validateFieldEmpty: (inputValue: string) => {
        return inputValue.trim().length > 0;
    },
    validateQuantity: (inputQuantity: string) => {
        return validateQuantityRegex.test(inputQuantity)
    }
}