import { useEffect, useState } from "react";
import "./NewNavbar.css";
import logo from "../../Components/Images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { CartStore, ProfileStore, apiLoadingSubject } from "../../Store";
import { PageEventTypeEnum } from "../../Model/ApiCallResponse";
import { ApiCallState } from "../../Model/Enums/ApiCallState";
import { PublishEventService } from "../../Services/PublishEventService";
import NavbarHideProps from "../../Model/Props/NavbarProps";
import { CheckService } from "../../Services/checkValidationService";
import { SearchParamsService } from "../../Services/SearchParamsService";
import { Avatar } from "@mui/material";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const NewNavbar = (props: NavbarHideProps) => {
  const [cartSize, setCartSize] = useState<number>(CartStore.getNoOfItems());
  const [paymentTotal, setPaymentTotal] = useState<number>(
    CartStore.getTotalCost()
  );
  const [searchValue, setSearchValue] = useState<string>(
    SearchParamsService.getParamsByKey("productName")
  );
  const navigate = useNavigate();

  useEffect(() => {
    const publishEventService = new PublishEventService();
    apiLoadingSubject.subscribe((event) => {
      if (
        event.eventType === PageEventTypeEnum.CART &&
        event.apiCallState === ApiCallState.CHANGED
      ) {
        setCartSize(CartStore.getNoOfItems());
        setPaymentTotal(CartStore.getTotalCost());
        publishEventService.dataLoaded(PageEventTypeEnum.CART);
      }
    });
  }, []);

  useEffect(() => {}, [cartSize, paymentTotal]);

  const searchHandler = (searchValue: string) => {
    if (searchValue.trim().length > 2) {
      props.getFilteredProductsFromNav(searchValue.trim());
    } else if (searchValue.trim() === "") {
      props.getFilteredProductsFromNav("");
    }
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const navigateToCartHandler = () => {
    navigate(`/newCart`);
    scrollToTop();
  };

  const navigateToLogin = () => {
    if (CheckService.isLoggedIn()) {
      navigate(`/newMyAccount`);
    } else {
      navigate(`/login`);
    }
  };

  const navigateToHomePage = () => {
    navigate("/");
    SearchParamsService.deleteParam("cid");
    window.scrollTo(0,0)
  };

  const adminName = CheckService.isLoggedIn() ? ProfileStore.getClientSpocName() : "";

  const stringToColor = (string: string) => {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */   
    for (i = 0; i < string?.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 40,
        height: 40,
      },
      children: `${name?.split(' ')[0][0]}${name?.split(' ')[1] ? name?.split(' ')[1][0] : ''}`,
    };
  }

  return (
    <><div className="container navbar-pos">
      <div className="navigation_bar py-3">
        <div className="logo_wrapper">
          <button className="d-inline-block_btn" onClick={navigateToHomePage}>
            <img className="w-100" src={logo} alt="LOGO" />
          </button>
        </div>

        <form
          className="search_form d-none d-xl-block"
          onSubmit={(e) => {
            e.preventDefault(); // Prevent form submission
            searchHandler(searchValue);
          } }
        >
          <input
            type="text"
            placeholder="Search here..."
            minLength={3}
            required
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            } } />
          <button>
            {searchValue.trim().length > 0 ? (
              <i
                className="bi bi-x"
                onClick={(e) => {
                  e.preventDefault();
                  setSearchValue("");
                  searchHandler("");
                } }
              ></i>
            ) : (
              <i className="bi bi-search"></i>
            )}
          </button>
        </form>
        <span className="v_line  d-none d-xl-block"></span>
        {CheckService.isLoggedIn() ? (
          <button
            className="navMainpage_link  d-xl-block"
            onClick={navigateToLogin}
          >
            {/* <i className="bi bi-person"></i> */}
            <span className="w-100 d-block">
              <OverlayTrigger
                placement={"bottom"}
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>{adminName}</Tooltip>}
              >
                <Avatar {...stringAvatar(adminName)} />
              </OverlayTrigger>
            </span>
          </button>
        ) : (
          <button
            className="navMainpage_link d-xl-block"
            onClick={navigateToLogin}
          >
            <OverlayTrigger
              placement={"bottom"}
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip>Log In</Tooltip>}
            >
              <i className="bi bi-box-arrow-in-right"></i>
            </OverlayTrigger>
            {/* <span className="w-100 d-block">Log In</span> */}
          </button>
        )}

        {/* <span className="v_line  d-none d-xl-block"></span>
    <Link className="navMainpage_link d-none d-xl-block" to="/newMyAccount">
      <i className="bi bi-truck"></i>
      <span className="w-100 d-block">Order Tracking</span>
    </Link> */}
        <span className="v_line   d-xl-block"></span>
        <Link
          className="navMainpage_link d-none d-xl-block"
          to=""
          target="_blank"
          onClick={() => (window.location.href = "mailto:dheeraj.gupta@nutritap.in")}
        >
          <OverlayTrigger
            placement={"bottom"}
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip>Contact Us</Tooltip>}
          >
            <i className="bi bi-envelope" style={{ width: 36, height: 36 }}></i>
          </OverlayTrigger>
          {/* <span className="w-100 d-block">Contact</span> */}
        </Link>
        <span className="v_line  d-none d-xl-block"></span>

        <button className="cart_btn cart_item_count" onClick={navigateToCartHandler}>
          <div className="cart_icon me-xl-2 cart_item">
            <i className="bi bi-cart3 cart_icon"></i>
            <span> {cartSize}</span>
            
          </div>
          <div className="d-none d-xl-block">
            <p className="mb-0 w-100">{cartSize} {cartSize === 1 ? <>Item</> : <>Items</>}</p>
            <p className="mb-0 w-100">
              ₹ {Number(paymentTotal.toFixed(2)).toLocaleString("en-IN")}
            </p>
          </div>
        </button>
      </div>
    </div></>
  );
};

export default NewNavbar;
