import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { ApprovalPendingOrderStore, CartStore, PrincipleStore, ProfileStore } from '../../../Store'
import { PageTypeEnum } from '../../../Model/Enums/PageTypeEnums'

const Logout = () => {

    const navigate = useNavigate()
    const NavigateToHome = ()=>{
        navigate(`${PageTypeEnum.HOME}`)
    }

    useEffect(() => {
        PrincipleStore.deleteUser();
        CartStore.deleteCart();
        ProfileStore.deleteProfile();
        ApprovalPendingOrderStore.deleteOrderDetails();
        NavigateToHome();
        window.location.reload()
    })
    
    return (
         <div></div>
    )
  
}

export default Logout
