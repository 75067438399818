import { BehaviorSubject } from "rxjs";
import { B2BConstants } from "./B2BConstants";

import {
  ApiResponseModel,
  PrincipleResponse,
} from "./Model/NutritapResponseModel";
import {
  CatalogueModel,
  CategoriesListModel,
  ProductModel,
  SubCategoriesListModel,
} from "./Model/CatalogueModel";
import {
  CartItem,
  LocationRequestDto,
  getInitialCheckoutProducts,
} from "./Model/CartModel";
import BaseOrder, {
  CreateOrderDto,
  OrderDisplayDto,
  OrderDto,
} from "./Model/OrderModel";
import { UserDto } from "./Model/UserModel";
import ClientAddressModel from "./Model/ClientAdddressModel";
import { CityModel, StateModel } from "./Model/StateCityModel";
import { ApiCallResponse } from "./Model/ApiCallResponse";
import { ApiCallState } from "./Model/Enums/ApiCallState";
import { PageEventTypeEnum } from "./Model/ApiCallResponse";
import { RoleTypeEnum } from "./Model/Enums/RoleTypeEnum";
import { PurchaseOrderDto } from "./Model/PurchaseOrderModel";
import { BannerModel } from "./Model/HomePageDto/BannerModel";
import {PositionEnum} from "./Model/Enums/PositionEnum";
import { BrandListModel } from "./Model/CategoryModel";

export const apiLoadingSubject = new BehaviorSubject<ApiCallResponse>({
  apiCallState: ApiCallState.NONE,
  eventType: PageEventTypeEnum.NONE,
  data: undefined,
});

let categories: Array<CategoriesListModel> = Array<CategoriesListModel>();
let subCategoryId: number;
let subCategoriesName: SubCategoriesListModel | null;
let defaultSubCategory: number;
export const CategoryStore = {
  setCategoryList: (category: Array<CategoriesListModel>) => {
    categories = category;
  },
  getCategoryList: () => {
    return categories;
  },
  setSubCategoryId: (id: number) => {
    subCategoryId = id;
  },
  getSubCategoryId: () => {
    return subCategoryId;
  },
  setSubCategoryName: (subcategory: SubCategoriesListModel | null) => {
    subCategoriesName = subcategory;
  },
  getSubCategoryName: () => {
    return subCategoriesName;
  },
  setDefaultSubCategory: (id: number) => {
    defaultSubCategory = id;
  },
  getDefaultSubCategory: () => {
    return defaultSubCategory;
  },
};

export const PrincipleStore = {
  setPrinciple: (user: PrincipleResponse) => {
    localStorage.setItem(B2BConstants.USERNAME, user.name);
    localStorage.setItem(B2BConstants.ROLE, user.role);
    localStorage.setItem(B2BConstants.USER_TOKEN, user.token.toString());
    localStorage.setItem(B2BConstants.EMAIL, user.email);
    localStorage.setItem(B2BConstants.PAGES, user.pages.toString());
    localStorage.setItem(B2BConstants.PERMISSION, user.permissions.toString());
    localStorage.setItem(B2BConstants.CITY, user.city);
    localStorage.setItem(B2BConstants.USER_TOKEN, user.token);
    localStorage.setItem(B2BConstants.ORGANIZATION, user.organization);
    localStorage.setItem(B2BConstants.MOBILE_NUMBER, user.mobile);
    localStorage.setItem(B2BConstants.USER_ID, user.userId.toString());
    localStorage.setItem(B2BConstants.PO_CLIENT, user.poClient.toString());
  },
  getUserName: () => {
    return localStorage.getItem(B2BConstants.USERNAME) || "";
  },
  getUserId: () => {
    const userId = localStorage.getItem(B2BConstants.USER_ID) || "";
    if (userId && userId.trim() !== "" && parseInt(userId)) {
      return parseInt(userId);
    }
  },
  getEmail: () => {
    return localStorage.getItem(B2BConstants.EMAIL) || "";
  },
  getToken: () => {
    return localStorage.getItem(B2BConstants.USER_TOKEN);
  },
  getRole: () => {
    return localStorage.getItem(B2BConstants.ROLE) || "";
  },
  getIsPoClient: () => {
    return localStorage.getItem(B2BConstants.PO_CLIENT) === "true";
  },
  getPages: () => {
    return (localStorage.getItem(B2BConstants.PAGES) || "").split(",");
  },
  getPermissions: () => {
    return (localStorage.getItem(B2BConstants.PERMISSION) || "").split(",");
  },
  getCity: () => {
    return localStorage.getItem(B2BConstants.CITY) || "";
  },
  getOrganizationName: () => {
    return localStorage.getItem(B2BConstants.ORGANIZATION) || "";
  },
  getMobileNumber: () => {
    return localStorage.getItem(B2BConstants.MOBILE_NUMBER) || "";
  },
  getCatalogueId: () => {
    return localStorage.getItem(B2BConstants.CATALOGUE_ID) || "";
  },
  deleteUser: () => {
    localStorage.removeItem(B2BConstants.USERNAME);
    localStorage.removeItem(B2BConstants.USER_ID);
    localStorage.removeItem(B2BConstants.ROLE);
    localStorage.removeItem(B2BConstants.PO_CLIENT);
    localStorage.removeItem(B2BConstants.USER_TOKEN);
    localStorage.removeItem(B2BConstants.EMAIL);
    localStorage.removeItem(B2BConstants.PAGES);
    localStorage.removeItem(B2BConstants.PERMISSION);
    localStorage.removeItem(B2BConstants.CITY);
    localStorage.removeItem(B2BConstants.USER_TOKEN);
    localStorage.removeItem(B2BConstants.ORGANIZATION);
    localStorage.removeItem(B2BConstants.MOBILE_NUMBER);
    localStorage.removeItem(B2BConstants.CATALOGUE_ID);
  },
};

let products: Array<ProductModel> = Array<ProductModel>();
export const ProductsStore = {
  setProducts: (category: Array<ProductModel>) => {
    products = category;
  },
  getProducts: () => {
    return products;
  },
};

let searchedValueFromNav: string;
export const NavbarStore = {
  setSearchValue: (value: string) => {
    searchedValueFromNav = value;
  },
  getSearchedValue: () => {
    return searchedValueFromNav;
  },
};

export const TokenActions = {
  setToken: (token: string) => {
    localStorage.setItem(B2BConstants.USER_TOKEN, token);
  },
  getToken: () => {
    return localStorage.getItem(B2BConstants.USER_TOKEN);
  },
  getCompleteToken: () => {
    return `Bearer ${localStorage.getItem(B2BConstants.USER_TOKEN)}`;
  },
  deleteToken: () => {
    return localStorage.removeItem(B2BConstants.USER_TOKEN);
  },
};

let signupResponse: ApiResponseModel;
export const UserStore = {
  setResponse: (res: ApiResponseModel) => {
    signupResponse = res;
  },
  getResponse: () => {
    return signupResponse;
  },
};

let catalogues: CatalogueModel | null;
let discountMapOfSearchedProducts: any;
let selectedCategoryName: string;
let searchedProducts: ProductModel[];

export const CatalogueStore = {
  setCatalogue: (catalogue: CatalogueModel | null) => {
    catalogues = catalogue;
  },
  getCatalogue: () => {
    return catalogues;
  },
  setDiscountMap: (discountMap: Map<number, number>) => {
    discountMapOfSearchedProducts = discountMap;
  },
  getDiscountMap: () => {
    return discountMapOfSearchedProducts;
  },
  deleteDiscountMap: () => {
    discountMapOfSearchedProducts = new Map<number, number>();
  },
  setSelectedCategoryName: (category: string) => {
    selectedCategoryName = category;
  },
  getSelectedCategoryName: () => {
    return selectedCategoryName;
  },
  setSearchedProducts: (products: Array<ProductModel>) => {
    searchedProducts = products;
  },
  getSearchedProducts: () => {
    return searchedProducts;
  },
};

let cartMap: Map<string, CartItem> = new Map<string, CartItem>();
let orderedDto: CreateOrderDto;

export const CartStore = {
  setCart: (cart: Map<string, CartItem>) => {
    localStorage.setItem(
      B2BConstants.CART,
      JSON.stringify(Array.from(cart.entries()))
    );
    cartMap = cart;
  },
  getCart: () => {
    const stringCart: string | null = localStorage.getItem(B2BConstants.CART);
    if (stringCart) {
      const cart = JSON.parse(stringCart);
      cartMap = new Map<string, CartItem>(cart);
    }
    return cartMap;
  },
  setCreatedOrderDto: (order: CreateOrderDto) => {
    orderedDto = order;
  },
  getCreatedOrderDto: () => {
    return orderedDto;
  },

  deleteCart: () => {
    localStorage.removeItem(B2BConstants.CART);
    cartMap = new Map<string, CartItem>();
  },
  getNoOfItems: () => {
    return CartStore.getCart().size;
  },
  getTotalCost: () => {
    let total = 0;
    CartStore.getCart().forEach((item) => {
      total +=
        item.product.cost *
        (1 - item.applicableDiscount / 100) *
        (!isNaN(item.qty) && item.qty ? item.qty : 1);
    });
    return total;
  },
};

let razorpayOrderId: string = "";
export const RazorpayOrderIdStore = {
  setRazorpayOrderId: (orderId: string) => {
    razorpayOrderId = orderId;
    localStorage.setItem(B2BConstants.RAZORPAY_ORDER_ID, razorpayOrderId);
  },
  getRazorpayOrderId: () => {
    const orderId = localStorage.getItem(B2BConstants.RAZORPAY_ORDER_ID);
    if (orderId) {
      razorpayOrderId = orderId;
    }
    return razorpayOrderId;
  },
  deleteRazorPayOrderId: () => {
    localStorage.removeItem(B2BConstants.RAZORPAY_ORDER_ID);
    razorpayOrderId = "";
  },
};

let placedOrder: OrderDto;
let orderDetail: OrderDto;
let selectedOrderId: number;

export const OrderStore = {
  setPlacedOrder: (order: OrderDto) => {
    placedOrder = order;
  },
  getPlacedOrder: () => {
    return placedOrder;
  },
  setOrderById: (order: OrderDto) => {
    orderDetail = order;
  },
  getOrderById: () => {
    return orderDetail;
  },
  getSelectedOrderId: () => {
    return selectedOrderId;
  },
  setSelectedOrderId: (orderId: number) => {
    selectedOrderId = orderId;
  },
};

let clientAllOrders: Array<OrderDisplayDto> = new Array<OrderDisplayDto>();
export const ClientAllOrderStore = {
  setOrder: (order: Array<OrderDisplayDto>) => {
    clientAllOrders = order;
  },
  getOrder: () => {
    return clientAllOrders;
  },
};
let approvedOrder: Array<OrderDto> = new Array<OrderDto>();
export const ApprovedOrderStore = {
  setOrder: (order: Array<OrderDto>) => {
    approvedOrder = order;
  },
  getOrder: () => {
    return approvedOrder;
  },
};
let splittedOrder: Array<OrderDto> = new Array<OrderDto>();
export const SplittedOrderStore = {
  setOrder: (order: Array<OrderDto>) => {
    splittedOrder = order;
  },
  getOrder: () => {
    return splittedOrder;
  },
};
let inProccessingOrder: Array<OrderDto> = new Array<OrderDto>();
export const InProcessingOrderStore = {
  setOrder: (order: Array<OrderDto>) => {
    inProccessingOrder = order;
  },
  getOrder: () => {
    return inProccessingOrder;
  },
};
let pendingOrder: Array<OrderDisplayDto> = new Array<OrderDisplayDto>();
export const ApprovalPendingOrderStore = {
  setOrder: (order: Array<OrderDisplayDto>) => {
    pendingOrder = order;
  },
  getOrder: () => {
    return pendingOrder;
  },
  deleteOrderDetails: () => {
    ApprovalPendingOrderStore.setOrder(new Array<OrderDisplayDto>());
  },
};
let completedOrder: Array<OrderDisplayDto> = new Array<OrderDisplayDto>();
export const CompletedOrderStore = {
  setOrder: (order: Array<OrderDisplayDto>) => {
    completedOrder = order;
  },
  getOrder: () => {
    return completedOrder;
  },
};
let inTransitOrders: Array<OrderDto> = new Array<OrderDto>();
export const InTransitOrderStore = {
  setOrder: (order: Array<OrderDto>) => {
    inTransitOrders = order;
  },
  getOrder: () => {
    return inTransitOrders;
  },
};

let profileDetails: UserDto;
export const ProfileStore = {
  setDetails: (details: UserDto) => {
    profileDetails = details;
    localStorage.setItem("UserDetail", JSON.stringify(profileDetails));
  },
  getDetails: () => {

    try {
      profileDetails = JSON.parse(localStorage.getItem("UserDetail") || "");
    } catch (err) {
      console.error(err);
    }
    return profileDetails;

  },
  deleteProfile: () => {
    ProfileStore.setDetails({
      active: false,
      businessAddress: "",
      clientSpocCompany: "",
      createdBy: 0,
      createdByName: "",
      createdOn: 0,
      email: "",
      gst: "",
      id: 0,
      latitude: 0,
      location: 0,
      longitude: 0,
      mobile: "",
      modifiedBy: "",
      modifiedByName: "",
      modifiedOn: 0,
      name: "",
      ntSpoc: 0,
      password: "",
      roiModel: "",
      role: "",
      city: "",
      state: "",
      address: "",
      shippingAddress: "",
      pincode: 0,
      locationName: "",
    });
  },

  getClientSpocName: () => {
    return ProfileStore.getDetails()?.clientSpocCompany;
  },
};

let clientAddresses: Array<ClientAddressModel> =
  new Array<ClientAddressModel>();
export const AccountStore = {
  setAddresses: (address: Array<ClientAddressModel>) => {
    clientAddresses = address;
  },
  getAddresses: () => {
    return clientAddresses;
  },
};

let loggedInClientInfo: UserDto;

export const LoggedInClientInfo = {
  setLoggedInClientInfo: (clientInfo: UserDto) => {
    loggedInClientInfo = clientInfo;
  },
  getLoggedInClientInfo: () => {
    return loggedInClientInfo;
  },
};

let stateList: Array<StateModel> = new Array<StateModel>();
export const StateStore = {
  setStateList: (list: Array<StateModel>) => {
    stateList = list;
  },
  getStateList: () => {
    return stateList;
  },
};

let cityListByState: Array<CityModel> = new Array<CityModel>();
export const CityStore = {
  setCityList: (cityList: Array<CityModel>) => {
    cityListByState = cityList;
  },
  getCityList: () => {
    return cityListByState;
  },
};

let allLocation: LocationRequestDto[];
let clientLocation: LocationRequestDto[];
export const LocationStore = {
  setAllLocation: (loc: LocationRequestDto[]) => {
    allLocation = loc;
  },
  getAllLocation: () => {
    return allLocation;
  },
  setLocation: (loc: LocationRequestDto[]) => {
    clientLocation = loc;
  },
  getLocation: () => {
    return clientLocation;
  },
};

let allPO: PurchaseOrderDto[];
let remarks: string;
export const PurchaseOrderStore = {
  setAllPurchaseOrder: (po: PurchaseOrderDto[]) => {
    allPO = po;
  },
  getAllPurchaseOrder: () => {
    return allPO;
  },
  setRemark: (remark: string) => {
    remarks = remark;
  },
  getRemark: () => {
    return remarks;
  },
};

let banners: BannerModel[];
let bannerPositionMap = new Map<PositionEnum, BannerModel[]>();
export const BannerStore = {
  setBanner: (banner: BannerModel[]) => {
    banners = banner;
  },
  getBanner: () => {
    return banners;
  },
  setBannerPositionMap: (positionMap: Map<PositionEnum, BannerModel[]>) => {
    bannerPositionMap = positionMap;
  },
  getBannerPositionMap: (position: PositionEnum) => {
    return bannerPositionMap.get(position) || new Array<BannerModel>();
  },
};
let brands: BrandListModel[];
export const BrandStore = {
  setBrandName: (brand: BrandListModel[]) => {
    brands = brand;
  },
  getBrand: () => {
    return brands;
  },
}
