import React, { useEffect, useState } from "react";
import {
  PurchaseOrderRequestDto,
  PurchaseOrderType,
} from "../../../Model/PurchaseOrderModel";
import { DateUtil } from "../../../Services/DateService/dateUtil";
import { OrderService } from "../../../Services/OrderService";
import { PurchaseOrderService } from "../../../Services/PurchaseOrderService";
import { messageConstants } from "../../../StringConstants";
import NewFooter from "../../PublicView/NewFooter/NewFooter";
import NewNavbar from "../../../Components/NavBar/NewNavbar";
import "./AddNewPo.css";
import { HashLink } from "react-router-hash-link";
import { PageTypeEnum } from "../../../Model/Enums/PageTypeEnums";
import { useNavigate } from "react-router";
import { showErrorToast, showInfoToast } from "../../../utils/Toastify/ToastifyHandler";

const AddNewPurchaseOrder = () => {
  const purchaseOrder = new PurchaseOrderService();
  const order = new OrderService();
  const date = new DateUtil();

  const [poNumber, setPoNumber] = useState<string>("");
  const [poAmount, setPoAmount] = useState<number>(0);

  const [poExpiryDate, setPoExpiryDate] = useState<Date>(
    new Date(date.getNextYearDate())
  );
  const [poDate, setPoDate] = useState<Date>(new Date());

  const [purchaseOrderFile, setPurchaseOrderFile] = useState<File | null>(null);
  const [purchaseOrderDocFile, setPurchaseOrderDocFile] = useState<File | null>(
    null
  );

  const [isItemBased, setIsItemBased] = useState<boolean>(false);
  const [selectedPoType, setSelectedPoType] = useState<string>("item-based");

  const selectPoTypeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPoType(event.target.value);
  };

  const textChangeHandlers = {
    getPoNumber: (
      e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
      let value = e.target.value;
      if (value && value.trim() !== "") {
        setPoNumber(value);
      } else {
        showErrorToast(messageConstants.CANNOT_EMPTY);
      }
    },
    getPoDate: (
      e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
      setPoDate(new Date(e.target.value));
    },
    getExpiryDate: (
      e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
      setPoExpiryDate(new Date(e.target.value));
    },
    getTotalAmount: (
      e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
      let value = e.target.value;
      if (!value && value.trim() === "") {
        showErrorToast(messageConstants.CANNOT_EMPTY);
      } else {
        setPoAmount(parseInt(e.target.value));
      }
    },
  };

  const downloadExcelFormatHandler = () => {
    order.downloadExcelFormat();
  };

  const selectExcelHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target.files;
    if (uploadedFile && uploadedFile[0]) {
      if (
        uploadedFile[0].type.includes("spreadsheetml") ||
        uploadedFile[0].type.includes("excel")
      ) {
        setPurchaseOrderFile(uploadedFile[0]);
      } else {
        showErrorToast(messageConstants.EXCEL_ALLOWED);
      }
    } else {
      setPurchaseOrderFile(null);
      showInfoToast(messageConstants.NO_FILES_SELECTED);
    }
  };

    const selectPdfHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const uploadedFile = e.target.files
        if (uploadedFile && uploadedFile[0]) {
            if (uploadedFile[0].type.includes('pdf')) {
                setPurchaseOrderDocFile(uploadedFile[0])
            }
            else
                showErrorToast(messageConstants.PDF_ALLOWED)
        }
        else {
            setPurchaseOrderDocFile(null)
            showInfoToast(messageConstants.NO_FILES_SELECTED);
        }
    };
    const navigate = useNavigate();
    const navigateToPurchaseOrderPage = () => {
        navigate('/newMyAccount')
    }

  const createPurchaseOrderHandler = () => {
    if (poNumber.trim().length === 0) {
      showErrorToast(messageConstants.CANNOT_EMPTY + " input");
      return;
    }
    if (!poDate) {
      showErrorToast(messageConstants.CANNOT_EMPTY + " podate");
      return;
    }
    if (!isItemBased && !poAmount) {
      showErrorToast(messageConstants.CANNOT_EMPTY + " poAmount");
      return;
    }
    if (isItemBased && !purchaseOrderFile) {
      showErrorToast(messageConstants.CANNOT_EMPTY + " excel");
      return;
    }
    if (!purchaseOrderDocFile) {
      showErrorToast(messageConstants.CANNOT_EMPTY + " pdf");
      return;
    }

    const newPurchaseOrder: PurchaseOrderRequestDto = {
      purchaseOrderDto: {
        poNo: poNumber,
        expDate: poExpiryDate.getTime(),
        poDate: poDate.getTime(),
        purchaseOrderType: isItemBased
          ? PurchaseOrderType.ITEM_WISE
          : PurchaseOrderType.AMOUNT_WISE,
        totalAmount: poAmount,
      },
      purchaseOrderDoc: purchaseOrderDocFile,
      purchaseOrder: purchaseOrderFile,
    };
    purchaseOrder.uploadPurchaseOrder(
      newPurchaseOrder,
      navigateToPurchaseOrderPage
    );
  };

  useEffect(() => {
    setIsItemBased(selectedPoType === "item-based");

    if (selectedPoType === "amount-based") {
      setPurchaseOrderFile(null);
    }
  }, [selectedPoType]);

  return (
    <div>
      <NewNavbar
        signIn={false}
        account={false}
        cart={false}
        searchBar={false}
        admin={false}
        getFilteredProductsFromNav={() => {}}
      />

      <div className="container">
        <div className="page_path border-bottom">
          <p>
            <HashLink to={PageTypeEnum.HOME}>Home</HashLink>
            <span>
              <i className="bi bi-chevron-right"></i>
            </span>
            <HashLink to={"/newMyAccount"}>My Account</HashLink>
            <span>
              <i className="bi bi-chevron-right"></i>
            </span>
            Add Purchase Order
          </p>
        </div>
        <h4 className="fw-bold mt-5 mb-2 mb-sm-0 me-3 me-sm-0 mt-4">
          Create New Purchase Order
        </h4>
        <div
          className="tab-pane fade show active"
          id="pills-AddPurchaseOrder"
          role="tabpanel"
          aria-labelledby="pills-AddPurchaseOrder-tab"
        >
          <form className="default_form mt-3 mt-md-4" action="">
            <div className="form-check form-check-inline mt-2">
              <input
                className="form-check-input"
                type="radio"
                name="radioOptions"
                id="item-based"
                value="item-based"
                checked={selectedPoType === "item-based"}
                onChange={selectPoTypeHandler}
              />
              <label className="form-check-label mt-2" htmlFor="item-based">
                Item-Based
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="radioOptions"
                id="amount-based"
                value="amount-based"
                checked={selectedPoType === "amount-based"}
                onChange={selectPoTypeHandler}
              />
              <label className="form-check-label" htmlFor="amount-based">
                Amount-Based
              </label>
            </div>{" "}
            <div className="row gx-3">
              <div className="col-sm-12">
                <div className="m-2">PO Number *</div>
                <input
                  className="w-100"
                  type="text"
                  placeholder="Enter PO Number *"
                  required
                  onChange={textChangeHandlers.getPoNumber}
                />
              </div>
              <div className="col-sm-6">
                <div className="m-2">PO Date*</div>
                <input
                  className="w-100"
                  type="date"
                  required
                  onChange={textChangeHandlers.getPoDate}
                  defaultValue={date.getTodayDate()}
                  max={date.getTodayDate()}
                />
              </div>
              <div className="col-sm-6">
                <div className="m-2">Valid Till</div>
                <input
                  className="w-100"
                  type="date"
                  onChange={textChangeHandlers.getExpiryDate}
                  defaultValue={""}
                  min={date.getTodayDate()}
                />
              </div>
              {isItemBased ? (
                <div className="col-sm-6">
                  <div className="m-2">
                    PO* (Select Excel)
                    <button
                      className="btn theme_btn download_sample"
                      onClick={downloadExcelFormatHandler}
                    >
                      Download Sample
                    </button>
                  </div>
                  <input
                    className="w-100"
                    type="file"
                    required
                    accept=".xlsx,.xls"
                    id="excelInput"
                    onChange={selectExcelHandler}
                  />
                </div>
              ) : (
                <div className="col-sm-6">
                  <div className="m-2">PO Amount *</div>
                  <input
                    className="w-100"
                    type="number"
                    placeholder="Enter PO Amount *"
                    required
                    onChange={textChangeHandlers.getTotalAmount}
                    defaultValue={0}
                  />
                </div>
              )}
              <div className="col-sm-6">
                <div className="m-2">PO Doc* (Select Pdf)</div>
                <input
                  className="w-100"
                  type="file"
                  required
                  accept="application/pdf"
                  id="pdfInput"
                  onChange={selectPdfHandler}
                />
              </div>
            </div>
          </form>
          <div className="row justify-content-center">
            <div className="col-auto">
              <button
                className="theme_btn px-4 mb-2 "
                onClick={createPurchaseOrderHandler}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
      <NewFooter />
    </div>
  );
};

export default AddNewPurchaseOrder;
