import * as ReactDOM from 'react-dom/client';
import AlertDialog from '../Components/Mui-Components/Dialog';
import ErrorDialog from '../Components/Error/ErrorDialog';

export const errorDialogHandler = (
  errorMessage ?: string,
) => {
  const element = document.getElementById('alert') as HTMLDivElement;
  const root = ReactDOM.createRoot(element);
  root.render(
    <AlertDialog title={'Error Occured'} open={true} content={<ErrorDialog errorMessage={errorMessage}/>} buttons={[]}/>
  );
};