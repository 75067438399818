import { Step, StepLabel } from "@mui/material"
import Box from "@mui/material/Box"
import Stepper from "@mui/material/Stepper"
import { createTrackOrderStausElement } from "../../../utils/StatusBox"
import { OrderStatusEnum } from "../../../Model/Enums/OrderStatusEnums"
import { useEffect, useState } from "react"
import { PaymentStatuEnum, PaymentTypeEnum, TrackOrderStatusEnum } from "../../../Model/Enums/PaymentTypeEnum"

interface props {
    Orderstatus: OrderStatusEnum
    Paymentstatus: string
    paymentMode: string
}

const TrackOrderStatus = ({ Orderstatus, Paymentstatus, paymentMode }: props) => {
    // if order is splitted
    const orderStepsSplit = [
        TrackOrderStatusEnum.PENDING,
        TrackOrderStatusEnum.PROCESSING,
        TrackOrderStatusEnum.SPLIT,
        TrackOrderStatusEnum.PARTIAL_DELIVER,
        TrackOrderStatusEnum.DELIVERED,
        TrackOrderStatusEnum.COMPLETED,
    ]
    // if order is not splitted
    const orderStepsNotSplit = [
        TrackOrderStatusEnum.PENDING,
        TrackOrderStatusEnum.PROCESSING,
        TrackOrderStatusEnum.READY_TO_DELIVER,
        TrackOrderStatusEnum.DELIVERED,
        TrackOrderStatusEnum.COMPLETED
    ]


    const [orderTrack, setOrderTrack] = useState<TrackOrderStatusEnum[]>(orderStepsNotSplit)
    const [activeStep, setAciveStep] = useState(0)
    

    const setOrderStatus = () => {
        //setOrder status if order is splitted
        if (Orderstatus === OrderStatusEnum.SPLIT || Orderstatus === OrderStatusEnum.PARTIAL_DELIVER) {
            setOrderTrack(orderStepsSplit)
            //check payment Mode 
            if (paymentMode === PaymentTypeEnum.CASH) {
                const order = [...orderStepsSplit]
                order.splice(order.length - 1, 0, TrackOrderStatusEnum.PAYMENT)
                setOrderTrack(order)
            }

        }
        else {
            // order is not splited
            setOrderTrack(orderStepsNotSplit)
            //check payment Mode 
            if (paymentMode === PaymentTypeEnum.CASH) {
                const order = [...orderStepsNotSplit]
                order.splice(order.length - 1, 0, TrackOrderStatusEnum.PAYMENT)
                setOrderTrack(order)
            }
        }


    }

    const findActiveOrderStatusIndex = () => {
        if(paymentMode === PaymentTypeEnum.CASH && Paymentstatus === PaymentStatuEnum.SUCCESS){
            setAciveStep(orderTrack.length-1)
        }
        const currentStep = orderTrack.findIndex((item: string) => item === Orderstatus);
        setAciveStep(currentStep)
      }

    useEffect(() => {
        findActiveOrderStatusIndex()
        
    }, [orderTrack])
    useEffect(()=>{
        setOrderStatus()
    },[])

   

    
    return (

        <div className="col border rounded p-4  content-md-center">
            <div>
                <Box sx={{ width: '100%' }}>
                    <Stepper alternativeLabel activeStep={activeStep}>
                        {orderTrack.map((label) => (
                            <Step>
                                <StepLabel

                                >{createTrackOrderStausElement(label)}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
            </div>
        </div>

    )
}
export default TrackOrderStatus