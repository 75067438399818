import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { ApiCallResponse, PageEventTypeEnum } from "../../../Model/ApiCallResponse";
import { ApiCallState } from "../../../Model/Enums/ApiCallState";
import { ProfileStore, apiLoadingSubject } from "../../../Store";
import { messageConstants } from "../../../StringConstants";
import { errorDialogHandler } from "../../../utils/ErrorDialogHandler";
import { UserDto } from "../../../Model/UserModel";
import { ClientInfoService } from "../../../Services/ClientInfoService";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const NewProfile = () => {
    const [profileDetails, setProfileDetails] = useState<UserDto>();
    

    const profileData = {
        name: profileDetails?.name,
        organisationName: profileDetails?.name,
        email: profileDetails?.email,
        phoneNumber: profileDetails?.mobile,
        city: profileDetails?.city,
        role: profileDetails?.role,
        address: `${profileDetails?.locationName} ${profileDetails?.address} ${profileDetails?.city} ${profileDetails?.state}`,
        shippingAddress: profileDetails?.shippingAddress,
        billingAddress: profileDetails?.businessAddress,
        clientSpocCompany: profileDetails?.clientSpocCompany
      }
  
    useEffect(() => {
        const clientInfoService = new ClientInfoService()

        let profileSubscriber: Subscription;
        if (ProfileStore.getDetails() && ProfileStore.getDetails().name) {
          setProfileDetails(ProfileStore.getDetails())
          window.dispatchEvent(new Event('resize'))
        }
        else {
          clientInfoService.getLoggedInClientInfo();
          window.dispatchEvent(new Event('resize'))
    
        }
        profileSubscriber = apiLoadingSubject.subscribe((response: ApiCallResponse) => {
          if (PageEventTypeEnum.PROFILE === response.eventType) {
            switch (response.apiCallState) {
              case ApiCallState.LOADING:
                break;
              case ApiCallState.LOADING_SUCCESS:
                setProfileDetails(ProfileStore.getDetails());
                break;
              case ApiCallState.LOADING_ERROR:
                errorDialogHandler(messageConstants.REFRESH)
                break;
            }
    
          }
        })
    
        return (() => {
          if (profileSubscriber) {
            profileSubscriber.unsubscribe();
          }
        })
      }, [])
  return (
    <>
      <p className="mb-2 fw-bold">Account Details</p>
      <form
        className="default_form mt-3 mt-md-4"
        action=""
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="row gx-3">
          <div className="col-sm-6">
            <label>Name</label>
            <input
              className="w-100"
              type="text"
              required
              disabled
              placeholder={profileData.clientSpocCompany}
            />
          </div>
          <div className="col-sm-6">
            <label>Organization</label>
            <input
              className="w-100"
              type="text"
              required
              disabled
              placeholder={profileData.organisationName}
            />
          </div>
          <div className="col-sm-6">
            <label>Contact No.</label>

            <input
              className="w-100"
              type="tel"
              required
              disabled
              placeholder={profileData.phoneNumber}
            />
          </div>
          <div className="col-sm-6">
            <label>E-mail Address</label>

            <input
              className="w-100"
              type="email"
              required
              disabled
              placeholder={profileData.email}
            />
          </div>
          <div className="col-sm-6">
            <label>Address</label>
              <textarea
                className="w-100"
                style={{resize: "none"}}
                required
                disabled
                placeholder={profileData.address}
                rows={(profileData.address).length/40}
              />
          </div>
        </div>
      </form>
    </>
  );
};

export default NewProfile;
