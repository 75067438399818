// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { getMessaging } from "firebase/messaging/sw";
import firebase from "firebase/compat/app";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBQ3E7HMhnly7ivA00bNrz_iddwIYVB4Bw",
    authDomain: "nutritap-b2b.firebaseapp.com",
    projectId: "nutritap-b2b",
    storageBucket: "nutritap-b2b.appspot.com",
    messagingSenderId: "772768832804",
    appId: "1:772768832804:web:cec6006102e15a1a1a67b1",
    measurementId: "G-V7HGW0W76G"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const vapidKey = "BOoDNLT4ZOky-R6zKqILgXkt3amYPyE--7-B7bUsqsxbsXXfQwYQ6JA1K3ZJdAWyX_e7GZu9c7-8_vOa6XfkMYI";
export const messaging = getMessaging(app);
export const performance = getPerformance(app);

export type EventName = firebase.analytics.EventName;
export type EventParams = firebase.analytics.EventParams;