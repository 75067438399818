import { createMessage } from "../CreateMessage";
import { PageEventTypeEnum } from "../Model/ApiCallResponse";
import { LocationRequestDto } from "../Model/CartModel";
import { ActionTypeEnum } from "../Model/Enums/ActionTypeEnum";
import { ApiResponseStatusEnum } from "../Model/Enums/ApiCallState";
import { ApiResponseModel } from "../Model/NutritapResponseModel";
import { LocationStore, PrincipleStore } from "../Store";
import { messageConstants } from "../StringConstants";
import { errorDialogHandler } from "../utils/ErrorDialogHandler";
import {
  ApiTypeEnum,
  ParameterEnum,
  SubPathEnum,
} from "../Model/Enums/ApiConstantEnum";
import { ApiUrlBuilder } from "../utils/Stringbuilder";
import { apiCaller } from "./ApiCallService";
import { PublishEventService } from "./PublishEventService";
import { showErrorToast, showSuccessToast } from "../utils/Toastify/ToastifyHandler";

export class UserLocationMappingService {
  private userId: number | undefined = PrincipleStore.getUserId();
  private userRole: string = PrincipleStore.getRole();

  getAllLocation(id: number): void {
    const publishEvent = new PublishEventService();
    publishEvent.loading(PageEventTypeEnum.LOCATIONS);
    const userLocationMappingUrl = new ApiUrlBuilder(
      ApiTypeEnum.USER_LOCATION_MAPPING
    );
    userLocationMappingUrl.addSubPath(SubPathEnum.GET_ALL);
    userLocationMappingUrl.addParameter(ParameterEnum.IS_ACTIVE, true);
    userLocationMappingUrl.addParameter(ParameterEnum.ROLE, this.userRole);
    userLocationMappingUrl.addParameter(ParameterEnum.userID, id);
    const url = userLocationMappingUrl.buildURL();
    // const url = `${B2BConstants.BASE_URL}${ApiConstants.LOCATION.GET_ALL}&role=${this.userRole}&userId=${id}`;
    apiCaller
      .getResponseFromServer(url)
      .then((res: ApiResponseModel) => {
        if (!res) {
          showErrorToast(messageConstants.FAILED);
        }
        if (res.status === ApiResponseStatusEnum.SUCCESS || res.success) {
          LocationStore.setAllLocation(res.dtoList);
          publishEvent.loadingSuccess(PageEventTypeEnum.LOCATIONS);
        } else {
          publishEvent.loadingError(PageEventTypeEnum.LOCATIONS);
        }
      })
      .catch(() => {
        publishEvent.loadingError(PageEventTypeEnum.LOCATIONS);
        errorDialogHandler(messageConstants.FAILED);
      });
  }

  getLocationsByClientId(id: string): void {
    const publishEvent = new PublishEventService();
    const userLocationMappingUrl = new ApiUrlBuilder(
      ApiTypeEnum.USER_LOCATION_MAPPING
    );
    userLocationMappingUrl.addSubPath(SubPathEnum.GET);
    userLocationMappingUrl.addParameter(ParameterEnum.ID, id);
    const url = userLocationMappingUrl.buildURL();

    apiCaller
      .getResponseFromServer(url)
      .then((res: ApiResponseModel) => {
        if (!res) {
          showErrorToast(messageConstants.FAILED);
        }
        if (res.status === ApiResponseStatusEnum.SUCCESS || res.success) {
          const clientLocations: LocationRequestDto[] = res.dtoList;
          LocationStore.setAllLocation(clientLocations);
          publishEvent.loadingSuccess(PageEventTypeEnum.LOCATIONS);
        } else {
          publishEvent.loadingError(PageEventTypeEnum.LOCATIONS);
        }
      })
      .catch(() => {
        errorDialogHandler(messageConstants.FAILED);
      });
  }

  addNewLocationMapping(newLocation: LocationRequestDto): void {
    const publishEvent = new PublishEventService();
    const userLocationMappingUrl = new ApiUrlBuilder(
      ApiTypeEnum.USER_LOCATION_MAPPING
    );
    userLocationMappingUrl.addSubPath(SubPathEnum.SAVE);
    const url = userLocationMappingUrl.buildURL();

    if (this.userId) {
      newLocation.userId = this.userId;
    }

    const body = JSON.stringify(newLocation);
    apiCaller
      .postResponseToServer(url, body)
      .then((res: ApiResponseModel) => {
        if (!res) {
          showErrorToast(messageConstants.FAILED);
        }
        if (res.status === ApiResponseStatusEnum.SUCCESS || res.success) {
          // showSuccessToast(createMessage.successMessage(ActionTypeEnum.ADD));
          publishEvent.loadingSuccess(PageEventTypeEnum.LOCATIONS);
          publishEvent.reloadPage(PageEventTypeEnum.LOCATIONS);
        } else {
          showErrorToast(createMessage.errorMessage(ActionTypeEnum.ADD));
        }
      })
      .catch(() => {
        errorDialogHandler(messageConstants.FAILED);
      });
  }

  updateLocation(updateLocation: LocationRequestDto): void {
    const publishEvent = new PublishEventService();
    const userLocationMappingUrl = new ApiUrlBuilder(
      ApiTypeEnum.USER_LOCATION_MAPPING
    );
    userLocationMappingUrl.addSubPath(SubPathEnum.UPDATE);
    const url = userLocationMappingUrl.buildURL();
    const body = JSON.stringify(updateLocation);
    apiCaller
      .postResponseToServer(url, body)
      .then((res: ApiResponseModel) => {
        if (!res) {
          showErrorToast(messageConstants.FAILED);
        }
        if (res.status === ApiResponseStatusEnum.SUCCESS || res.success) {
          showSuccessToast(createMessage.successMessage(ActionTypeEnum.EDIT));
          publishEvent.reloadPage(PageEventTypeEnum.LOCATIONS);
        } else {
          showErrorToast(createMessage.errorMessage(ActionTypeEnum.EDIT));
        }
      })
      .catch(() => {
        errorDialogHandler(messageConstants.FAILED);
      });
  }

  deleteLocation(id: number): void {
    const publishEvent = new PublishEventService();
    const userLocationMappingUrl = new ApiUrlBuilder(
      ApiTypeEnum.USER_LOCATION_MAPPING
    );
    userLocationMappingUrl.addSubPath(SubPathEnum.DELETE);
    userLocationMappingUrl.addSubPath(id);
    const url = userLocationMappingUrl.buildURL();
    const body = "";

    // const url = `${B2BConstants.BASE_URL}${ApiConstants.LOCATION.DELETE}id=${id}`;

    apiCaller
      .postResponseToServer(url, body)
      .then((res: ApiResponseModel) => {
        if (!res) {
          showErrorToast(messageConstants.FAILED);
        }
        if (res.status === ApiResponseStatusEnum.SUCCESS || res.success) {
          showSuccessToast(
            createMessage.successMessage(ActionTypeEnum.DELETE)
          );
          publishEvent.reloadPage(PageEventTypeEnum.LOCATIONS);
        } else {
          showErrorToast(createMessage.errorMessage(ActionTypeEnum.EDIT));
        }
      })
      .catch(() => {
        errorDialogHandler(messageConstants.FAILED);
      });
  }
}
